import { FormattedMessage, useIntl } from "react-intl";

import Button from "common/core/button";
import { Hr } from "common/core/horizontal_rule";
import { Select } from "common/core/form/select";
import { defaultRequiredMessage, isAriaInvalid } from "common/core/form/error";
import { useForm } from "common/core/form";
import { AutomaticFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { useMutation } from "util/graphql";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import Styles from "./updated_onboarding.module.scss";
import UpdateOrganizationMutation from "../update_organization_mutation.graphql";
import type { Slideshow_viewer_user_organization as Organization } from "../slideshow_query.graphql";

type FormValues = {
  industry: string | null;
  industryOther: string;
};
const OTHER_INDUSTRY = "Other";

export function IndustryModal({
  industryOptions,
  organization,
  onNext,
}: {
  industryOptions: string[];
  organization: Organization;
  onNext: () => void;
}) {
  const updateOrganizationMutateFn = useMutation(UpdateOrganizationMutation);
  const intl = useIntl();
  const form = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      industry: null,
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form;
  const industryValue = watch("industry");

  const saveIndustry = (formValues: FormValues) => {
    const industry =
      formValues.industry === OTHER_INDUSTRY ? formValues.industryOther : formValues.industry;

    updateOrganizationMutateFn({
      variables: {
        input: {
          id: organization.id,
          industry,
        },
      },
    }).then(() => {
      segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_INDUSTRY, {
        industry,
        other_industry: formValues.industry === OTHER_INDUSTRY,
        skipped: false,
      });
      onNext();
    });
  };

  return (
    <>
      <div className={`${Styles.slideContent} ${Styles.modalContent}`}>
        <AutomaticFormRow<FormValues>
          label={
            <FormattedMessage
              id="3352ba20-b41b-474c-b853-d5f99bfd8889"
              defaultMessage="Which industry best describes {orgName}?"
              values={{ orgName: organization.name }}
            />
          }
          form={form}
          name="industry"
          registerOptions={{ required: defaultRequiredMessage(intl) }}
          fullWidth
        >
          <Select
            items={industryOptions.map((i) => {
              return { value: i, label: i };
            })}
            aria-invalid={isAriaInvalid(errors.industry)}
          />
        </AutomaticFormRow>

        {industryValue === OTHER_INDUSTRY && (
          <>
            <AutomaticFormRow<FormValues>
              label={
                <FormattedMessage
                  id="af55332b-9519-4f0e-afba-8cd8ff9d8f41"
                  defaultMessage="Enter industry"
                />
              }
              form={form}
              name="industryOther"
              registerOptions={{ required: defaultRequiredMessage(intl) }}
              fullWidth
              as={TextInput}
            />
          </>
        )}
      </div>
      <Hr />
      <div className={Styles.buttons}>
        <Button
          key="skip"
          buttonColor="action"
          variant="secondary"
          automationId="industry-modal-skip-btn"
          onClick={() => {
            segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_INDUSTRY, {
              skipped: true,
            });
            onNext();
          }}
        >
          <FormattedMessage id="9e185784-919b-404c-bb50-cdf0cbf751ef" defaultMessage="Skip" />
        </Button>
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isSubmitting}
          automationId="industry-modal-primary-btn"
          onClick={handleSubmit(saveIndustry)}
        >
          <FormattedMessage id="2a46d6a7-e98f-484a-b792-b3b8c3d697ed" defaultMessage="Continue" />
        </Button>
      </div>
    </>
  );
}
