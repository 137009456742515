import { differenceInCalendarDays, isPast } from "date-fns";
import { memo, type ReactNode, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";
import CertificateError from "assets/images/signer/id-failure.svg";
import CertificateExpiring from "assets/images/signer/id-right.svg";
import WorkflowModal from "common/modals/workflow_modal";
import AppSubdomains from "constants/app_subdomains";
import Button from "common/core/button";
import { CertificateMigrationStatus } from "graphql_globals";
import { redirectToSubdomain } from "util/application_redirect";
import { shouldMigrateToProofCertificate } from "common/notary/profile_wizard/section/digital_certificate/proof";

import type { NotaryCertExpired_user as User } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

const CERT_MODAL_DISMISSED_15_DAYS_KEY = "cert-modal-dismissed-15-days";
const CERT_MODAL_DISMISSED_5_DAYS_KEY = "cert-modal-dismissed-5-days";
const CERT_MODAL_DISMISSED_1_DAY_KEY = "cert-modal-dismissed-1-day";

type Props = {
  children: ReactNode;
  viewer: { user: User | null };
};

function ExpiredCertBlocker({ viewer, children }: Props) {
  const notaryProfile = viewer.user?.notaryProfile;
  const certificateExpiration =
    notaryProfile?.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE
      ? notaryProfile.proofCertificate?.validTo
      : notaryProfile?.certExpiry;
  const isCertificateExpired = certificateExpiration && isPast(new Date(certificateExpiration));
  const shouldMigrateNotaries =
    notaryProfile &&
    shouldMigrateToProofCertificate(
      notaryProfile.usState.proofCertEnabled,
      certificateExpiration,
      notaryProfile.certificateMigrationStatus,
    );
  const [showModal, setShowModal] = useState(isCertificateExpired);
  const daysUntilExpiry = certificateExpiration
    ? differenceInCalendarDays(new Date(certificateExpiration), new Date())
    : 0;
  const expiryBetween15and6Days = daysUntilExpiry <= 15 && daysUntilExpiry >= 6;
  const expiryBetween5and2Days = daysUntilExpiry <= 5 && daysUntilExpiry >= 2;
  const expiryIn1Day = daysUntilExpiry === 1;

  useEffect(() => {
    const dismissed15Days = localStorage.getItem(CERT_MODAL_DISMISSED_15_DAYS_KEY);
    const dismissed5Days = localStorage.getItem(CERT_MODAL_DISMISSED_5_DAYS_KEY);
    const dismissed1Day = localStorage.getItem(CERT_MODAL_DISMISSED_1_DAY_KEY);

    if (expiryBetween15and6Days && !dismissed15Days) {
      setShowModal(true);
    } else if (expiryBetween5and2Days && !dismissed5Days) {
      setShowModal(true);
    } else if (expiryIn1Day && !dismissed1Day) {
      setShowModal(true);
    }
  }, []);

  const handleDismiss = () => {
    if (expiryBetween15and6Days) {
      localStorage.setItem(CERT_MODAL_DISMISSED_15_DAYS_KEY, "true");
    } else if (expiryBetween5and2Days) {
      localStorage.setItem(CERT_MODAL_DISMISSED_5_DAYS_KEY, "true");
    } else if (expiryIn1Day) {
      localStorage.setItem(CERT_MODAL_DISMISSED_1_DAY_KEY, "true");
    }
    setShowModal(false);
  };

  return (
    <>
      {showModal && shouldMigrateNotaries && (
        <WorkflowModal
          title={
            <h1 className={Styles.container}>
              <img
                className={Styles.image}
                src={isCertificateExpired ? CertificateError : CertificateExpiring}
                alt=""
              />
              {isCertificateExpired ? (
                <FormattedMessage
                  id="1cd093e9-73a3-4780-93ac-12d09e122772"
                  defaultMessage="Your digital certificate has expired"
                />
              ) : (
                <FormattedMessage
                  id="5d3708b2-ce29-4a0e-8512-75a5b4a5eae0"
                  defaultMessage="Your digital certificate expires in {daysUntilExpiry, plural, one{1 day} other{# days}}"
                  values={{ daysUntilExpiry }}
                />
              )}
            </h1>
          }
          buttons={[
            <div key="confirm-publish">
              <Button
                className={Styles.notNowButton}
                variant="tertiary"
                buttonColor="dark"
                onClick={() => {
                  handleDismiss();
                }}
              >
                <FormattedMessage
                  id="e740f22f-6d27-4484-8498-bddf63ded0bc"
                  defaultMessage="Not now"
                />
              </Button>
              <Button
                className={Styles.verifyButton}
                buttonColor="action"
                variant="primary"
                onClick={() => {
                  handleDismiss();
                  redirectToSubdomain(
                    AppSubdomains.customer,
                    "/settings/notary/profile/identity-verification",
                  );
                }}
              >
                <FormattedMessage
                  id="5281a76f-2cbd-44ac-af78-11a83acdca6a"
                  defaultMessage="Verify identity"
                />
              </Button>
            </div>,
          ]}
        >
          <div className={Styles.textContainer}>
            <h2 className={Styles.subheading}>
              {!isCertificateExpired && (
                <FormattedMessage
                  id="bea47d11-fbc9-453e-a974-1900ae4a0989"
                  defaultMessage="Proof Digital Certificate"
                />
              )}
            </h2>
            <div className={Styles.paragraph}>
              {isCertificateExpired ? (
                <>
                  <p className={Styles.text}>
                    <FormattedMessage
                      id="b566370a-3666-41f9-8fdd-cb726cdba38d"
                      defaultMessage="Generate a new <b>Proof Digital Certificate</b> for notaries and continue seamlessly notarizing on the platform."
                      values={{ b }}
                    />
                  </p>
                  <p className={Styles.text}>
                    <FormattedMessage
                      id="2543503f-ca3e-4069-a22e-04d550ab5cdf"
                      defaultMessage="Follow these steps to continue:"
                    />
                  </p>
                </>
              ) : (
                <p className={Styles.text}>
                  <FormattedMessage
                    id="2ec3f1ab-9af1-49eb-9eda-414a52518c14"
                    defaultMessage="Don’t disrupt your service. Generate a new <b>Proof Digital Certificate</b> for notaries and continue seamlessly notarizing on the platform. Follow these steps to continue:"
                    values={{ b }}
                  />
                </p>
              )}
            </div>
            <ol className={Styles.stepsContainer}>
              <FormattedMessage
                id="6a0b28f1-3f27-4d8c-9f77-2f3e97e7f8af"
                defaultMessage="Verify your identity"
                tagName="li"
              />
              <FormattedMessage
                id="c7d60f78-6f72-465e-8f8e-38b70dd1397b"
                defaultMessage="Generate your certificate"
                tagName="li"
              />
            </ol>
          </div>
        </WorkflowModal>
      )}
      {children}
    </>
  );
}

export default memo(ExpiredCertBlocker);
