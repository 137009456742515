import { type UsState, makeFontDesc } from ".";
import { wrapText, FULL_SEAL_WIDTH } from "./text";

const CENTER_TEXT = 495;

type CityParams = {
  context: CanvasRenderingContext2D;
  city: string;
  usState: UsState;
};

type DrawCityParams = {
  context: CanvasRenderingContext2D;
  city: string;
  textAlign?: CanvasTextAlign;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
  maxWidth?: number;
  lineHeight?: number;
};

function drawCity({
  context,
  city,
  textAlign = "center",
  fontSize = 20,
  bold = false,
  x = CENTER_TEXT,
  y = 187,
  maxWidth = FULL_SEAL_WIDTH,
  lineHeight = 30,
}: DrawCityParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  wrapText({ context, text: city, x, y, maxWidth, lineHeight });
}

export function placeCity({ context, city, usState }: CityParams) {
  switch (usState) {
    case "MT":
      city = `Residing at ${city}, Montana`;
      return drawCity({
        context,
        city,
        bold: false,
        x: 490,
        y: 169,
        fontSize: 24,
      });
    case "MO":
      return drawCity({
        context,
        city: `City of ${city} - State of Missouri`,
        bold: false,
        x: 360,
        y: 150,
        fontSize: 28,
      });
    case "NJ":
      return drawCity({
        context,
        city: `STATE OF NEW JERSEY`,
        bold: false,
        x: 360,
        y: 160,
        fontSize: 34,
      });
    case "WV":
      return drawCity({
        context,
        city: `STATE OF WEST VIRGINIA`,
        bold: false,
        x: 460,
        y: 90,
        fontSize: 18,
      });
    case "KS":
    case "AZ":
    case "CO":
    case "MI":
    case "PA":
    case "TN":
    case "TX":
    case "VA":
    case "FL":
    case "NV":
    case "IN":
    case "IA":
    case "ID":
    case "KY":
    case "MN":
    case "ND":
    case "NE":
    case "OH":
    case "OK":
    case "MD":
    case "WA":
    case "AK":
    case "NY":
    case "WI":
    case "NH":
    case "AR":
      return;

    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
