import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useEffect } from "react";

import { SignerCapacityTypes } from "graphql_globals";
import { Select } from "common/core/form/select";
import Button from "common/core/button";
import { TextInput } from "common/core/form/text";
import { representativeSignerTypeLabels } from "util/representative_signers";
import { useFieldArray, useWatch } from "common/core/form";
import { AutomaticFormRow } from "common/core/form/layout";
import { isAriaInvalid, useNestedError } from "common/core/form/error";

import Styles from "./index.module.scss";
import { type Form, type FormCapacity, RECIPIENTS } from "..";

const MESSAGES = defineMessages({
  representativeOf: {
    id: "6a2a9acb-0231-4bff-9131-82c90973a4cd",
    defaultMessage: "Signing on behalf of",
  },
  type: {
    id: "d7abf2e9-08b9-47a9-8085-f0df12f4fafa",
    defaultMessage: "Type",
  },
  capacityType: {
    id: "0ab137cb-d902-494d-a7bb-373e36a622e7",
    defaultMessage: "Type of capacity",
  },
  capacity: {
    id: "3000972f-66ec-4b4e-863b-0c4b396647d8",
    defaultMessage: "Capacity",
  },
  capacityError: {
    id: "a9fa2dca-8d7d-46ac-a316-435fa0f8489e",
    defaultMessage: "Capacity is required",
  },
  removeCapacity: {
    id: "28d79ee7-5026-4a62-9354-caa5626b95b1",
    defaultMessage: "Remove capacity",
  },
  representativeOfError: {
    id: "bd7d78a6-64a0-4396-b6ec-53deaa98073c",
    defaultMessage: "Signing on behalf of is required",
  },
  capacityTypeError: {
    id: "43a213b2-f246-493d-83be-8fd577ae6e20",
    defaultMessage: "Capacity type is required",
  },
});

const representativeSignerItems = (() => {
  const typeLabels = Object.keys(
    representativeSignerTypeLabels,
  ) as (keyof typeof representativeSignerTypeLabels)[];

  return typeLabels.map((capacityType) => {
    return {
      value: capacityType,
      label: representativeSignerTypeLabels[capacityType],
    };
  });
})();

function Capacity({
  capacity,
  capacityIndex,
  recipientIndex,
  form,
  onRemove,
  disabled,
}: {
  capacity: FormCapacity & { id: string };
  capacityIndex: number;
  recipientIndex: number;
  form: Form;
  disabled: boolean;
  onRemove?: (i: number) => void;
}) {
  const intl = useIntl();
  const autoIdPrefix = `signerDetails[${recipientIndex}].capacities[${capacityIndex}]`;
  const fieldPrefix = `${RECIPIENTS}.${recipientIndex}.capacities.${capacityIndex}` as const;
  const capacityType = useWatch({
    control: form.control,
    name: `${fieldPrefix}.capacityType`,
  });
  const capacityTypeError = useNestedError({
    control: form.control,
    name: `${fieldPrefix}.capacityType`,
  });

  const showCapacityField =
    capacityType === SignerCapacityTypes.OTHER ||
    capacityType === SignerCapacityTypes.CORPORATE_OFFICER;

  useEffect(() => {
    // Unregister capacity field when capacity type changes
    // When other, capacity is required, when corporate officer, capacity is optional
    if (capacityType === SignerCapacityTypes.OTHER) {
      return;
    }
    form.unregister(`${fieldPrefix}.capacity`);
  }, [capacityType]);

  return (
    <>
      <div
        className={Styles.capacityWrapper}
        key={capacity.id}
        data-automation-id={`recipient-${recipientIndex}-capacity-${capacityIndex}`}
      >
        <AutomaticFormRow
          disabled={disabled}
          fullWidth
          required
          form={form}
          name={`${fieldPrefix}.representativeOf`}
          label={intl.formatMessage(MESSAGES.representativeOf)}
          as={TextInput}
          data-automation-id={`${autoIdPrefix}-representativeOf`}
          registerOptions={{
            required: intl.formatMessage(MESSAGES.representativeOfError),
          }}
        />

        <div className={Styles.selectWrapper}>
          <div className={Styles.selectWrapperInner}>
            <strong className={Styles.asText}>
              <FormattedMessage id="93591a56-5283-411e-b340-858449f36969" defaultMessage="as" />
            </strong>

            <AutomaticFormRow
              disabled={disabled}
              fullWidth
              form={form}
              required
              data-automation-id={`${autoIdPrefix}-capacityType`}
              name={`${fieldPrefix}.capacityType`}
              label={intl.formatMessage(MESSAGES.type)}
              aria-label={intl.formatMessage(MESSAGES.capacityType)}
              placeholder={intl.formatMessage(MESSAGES.capacityType)}
              registerOptions={{
                required: intl.formatMessage(MESSAGES.capacityTypeError),
              }}
            >
              <Select
                aria-invalid={isAriaInvalid(capacityTypeError)}
                items={representativeSignerItems}
              />
            </AutomaticFormRow>
          </div>
        </div>

        {showCapacityField && (
          <AutomaticFormRow
            disabled={disabled}
            fullWidth
            form={form}
            name={`${fieldPrefix}.capacity`}
            label={intl.formatMessage(MESSAGES.capacity)}
            as={TextInput}
            required={capacityType === SignerCapacityTypes.OTHER}
            registerOptions={{
              required:
                capacityType === SignerCapacityTypes.OTHER &&
                intl.formatMessage(MESSAGES.capacityError),
            }}
          />
        )}
        {onRemove && (
          <Button
            disabled={disabled}
            className={Styles.removeButton}
            buttonSize="condensed"
            buttonColor="danger"
            variant="tertiary"
            withIcon={{ name: "delete", placement: "left" }}
            onClick={() => onRemove(capacityIndex)}
            aria-label={intl.formatMessage(MESSAGES.removeCapacity)}
          >
            <FormattedMessage id="ba6be099-aa32-4040-910e-8b6c0fecb43c" defaultMessage="Remove" />
          </Button>
        )}
      </div>
    </>
  );
}

export function Capacities({
  form,
  index,
  disabled,
}: {
  form: Form;
  index: number;
  disabled: boolean;
}) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `${RECIPIENTS}.${index}.capacities`,
  });

  return (
    <>
      {fields.map((capacity, i) => {
        return (
          <Capacity
            key={`${capacity.capacityType}-${i}`}
            disabled={disabled}
            capacity={capacity}
            recipientIndex={index}
            capacityIndex={i}
            form={form}
            onRemove={fields.length > 1 ? () => remove(i) : undefined}
          />
        );
      })}
      <Button
        disabled={disabled}
        buttonSize="condensed"
        buttonColor="action"
        variant="tertiary"
        onClick={() => {
          append({
            capacityId: undefined,
            representativeOf: "",
            capacityType: undefined,
            capacity: "",
          });
        }}
        withIcon={{ name: "add-1", placement: "left" }}
      >
        <FormattedMessage id="23f56399-d201-45c3-8821-753ca6cbe7f2" defaultMessage="Add capacity" />
      </Button>
    </>
  );
}
