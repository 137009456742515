import type { ComponentProps } from "react";
import { type InjectedFormProps, reduxForm, type FormErrors } from "redux-form";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import TosV2 from "common/tos";
import { validateEmailFormat, validatePassword } from "validators/account";
import { validatePresence } from "validators/form";
import { composeValidators } from "util/form";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { useMatchScreenClass } from "common/core/responsive";

import NotarySignupFields from "./fields";
import Styles from "./index.module.scss";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  businessName?: string;
  state: string;
};
type Props = {
  onCommit: (formValues: FormValues) => void;
  signupUsStates: ComponentProps<typeof NotarySignupFields>["usStates"];
};
type InjectedProps = InjectedFormProps<FormValues, Props>;
type InnerProps = InjectedProps & Props;

function handleSubmitFail(errors: FormErrors<FormData>) {
  if (Object.keys(errors).includes("password")) {
    segmentTrack(EVENT.INVALID_PASSWORD_SUBMITTED);
  }
}

const validate = composeValidators(
  validateEmailFormat({ field: "email" }),
  validatePassword({ field: "password", label: "Password" }),
  validatePresence({ field: "firstName", label: "First name" }),
  validatePresence({ field: "lastName", label: "Last name" }),
  validatePresence({ field: "state", label: "Commissioning US State" }),
  validateEmailFormat({ field: "referrerEmail", label: "Referrer Email" }),
);

function NotarySignupForm({
  invalid,
  submitting,
  submitSucceeded,
  onCommit,
  handleSubmit,
  signupUsStates,
}: InnerProps) {
  const outstanding = submitting || submitSucceeded;
  const isExtraSmall = useMatchScreenClass("xs");

  return (
    <form
      className={Styles.form}
      data-automation-id="signup-form"
      autoComplete="off"
      onSubmit={handleSubmit(onCommit)}
    >
      <div className={Styles.inputs}>
        <NotarySignupFields usStates={signupUsStates} />
      </div>

      <TosV2
        size={isExtraSmall ? "defaultSize" : "small"}
        className={Styles.termsOfService}
        actionText={
          <FormattedMessage
            id="264fd9a8-a0ce-4f14-b5d4-18691861e9ce"
            defaultMessage="By clicking 'Sign up' and continuing,"
          />
        }
        underlined
      />

      <Button
        type="submit"
        disabled={outstanding || invalid}
        automationId="create-account-button"
        buttonColor="action"
        buttonSize="large"
        variant="primary"
        fullwidth
        className={Styles.emailButton}
      >
        <FormattedMessage
          id="aeafcb30-06a6-4a96-8fb2-662e9c31bf40"
          defaultMessage="{outstanding, select, true{Creating...} other{Sign up}}"
          values={{ outstanding }}
        />
      </Button>
    </form>
  );
}

export default reduxForm<FormValues, Props>({
  form: "notarySignup",
  validate,
  onSubmitFail: handleSubmitFail,
})(NotarySignupForm);
