import { memo, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryCapacityType } from "graphql_globals";
import FormattedPrice from "common/core/format/formatted_price";
import { NSTSectionTitle } from "common/get_started/common/section";
import WarnNavigateOffNotarizeModal from "common/notary/profile_wizard/section/payout/warn_navigate_off_notarize";
import Icon from "common/core/icon";
import { NotaryLevelBadge } from "common/notary/level";
import ActionButton from "common/core/action_button";
import { isNotaryODN } from "common/notary/capacity";

import SectionStyles from "../common/section/index.module.scss";
import Styles from "./notary_stats.module.scss";
import type { BusinessGetStarted_viewer as Viewer } from "./business_get_started_query.graphql";

type Props = {
  viewer: Viewer;
  handleLearnMoreAboutTiers: () => void;
};

function StatSection({
  title,
  actions,
  children,
}: {
  title: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
}) {
  return (
    <section>
      <div className={Styles.statContent}>{children}</div>
      <p className={Styles.statTitle}>{title}</p>
      {actions}
    </section>
  );
}

function Stats({ viewer, handleLearnMoreAboutTiers }: Props) {
  const notaryProfile = viewer.user!.notaryProfile!;
  const { stripeBalance, stripeConnected, stripeLoginLink } = notaryProfile;
  const [showWarnNavigateOffNotarizeModal, setShowWarnNavigateOffNotarizeModal] = useState(false);

  const renderWarnNavigateOffNotarizeModal = ({ redirectUrl }: { redirectUrl: string }) => {
    const cancel = () => {
      setShowWarnNavigateOffNotarizeModal(false);
    };
    return (
      <WarnNavigateOffNotarizeModal
        onContinue={() => {
          window.open(redirectUrl);
          cancel();
        }}
        onCancel={cancel}
      />
    );
  };

  return (
    <div className={SectionStyles.nstSection}>
      <NSTSectionTitle
        title={
          <FormattedMessage id="ab8e0461-8335-4df8-a796-7d75d8334044" defaultMessage="Your stats" />
        }
      />
      {stripeConnected &&
        stripeLoginLink &&
        showWarnNavigateOffNotarizeModal &&
        renderWarnNavigateOffNotarizeModal({ redirectUrl: stripeLoginLink })}
      <div className={Styles.onDemandStats}>
        {stripeConnected && stripeLoginLink && (
          <StatSection
            title={
              <FormattedMessage
                id="6148be78-00df-4346-b326-c5e2c3c92920"
                defaultMessage="Available for payout"
              />
            }
            actions={
              <ActionButton
                className={Styles.viewStripeLink}
                onClick={() => setShowWarnNavigateOffNotarizeModal(true)}
              >
                <FormattedMessage
                  id="617cc8dc-2b4b-47ff-8926-96303607a874"
                  defaultMessage="View Stripe"
                />
              </ActionButton>
            }
          >
            <div className={Styles.statContentWrapper}>
              <Icon name="card" size="large" />
              <FormattedPrice cents={stripeBalance} />
            </div>
          </StatSection>
        )}

        {isNotaryODN(notaryProfile) && (
          <>
            <StatSection
              title={
                <FormattedMessage
                  id="70c296c7-a3c5-4ec0-aff8-bf96733dd275"
                  defaultMessage="Your tier"
                />
              }
              actions={
                <ActionButton className={Styles.levelLink} onClick={handleLearnMoreAboutTiers}>
                  <FormattedMessage
                    id="617cc8dc-2b4b-47ff-8926-96303607a874"
                    defaultMessage="View more"
                  />
                </ActionButton>
              }
            >
              <NotaryLevelBadge level={notaryProfile.notaryLevel} size="large" />
            </StatSection>

            <StatSection
              title={
                <FormattedMessage
                  id="77b46906-fcfb-44a7-89f4-f8b40654191c"
                  defaultMessage="Customer satisfaction score"
                />
              }
            >
              <div className={Styles.statContentWrapper}>
                <Icon name="notaries" size="large" />
                {notaryProfile.averageRating}
              </div>
            </StatSection>
          </>
        )}

        <StatSection
          title={
            <FormattedMessage
              id="5d80489d-b0f6-4a0a-8582-75361b376fc0"
              defaultMessage="Seals placed"
            />
          }
        >
          <div className={Styles.statContentWrapper}>
            <Icon className={Styles.iconNotarySeal} name="notary-seal" size="large" />
            {notaryProfile.sealsPlaced}
          </div>
        </StatSection>
      </div>
    </div>
  );
}

function OdnStatsIntroduction({ handleLearnMoreAboutTiers }: Omit<Props, "viewer">) {
  return (
    <div className={SectionStyles.nstSection}>
      <div className={Styles.panel}>
        <FormattedMessage
          id="366a8aba-460d-47f2-9b74-e3b3d1b14d66"
          tagName="h3"
          defaultMessage="On-Demand stats & tiers"
        />
        <p>
          <FormattedMessage
            id="ec73b6f6-58c5-4143-ab7c-99e2a8e694e5"
            defaultMessage="Once you complete your first On-Demand meeting, your stats and active tier information will appear here."
          />
        </p>
        <p>
          <ActionButton className={Styles.levelLink} onClick={handleLearnMoreAboutTiers}>
            <FormattedMessage
              id="617cc8dc-2b4b-47ff-8926-96303607a874"
              defaultMessage="Learn more about tiers"
            />
          </ActionButton>
        </p>
      </div>
    </div>
  );
}

function NotaryStats({ viewer, handleLearnMoreAboutTiers }: Props) {
  const onDemandCapacity = viewer.user?.notaryProfile?.capacities.find(
    ({ type }) => type === NotaryCapacityType.ON_DEMAND,
  );
  const meetingCount = onDemandCapacity?.completedMeetingCount || 0;

  if (onDemandCapacity && meetingCount === 0) {
    return <OdnStatsIntroduction handleLearnMoreAboutTiers={handleLearnMoreAboutTiers} />;
  }
  return <Stats viewer={viewer} handleLearnMoreAboutTiers={handleLearnMoreAboutTiers} />;
}

export default memo(NotaryStats);
