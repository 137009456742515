import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState } from "react";

import { useForm } from "common/core/form";
import { OrganizationApiKeyPayer } from "graphql_globals";
import { useMutation, useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { pushNotification } from "common/core/notification_center/actions";
import { canOrgPay, FormPageLayout } from "common/dashboard/common";
import LoadingIndicator from "common/core/loading_indicator";
import Env from "config/environment";

import {
  COMMON_MESSAGES,
  NameInput,
  RadioAccountBranding,
  RadioReferralPayer,
  useNavigateToReferralsDashaboard,
  type ReferralsCampaignFormType,
} from "./common";
import CreateReferralCampaignMutation from "./create_referral.mutation.graphql";
import ReferralCampaignCreate, {
  type ReferralCampaignCreate_organization_Organization as Organization,
} from "./referral_create.query.graphql";

const MESSAGES = defineMessages({
  createReferralMutationSuccess: {
    id: "db05ea8b-d909-4855-ac34-ec6dc62c774a",
    defaultMessage: "{name} has been successfully created!",
  },
});

export function ReferralsCreateForm() {
  const [activeOrganizationId] = useActiveOrganization();
  const intl = useIntl();
  const [organizationId] = useActiveOrganization();
  const navigateToReferralsDashboard = useNavigateToReferralsDashaboard();
  const form = useForm<ReferralsCampaignFormType>({
    defaultValues: {
      payer: OrganizationApiKeyPayer.CUSTOMER,
      branding: "true",
    },
  });
  const { handleSubmit, formState } = form;
  const createReferralCampaign = useMutation(CreateReferralCampaignMutation);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { data } = useQuery(ReferralCampaignCreate, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (!data) {
    return <LoadingIndicator />;
  }

  const organization = data.organization as Organization;
  const defaultPayer = organization.defaultPayer;
  const defaultPaymentSource = organization.defaultPaymentSource;

  return (
    <FormPageLayout
      heading={
        <FormattedMessage
          id="026a881a-12f7-4b6a-8381-90338f0eaea3"
          defaultMessage="New referral campaign"
        />
      }
      title={intl.formatMessage(COMMON_MESSAGES.campaignDetails)}
      loading={formState.isSubmitting}
      submitText={
        <FormattedMessage
          id="8b410817-8724-4c38-9df7-841418d6c74c"
          defaultMessage="Create campaign"
        />
      }
      onSubmit={handleSubmit((values) => {
        setErrorMessage(undefined);
        return createReferralCampaign({
          variables: {
            input: {
              coverPayment: values.payer === OrganizationApiKeyPayer.ORGANIZATION,
              name: values.name,
              organizationId: organizationId!,
              redirectUrl: `https://app.${Env.hostName}/signup/upload`,
              useBranding: values.branding === "true",
            },
          },
        })
          .then(() => {
            pushNotification({
              position: "topCenter",
              message: intl.formatMessage(MESSAGES.createReferralMutationSuccess, {
                name: <strong>{values.name}</strong>,
              }),
            });
            navigateToReferralsDashboard();
          })
          .catch(() => {
            setErrorMessage(intl.formatMessage(COMMON_MESSAGES.referralMutationError));
          });
      })}
      cancelText={
        <FormattedMessage
          id="bfe398ff-c846-4739-8742-1610a892a629"
          defaultMessage="Cancel and return to dashboard"
        />
      }
      onCancel={() => navigateToReferralsDashboard()}
      errorMessage={errorMessage}
    >
      <NameInput form={form} />
      <RadioAccountBranding form={form} />
      <RadioReferralPayer form={form} canOrgPay={canOrgPay(defaultPayer, defaultPaymentSource)} />
    </FormPageLayout>
  );
}
