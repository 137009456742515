import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { type UseFormReturn } from "common/core/form";
import { TextInput } from "common/core/form/text";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { AutomaticFormRow, FormRow } from "common/core/form/layout";
import { useId } from "util/html";
import { RadioGroup, RadioInput, RadioLabel } from "common/core/form/option";
import Link from "common/core/link";
import { OrganizationApiKeyPayer } from "graphql_globals";
import {
  RadioGroupAddPaymentMethodWarning,
  FormCard,
  type FormCardProps,
} from "common/dashboard/common";

import Styles from "./common.module.scss";

export const REFERRALS_PATH = "/referrals";

export const COMMON_MESSAGES = defineMessages({
  campaignDetails: {
    id: "b0f8a118-9656-445e-a1b1-1d7e0f9cd11c",
    defaultMessage: "Campaign details",
  },
  referralMutationError: {
    id: "11c84c11-8cb4-422d-9b3c-5c07d6461475",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
});

export function useNavigateToReferralsDashaboard() {
  const navigate = useNavigate();

  return useCallback((status: "active" | "inactive" = "active") => {
    navigate({ pathname: REFERRALS_PATH, search: `?status=${status}` });
  }, []);
}

export type ReferralsCampaignFormType = {
  id: string;
  active: boolean;
  name: string;
  payer: OrganizationApiKeyPayer;
  branding: "true" | "false";
};

const MESSAGES = defineMessages({
  nameRequiredMessage: {
    id: "1539dccc-1ef2-487b-ac0b-acf1eebe7cd8",
    defaultMessage: "A referral campaign name is required.",
  },
  nameLabel: {
    id: "18513634-eb9d-4c74-b8bf-ae8fdc1e2974",
    defaultMessage: "Referral campaign name",
  },
});

function ReferralsCard(props: FormCardProps) {
  return <FormCard className={Styles.cardSpacing} {...props} />;
}

type NameField = {
  name: string;
};
export function NameInput<FormFields extends NameField>({
  form: untypedFormReturn,
}: {
  form: UseFormReturn<FormFields>;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<NameField>;

  const intl = useIntl();

  return (
    <ReferralsCard
      cardTitle={
        <>
          <FormattedMessage
            id="f0def724-0b15-465f-8b80-2c311ebdd1e6"
            defaultMessage="Enter a name for your referral campaign"
          />
          <RequiredAsterisk />
        </>
      }
      cardDescription={
        <FormattedMessage
          id="bdf45a3e-0e48-41e3-99a8-3eff5961dfc8"
          defaultMessage="This will only be visible to you and your team."
        />
      }
    >
      <AutomaticFormRow
        form={form}
        name="name"
        aria-label={intl.formatMessage(MESSAGES.nameLabel)}
        registerOptions={{ required: intl.formatMessage(MESSAGES.nameRequiredMessage) }}
        dynamicHeightError
        highlightLabelOnError
        as={TextInput}
      />
    </ReferralsCard>
  );
}

type BrandingField = { branding: ReferralsCampaignFormType["branding"] };
export function RadioAccountBranding<FormFields extends BrandingField>({
  form: untypedFormReturn,
  disabled,
}: {
  form: UseFormReturn<FormFields>;
  disabled?: boolean;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<BrandingField>;
  const { register } = form;
  const radioGroupTitle = useId();
  const radioGroupDescription = useId();

  return (
    <ReferralsCard
      cardTitle={
        <span id={radioGroupTitle}>
          <FormattedMessage
            id="1bd5f4af-cb10-41fe-9633-ec077865b449"
            defaultMessage="Do you want to use your organization’s branding?"
          />
        </span>
      }
      cardDescription={
        <span id={radioGroupDescription}>
          <FormattedMessage
            id="146c77a1-4c7b-412e-8697-220fa6da4088"
            defaultMessage="You can choose if your organization’s logo and brand colors will appear throughout the signer experience."
          />
        </span>
      }
    >
      <FormRow>
        <RadioGroup aria-labelledby={`${radioGroupTitle} ${radioGroupDescription}`}>
          <RadioLabel
            label={
              <FormattedMessage
                id="6472997e-e411-41bb-a41d-06364555efa1"
                defaultMessage="Yes, use my organization’s branding"
              />
            }
            subLabel={
              <FormattedMessage
                id="923b4978-f8e7-48d0-952e-6cb96726975c"
                defaultMessage="This will only apply if custom branding has been configured via <link>settings</link>."
                values={{
                  link: (text) => <Link to="/settings/brand/settings/logo-header">{text}</Link>,
                }}
              />
            }
            radio={<RadioInput disabled={disabled} value="true" {...register("branding")} />}
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="e3ce6e06-101f-4f39-a17e-041b8e3ba434"
                defaultMessage="No, I want to use Proof default branding"
              />
            }
            radio={<RadioInput disabled={disabled} value="false" {...register("branding")} />}
          />
        </RadioGroup>
      </FormRow>
    </ReferralsCard>
  );
}

type PayerField = { payer: OrganizationApiKeyPayer };
export function RadioReferralPayer<FormFields extends PayerField>({
  form: untypedFormReturn,
  disabled,
  canOrgPay,
}: {
  form: UseFormReturn<FormFields>;
  disabled?: boolean;
  canOrgPay?: boolean;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<PayerField>;
  const { register } = form;
  const radioGroupId = useId();

  return (
    <ReferralsCard
      cardTitle={
        <span id={radioGroupId}>
          <FormattedMessage
            id="1f92eb3b-d560-431f-b6a4-7141d050eda2"
            defaultMessage="Who will be paying for the transaction?"
          />
        </span>
      }
      extraSpacing
    >
      <FormRow>
        <RadioGroup aria-labelledby={radioGroupId}>
          <RadioLabel
            label={
              <FormattedMessage
                id="328db3f9-614f-423d-b824-c9d76297dbe7"
                defaultMessage="The signer"
              />
            }
            subLabel={
              <FormattedMessage
                id="6502b3f3-5f73-4dd2-9fd6-7f8e524c1d90"
                defaultMessage="One reusable referral link will be created."
              />
            }
            radio={
              <RadioInput
                disabled={disabled}
                value={OrganizationApiKeyPayer.CUSTOMER}
                {...register("payer")}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="596ea80c-da98-4d69-8977-03446bd2d172"
                defaultMessage="My organization"
              />
            }
            subLabel={
              <FormattedMessage
                id="13ed1de8-d7cb-4bd4-949c-fc3ea3db11c5"
                defaultMessage="To ensure that links are not being reused when your organization is providing payment, a unique single-use referral link will be generated for every transaction."
              />
            }
            radio={
              <RadioInput
                disabled={!canOrgPay || disabled}
                value={OrganizationApiKeyPayer.ORGANIZATION}
                {...register("payer")}
              />
            }
          />
        </RadioGroup>
        {!canOrgPay && <RadioGroupAddPaymentMethodWarning />}
      </FormRow>
    </ReferralsCard>
  );
}
