import "./index.scss";

import { FormattedMessage, useIntl } from "react-intl";
import { addMinutes } from "date-fns";

import { LongFormattedDateTime } from "common/core/format/date";
import modalScrollContent from "common/core/modal_scroll_content";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import { ACTIONS, EVENTS } from "constants/history";
import { auditTrailsComparator, isProgressStale } from "util/history";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import HistoryItem from "./history_item";
import type { DocumentBundleForTransactionDetailsHistory } from "./index_fragment.graphql";

type SingleAuditTrail = DocumentBundleForTransactionDetailsHistory["auditTrails"][number];
type AuditTrailGroup = {
  action: string | null;
  createdAt: string | null;
  id: string | null;
  items: SingleAuditTrail[];
};
type Props = {
  bundle: DocumentBundleForTransactionDetailsHistory;
};

const MAX_IN_PROGRESS_TIME_MINUTES = 90;

function createAbandonedMeetingItem(group: AuditTrailGroup) {
  // Last event was in progress, but is old: meeting has most likely been abandoned. There's currently
  // no way to fully detect this on the back end, so we'll add a temporary history item here so it
  // doesn't look like the meeting has been in progress for ages. We'll say that meeting was ended
  // MAX_IN_PROGRESS_TIME_MINUTES after last event.
  const createdAt = addMinutes(
    new Date(group.createdAt!),
    MAX_IN_PROGRESS_TIME_MINUTES,
  ).toISOString();
  return {
    action: ACTIONS.FAILED_MEETING_ATTEMPT,
    id: createdAt,
    createdAt,
    actionMetadata: JSON.stringify({ event: EVENTS.CONNECTION_LOST }),
  };
}

function addToCurrentGroup(
  [currentGroup, ...prevGroups]: AuditTrailGroup[],
  item: SingleAuditTrail,
): AuditTrailGroup[] {
  // put items on group in reverse chronological order.
  const newItem = { ...currentGroup, items: [item, ...currentGroup.items] };
  return [newItem, ...prevGroups];
}

function createNewGroup(accum: AuditTrailGroup[], item: SingleAuditTrail): AuditTrailGroup[] {
  const newItem = {
    action: item.action,
    createdAt: item.createdAt,
    id: item.id,
    items: [item],
  };
  // put groups on list in reverse chronological order.
  return [newItem, ...accum];
}

/**
 * @description
 * This util takes a flat list of auditTrails and groups them by category. Sent, received, completion and
 * most failures will have just a single item in the group. Progress events could have several events in
 * a single group. but a new id process signals a new group. for failures, we want to group notary
 * termination and notary termination report.
 */
function groupHistory(auditTrails: SingleAuditTrail[]): AuditTrailGroup[] {
  const itemList = auditTrails
    .slice()
    // first go through items in chronological order
    .sort(auditTrailsComparator)
    .reduce<AuditTrailGroup[]>((accum, item) => {
      switch (item.action) {
        case ACTIONS.MEETING_IN_PROGRESS:
          // always group sequential progress actions...
          // UNLESS event is KBA_IN_PROGRESS, which is start of new session, so gets a new group.
          return accum[0]?.action === ACTIONS.MEETING_IN_PROGRESS &&
            (JSON.parse(item.actionMetadata!) as { event: unknown }).event !==
              EVENTS.KBA_IN_PROGRESS
            ? addToCurrentGroup(accum, item)
            : createNewGroup(accum, item);
        case ACTIONS.FAILED_MEETING_ATTEMPT:
          // always group sequential attempt actions.
          return accum[0]?.action === ACTIONS.FAILED_MEETING_ATTEMPT
            ? addToCurrentGroup(accum, item)
            : createNewGroup(accum, item);
        case ACTIONS.PAYMENT_FAILED:
          // always group sequential payment attempt actions.
          return accum[0]?.action === ACTIONS.PAYMENT_FAILED
            ? addToCurrentGroup(accum, item)
            : createNewGroup(accum, item);
        default:
          // make new group with just this item
          return createNewGroup(accum, item);
      }
    }, []);

  // If latest item in the list is "in progress" and older than MAX_IN_PROGRESS_TIME_MINUTES
  // then meeting has almost certainly been abandoned.
  const [firstItem] = itemList;
  return isProgressStale(firstItem)
    ? createNewGroup(itemList, createAbandonedMeetingItem(firstItem))
    : itemList;
}

function renderHeader(action: string | null) {
  switch (action) {
    case ACTIONS.DOCUMENT_BUNDLE_SENT:
      return <FormattedMessage id="bb3e4cc9-be69-4143-aeaa-8f8ba92f20a6" defaultMessage="Sent" />;
    case ACTIONS.DOCUMENT_BUNDLE_ACTIVE:
      return <FormattedMessage id="e96d142f-6399-40b6-acb9-f0cf8303caf5" defaultMessage="Active" />;
    case ACTIONS.DOCUMENT_BUNDLE_RECEIVED:
      return (
        <FormattedMessage id="dc454f85-5a02-4ca7-ac64-821eda9e288c" defaultMessage="Received" />
      );
    case ACTIONS.DOCUMENT_BUNDLE_VIEWED:
      return <FormattedMessage id="0645a3eb-7b14-441d-b851-3ee63fd436cd" defaultMessage="Viewed" />;
    case ACTIONS.MEETING_IN_PROGRESS:
      return (
        <FormattedMessage id="bf8d1953-4d5d-4f0a-8c04-3b4642a84d41" defaultMessage="In Progress" />
      );
    case ACTIONS.FAILED_MEETING_ATTEMPT:
      return (
        <FormattedMessage id="a956d7af-298b-454a-9f0d-f8c78b628bdf" defaultMessage="Attempted" />
      );
    case ACTIONS.MEETING_COMPLETION:
      return (
        <FormattedMessage
          id="7829ceb9-87f0-4b14-8c40-70046d8b7486"
          defaultMessage="Meeting Completed"
        />
      );
    case ACTIONS.DOCUMENT_BUNDLE_COMPLETE_WITH_REJECTIONS:
      return (
        <FormattedMessage
          id="5612e9fe-c22c-4a49-b2c8-691c828bb008"
          defaultMessage="Transaction Completed with Rejections"
        />
      );
    case ACTIONS.DOCUMENT_BUNDLE_PARTIALLY_COMPLETE:
      return (
        <FormattedMessage
          id="b66119a3-8091-47f4-a082-1effa389b11c"
          defaultMessage="Transaction Partially Completed"
        />
      );
    case ACTIONS.DOCUMENT_BUNDLE_COMPLETE:
      return (
        <FormattedMessage
          id="a33c68d9-b7a8-4617-a5a5-fb2223353476"
          defaultMessage="Transaction Completed"
        />
      );
    case ACTIONS.PAYMENT_FAILED:
      return (
        <FormattedMessage
          id="e253173a-9271-4fd2-9f1f-a1578bd7398d"
          defaultMessage="Payment Failed"
        />
      );
    case ACTIONS.RECALLED:
      return (
        <FormattedMessage
          id="867717b9-7d5b-4a8e-bb21-83da19ca2822"
          defaultMessage="Transaction Recalled"
        />
      );
    case ACTIONS.SENT_TO_CLOSING_OPS:
      return (
        <FormattedMessage
          id="566c03de-b538-4d3d-b9eb-83b2630d52c7"
          defaultMessage="Sent to Proof Closing Ops"
        />
      );
    case ACTIONS.SENT_TO_TITLE_AGENCY:
      return (
        <FormattedMessage
          id="f63515f6-4d75-4d6b-8fda-d972a9a75a10"
          defaultMessage="Sent to Title Agent"
        />
      );
    case ACTIONS.CONVERTED_TO_HYBRID:
      return (
        <FormattedMessage
          id="c324d908-8037-4b6b-a8bf-c8af7aa826f5"
          defaultMessage="Converted to Hybrid"
        />
      );
    default:
      return action;
  }
}

function hasMetadata({ actionMetadata }: { actionMetadata: string | null }): boolean {
  // actionMetadata can contain event, details and metadata. Minimally event.
  // so if event does not exist, there is no metadata.
  return Boolean(
    actionMetadata && (JSON.parse(actionMetadata) as { event: unknown } | undefined | null)?.event,
  );
}

function GroupOfAuditTrails(props: {
  group: AuditTrailGroup;
  completionRequirements: DocumentBundleForTransactionDetailsHistory["completionRequirements"];
}) {
  const { group, completionRequirements } = props;
  const { items } = group;
  const itemsWithMetaData = items.filter(hasMetadata);
  const itemCutoff = items.length - 1;
  return (
    <CollapsibleListItem
      header={renderHeader(group.action)}
      subheader={<LongFormattedDateTime value={group.createdAt} />}
    >
      {Boolean(itemsWithMetaData.length) && (
        <div>
          {itemsWithMetaData.map((item, index) => (
            <HistoryItem
              key={item.id}
              item={item}
              separated={index < itemCutoff}
              completionRequirements={completionRequirements}
            />
          ))}
        </div>
      )}
    </CollapsibleListItem>
  );
}

function History({ bundle }: Props) {
  const { auditTrails, completionRequirements } = bundle;
  const groupedAuditTrails = groupHistory(auditTrails);
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().transactionDetailsHistory),
  });

  return (
    <div className="History">
      <CollapsibleList>
        {groupedAuditTrails.map((group) => (
          <GroupOfAuditTrails
            key={group.id}
            group={group}
            completionRequirements={completionRequirements}
          />
        ))}
      </CollapsibleList>
    </div>
  );
}

export default modalScrollContent(History);
