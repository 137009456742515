import "../starter_index.scss";

import { FormattedMessage } from "react-intl";

import { getTierPricingUrl } from "util/tier";
import StarterUpgradeImage from "assets/images/add-team-members.svg";
import { ButtonStyledLink } from "common/core/button/button_styled_link";

function StarterUpgrade(props) {
  const { activeTier } = props;
  return (
    <div className="BusinessStarter">
      <img alt="starter trial" src={StarterUpgradeImage} />
      <div className="BusinessStarter--header">
        <FormattedMessage
          id="e968618b-a060-4174-af91-41f88e303ace"
          defaultMessage="Upgrade to add to your team"
        />
      </div>
      <div className="BusinessStarter--line-1">
        <FormattedMessage
          id="61a66a1f-8009-4b02-953c-831886d24249"
          defaultMessage="Accounts on a paid plan can collaborate with team"
        />
        <br />
        <FormattedMessage
          id="8e04d00d-f632-41f0-97f9-1d3ec8fba6bd"
          defaultMessage="members to send and manage documents."
        />
      </div>
      <div className="BusinessStarter--buttons">
        <ButtonStyledLink
          href={getTierPricingUrl({ tierName: activeTier })}
          automationId="link-see-pricing"
          buttonColor="action"
          buttonSize="large"
          variant="primary"
        >
          <FormattedMessage
            id="a3015a36-34a1-423f-9b8f-1f6bfaa02b63"
            defaultMessage="See Pricing Plans"
          />
        </ButtonStyledLink>
      </div>
    </div>
  );
}

export default StarterUpgrade;
