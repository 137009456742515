import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import Link from "common/core/link";
import connectionFailImage from "assets/images/tech/connection-fail.svg";
import { FIREWALL_TROUBLESHOOTING_URL } from "constants/support";

const title = (
  <FormattedMessage id="0448182d-76b1-4e1b-b114-d06049f9a571" defaultMessage="Unable to connect " />
);
const message = (
  <FormattedMessage
    id="70a96cc0-f6df-4995-8581-ecb5d9cda546"
    defaultMessage="We’ve detected a firewall that will prevent us from connecting you over video. Please follow our <link>suggestions</link> to troubleshoot firewall protections."
    values={{ link: (text) => <Link href={FIREWALL_TROUBLESHOOTING_URL}>{text}</Link> }}
  />
);
const retry = <FormattedMessage id="64c2c6f3-377b-45c7-8ebd-9e639b4949f2" defaultMessage="Retry" />;

class FirewallModal extends PureComponent {
  render() {
    const { onRetry } = this.props;

    return (
      <IllustrationModal
        title={title}
        src={connectionFailImage}
        alt="connection fail"
        buttons={[
          <Button buttonColor="action" variant="primary" key="retry" onClick={onRetry}>
            {retry}
          </Button>,
        ]}
      >
        {message}
      </IllustrationModal>
    );
  }
}

FirewallModal.propTypes = {
  onRetry: PropTypes.func.isRequired,
};

export default FirewallModal;
