import "./basic_container.scss";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function BasicAppFrameHeader({ children }: Props) {
  return <header className="BasicAppFrameHeader">{children}</header>;
}

export function BasicAppFrameTitle({ children }: Props) {
  return <div className="BasicAppFrameTitle">{children}</div>;
}

function BasicAppFrameContainer({ children }: Props) {
  return <div className="BasicAppFrameContainer">{children}</div>;
}

export default BasicAppFrameContainer;
