import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import classnames from "classnames";

import Button from "common/core/button";
import { testDownloadSpeed, canMakeTestCall } from "util/connection";
import { Mutation } from "util/graphql/mutation";
import { Heading, Paragraph } from "common/core/typography";

import CREATE_TEST_VIDEO_SESSION_MUTATION from "./create_test_video_session_mutation.graphql";
import FirewallModal from "./firewall_modal";

const noop = () => {};

const CONNECTING = Symbol("Connecting");
const SUCCEEDED = Symbol("Succeeded Test");
const WEAK_CONNECTION = Symbol("Weak Connection");
const BAD_CONNECTION = Symbol("Bad Connection");
const TEST_MAX_TIME_SEC = process.env.NODE_ENV !== "development" ? 10 : 1;

// All thresholds in mega*bytes* per second.
const GOOD_CONNECTION_THRESHOLD_MB_SEC = 0.3;
const WEAK_CONNECTION_THRESHOLD_MB_SEC = 0; // This means we will never fail! (always at least weak)

const CONNECTION_TEST_COPY = Object.freeze({
  [SUCCEEDED]: Object.freeze({
    title: (
      <FormattedMessage
        id="a6db319e-7291-4c61-9d6a-376e0fa0e2e5"
        description="title"
        defaultMessage="Internet Connection"
      />
    ),
  }),
  [CONNECTING]: Object.freeze({
    title: (
      <FormattedMessage
        id="bc262164-4cf4-42bd-ab49-de545b7ee3c0"
        description="title"
        defaultMessage="Internet Connection"
      />
    ),
  }),
  [BAD_CONNECTION]: Object.freeze({
    title: (
      <FormattedMessage
        id="eb9da38d-7eb0-47ea-bdc5-82c271576056"
        description="title"
        defaultMessage="Connection Test Failed"
      />
    ),
    body: (
      <FormattedMessage
        id="4b66cc5a-fabf-4301-9d22-816decc308dc"
        description="body"
        defaultMessage="Make sure you are in an area that allows a strong connection before trying again"
      />
    ),
  }),
  [WEAK_CONNECTION]: Object.freeze({
    title: (
      <FormattedMessage
        id="312fe8cf-c7a8-4397-8c27-32f8bec6bd56"
        description="title"
        defaultMessage="Weak Signal"
      />
    ),
    body: (
      <FormattedMessage
        id="aedd423d-fab8-4829-9e8a-91e6a857307b"
        description="body"
        defaultMessage={
          "We've detected a weak internet connection. Try moving to an area with a stronger " +
          "signal to ensure a quality video call."
        }
      />
    ),
  }),
});

class ConnectionTest extends Component {
  state = {
    connectionTestStep: CONNECTING,
    showFirewallModal: false,
  };

  handleRetryConnection = () => {
    this.setState({ showFirewallModal: false });
    this.props.onRetry();
    this.initiateConnection();
  };

  initiateConnection() {
    this.setState(() => ({ connectionTestStep: CONNECTING }));
    this.props.onStart();

    testDownloadSpeed(TEST_MAX_TIME_SEC)
      // Always report this as no speed which will fail.
      .catch(() => 0)
      .then(async (speed) => {
        const canMakeTestCall = await this.canMakeTestCall();
        if (!this._mounted) {
          return;
        }
        if (speed >= GOOD_CONNECTION_THRESHOLD_MB_SEC) {
          this.props.onSuccess(speed, canMakeTestCall);
          if (canMakeTestCall) {
            this.setState(() => ({ connectionTestStep: SUCCEEDED }));
          }
        } else if (speed >= WEAK_CONNECTION_THRESHOLD_MB_SEC) {
          this.props.onWarning(speed);
          if (canMakeTestCall) {
            this.setState(() => ({ connectionTestStep: WEAK_CONNECTION }));
          }
        } else {
          this.props.onFailure(speed);
          this.setState(() => ({ connectionTestStep: BAD_CONNECTION }));
        }
      });
  }

  async canMakeTestCall() {
    try {
      const { data } = await this.props.createTestVideoSession();
      const { sessionId, token } = data.createTestVideoSession;
      const callSucceeded = await canMakeTestCall({ room: sessionId, token });
      if (!callSucceeded) {
        this.setState({ showFirewallModal: true });
      }
      return callSucceeded;
    } catch (_error) {
      // if mutation fails assume user can make test call instead of blocking them
      return Promise.resolve(true);
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.initiateConnection();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { onConnectAnyway, onExit } = this.props;
    const { connectionTestStep, showFirewallModal } = this.state;

    const failed = connectionTestStep === BAD_CONNECTION;
    const warning = connectionTestStep === WEAK_CONNECTION;
    const connecting = connectionTestStep === CONNECTING;
    const succeeded = connectionTestStep === SUCCEEDED;
    const copy = CONNECTION_TEST_COPY[connectionTestStep];

    let { body } = copy || {};

    if (connecting || succeeded) {
      body = (
        <FormattedMessage
          id="6b5c8741-fc9c-44d6-8d6b-0c29e1b38806"
          defaultMessage="Checking your internet connection to ensure a quality notary video call. This test will take a few seconds."
        />
      );
    }

    return (
      <div className="ConnectionTest" data-automation-id="connection-test">
        <div className={classnames("ConnectionTest--icon", { failed, warning })} />
        <Heading
          level="h2"
          textStyle="headingFour"
          textAlign="center"
          data-automation-id="tech-check-status"
        >
          {copy.title}
        </Heading>
        <Paragraph textAlign="center" className="TechCheck--info">
          {body}
        </Paragraph>
        {!showFirewallModal && (connecting || succeeded) && (
          <div
            data-automation-id="connection-progress-bar"
            className={classnames("ConnectionTest--progress", { succeeded })}
          >
            <div style={{ animationDuration: `${TEST_MAX_TIME_SEC}s` }} />
          </div>
        )}
        {failed && (
          <Button
            className="TechCheck--button"
            buttonColor="action"
            variant="primary"
            onClick={this.handleRetryConnection}
            automationId="retry-connection-button"
          >
            <FormattedMessage
              id="a1e218d2-8b78-46d8-bbcc-301a864374e5"
              description="failed"
              defaultMessage="Retry"
            />
          </Button>
        )}
        {warning && (
          <Button
            className="TechCheck--button"
            onClick={onConnectAnyway}
            automationId="tech-check-connect-anyway-button"
            buttonColor="action"
            variant="secondary"
          >
            <FormattedMessage
              id="1b12b17f-12fc-4ba8-8566-bf7bfa9b9b18"
              description="retry_anyway"
              defaultMessage="Connect anyway"
            />
          </Button>
        )}
        {(warning || failed) && (
          <Button
            className="TechCheck--button"
            onClick={onExit}
            automationId="tech-check-dashboard-button"
            buttonColor="action"
            variant="secondary"
          >
            <FormattedMessage
              id="81480ad0-a06b-406a-9756-cb6826cdb5ca"
              description="return_to_dashboard"
              defaultMessage="Return to dashboard"
            />
          </Button>
        )}
        {showFirewallModal && <FirewallModal onRetry={this.handleRetryConnection} />}
      </div>
    );
  }
}

ConnectionTest.propTypes = {
  onStart: PropTypes.func,
  onSuccess: PropTypes.func,
  onWarning: PropTypes.func,
  onFailure: PropTypes.func,
  onRetry: PropTypes.func,
  onConnectAnyway: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,

  // ConnectionTestContainer
  createTestVideoSession: PropTypes.func.isRequired,
};

ConnectionTest.defaultProps = {
  onStart: noop,
  onSuccess: noop,
  onWarning: noop,
  onFailure: noop,
  onRetry: noop,
};

function ConnectionTestContainer(props) {
  return (
    <Mutation mutation={CREATE_TEST_VIDEO_SESSION_MUTATION}>
      {(mutate) => <ConnectionTest createTestVideoSession={mutate} {...props} />}
    </Mutation>
  );
}

export default connect()(ConnectionTestContainer);
