import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { userFullName } from "util/user";
import { ROLE_LABELS } from "constants/points_of_contact";
import CollapsibleListItem from "common/core/collapsible_list/item";

import ItemizedList from "./itemized";
import type { RealtorTransactionInfoModal_node as OrganizationTransaction } from "./index_fragment.graphql";

type Props = {
  transaction: OrganizationTransaction;
};

function Contacts({ transaction }: Props) {
  const { contacts } = transaction;
  if (!contacts.length) {
    return (
      <FormattedMessage id="7bc8b3c7-0705-45d2-a736-a5d1c7e0199f" defaultMessage="No contacts" />
    );
  }
  return (
    <>
      {contacts.map((contact) => (
        <CollapsibleListItem
          key={contact.id}
          initialDisplay
          header={userFullName(contact)}
          subheader={ROLE_LABELS[contact.role]}
        >
          <ItemizedList>
            <li>
              <FormattedMessage
                id="44abf299-7025-4999-882a-bde82697cb74"
                tagName="span"
                defaultMessage="Phone Number"
              />
              <span>{contact.phoneNumber}</span>
            </li>
            <li>
              <FormattedMessage
                id="1ede5e6d-12c0-413c-851e-90e3cedd4d5c"
                tagName="span"
                defaultMessage="Email"
              />
              <span>{contact.email}</span>
            </li>
          </ItemizedList>
        </CollapsibleListItem>
      ))}
    </>
  );
}

export default memo(Contacts);
