import { useState, useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Button from "common/core/button";
import { segmentTrack } from "util/segment";
import WebcamIcon from "assets/images/tech/webcam.svg";
import ErrorIcon from "assets/images/tech/webcam-error.svg";
import { Paragraph } from "common/core/typography";
import BackgroundSettings from "common/video_conference/background_settings";
import type { UpdateNotaryProfileSetting_updateNotaryProfileSetting_notaryProfile as NotaryProfile } from "common/tech_check/video/update_notary_profile_setting_mutation.graphql";

import { WebcamPicker, WebcamPreview } from "./webcam";
import UserTechCheckControl from "../user_control";

const LABELS = defineMessages({
  webcam: {
    id: "5806c3e0-d7bb-48d4-abb9-14ccf484d94e",
    defaultMessage: "Camera",
  },
  background: {
    id: "bd651419-ab98-4e32-a5d5-f2cd9b93ff1a",
    defaultMessage: "Video Background",
  },
});

type VideoConfigurationProps = {
  notaryProfile: NotaryProfile | null;
  onMissingWebcam: () => void;
  missingEquipment?: boolean;
  onWebcamSelection: () => void;
  selectedWebcamId?: string;
};

function VideoConfiguration({
  notaryProfile,
  onMissingWebcam,
  missingEquipment,
  onWebcamSelection,
  selectedWebcamId,
}: VideoConfigurationProps) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const intl = useIntl();

  const onShowPreview = () => {
    segmentTrack("quiet_video_tech_check Show Preview Clicked", {
      missingEquipment,
    });
    setPreviewOpen(!previewOpen);
  };

  useEffect(() => {
    segmentTrack("quiet_video_tech_check Impression", { missingEquipment });
  }, []);

  return (
    <>
      <UserTechCheckControl
        label={intl.formatMessage(LABELS.webcam)}
        icon={missingEquipment ? ErrorIcon : WebcamIcon}
        techCheckDropdown={
          <WebcamPicker
            onDeviceSelect={onWebcamSelection}
            onDeviceMissing={onMissingWebcam}
            selectedDeviceId={selectedWebcamId}
            autoFocus
          />
        }
      >
        <>
          <Button
            aria-expanded={previewOpen}
            variant="tertiary"
            buttonColor="action"
            withIcon={{ name: "witness-line", placement: "left" }}
            automationId="show-preview-button"
            onClick={onShowPreview}
          >
            {previewOpen ? (
              <FormattedMessage
                id="072a5e00-aa81-43c9-a4b8-a3e820fb277b"
                defaultMessage="Close video preview"
              />
            ) : (
              <FormattedMessage
                id="d4991c4f-d753-4f1f-9fc2-ccbefaebcac4"
                defaultMessage="Preview my video"
              />
            )}
          </Button>
          {previewOpen && (
            <div className="TechCheck--webcam-preview-container">
              <WebcamPreview webcamId={selectedWebcamId} />
              <Paragraph textAlign="center">
                <FormattedMessage
                  id="68f47a05-a39e-48ca-9336-d9ea1e4eaee9"
                  defaultMessage="Make sure you can see yourself in the video feed."
                />
              </Paragraph>
              <Paragraph textAlign="center">
                <FormattedMessage
                  id="d0bf11d2-a929-42d3-b6eb-2a6c01815663"
                  defaultMessage="If you can't, use the menu to select the correct camera."
                />
              </Paragraph>
            </div>
          )}
        </>
      </UserTechCheckControl>
      {notaryProfile && (
        <UserTechCheckControl label={intl.formatMessage(LABELS.background)}>
          <BackgroundSettings notaryProfile={notaryProfile} />
        </UserTechCheckControl>
      )}
    </>
  );
}

export default VideoConfiguration;
