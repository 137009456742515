import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import { UserRole } from "graphql_globals";
import Button from "common/core/button";
import Webcam from "util/react_user_media";
import { isSafari } from "util/support";
import webcam from "assets/images/tech/webcam.svg";
import error from "assets/images/tech/webcam-error.svg";
import mediaBlocked from "assets/images/media-blocked.png";
import { Heading, Paragraph } from "common/core/typography";

import { BLOCKED_PERM, REQUESTED_PERM } from "../permission";
import { NOTARY_LABELS, SIGNER_LABELS } from "./messages";
import Styles from "../permission.module.scss";

class VideoPermissionGetter extends PureComponent {
  componentDidMount() {
    this.props.onMount();
  }

  refreshPage = () => {
    window.location.reload();
  };

  getContent() {
    const { role, permissionStatus, onAccessClick, intl, missingEquipment } = this.props;
    const allow_access_button = (
      <Button
        buttonColor="action"
        variant="primary"
        onClick={onAccessClick}
        isLoading={permissionStatus === REQUESTED_PERM}
        automationId="allow-camera-access-button"
      >
        {permissionStatus === BLOCKED_PERM ? (
          <FormattedMessage id="2375d50c-1f15-48e4-a28c-1d9e59fc1331" defaultMessage="Retry" />
        ) : (
          <FormattedMessage
            id="10d366c7-cad9-4778-b305-770e970895f5"
            defaultMessage="Allow Camera Access"
          />
        )}
      </Button>
    );

    if (missingEquipment) {
      return (
        <>
          <Paragraph textColor="subtle" textAlign="center">
            <FormattedMessage
              id="94c4aa7a-ca80-4897-a345-7daced08ed3d"
              defaultMessage="No webcams detected. Please check your webcam and try again. If the problem persists, try switching browsers or using a mobile device."
            />
          </Paragraph>
          {allow_access_button}
        </>
      );
    }

    if (permissionStatus === BLOCKED_PERM) {
      if (isSafari()) {
        return (
          <>
            <Paragraph textColor="subtle" textAlign="center">
              <FormattedMessage
                id="2378e8d8-a11a-482a-bc1d-a726465b3b47"
                defaultMessage="We have not been granted access to your camera. Please try again or change your Safari Preferences for this site. If the problem persists, try switching browsers or using a mobile device."
              />
            </Paragraph>
            <Button
              buttonColor="action"
              variant="primary"
              onClick={this.refreshPage}
              disabled={permissionStatus === REQUESTED_PERM}
              automationId="allow-camera-access-button-try-again"
            >
              <FormattedMessage
                id="c8465660-e5e1-4cde-8105-fa96c683cc91"
                defaultMessage="Try Again"
              />
            </Button>
          </>
        );
      }

      return (
        <>
          <Paragraph textColor="subtle" textAlign="center">
            <FormattedMessage
              id="399cbfba-dd5d-4096-97b7-98c67223a4e8"
              defaultMessage="Your camera has been blocked. Click "
            />
            <img src={mediaBlocked} alt="media blocked" />
            <FormattedMessage
              id="86289af1-8cc0-47cb-995c-a05e4faebba4"
              defaultMessage=" to enable it."
            />
          </Paragraph>
          {allow_access_button}
        </>
      );
    }

    const labels = role === UserRole.NOTARY ? NOTARY_LABELS : SIGNER_LABELS;
    return (
      <>
        <Paragraph textColor="subtle" textAlign="center">
          {intl.formatMessage(labels.videoPermission)}
        </Paragraph>
        {allow_access_button}
      </>
    );
  }

  render() {
    const {
      permissionStatus,
      onAccessGranted,
      onAccessBlocked,
      onMissingWebcam,
      missingEquipment,
    } = this.props;

    return (
      <div className={Styles.permissionContainer}>
        <img
          className="TechCheck--step-icon"
          src={permissionStatus === BLOCKED_PERM ? error : webcam}
          alt=""
        />
        <Heading level="h2" textStyle="headingFour" textAlign="center">
          {missingEquipment ? "No Camera Detected" : "Video"}
        </Heading>
        {this.getContent()}
        {permissionStatus === REQUESTED_PERM && (
          <Webcam
            video
            audio={false}
            width={0}
            height={0}
            onSuccess={onAccessGranted}
            onFailure={(error) =>
              error.name === "NotFoundError" ? onMissingWebcam(error) : onAccessBlocked(error)
            }
          />
        )}
      </div>
    );
  }
}

VideoPermissionGetter.propTypes = {
  role: PropTypes.string.isRequired,
  permissionStatus: PropTypes.string.isRequired,
  onAccessClick: PropTypes.func.isRequired,
  onAccessGranted: PropTypes.func.isRequired,
  onAccessBlocked: PropTypes.func.isRequired,
  onMissingWebcam: PropTypes.func.isRequired,
  missingEquipment: PropTypes.bool,

  // injectIntl
  intl: PropTypes.object.isRequired,
};

VideoPermissionGetter.defaultProps = {
  missingEquipment: null,
};

export default injectIntl(VideoPermissionGetter);
