import type { ComponentProps } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import TtlExpiredAlert, { isTtlExpired } from "common/details/summary/ttl_expired_alert";
import { usePermissions } from "common/core/current_user_role";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME } from "constants/feature_gates";

import DocumentsListContainer from "./list.container";
import DocumentBundleActions from "../actions";
import { asArchive, downloadAllOriginalAssets } from "../save_all";
import type { DocumentBundleForTransactionDetailsList } from "./list_fragment.graphql";

type ListContainerProps = ComponentProps<typeof DocumentsListContainer>;
type Props = {
  bundle: DocumentBundleForTransactionDetailsList;
  lastOpenedId: string | null;
  onDocumentSelected: ListContainerProps["onDocumentSelected"];
  onSupplementaryDocSelected: ListContainerProps["onSupplementaryDocSelected"];
  viewable?: boolean;
  downloadable?: boolean;
  shareable?: boolean;
  showHeader?: boolean;
  showLegalInfoItem?: boolean;
  showMergedDocs?: boolean;
  showNotarialActs?: boolean;
  showResponsiveView?: boolean;
};

function DocumentsListWithActions(props: Props) {
  const { bundle, showResponsiveView } = props;
  const { documents } = bundle;
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermissionFor } = usePermissions();
  const notTtlExpired = !isTtlExpired(documents);
  const finalDocumentExists = Boolean(documents.edges[0]?.node.finalAsset?.filename);
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const organizationName = bundle.transaction.publicOrganization.name;
  const brandName = brandNameEnabled
    ? bundle.transaction.publicOrganization.organizationBrand.name
    : organizationName;

  return (
    <div
      className={`NotarizationDetails-documents ${
        showResponsiveView ? "NotarizationDetails-responsive" : ""
      }`}
    >
      {!showResponsiveView && (
        <div className="NotarizationDetails-documents-ttlwrapper">
          {brandName && <TtlExpiredAlert orgName={brandName} documents={documents} />}
        </div>
      )}
      <DocumentsListContainer
        bundle={bundle}
        viewable={props.viewable}
        ttlExpired={!notTtlExpired}
        showMergedDocs={props.showMergedDocs}
        onDocumentSelected={props.onDocumentSelected}
        onSupplementaryDocSelected={props.onSupplementaryDocSelected}
        showHeader={props.showHeader}
        showLegalInfoItem={props.showLegalInfoItem}
        showNotarialActs={props.showNotarialActs}
        canViewSplittingResults={hasPermissionFor("canViewSplittingResults")}
        lastOpenedId={props.lastOpenedId}
        showResponsiveView={showResponsiveView}
      />
      {(finalDocumentExists || hasPermissionFor("documentActions")) && notTtlExpired && (
        <DocumentBundleActions
          isMultiDocument
          shareAction={
            props.shareable
              ? () => navigate(`bundleShare/${bundle.id}${location.search}`)
              : undefined
          }
          // if we are listing unfinalized docs, there are no final assets to download
          downloadAction={
            bundle.releasable && props.downloadable ? () => asArchive(bundle) : undefined
          }
          uploadedAssetDownloadAction={
            hasPermissionFor("assetDownloadAction")
              ? () => downloadAllOriginalAssets(bundle)
              : undefined
          }
          showDownloadWarning={bundle.downloadWarningEnabled}
        />
      )}
    </div>
  );
}

DocumentsListWithActions.defaultProps = {
  downloadable: true,
  showLegalInfoItem: true,
};

export default DocumentsListWithActions;
