import { endOfDay } from "date-fns";
import { defineMessages, type IntlShape } from "react-intl";

import type { BaseRecordingLocation as RecordingLocation } from "common/mortgage/transactions/recording_location_service";
import { format } from "common/core/format/date";
import { setCookie } from "util/cookie";
import {
  ESIGN_ONLY_TRANSACTIONS,
  LENDER_HYBRID_TRANSACTION_TYPES,
  TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES,
} from "constants/transaction";
import {
  MortgageTransactionType,
  RecordingTypes,
  type AddressType,
  type OrganizationTransactionVariant,
} from "graphql_globals";
import { getTransactionTypeMessage } from "common/dashboard/util";
import { formattedTransactionVariant } from "common/core/format/formatted_transaction_variant";

const allHybridLenderTransactionTypeValues = Object.values(LENDER_HYBRID_TRANSACTION_TYPES);
const allHybridTitleTransactionTypeValues = Object.values(TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES);
const MORTGAGE_TRANSACTION_COMPLETED_COOKIE = "notarize-mortgage-completed";

/**
 * @description Utility method to determine if a transaction type is a hybrid lender one.
 */
function isHybridLenderTransactionType(transactionType: string) {
  return allHybridLenderTransactionTypeValues.includes(transactionType);
}

/**
 * @description Utility method to determine if a transaction type is a hybrid title one.
 */
function isHybridTitleTransactionType(transactionType: string) {
  return allHybridTitleTransactionTypeValues.includes(transactionType);
}

/**
 * @description Utility method to determine if a transaction type is a hybrid one (lender or title).
 */
export function isHybridTransactionType(transactionType: string) {
  return (
    isHybridLenderTransactionType(transactionType) || isHybridTitleTransactionType(transactionType)
  );
}

/**
 * @description Utility method to set cookie of a completed mortgage transaction
 */
export function setTransactionCompletionCookie(organizationTransactionId: string) {
  const options = { expires: endOfDay(new Date()) };
  setCookie(MORTGAGE_TRANSACTION_COMPLETED_COOKIE, organizationTransactionId, options);
}

/**
 * @description Utility method to determine if a recording location supports e-record or papering-out
 * AND notarize supports eClosings in the state.
 */
export function locationRecordable(recordingLocation: RecordingLocation | null) {
  return (
    Boolean(
      recordingLocation?.erecordingSupported ||
        recordingLocation?.recordingType === RecordingTypes.PAPER,
    ) && Boolean(recordingLocation?.usState.ronSupported)
  );
}

/**
 * @description Format a given string timezone with "(GMT {timezone})". This is just for UI purposes.
 *
 * To take care of this potential incompatibility (e.g. "America/New York" vs "America/New_York")
 * we do a string replace of spaces with underscores.
 */
export const timezoneWithOffset = (timezone: string) => {
  if (!timezone) {
    return null;
  }
  const formattedTZ = format({
    value: new Date(),
    asTimeZone: timezone.replace(" ", "_"),
    formatStyle: "OOOO",
  });
  return `(${formattedTZ}) ${timezone}`;
};

/**
 * @description Verify a given address has the required lines filled out
 */
export const isAddress = (address: AddressType): address is AddressType => {
  return (
    Boolean(address) &&
    Boolean(address.line1) &&
    Boolean(address.city) &&
    Boolean(address.postal) &&
    Boolean(address.state) &&
    Boolean(address.country)
  );
};

const TRANSACTION_TYPE_MESSAGES = defineMessages({
  closingType: {
    id: "8390e9c2-6535-4ad6-9491-8373d0be3c6d",
    defaultMessage:
      "{requiresNsaMeeting, select, true {eClose} other {Hybrid}} - {transactionType}",
  },
});

/**
 * @description Utility method to render lender or title transaction type in UI
 */
export function formattedTransactionType({
  requiresNsaMeeting,
  transactionVariant,
  type,
  intl,
  ps1583AttestationEnabled,
}: {
  requiresNsaMeeting: boolean | null;
  type: string | null;
  transactionVariant?: OrganizationTransactionVariant;
  intl: IntlShape;
  ps1583AttestationEnabled?: boolean;
}) {
  if (!type && transactionVariant) {
    return formattedTransactionVariant(transactionVariant, intl, ps1583AttestationEnabled);
  } else if (type === MortgageTransactionType.wet_sign) {
    return getTransactionTypeMessage(MortgageTransactionType.wet_sign, intl);
  } else if (type && ESIGN_ONLY_TRANSACTIONS.includes(type)) {
    return getTransactionTypeMessage(type, intl);
  }
  return intl.formatMessage(TRANSACTION_TYPE_MESSAGES.closingType, {
    requiresNsaMeeting,
    transactionType: getTransactionTypeMessage(type, intl),
  });
}
