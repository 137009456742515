import { memo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Button from "common/core/button";
import { NotaryCapacityType, type NotaryActivationStatus } from "graphql_globals";
import MeetingStats from "common/get_started/business/meeting_stats";

import Styles from "./start_shift_panel.module.scss";

type NotaryProfileWithCapacity = {
  id: string;
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
    completedMeetingCount: number;
  }[];
};

type Props = {
  onStartShift: () => void;
  notaryProfile: NotaryProfileWithCapacity;
};

const MESSAGES = defineMessages({
  onDemandCalls: {
    id: "75957093-7dfe-40aa-9e53-112cead37743",
    defaultMessage: "On-demand calls",
  },
});

function StartShiftPanel({ onStartShift, notaryProfile }: Props) {
  const intl = useIntl();
  return (
    <div className={Styles.panel}>
      <div className={Styles.panelContentWrapper}>
        <MeetingStats
          meetingCount={
            notaryProfile.capacities.find((c) => c.type === NotaryCapacityType.ON_DEMAND)
              ?.completedMeetingCount || 0
          }
          iconName="notaries"
          title={intl.formatMessage(MESSAGES.onDemandCalls)}
        />
        <footer>
          <Button buttonSize="large" buttonColor="action" variant="primary" onClick={onStartShift}>
            <FormattedMessage
              id="a59d02bb-0931-4c31-8944-5594057e2905"
              defaultMessage="Join on-demand queue"
            />
          </Button>
        </footer>
      </div>
    </div>
  );
}

export default memo(StartShiftPanel);
