import { type UsState, makeFontDesc, drawTextAlongArc } from ".";

const CENTER_TEXT = 495;
const FULL_SEAL_WIDTH = 700;

type WrapTextParams = {
  context: CanvasRenderingContext2D;
  text: string;
  x: number;
  y: number;
  maxWidth: number;
  lineHeight: number;
  pixelAdjustmentOnWrap: number;
};

type NameParams = {
  context: CanvasRenderingContext2D;
  name: string;
  usState: UsState;
  isAttorney: boolean | null;
};

type DrawNameParams = {
  context: CanvasRenderingContext2D;
  name: string;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
  maxWidth?: number;
  lineHeight?: number;
  pixelAdjustmentOnWrap?: number;
  textAlign?: CanvasTextAlign;
};

function wrapText({
  context,
  text,
  x,
  y,
  maxWidth,
  lineHeight,
  pixelAdjustmentOnWrap,
}: WrapTextParams) {
  let line = "";
  for (const word of text.split(" ")) {
    const testLine = `${line}${word} `;
    const { width } = context.measureText(testLine);
    if (width > maxWidth && line) {
      context.fillText(line.trim(), x, y - pixelAdjustmentOnWrap);
      line = `${word} `;
      y += lineHeight - pixelAdjustmentOnWrap;
    } else {
      line = testLine;
    }
  }
  context.fillText(line.trim(), x, y);
}

function drawName({
  context,
  name,
  fontSize = 24,
  bold = true,
  x = CENTER_TEXT,
  y = 80,
  maxWidth = 400,
  lineHeight = 21,
  pixelAdjustmentOnWrap = 0,
  textAlign = "center",
}: DrawNameParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  wrapText({ context, text: name, x, y, maxWidth, lineHeight, pixelAdjustmentOnWrap });
}

function drawTennesseeName(name: string, context: CanvasRenderingContext2D) {
  context.fillStyle = "#0E3472";
  const centerX = 287;
  const centerY = 245;
  const angle = Math.PI * 0.8;
  const radius = 160;
  context.font = makeFontDesc(20, false);
  drawTextAlongArc({ context, str: name.toUpperCase(), centerX, centerY, radius, angle });
}

function drawMontanaName(name: string, context: CanvasRenderingContext2D) {
  drawName({
    context,
    name: name.toUpperCase(),
    bold: false,
    x: 485,
    y: 70,
  });
  const centerX = 150;
  const centerY = 148;
  const angle = 3;
  const radius = 76;
  context.font = makeFontDesc(18, false);
  drawTextAlongArc({ context, str: name.toUpperCase(), centerX, centerY, radius, angle });
}

export function placeName({ context, name, usState, isAttorney }: NameParams) {
  switch (usState) {
    case "TX":
    case "VA":
      return drawName({ context, name });
    case "MD":
      return drawName({
        context,
        name: name.toUpperCase(),
        fontSize: 26,
        y: 70,
        x: 360,
      });
    case "FL":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        y: 70,
      });
    case "NV":
      return drawName({
        context,
        name,
        y: 50,
      });
    case "IN":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 445,
        y: 90,
        fontSize: 26,
        lineHeight: 22,
      });
    case "CO":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 360,
        y: 50,
        bold: false,
        fontSize: 28,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "AZ":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 475,
      });
    case "IA":
      return drawName({
        context,
        name: name.toUpperCase(),
        fontSize: 28,
        x: 375,
      });
    case "MI":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 360,
        y: 70,
      });
    case "MO":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 360,
        y: 50,
        fontSize: 28,
        bold: false,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "MT":
      return drawMontanaName(name, context);
    case "ID":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 360,
        y: 70,
        fontSize: 28,
      });
    case "KY":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 375,
        y: 70,
      });
    case "MN":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: true,
        x: 415,
        y: 60,
      });
    case "ND":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 310,
        y: 70,
      });
    case "NE":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 355,
        y: 75,
      });
    case "OH":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        fontSize: 24,
        x: 475,
        y: 90,
        pixelAdjustmentOnWrap: 20,
      });
    case "OK":
      return drawName({
        context,
        name: name.toUpperCase(),
        x: 350,
      });
    case "PA":
      return drawName({
        context,
        name: `${name}, Notary Public`,
        x: 360,
        y: 105,
        bold: false,
        fontSize: 26,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "TN":
      return drawTennesseeName(name, context);
    case "WA":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 375,
        y: 70,
        fontSize: 35,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "AK":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 360,
        y: 110,
        fontSize: 28,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "NY":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 360,
        y: 65,
        fontSize: 40,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "NJ":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: true,
        x: 360,
        y: 80,
        fontSize: 34,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "WI":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: true,
        x: 360,
        y: 70 + (isAttorney ? 30 : 0),
        fontSize: 34,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "NH":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: true,
        x: 360,
        y: 95,
        fontSize: 30,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "AR":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: true,
        x: 50,
        y: 55,
        fontSize: 34,
        maxWidth: FULL_SEAL_WIDTH,
        textAlign: "left",
      });
    case "KS":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: true,
        x: 360,
        y: 65,
        fontSize: 34,
        maxWidth: FULL_SEAL_WIDTH,
      });
    case "WV":
      return drawName({
        context,
        name: name.toUpperCase(),
        bold: false,
        x: 460,
        y: 140,
        fontSize: 18,
        maxWidth: FULL_SEAL_WIDTH,
      });
    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
