import { useLocation } from "react-router-dom";
import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { NetworkStatus } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { isNotaryIHN, isNotaryNST } from "common/notary/capacity";
import { NotaryAccountStatuses } from "graphql_globals";
import NotaryInvalidProfileImg from "assets/images/remove-signer.svg";
import Link from "common/core/link";
import { useViewer } from "util/viewer_wrapper";
import { SETTINGS_PATH, TRANSACTION_PATH } from "util/routes";
import { TEAM_PATH } from "common/proof_frame/path";

import type { NotaryProfileDisabled as Viewer } from "./notary_profile_disabled.graphql";
import Styles from "./notary_profile_disabled.module.scss";

const allowedRoutes = ["/journal", "/notary-meeting-history", SETTINGS_PATH];

const ignoreForIhn = [TRANSACTION_PATH, `${TEAM_PATH}/member/active`, TEAM_PATH];

function isRouteAllowed(isIhn: boolean, pathname: string) {
  return (
    (pathname.startsWith("/settings") && pathname !== "/settings/api") ||
    allowedRoutes.includes(pathname) ||
    (isIhn && ignoreForIhn.includes(location.pathname))
  );
}

function NotaryProfileDisabledRedirect({ children }: { children: ReactNode }) {
  const location = useLocation();
  const { viewer, networkStatus } = useViewer<Viewer>();

  const notaryProfile = viewer.user?.notaryProfile || null;

  const isDisabled = notaryProfile?.accountStatus === NotaryAccountStatuses.DISABLED;
  const isIhn = isNotaryIHN(notaryProfile);
  const isNst = isNotaryNST(notaryProfile);

  if (networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }

  if (isDisabled && !isRouteAllowed(isIhn, location.pathname) && (isNst || isIhn)) {
    return (
      <div className={Styles.error}>
        <img src={NotaryInvalidProfileImg} alt="" aria-hidden="true" />
        <div className={Styles.heading}>
          <FormattedMessage
            id="d2d96ae8-6686-4cd0-92e1-39dc61185154"
            defaultMessage="Uh-oh, you can’t access this page... "
          />
        </div>
        <div className={Styles.body}>
          <FormattedMessage
            id="c2eac40c-81ef-4d60-b219-70ebd260b0fa"
            defaultMessage="Your account is disabled. Contact someone at Notarize if you have questions. In the meantime, visit <journalLink>Notary Journal</journalLink> or <historyLink>Meeting History</historyLink>."
            values={{
              journalLink: (msg) => (
                <Link href="/journal" openInCurrentTab>
                  {msg}
                </Link>
              ),
              historyLink: (msg) => (
                <Link href="/notary-meeting-history" openInCurrentTab>
                  {msg}
                </Link>
              ),
            }}
          />
        </div>
      </div>
    );
  }
  return <>{children}</>;
}

export default NotaryProfileDisabledRedirect;
