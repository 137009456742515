import { memo } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { UserRole } from "graphql_globals";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";

import TechCheck from ".";
import { NOTARY_STEPS } from "./step";
import NotaryTechCheckQuery from "./notary_tech_check.graphql";

const ANALYTICS_LABEL_NOTARY = "Notary";

function NotaryTechCheck() {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const { loading, data } = useQuery(NotaryTechCheckQuery);
  if (loading) {
    return <LoadingIndicator />;
  }
  const notaryProfile = data!.viewer.user!.notaryProfile;
  return (
    <TechCheck
      notaryProfile={notaryProfile}
      basePath="/tech-check"
      nextRoute="/"
      exitRoute="/tech-check"
      role={UserRole.NOTARY}
      roleLabel={ANALYTICS_LABEL_NOTARY}
      steps={NOTARY_STEPS}
      currentStep={stepId}
      navigate={navigate}
    />
  );
}

export default memo(NotaryTechCheck);
