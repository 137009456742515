import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import WorkflowModal from "common/modals/workflow_modal";

import DotloopWizard from "./dotloop_wizard";
import Styles from "./dotloop.module.scss";

const QUERY_ARG = "import_loop";

export function useDotLoop() {
  const [rawQueryArgs, setRawQueryArgs] = useSearchParams();

  const showDotloopModal = Boolean(rawQueryArgs.get(QUERY_ARG));
  const enableDotloopModal = () => {
    rawQueryArgs.append(QUERY_ARG, "true");
    setRawQueryArgs(rawQueryArgs);
  };
  const closeDotloopModal = () => {
    rawQueryArgs.delete(QUERY_ARG);
    setRawQueryArgs(rawQueryArgs);
  };

  return {
    showDotloopModal,
    enableDotloopModal,
    closeDotloopModal,
  };
}

export function DotLoopPopoutItem({ onClick }: { onClick: () => void }) {
  return (
    <PopoutMenuMultilineItem
      data-automation-id="new-dotloop-transaction"
      onClick={onClick}
      primaryContent={
        <FormattedMessage id="775c0030-ba42-49c3-a955-b2306ffbc7cf" defaultMessage="Loop" />
      }
      iconName="notarize-filled"
    />
  );
}

export function DotLoopModal({ onClose }: { onClose: () => void }) {
  return (
    <WorkflowModal className={Styles.dotloopModal}>
      <DotloopWizard onCancel={onClose} />
    </WorkflowModal>
  );
}
