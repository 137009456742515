import { lazy, type ReactNode, type ComponentProps, type ReactElement, Suspense } from "react";
import {
  Routes,
  Navigate,
  Outlet,
  Route,
  useMatch,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { EasylinkTypes, Feature } from "graphql_globals";
import { TIER_PATH } from "constants/tier";
import {
  useIsAuthenticated,
  HardLoginRedirect,
  SingleShotAuthenticationProvider,
} from "common/authentication";
import { MfaSetupBlocker } from "common/authentication/mfa_setup_blocker";
import SessionExpiredModal from "common/authorization/session_expired_modal";
import App from "common/app";
import { composeViewerWrappers, createViewerManager, useViewer } from "util/viewer_wrapper";
import { useMatchManyFirstPathParts } from "util/location";
import APP from "constants/applications";
import { SigningContextWrapper, useMeetingIdParam } from "common/signing_context";
import ResetPassword from "common/account/login/reset_password";
import TierUpgrade from "common/tier/upgrade";
import SignupV2 from "common/account/signup_v2";
import RealtorSignup from "common/account/realtor_signup";
import Activation from "common/account/activation";
import ExportModal from "common/transactions/transaction_v2/export";
import LinkBuilder from "common/link_builder";
import ShortcutCheatsheet from "common/shortcut_cheatsheet";
import type { DefaultAppFrameWithQuery } from "common/app_frame/default";
import TermsOfServiceBlocking from "common/modals/terms_of_service/blocking";
import ExpiredCertBlocker from "common/modals/expired_cert";
import TierBlocker from "common/tier/blocker";
import { BusinessRedirectChecker } from "common/authorization";
import SupportChat from "common/support/chat";
import { businessDashboardDeserializer } from "business_portal/dashboard/filter";
import Logger from "common/logger";
import VerifyEnabled from "common/organization/authorization/verify_enabled";
import OrganizationRoleContext from "common/organization/organization_role_context";
import { RecipientColorProvider } from "common/pdf/recipient_colors/context";
import {
  OrganizationFeatureGatingSpecialization,
  useFeatureGatingSpecialization,
} from "common/feature_gating/specialization";
import InvalidPaymentBlocker from "common/configured/invalid_payment";
import FeatureAccessGate from "common/configured/feature_access_gate";
import { OnboardingSlideshow } from "business_portal/onboarding/slideshow";
import { GetStarted } from "common/get_started/business";
import ActivityLogs from "common/transactions/activity_logs";
import AudioVideoSettings from "common/meeting/audio_video_settings";
import MeetingCompleted from "common/meeting/completed";
import Meeting from "business_portal/meeting";
import { OrganizationTemplates } from "common/organization_templates";
import OrganizationTemplatePrepare from "common/organization_templates/prepare";
import OrganizationTemplateEdit from "common/organization_templates/edit";
import OrganizationTemplateTools from "common/organization_templates/tools";
import BusinessEmployeeTransactions from "common/employees/transactions";
import TransactionDetailsContainer from "business_portal/transactions/details_container";
import { SummaryContainer } from "common/details/summary";
import Signer from "common/details/recipients/signer";
import IdentityDetails from "common/details/identity";
import { DeprecatedNotaryDetailsContainer } from "common/details/meeting/notary_details/deprecated/deprecated_index";
import { DeprecatedNotaryDetail } from "common/details/meeting/notary_details/deprecated/deprecated_notary_detail";
import { DeprecatedVideo } from "common/details/meeting/videos/deprecated/deprecated_video";
import { DeprecatedWitness } from "common/details/meeting/witness_details/deprecated_index";
import { DeprecatedVideoContainer } from "common/details/meeting/videos/deprecated/deprecated_index";
import TransactionDetailsBundle from "common/details/bundle";
import History from "common/details/history";
import InstructionDetails from "common/details/instructions";
import RealtorClosingsDashboard from "business_portal/realtor/closings/dashboard";
import NewTransaction from "business_portal/transactions/new";
import TransactionEditContainer from "common/transactions/transaction_v2";
import TransactionEmployeeDetails from "common/transactions/employee_details";
import { OnlineHeartBeatUnavailable } from "common/notary/online_heartbeat";
import { NotaryQueueProvider } from "common/notary/queue";
import BusinessTransactions from "business_portal/transactions";
import NotarySignup from "business_portal/notary/signup";
import { ON_DEMAND_QUEUE_PATH } from "common/notary/queue/path";
import NotaryOnboarding from "common/notary/onboarding";
import { EASYLINK_DASHBOARD_PATH, TEMPLATES_DASHBOARD_PATH, TRANSACTION_PATH } from "util/routes";
import { SCHEDULED_PATH } from "common/closing_agenda/path";
import { PATH as NOTARY_MEETING_HISTORY_PATH } from "common/notary/meeting_history/path";
import {
  BASE_PATH as JOURNAL_BASE_PATH,
  COPY_REQUEST_PATH,
  CERTIFY_PATH,
  DOWNLOAD_EXPORT_PATH,
} from "common/notary/journal/routing";
import NotaryTechCheck from "common/tech_check/notary_tech_check";
import MeetingSimulator from "common/meeting_simulator";
import Settings from "common/settingsv2";
import { makeUserManagementRoutes } from "common/organization/member_management/routing";
import NotaryProfileDisabledRedirect from "common/configured/notary_profile_disabled";
import NotaryMfaDisabled from "common/notary/notary_mfa_disabled";
import { BusinessDashboard } from "business_portal/dashboard";
import { EasylinkManager } from "common/dashboard/easy_link";
import { EasylinkCreateForm } from "common/dashboard/easy_link/create";
import { EasylinkEditForm } from "common/dashboard/easy_link/edit";
import NotaryMfaBlocker from "common/notary/notary_mfa_blocker";
import LoadingIndicator from "common/core/loading_indicator";
import ProofFrame from "common/proof_frame";
import { GET_STARTED_PATH } from "common/proof_frame/path";
import { useFeatureFlag } from "common/feature_gating";
import { MOBILE_BUSINESS_PORTAL } from "constants/feature_gates";
import { ClearPendoTransforms } from "util/pendo";
import {
  PermissionRedirect as BasePermissionRedirect,
  type OrganizationPermissions,
} from "common/core/current_user_role";
import { ReferralsCreateForm } from "business_portal/dashboard/referrals/create";
import { EditReferralCampaign } from "business_portal/dashboard/referrals/edit";
import { makeSetActiveOrgAndRedirectRoute } from "common/account/active_organization/routing";
import {
  BusinessAccountOnboarding,
  BusinessAccountOnboardingWrapper,
} from "business_portal/onboarding";
import { STEPS } from "common/onboarding/steps";
import { useProMobileOnboarding, useTxnDetailsRedesign } from "util/feature_detection";
import { isIndependentNotary } from "common/notary/capacity";
import { isMobileDevice } from "util/support";
import { RecipientsContainer } from "common/details/recipients";
import { MeetingContainer } from "common/details/meeting";
import ErrorBoundary from "common/error_boundary";
import PointsOfContactDetails from "common/details/recipients/points_of_contact";

import ViewerQuery, { type BusinessRouterViewer_viewer as Viewer } from "./viewer_query.graphql";
import RedirectRoot from "./redirect_root";

const VerifyMeeting = lazy(() => import("business_portal/verify_meeting/meeting"));
const VerifyMeetingComplete = lazy(() => import("business_portal/verify_meeting/meeting/complete"));
const TrustedRefereeMeeting = lazy(() => import("business_portal/trusted_referee/meeting"));
const TrustedRefereeMeetingCompleted = lazy(
  () => import("business_portal/trusted_referee/post_meeting/completed"),
);
const TrustedRefereeMeetingTerminated = lazy(
  () => import("business_portal/trusted_referee/post_meeting/terminated"),
);
const RemoteWitnessMeeting = lazy(() => import("common/meeting/witness"));
const VerifyEmail = lazy(() => import("common/account/verify_email"));
const MeetingSuccess = lazy(() => import("common/meeting_success"));
const NotaryJournal = lazy(() => import("common/notary/journal"));
const NotaryJournalCertifyCopy = lazy(() => import("common/notary/journal/certify_copy"));
const NotaryJournalDownload = lazy(() => import("common/notary/journal/download"));
const NotaryJournalCopyRequest = lazy(() => import("common/notary/journal/copy_request"));
const NotaryOnDemandQueue = lazy(() => import("business_portal/notary/on_demand_queue"));
const ClosingAgenda = lazy(() => import("business_portal/closing_agenda"));
const NotaryMeetingHistoryDetails = lazy(() => import("common/notary/meeting_history/details"));
const NotaryMeetingHistory = lazy(() => import("common/notary/meeting_history"));
const BusinessNotaryMeeting = lazy(() => import("business_portal/notary/meeting"));
const NotaryMeetingTerminationReport = lazy(
  () => import("common/notary/meeting_termination_report"),
);
const NotarySettings = lazy(() => import("common/settingsv2/sidebar_settings/notary"));
const Referrals = lazy(() => import("business_portal/dashboard/referrals"));
const PanelMembershipDetails = lazy(() => import("common/notary/panel/membership_details"));
const TransactionCreationForm = lazy(() => import("common/transaction_creation/v3/form"));
const ExemplarDownload = lazy(() => import("common/notary/exemplar/download"));

const ENTRY = APP.BUSINESS;

// Mobile routes supported for independent notaries (NST/ODN)
const INDEPENDENT_NOTARY_MOBILE_ROUTES = new Set([
  "reset_password",
  "verify-account",
  "guest/transaction",
  "upgrade",
  "mobile-handoff",
  "signup",
  "login",
  "meeting",
]);

// Mobile routes *not* supported for independent notaries (NST/ODN)
const MOBILE_ROUTES = new Set([
  "/",
  "onboarding",
  "get-started",
  "member-management",
  "settings",
  "scheduled-meetings",
  "transaction",
]);

// "pro-mobile-onboarding" flag cleanup - spread ...INDEPENDENT_NOTARY_MOBILE_ROUTES onto MOBILE_ROUTES
const DEPRECATED_MOBILE_ROUTES = new Set([...INDEPENDENT_NOTARY_MOBILE_ROUTES]);
const MOBILE_ROUTES_WITH_ROOT = new Set([...INDEPENDENT_NOTARY_MOBILE_ROUTES, "/"]);

function useOrganizationFeatureGatingSpecialization(viewer: Viewer | undefined): boolean {
  const user = viewer?.user;
  const orgUser = user?.organization ? { key: user.organization.id, email: user.email } : undefined;
  return useFeatureGatingSpecialization(orgUser);
}

const ViewerManager = createViewerManager<Viewer>(ViewerQuery, [
  useOrganizationFeatureGatingSpecialization,
]);
const DefaultWrapperWithoutFrame = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  BusinessRedirectChecker,
  VerifyEnabled,
  TierBlocker,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  Logger,
  MfaSetupBlocker,
  ExpiredCertBlocker,
);
const MeetingWrapper = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  BusinessRedirectChecker,
  VerifyEnabled,
  TierBlocker,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  Logger,
  MfaSetupBlocker,
  ExpiredCertBlocker,
);
const DefaultWrapperUnauth = composeViewerWrappers<Viewer>(SupportChat);

const Frame = (props: ComponentProps<typeof DefaultAppFrameWithQuery>) => {
  return (
    <ProofFrame portal="business">
      <ClearPendoTransforms />
      {props.children}
    </ProofFrame>
  );
};

function DefaultWrapper({
  children,
  withoutFrame,
}: {
  children?: ReactElement;
  withoutFrame?: boolean;
}) {
  return withoutFrame ? (
    <DefaultWrapperWithoutFrame>{children || <Outlet />}</DefaultWrapperWithoutFrame>
  ) : (
    <DefaultWrapperWithoutFrame>
      <NotaryQueueProvider>
        <Frame>
          <NotaryProfileDisabledRedirect>{children || <Outlet />}</NotaryProfileDisabledRedirect>
        </Frame>
      </NotaryQueueProvider>
    </DefaultWrapperWithoutFrame>
  );
}

function PermissionRedirect({
  permissions,
  children,
}: {
  permissions: OrganizationPermissions[];
  children?: ReactNode;
}) {
  return (
    <BasePermissionRedirect redirectRoute={<RedirectRoot />} permissions={permissions}>
      {children}
    </BasePermissionRedirect>
  );
}

function WithPaidFeatureAccessCheck({ children }: { children?: ReactNode }) {
  return (
    <InvalidPaymentBlocker>
      <FeatureAccessGate>{children || <Outlet />}</FeatureAccessGate>
    </InvalidPaymentBlocker>
  );
}

function WithMfaCheck({ children }: { children?: ReactNode }) {
  return <NotaryMfaDisabled>{children || <Outlet />}</NotaryMfaDisabled>;
}

function WithMfaCheckAndBlock({
  children,
  dismissableOnly,
}: {
  children?: ReactNode;
  dismissableOnly?: boolean;
}) {
  return (
    <NotaryMfaBlocker dismissableOnly={dismissableOnly}>
      <NotaryMfaDisabled>{children || <Outlet />}</NotaryMfaDisabled>
    </NotaryMfaBlocker>
  );
}

function WithPaidFeatureAccessCheckAndMfaCheck({ children }: { children?: ReactNode }) {
  return (
    <WithPaidFeatureAccessCheck>
      <NotaryMfaBlocker>
        <NotaryMfaDisabled>{children || <Outlet />}</NotaryMfaDisabled>
      </NotaryMfaBlocker>
    </WithPaidFeatureAccessCheck>
  );
}

/* Use as Route element where possible so that parent element stays the same between routes, ensuring single page navigation and preventing unneccessary refresh. Additionally, the <Suspense/> below will keep the nav/sidebar mounted and only put up a loading indicator for the main content when lazy loading. */
function RouteWrapper({
  children,
  withoutFrame,
}: {
  children?: ReactElement;
  withoutFrame?: boolean;
}) {
  return (
    <DefaultWrapper withoutFrame={withoutFrame}>
      <Suspense fallback={<LoadingIndicator />}>{children || <Outlet />}</Suspense>
    </DefaultWrapper>
  );
}

function RedirectToBusinessPro() {
  return <Navigate to={`/signup/${TIER_PATH.BUSINESS_PRO_ODN}`} />;
}

function WithOnboarding({ children }: { children?: ReactElement }) {
  const proMobileOnboardingEnabled = useProMobileOnboarding();

  if (proMobileOnboardingEnabled) {
    return (
      <BusinessAccountOnboardingWrapper>{children || <Outlet />}</BusinessAccountOnboardingWrapper>
    );
  }
  return <OnboardingSlideshow>{children || <Outlet />}</OnboardingSlideshow>;
}

function TransactionDetailsRoutes({ isTxnDetailsRedesign }: { isTxnDetailsRedesign: boolean }) {
  return (
    <TransactionDetailsContainer>
      {(props) => (
        <Routes>
          <Route path="summary" element={<SummaryContainer {...props} />} />
          <Route
            path="user"
            element={
              isTxnDetailsRedesign ? <RecipientsContainer {...props} /> : <Signer {...props} />
            }
          />
          <Route
            path="contacts"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../user" replace />
              ) : (
                <PointsOfContactDetails ccRecipientsOnly {...props} />
              )
            }
          />
          {isTxnDetailsRedesign && (
            <Route path="meeting" element={<MeetingContainer {...props} />} />
          )}
          <Route path="identity" element={<IdentityDetails {...props} />} />
          <Route
            path="notary"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedNotaryDetailsContainer {...props} />
              )
            }
          />
          <Route
            path="witness"
            element={
              isTxnDetailsRedesign ? <Navigate to="../meeting" /> : <DeprecatedWitness {...props} />
            }
          />
          <Route path="document" element={<TransactionDetailsBundle {...props} />} />
          <Route
            path="video"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedVideoContainer {...props} />
              )
            }
          />
          <Route path="history" element={<History {...props} />} />
          <Route path="notes" element={<InstructionDetails {...props} />} />
          <Route path="activity" element={<ActivityLogs {...props} />} />
          <Route path="*" element={<Navigate replace to="summary" />} />
        </Routes>
      )}
    </TransactionDetailsContainer>
  );
}

const UNAUTH_ROUTES = (
  <Route element={<DefaultWrapperUnauth />}>
    <Route path="/reset_password/:token" element={<ResetPassword />} />

    <Route path="/signup">
      <Route path={TIER_PATH.BASIC} element={<RedirectToBusinessPro />} />
      <Route
        path={TIER_PATH.BUSINESS_PRO_IHN}
        element={
          <SignupV2
            tierPath={TIER_PATH.BUSINESS_PRO_IHN}
            selectedAccount={APP.BUSINESS}
            entry={ENTRY}
          />
        }
      />
      <Route
        path={TIER_PATH.BUSINESS_PRO_ODN}
        element={
          <SignupV2
            tierPath={TIER_PATH.BUSINESS_PRO_ODN}
            selectedAccount={APP.BUSINESS}
            entry={ENTRY}
          />
        }
      />
      <Route path={TIER_PATH.ELITE} element={<RedirectToBusinessPro />} />
      <Route path={TIER_PATH.NOTARY_MARKETPLACE_BASIC} element={<NotarySignup />} />
      <Route path="realtor" element={<RealtorSignup />} />
      {/* This also matches routes like /signup/choose and /signup/title-agency for backwards compatibility with marketing site */}
      <Route index element={<Navigate to={TIER_PATH.BUSINESS_PRO_ODN} />} />
      <Route path="*" element={<Navigate to={TIER_PATH.BUSINESS_PRO_ODN} />} />
    </Route>

    <Route path="*" element={<HardLoginRedirect />} />
  </Route>
);

const authRoutes = (transactionDetailsRedesignFlag: boolean, showMobileDeviceContent: boolean) => {
  return (
    <>
      {/* needed to prevent redirecting to default route in AUTH_ROUTES after signup */}
      <Route path="/signup/*" element={<LoadingIndicator />} />

      <Route
        path="/onboarding"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["addTeamMember", "editOrganizationDetails"]} />
          </RouteWrapper>
        }
      >
        <Route path=":step" element={<BusinessAccountOnboarding />} />
        <Route index element={<Navigate to={STEPS.INDUSTRY} />} />
      </Route>

      <Route
        path={GET_STARTED_PATH}
        element={
          <RouteWrapper>
            <PermissionRedirect
              permissions={["sendOrganizationTransactions", "viewEasyLinks", "manageTemplates"]}
            >
              <WithMfaCheckAndBlock dismissableOnly>
                <WithOnboarding>
                  <GetStarted showMobileDeviceContent={showMobileDeviceContent} />
                </WithOnboarding>
              </WithMfaCheckAndBlock>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/notary-panel-memberships/:panelNotaryMembershipId"
        element={
          <RouteWrapper>
            <WithMfaCheck>
              <PanelMembershipDetails />
            </WithMfaCheck>
          </RouteWrapper>
        }
      />

      <Route path="/settings/notary" element={<RouteWrapper />}>
        <Route path="onboarding" element={<NotaryOnboarding />} />
        <Route path="onboarding/:stepId" element={<NotaryOnboarding />} />
      </Route>

      <Route
        path="/settings/notary"
        element={
          <RouteWrapper>
            <WithMfaCheck />
          </RouteWrapper>
        }
      >
        <Route path="profile" element={<NotarySettings />} />
        <Route path="profile/:sectionId" element={<NotarySettings />} />
      </Route>

      <Route
        path={"/exemplar/download-export/:documentBundleId"}
        element={
          <RouteWrapper>
            <ExemplarDownload />
          </RouteWrapper>
        }
      />

      <Route
        path="/settings/*"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationDetails"]}>
              <WithMfaCheck>
                <Settings showMobileDeviceContent={showMobileDeviceContent} />
              </WithMfaCheck>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/notary-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <OnlineHeartBeatUnavailable>
                <SigningContextWrapper useSiginingContextId={useMeetingIdParam}>
                  <BusinessNotaryMeeting />
                </SigningContextWrapper>
              </OnlineHeartBeatUnavailable>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/remote-witness-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <OnlineHeartBeatUnavailable>
              <RemoteWitnessMeeting />
            </OnlineHeartBeatUnavailable>
          </RouteWrapper>
        }
      />

      <Route
        path="/verify-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <VerifyMeeting defaultReturnPath="/verify-meeting-complete" />
          </RouteWrapper>
        }
      />

      <Route
        path="/verify-meeting-complete"
        element={
          <RouteWrapper withoutFrame>
            <VerifyMeetingComplete />
          </RouteWrapper>
        }
      />

      <Route
        path="/referee-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <OnlineHeartBeatUnavailable>
              <TrustedRefereeMeeting />
            </OnlineHeartBeatUnavailable>
          </RouteWrapper>
        }
      />

      <Route
        path="/referee-meeting/:meetingId/terminated"
        element={
          <RouteWrapper withoutFrame>
            <OnlineHeartBeatUnavailable>
              <TrustedRefereeMeetingTerminated />
            </OnlineHeartBeatUnavailable>
          </RouteWrapper>
        }
      />

      <Route
        path="/referee-meeting/:meetingId/completed"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <OnlineHeartBeatUnavailable>
                <TrustedRefereeMeetingCompleted />
              </OnlineHeartBeatUnavailable>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/meeting-termination-report/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <OnlineHeartBeatUnavailable>
                <NotaryMeetingTerminationReport defaultReturnPath={ON_DEMAND_QUEUE_PATH} />
              </OnlineHeartBeatUnavailable>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/meeting_success/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <OnlineHeartBeatUnavailable>
                <MeetingSuccess defaultReturnPath={SCHEDULED_PATH} />
              </OnlineHeartBeatUnavailable>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/simulated-meeting/:meetingId"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <MeetingSimulator />
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/meeting/:meetingId"
        element={
          <MeetingWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <AudioVideoSettings>
                <Meeting />
              </AudioVideoSettings>
            </PermissionRedirect>
          </MeetingWrapper>
        }
      />

      <Route
        path="/meeting/completed"
        element={
          <MeetingWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <MeetingCompleted />
            </PermissionRedirect>
          </MeetingWrapper>
        }
      />

      <Route
        path="/templates"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["manageTemplates"]}>
              <WithPaidFeatureAccessCheck />
            </PermissionRedirect>
          </RouteWrapper>
        }
      >
        <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
        <Route path=":templateId/new" element={<OrganizationTemplateEdit />} />
        <Route path=":templateId/edit" element={<OrganizationTemplateEdit />} />
        <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
        <Route index element={<OrganizationTemplates />} />
      </Route>

      <Route
        path="/realtor_closings"
        element={
          <RouteWrapper>
            <WithPaidFeatureAccessCheck>
              <RealtorClosingsDashboard />
            </WithPaidFeatureAccessCheck>
          </RouteWrapper>
        }
      />

      <Route
        path={ON_DEMAND_QUEUE_PATH}
        element={
          <RouteWrapper>
            <WithMfaCheckAndBlock>
              <NotaryOnDemandQueue />
            </WithMfaCheckAndBlock>
          </RouteWrapper>
        }
      />
      {/* Can remove with notary-meeting-consolidation removal */}
      <Route
        path={SCHEDULED_PATH}
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <WithPaidFeatureAccessCheckAndMfaCheck>
                <ClosingAgenda showMobileDeviceContent={showMobileDeviceContent} />
              </WithPaidFeatureAccessCheckAndMfaCheck>
            </PermissionRedirect>
          </RouteWrapper>
        }
      >
        <Route path=":filter" element={null} />
      </Route>
      <Route
        path="/meetings/*"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <WithMfaCheckAndBlock>
                <ClosingAgenda />
              </WithMfaCheckAndBlock>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />
      {/* Can remove with notary-meeting-consolidation removal */}
      <Route
        path={NOTARY_MEETING_HISTORY_PATH}
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <NotaryMeetingHistory />
            </PermissionRedirect>
          </RouteWrapper>
        }
      >
        <Route path="page/:page" element={null} />
        <Route
          path="page/:page/:meetingId/*"
          element={
            <NotaryMeetingHistoryDetails>
              {({ meeting }) => (
                <Routes>
                  <Route
                    path="notary"
                    element={<DeprecatedNotaryDetail useWrapperDiv meeting={meeting} />}
                  />
                  <Route
                    path="video"
                    element={<DeprecatedVideo useWrapperDiv meeting={meeting} />}
                  />
                </Routes>
              )}
            </NotaryMeetingHistoryDetails>
          }
        />
      </Route>

      <Route path={JOURNAL_BASE_PATH} element={<RouteWrapper />}>
        <Route path={COPY_REQUEST_PATH} element={<NotaryJournalCopyRequest />} />
        <Route path={CERTIFY_PATH} element={<NotaryJournalCertifyCopy />} />
        <Route path={DOWNLOAD_EXPORT_PATH} element={<NotaryJournalDownload />} />
        <Route index element={<NotaryJournal />} />
      </Route>

      <Route
        path="/transaction"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <WithMfaCheckAndBlock>
                <WithPaidFeatureAccessCheck />
              </WithMfaCheckAndBlock>
            </PermissionRedirect>
          </RouteWrapper>
        }
      >
        <Route index element={<Navigate replace to={TRANSACTION_PATH} />} />
        <Route path="new" element={<NewTransaction />} />
        <Route path="update/:transactionId" element={<TransactionEditContainer />} />
        <Route path="esign/:transactionId" element={<TransactionEditContainer />} />
        <Route path="proof/:transactionId" element={<TransactionEditContainer />} />
        <Route path=":transactionId" element={<TransactionCreationForm />} />
      </Route>

      <Route
        path="/"
        element={
          <RouteWrapper>
            <WithPaidFeatureAccessCheckAndMfaCheck>
              <BusinessDashboard showMobileDeviceContent={showMobileDeviceContent} />
            </WithPaidFeatureAccessCheckAndMfaCheck>
          </RouteWrapper>
        }
      >
        <Route index element={<RedirectRoot />} />
        <Route
          path={TRANSACTION_PATH}
          element={
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <WithOnboarding>
                <BusinessTransactions showMobileDeviceContent={showMobileDeviceContent} />
              </WithOnboarding>
            </PermissionRedirect>
          }
        >
          <Route
            path="export"
            element={<ExportModal deserializer={businessDashboardDeserializer} />}
          />
          <Route path="employee/:orgMembershipId" element={<TransactionEmployeeDetails />} />
          {!transactionDetailsRedesignFlag && (
            // Old transaction details MODAL
            <Route
              path=":transactionID/*"
              element={
                <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
              }
            />
          )}
        </Route>

        <Route path={EASYLINK_DASHBOARD_PATH}>
          <Route
            index
            element={
              <PermissionRedirect permissions={["viewEasyLinks"]}>
                <EasylinkManager
                  allowedEasylinkTypes={[
                    EasylinkTypes.IDENTIFY,
                    EasylinkTypes.DOCUMENTS_PROVIDED,
                    EasylinkTypes.SIGNER_UPLOAD,
                  ]}
                />
              </PermissionRedirect>
            }
          />
        </Route>

        <Route path="/referrals">
          <Route
            index
            element={
              <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
                <Referrals />
              </PermissionRedirect>
            }
          />
        </Route>

        <Route
          path={TEMPLATES_DASHBOARD_PATH}
          element={<PermissionRedirect permissions={["manageTemplates"]} />}
        >
          <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
          <Route path=":templateId/new" element={<OrganizationTemplateEdit />} />
          <Route path=":templateId/edit" element={<OrganizationTemplateEdit />} />
          <Route index element={<OrganizationTemplates dashboardMode />} />
        </Route>
      </Route>
      {transactionDetailsRedesignFlag && (
        // New transaction details PAGE
        <Route
          path={`${TRANSACTION_PATH}/:transactionID/*`}
          element={
            <RouteWrapper>
              <WithPaidFeatureAccessCheckAndMfaCheck>
                <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
              </WithPaidFeatureAccessCheckAndMfaCheck>
            </RouteWrapper>
          }
        />
      )}
      <Route
        path="/"
        element={
          <RouteWrapper>
            <WithPaidFeatureAccessCheck />
          </RouteWrapper>
        }
      >
        <Route
          path="/referrals/new"
          element={
            <PermissionRedirect permissions={["createReferralCampaigns"]}>
              <ReferralsCreateForm />
            </PermissionRedirect>
          }
        />
        <Route
          path="/referrals/edit/:campaignId"
          element={
            <PermissionRedirect permissions={["updateReferralCampaigns"]}>
              <EditReferralCampaign />
            </PermissionRedirect>
          }
        />
        <Route
          path="/easylinks/new"
          element={
            <PermissionRedirect permissions={["createEasyLinks"]}>
              <EasylinkCreateForm />
            </PermissionRedirect>
          }
        />
        <Route
          path="/easylinks/edit/:easylinkId"
          element={
            <PermissionRedirect permissions={["updateEasyLinks"]}>
              <EasylinkEditForm />
            </PermissionRedirect>
          }
        />
        <Route
          path="templates-dashboard/:globalID"
          element={
            <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
              <OrganizationTemplatePrepare />
            </PermissionRedirect>
          }
        />
      </Route>

      {makeUserManagementRoutes({
        wrapper: (
          <RouteWrapper>
            <PermissionRedirect permissions={["viewTeamDetails"]}>
              <WithPaidFeatureAccessCheck />
            </PermissionRedirect>
          </RouteWrapper>
        ),
        showMobileDeviceContent,
      })}

      <Route
        path="/employee-transactions/:organizationId/:memberUserId"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["accessSiloedTransactions"]}>
              <WithPaidFeatureAccessCheck>
                <BusinessEmployeeTransactions />
              </WithPaidFeatureAccessCheck>
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/tech-check/:stepId"
        element={
          <RouteWrapper>
            <NotaryTechCheck />
          </RouteWrapper>
        }
      />

      {makeSetActiveOrgAndRedirectRoute({
        path: "/set-active-org/:orgId",
        wrapper: <RouteWrapper />,
        redirect: <RedirectRoot />,
      })}

      <Route path="*" element={<RedirectRoot />} />
    </>
  );
};

function BusinessRouter() {
  const isAuthenticated = useIsAuthenticated();
  const { viewer } = useViewer<Viewer>();

  const showMobileDeviceContent = useProMobileOnboarding() && isMobileDevice();

  let mobileRoutes;
  if (isAuthenticated) {
    if (isIndependentNotary(viewer.user?.notaryProfile || null)) {
      mobileRoutes = INDEPENDENT_NOTARY_MOBILE_ROUTES;
    } else if (showMobileDeviceContent) {
      mobileRoutes = new Set([...DEPRECATED_MOBILE_ROUTES, ...MOBILE_ROUTES]);
    } else {
      mobileRoutes = DEPRECATED_MOBILE_ROUTES;
    }
  } else {
    mobileRoutes = MOBILE_ROUTES_WITH_ROOT;
  }

  const isMobileSupportedRoute = useMatchManyFirstPathParts(mobileRoutes);
  const mobileEnabled = useFeatureFlag(MOBILE_BUSINESS_PORTAL);
  const isTxnDetailsRedesign = useTxnDetailsRedesign("business");

  if (useMatch("/shortcut-cheatsheet")) {
    return <ShortcutCheatsheet />;
  }

  return (
    <SingleShotAuthenticationProvider>
      <App
        isAuthenticated={isAuthenticated}
        mobileSupported={
          mobileEnabled ||
          isMobileSupportedRoute ||
          viewer.user?.organization?.featureList.includes(Feature.SIGN_ON_DEVICE)
        }
      >
        <Routes>
          <Route path="/session_expired" element={<SessionExpiredModal />} />

          <Route path="/verify-account" element={<VerifyEmail />} />

          <Route
            path="/upgrade"
            element={
              <DefaultWrapperUnauth>
                <TierUpgrade />
              </DefaultWrapperUnauth>
            }
          />

          <Route path="/activate/:token" element={<Activation entry={ENTRY} />} />

          <Route path="/tools/links" element={<LinkBuilder />} />
          {isAuthenticated
            ? authRoutes(isTxnDetailsRedesign, showMobileDeviceContent)
            : UNAUTH_ROUTES}
        </Routes>
      </App>
    </SingleShotAuthenticationProvider>
  );
}

const Root = () => (
  <ErrorBoundary>
    <ViewerManager>
      <BusinessRouter />
    </ViewerManager>
  </ErrorBoundary>
);

const router = createBrowserRouter([{ path: "*", element: <Root /> }]);

export default () => {
  return <RouterProvider router={router} />;
};

export const __BusinessRouter = Root;
