import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Address from "common/user/address";
import { userFullName } from "util/user";
import CollapsibleListItem from "common/core/collapsible_list/item";

import ItemizedList from "./itemized";
import type {
  RealtorTransactionInfoModal_node_customerSigners_signerRole as SignerRole,
  RealtorTransactionInfoModal_node as OrganizationTransaction,
} from "./index_fragment.graphql";

type Props = {
  transaction: OrganizationTransaction;
};

function renderSignerRole({ role }: SignerRole) {
  if (role === "SIGNER") {
    return <FormattedMessage id="aba2b454-2b06-44a4-8520-f711e1cff0bf" defaultMessage="Signer" />;
  } else if (role === "WITNESS") {
    return <FormattedMessage id="93bc1682-7d7b-4f7d-8f11-0cc41eaeeb11" defaultMessage="Witness" />;
  }
}

function Signers({ transaction }: Props) {
  const { customerSigners, documentBundle } = transaction;

  // This should never be an empty list...
  return (
    <>
      {customerSigners.map((signer) => {
        const participant = documentBundle?.participants?.find(
          (p) => p?.signerRole.index === signer.signerRole.index,
        );
        return (
          <CollapsibleListItem
            key={signer.id}
            initialDisplay
            header={userFullName(participant)}
            subheader={renderSignerRole(signer.signerRole)}
          >
            <ItemizedList>
              <li>
                <FormattedMessage
                  id="8bc406a8-4f99-45fb-abd7-e487ec0e0a88"
                  tagName="span"
                  defaultMessage="Address"
                />
                <Address address={signer.address} />
              </li>
              <li>
                <FormattedMessage
                  id="658c13ab-9178-4e07-8c6c-d77f05093757"
                  tagName="span"
                  defaultMessage="Email"
                />
                <span>{participant?.email}</span>
              </li>
            </ItemizedList>
          </CollapsibleListItem>
        );
      })}
    </>
  );
}

export default memo(Signers);
