import { PageTypes, NotarialActs, DocumentRequirementEnum } from "graphql_globals";

export function notarialActLabel(notarialAct: NotarialActs): string {
  switch (notarialAct) {
    case NotarialActs.ATTESTATION:
      return "Attestation";
    case NotarialActs.ACKNOWLEDGEMENT:
      return "Acknowledgement";
    case NotarialActs.AFFIDAVIT:
      return "Affidavit";
    case NotarialActs.COPY_CERTIFICATION:
      return "Copy Certification";
    case NotarialActs.VERIFICATION_OF_FACT:
    case NotarialActs.VERIFICATION_OF_FACT_PS1583:
      return "Verification of Fact";
    case NotarialActs.OATH_AFFIRMATION:
      return "Oath / Affirmation";
    case NotarialActs.JURAT:
      return "Jurat";
    default:
      throw new Error(`Unknown act ${notarialAct}`);
  }
}

export function looseLeafPageLabel(llp: PageTypes): string | undefined {
  switch (llp) {
    case PageTypes.CERTIFICATE_OF_ACKNOWLEDGEMENT:
      return "Certificate of Acknowledgement";
    case PageTypes.AFFIDAVIT:
      return "Affidavit";
    case PageTypes.JURAT:
      return "Jurat";
    case PageTypes.COPY_CERTIFICATION:
      return "Copy Certification";
    case PageTypes.VERIFICATION_OF_FACT_PS1583:
      return "Verification of Fact";
    case PageTypes.ATTESTATION:
      return "Attestation";
    default:
      return undefined;
  }
}

/** Based on requirement, some requirements do not support splitting because issues
 * could arise from separating a page. For example, with Certify if a second page without a QR is split,
 * the second page won't be valid.
 */
export function docCanBeSplit(document: {
  requirement: DocumentRequirementEnum | null | undefined;
}) {
  return !requiresCertification(document);
}

// There are components that use this that pass in the mergedDocument for mortgage transactions. The mergedDocument
// doesn't have a requirement on it, so they'll continue to to get the same results as before, but have to put requirement
// as potentially undefined here
export function requiresCertification(document: { requirement?: DocumentRequirementEnum | null }) {
  return document.requirement === DocumentRequirementEnum.CERTIFY;
}
