import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import DocIcon from "assets/images/customer-doc-icon.svg";
import ActionButton from "common/core/action_button";
import Button from "common/core/button";

type Props = {
  viewable?: boolean;
  name: string | null;
  onClick: () => void;
  showResponsiveView?: boolean;
};

function DocumentsListItem(props: Props) {
  const { name, showResponsiveView } = props;

  return (
    <div className="list-item-container">
      <div className={classnames("list-item", props.viewable && "list-item--viewable")}>
        {!showResponsiveView && (
          <div className="icon">
            <img alt="" aria-hidden="true" src={DocIcon} />
          </div>
        )}
        <div className="content">
          <div className="name" data-automation-id="document-name">
            <ActionButton
              onClick={props.onClick}
              className="wrap-word"
              automationId={`document-item-${name}`}
            >
              {name}
            </ActionButton>
          </div>
          {showResponsiveView && (
            <Button
              buttonColor="action"
              variant="secondary"
              onClick={props.onClick}
              className="view-button"
              automationId={`document-item-${name}-view`}
            >
              <FormattedMessage id="f1e8fd7e-a271-41b8-9d73-2406ddd6b171" defaultMessage="View" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DocumentsListItem;
