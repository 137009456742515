import "./state.scss";

import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { OrganizationTransactionDetailedStatus } from "graphql_globals";

import type { RealtorTransactionFilters as Organization } from "./index_fragment.graphql";

type State = OrganizationTransactionDetailedStatus | null;
type Props = {
  onChange: (newState: State) => void;
  organization: Organization;
  value: State;
};

type Counts = {
  attempted: number;
  completed: number;
  completedWithRejections: number;
  sent: number;
  partiallyCompleted: number;
};
const STATE_LOOKUP: readonly {
  value: State;
  content: (counts: Counts) => ReactNode;
}[] = Object.freeze([
  {
    value: null,
    content: () => (
      <FormattedMessage id="baac6128-cf44-4a8d-ac52-1fb2f3f69264" defaultMessage="All" />
    ),
  },
  {
    value: OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
    content: (counts) => (
      <FormattedMessage
        id="d88872c3-1126-4aef-bac0-c69607ccc7b0"
        defaultMessage="{value} Sent"
        values={{ value: counts.sent }}
      />
    ),
  },
  {
    value: OrganizationTransactionDetailedStatus.ATTEMPTED,
    content: (counts) => (
      <FormattedMessage
        id="e62c7288-9d57-48e5-bc70-ee25b64b1ede"
        defaultMessage="{value} Started"
        values={{ value: counts.partiallyCompleted + counts.attempted }}
      />
    ),
  },
  {
    value: OrganizationTransactionDetailedStatus.COMPLETE,
    content: (counts) => (
      <FormattedMessage
        id="6a449c49-d96e-499b-8fb3-31deb40d06f5"
        defaultMessage="{value} Completed"
        values={{ value: counts.completed + counts.completedWithRejections }}
      />
    ),
  },
]);

function StateField({ value, organization, onChange }: Props) {
  const {
    attemptedTransactions,
    sentTransactions,
    partiallyCompletedTransactions,
    completedTransactions,
    completedWithRejectionsTransactions,
  } = organization;

  const observedTransactionCounts = {
    completed: completedTransactions.totalCount,
    completedWithRejections: completedWithRejectionsTransactions.totalCount,
    partiallyCompleted: partiallyCompletedTransactions.totalCount,
    attempted: attemptedTransactions.totalCount,
    sent: sentTransactions.totalCount,
  };

  return (
    <div className="StateField">
      {STATE_LOOKUP.map(({ value: itemValue, content }) => (
        <div
          key={itemValue || "all"}
          onClick={() => onChange(itemValue)}
          className={`StateField--Pill${itemValue === value ? " selected" : ""}`}
        >
          {content(observedTransactionCounts)}
        </div>
      ))}
    </div>
  );
}

export default memo(StateField);
