import { forwardRef, type Ref, type RefAttributes, type ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { useId } from "util/html";
import { useA11y } from "common/accessibility";

import { HelperText } from "../..";
import { useAriaErrorDescribedId } from "../../error";
import type { PresentNode, OptionGroupProps } from "..";
import Styles from "./index.module.scss";

type RadioInputProps<V extends string> = Omit<
  ComponentPropsWithoutRef<"input">,
  "type" | "value"
> & {
  value: V;
};
type RadioGroupProps = OptionGroupProps & {
  helperText?: PresentNode;
  horizontal?: boolean;
  className?: string;
  inputsClassname?: string;
  "data-automation-id"?: string;
  "aria-describedby"?: string;
};

function RadioInput<V extends string>(props: RadioInputProps<V>, ref: Ref<HTMLInputElement>) {
  const ariaDescribedBy = useAriaErrorDescribedId(props);
  return (
    <input
      {...props}
      aria-describedby={classnames(ariaDescribedBy, props["aria-describedby"])}
      aria-disabled={props.disabled}
      className={classnames(Styles.radio, SENSITIVE_CLASS, props.className)}
      ref={ref}
      type="radio"
    />
  );
}

const RadioInputWithRef = forwardRef(RadioInput) as <V extends string>(
  props: RadioInputProps<V> & RefAttributes<HTMLInputElement>,
) => ReturnType<typeof RadioInput>;

export function RadioLabel({
  radio,
  label,
  "data-automation-id": dataAutomationId,
  additionalLabelContent,
  subLabel,
}: {
  radio: PresentNode;
  label: PresentNode;
  "data-automation-id"?: string;
  additionalLabelContent?: PresentNode;
  subLabel?: PresentNode;
}) {
  return (
    <div className={Styles.optionContainer}>
      <label
        data-automation-id={dataAutomationId}
        className={classnames(Styles.optionLabel, subLabel && Styles.optionWithSubLabel)}
      >
        {radio}
        <span className={Styles.labelText}>
          <span className={Styles.optionLabelText}>{label}</span>
          {subLabel && <span className={Styles.optionSubLabelText}>{subLabel}</span>}
        </span>
      </label>
      {additionalLabelContent && (
        <div className={Styles.optionAdditionalLabelContent}>{additionalLabelContent}</div>
      )}
    </div>
  );
}

function RadioGroup(
  {
    label,
    labelSize,
    helperText,
    children,
    groupName,
    groupError,
    horizontal,
    className,
    inputsClassname,
    "data-automation-id": dataAutomationId,
    "aria-describedby": ariaDescribedBy,
    "aria-labelledby": ariaLabelledby,
  }: RadioGroupProps,
  ref: Ref<HTMLLegendElement>,
) {
  const describedbyId = useA11y().useLabelledOrDescribedBy(
    groupName || groupError?.props.inputName,
  );
  const helperTextId = useId();
  return (
    <fieldset
      role="radiogroup"
      aria-labelledby={ariaLabelledby}
      aria-describedby={classnames(
        describedbyId || helperText ? helperTextId : undefined,
        ariaDescribedBy,
      )}
      className={classnames(Styles.radioGroup, className)}
      data-automation-id={dataAutomationId}
    >
      {(label || helperText) && (
        <legend
          ref={ref}
          tabIndex={-1}
          className={classnames(
            Styles.radioGroupLabel,
            labelSize === "large" && Styles.radioGroupLabelLarge,
          )}
        >
          {label}
          {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
        </legend>
      )}
      <div
        className={classnames(
          Styles.radioGroupInputs,
          horizontal && Styles.radioGroupInputsHorizontal,
          inputsClassname,
        )}
      >
        {children}
      </div>
      {groupError}
    </fieldset>
  );
}

const RadioGroupWithRef = forwardRef(RadioGroup);

export { RadioInputWithRef as RadioInput, RadioGroupWithRef as RadioGroup };
