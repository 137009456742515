import { FormattedMessage } from "react-intl";
import { useState } from "react";

import MfaIcon from "assets/images/mfa_icon.svg";
import { Heading, Paragraph } from "common/core/typography";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import Button from "common/core/button";
import { useForm } from "common/core/form";

import SmsMfaSetup from "./sms";
import TotpMfaSetup from "./totp";
import Styles from "./index.module.scss";

type AuthenticationMethod = "totp" | "sms";
type SetupScreen = "selection" | AuthenticationMethod;

type FormValues = {
  authenticationMethod: AuthenticationMethod;
};

function NotaryMfaSetup({ refetch }: { refetch?: () => void }) {
  const { handleSubmit, register } = useForm<FormValues>({
    defaultValues: {
      authenticationMethod: "totp",
    },
  });
  const [currentScreen, setCurrentScreen] = useState<SetupScreen>("selection");
  const onSubmit = (data: FormValues) => {
    setCurrentScreen(data.authenticationMethod);
  };

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case "selection":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={Styles.container}>
              <img className={Styles.image} src={MfaIcon} aria-hidden="true" alt="" />
              <Heading
                level="h1"
                textStyle="headingFour"
                textAlign="center"
                className={Styles.heading}
              >
                <FormattedMessage
                  id="b6bd61af-8e70-4878-8c2a-472c796c96cd"
                  defaultMessage="Secure your account"
                />
              </Heading>
              <Paragraph size="large" className={Styles.info}>
                <FormattedMessage
                  id="799df674-f5dc-434f-8d71-53b9a679780a"
                  defaultMessage="Set up multi-factor authentication (MFA) to keep your account safe. Start by selecting an authentication method."
                />
              </Paragraph>
              <RadioGroup aria-labelledby="radio-group-label">
                <RadioLabel
                  label="Authenticator app (Recommended)"
                  radio={<RadioInput value="totp" {...register("authenticationMethod")} />}
                />
                <RadioLabel
                  label="Text message"
                  radio={<RadioInput value="sms" {...register("authenticationMethod")} />}
                />
              </RadioGroup>
              <div className={Styles.divider}></div>
              <div className={Styles.buttonContainer}>
                <Button
                  buttonColor="action"
                  variant="primary"
                  buttonSize="large"
                  className={Styles.continueButton}
                  type="submit"
                >
                  <FormattedMessage
                    id="72f9d8a6-6813-40bc-9518-77b57efe5e9d"
                    defaultMessage="Continue"
                  ></FormattedMessage>
                </Button>
              </div>
            </div>
          </form>
        );
      case "totp":
        return <TotpMfaSetup refetch={refetch} />;
      case "sms":
        return <SmsMfaSetup refetch={refetch} />;
      default:
        return null;
    }
  };

  return <div>{renderCurrentScreen()}</div>;
}

export default NotaryMfaSetup;
