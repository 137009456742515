import { memo, useEffect, type ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import SROnly from "common/core/screen_reader";
import Button from "common/core/button";
import SpeakerImg from "assets/images/tech/speaker.svg";
import MicrophoneImg from "assets/images/tech/mic.svg";
import ErrorImg from "assets/images/tech/mic-error.svg";
import { Paragraph } from "common/core/typography";
import { useId } from "util/html";

import UserTechCheckControl from "../user_control";
import { TechCheckTestSoundPlayer } from "./test_sound_player";
import { SpeakerPicker, MicrophonePicker } from "./picker";
import MicrophoneVolume from "./microphone_volume";
import NotaryCustomizeRingtone from "./ringtone";
import Styles from "./config.module.scss";

type Props = {
  selectedSpeakerId?: string;
  onSpeakerSelection: (newId: string | null, properties?: { autoSelected?: boolean }) => void;
  onSpeakerMissing: () => void;
  selectedMicrophoneId?: string | undefined;
  onMicrophoneSelection: (newId: string | null, properties?: { autoSelected?: boolean }) => void;
  onMicrophoneMissing: () => void;
  onConfirmation?: () => void;
  missingEquipment?: boolean;
  onMount: () => void;
  onSpeakerSelectionDisabled: () => void;
  isContinueDisabled?: boolean;
  notaryProfile?: ComponentProps<typeof NotaryCustomizeRingtone>["notaryProfile"];
  autoFocus?: boolean;
};

const MESSAGES = defineMessages({
  microphone: {
    id: "07c79511-049d-4108-b4a6-a8fa1c4361db",
    defaultMessage: "Microphone",
  },
  speaker: {
    id: "45956ff6-85bb-4494-93d9-dfe89e2ddd19",
    defaultMessage: "Speaker",
  },
  ringtone: {
    id: "6cc8b80e-d60d-41e2-8b6f-8ca2868eab17",
    defaultMessage: "Ringtone",
  },
});

function AudioConfiguration(props: Props) {
  const {
    notaryProfile,
    missingEquipment,
    selectedSpeakerId,
    selectedMicrophoneId,
    onConfirmation,
    autoFocus,
  } = props;
  const intl = useIntl();
  const microphoneSelectId = useId();
  useEffect(() => {
    props.onMount();
  }, []);
  return (
    <div>
      <UserTechCheckControl
        label={intl.formatMessage(MESSAGES.microphone)}
        icon={missingEquipment ? ErrorImg : MicrophoneImg}
        techCheckDropdown={
          <MicrophonePicker
            onDeviceSelect={props.onMicrophoneSelection}
            onDeviceMissing={props.onMicrophoneMissing}
            selectedDeviceId={selectedMicrophoneId}
            autoFocus={autoFocus}
            aria-describedby={microphoneSelectId}
          />
        }
      >
        <div className={Styles.audioInstructions}>
          <MicrophoneVolume microphoneId={selectedMicrophoneId} />
          <Paragraph aria-hidden>
            <FormattedMessage
              id="17854d9a-92cb-45d4-85d2-f7e608527d46"
              defaultMessage="Speak at normal volume. If the volume indicator on the left does not move, try selecting a different microphone or restarting your browser."
            />
          </Paragraph>
          <SROnly>
            <span id={microphoneSelectId}>
              <FormattedMessage
                id="17854d9a-92cb-45d4-85d2-f7e608527d46"
                defaultMessage="Speak at normal volume. If your microphone is working correctly, you will hear an alert indicating that audio has been detected."
              />
            </span>
          </SROnly>
        </div>
      </UserTechCheckControl>
      <UserTechCheckControl
        label={intl.formatMessage(MESSAGES.speaker)}
        icon={SpeakerImg}
        techCheckDropdown={
          <SpeakerPicker
            onDeviceSelect={props.onSpeakerSelection}
            onDeviceMissing={props.onSpeakerMissing}
            onDisable={props.onSpeakerSelectionDisabled}
            selectedDeviceId={selectedSpeakerId}
          />
        }
      >
        <TechCheckTestSoundPlayer
          className={Styles.audioInstructions}
          speakerDeviceId={selectedSpeakerId}
        />
      </UserTechCheckControl>
      {notaryProfile && (
        <UserTechCheckControl
          label={intl.formatMessage(MESSAGES.ringtone)}
          techCheckDropdown={<NotaryCustomizeRingtone notaryProfile={notaryProfile} />}
        />
      )}
      {onConfirmation && !missingEquipment && (
        <Button
          className="TechCheck--button"
          buttonColor="action"
          variant="primary"
          onClick={onConfirmation}
          automationId="confirm-audio-settings-button"
          disabled={props.isContinueDisabled}
        >
          <FormattedMessage
            id="a28301f8-e499-4152-a6ba-bec1612446e6"
            defaultMessage="Confirm selected devices"
          />
        </Button>
      )}
    </div>
  );
}

export default memo(AudioConfiguration);
