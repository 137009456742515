import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { useActiveOrganization } from "common/account/active_organization";
import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import { useQuery } from "util/graphql";
import blockedImage from "assets/images/wrong-account.svg";
import { useNotaryCommonSetupRedirect } from "common/notary/profile_wizard/util";
import { isNotaryNST, isNotaryNSTCallReady, isBusinessPortalReady } from "common/notary/capacity";

import FeatureAccessGateQuery from "./feature_access_gate_query.graphql";

type Props = {
  children: ReactNode;
};

function FeatureAccessGateBlockingModal({ children }: Props) {
  const [activeOrganizationId] = useActiveOrganization();

  const { data, loading } = useQuery(FeatureAccessGateQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = loading ? null : data!.node!;
  const notaryProfile = loading ? null : data!.viewer.user!.notaryProfile;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const showPortalBlockedModal = !loading && !isBusinessPortalReady(notaryProfile, organization);
  const redirectToNotarySetup =
    !loading &&
    !showPortalBlockedModal &&
    isNotaryNST(notaryProfile) &&
    !isNotaryNSTCallReady(notaryProfile);

  const notarySetupRedirect = useNotaryCommonSetupRedirect();
  useEffect(() => {
    if (redirectToNotarySetup) {
      notarySetupRedirect(notaryProfile!);
    }
  }, [redirectToNotarySetup]);

  return (
    <>
      {showPortalBlockedModal ? (
        <BlockingModal isOpen automationId="access-blocked-modal">
          <ModalGraphic>
            <img alt="blocked" src={blockedImage} />
          </ModalGraphic>
          <ModalHeader>
            <FormattedMessage id="bf05d72b-fecb-475e-b267-aac88d291e3a" defaultMessage="Blocked" />
          </ModalHeader>
          <ModalSubheader>
            <FormattedMessage
              id="b6e38ed7-2ca2-416c-b7d7-436da11ec6d2"
              defaultMessage="You do not have access to this portal. If you think this is an error, please reach out to support."
            />
          </ModalSubheader>
        </BlockingModal>
      ) : (
        children
      )}
    </>
  );
}

export default FeatureAccessGateBlockingModal;
