import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";
import Button from "common/core/button";
import PaginationCaret from "common/pagination/caret";

import Styles from "./import_loop.module.scss";
import type { Loop, Document, Signer } from ".";

type Props = {
  onGoBack: () => void;
  onCancel: () => void;
  goToNextStep: () => void;
  selectedLoop: Loop;
  selectedSigners: Set<Signer>;
  selectedDocuments: Set<Document>;
};

export default function ConfirmDetails({
  onGoBack,
  onCancel,
  goToNextStep,
  selectedLoop,
  selectedSigners,
  selectedDocuments,
}: Props) {
  const loopName = selectedLoop.name;

  return (
    <div>
      <div className={Styles.confirmDetailsTitle}>
        <FormattedMessage
          id="e32c4c82-9576-464a-a907-ed3c0bc56053"
          defaultMessage="Confirm Details"
          tagName="h3"
        />
      </div>
      <div className={Styles.loopAndSigner}>
        <div className={Styles.confirmLoopName}>
          <div>
            <FormattedMessage
              id="e32c4c82-9576-464a-a907-ed3c0bc56055"
              defaultMessage="LOOP NAME"
            />
          </div>
          <div className={Styles.loopName}>{loopName}</div>
        </div>

        <div className={Styles.confirmSignerNames}>
          <div>
            <FormattedMessage
              id="e32c4c82-9576-464a-a907-ed3c0bc56056"
              defaultMessage="SIGNER(S)"
            />
          </div>
          {Array.from(selectedSigners).map((signer) => (
            <div className={Styles.signerName} key={signer.dotloop_id}>
              {signer.first_name} {signer.last_name}
            </div>
          ))}
        </div>
      </div>

      <div className={Styles.confirmDocumentNames}>
        <div className={Styles.documentsHeader}>
          <FormattedMessage
            id="e32c4c82-9576-464a-a907-ed3c0bc56057"
            defaultMessage="DOCUMENT(S)"
          />
        </div>

        {Array.from(selectedDocuments).map((document) => (
          <div className={Styles.documentRow} key={document.id}>
            <div className={Styles.documentIcon}>
              <Icon name="document-pdf" />
            </div>
            <div>{document.name}</div>
          </div>
        ))}
      </div>
      <div className={Styles.buttons}>
        <Button
          className={Styles.backButton}
          onClick={onGoBack}
          variant="secondary"
          buttonColor="action"
        >
          <PaginationCaret prev />
          <FormattedMessage id="d0e7c22d-a0e0-457d-82c8-230f8e1caacc" defaultMessage="Back" />
        </Button>
        <Button onClick={onCancel} variant="secondary" buttonColor="action">
          <FormattedMessage id="f260b24b-5b8a-4674-899f-0ccb2500aba3" defaultMessage="Cancel" />
        </Button>
        <Button onClick={goToNextStep} variant="primary" buttonColor="action">
          <FormattedMessage id="75e9a0b5-7a82-4988-bbed-670b9bb5419c" defaultMessage="Confirm" />
        </Button>
      </div>
    </div>
  );
}
