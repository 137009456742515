import "../signup_v2/index.scss";

import { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { Tier } from "graphql_globals";
import { useSelector } from "redux/util";
import Button from "common/core/button";
import { canLoginWithGoogle } from "common/account/util";
import { useIsAuthenticated } from "common/authentication";
import { getTierPricingUrl } from "util/tier";
import { useApolloClient } from "util/graphql";
import { hardNavigateToUnsafe } from "util/navigation";
import { useViewer } from "util/viewer_wrapper";
import { TIER_PATH } from "constants/tier";
import { loginReset } from "redux/actions/authentication";
import Apps from "constants/applications";
import { useDocumentTitle } from "util/html";
import { TRANSACTION_PATH } from "util/routes";

import { MESSAGES, getFields, handleEmailSubmit, handleGoogleSubmit } from "../signup_v2";
import { ACCOUNT_TYPES } from "../constants";
import { WrappedSignupForm } from "../signup_v2/form";
import GradientContainer from "../signup_v2/gradient_container";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const canGoogleLogin = canLoginWithGoogle({ tier: Tier.BASIC, selectedAccount: Apps.BUSINESS });
const fields = getFields({ tier: Tier.BASIC, selectedAccount: Apps.BUSINESS });

function RealtorSignup() {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const { refetch: refetchViewer } = useViewer();
  const isAuthenticated = useIsAuthenticated();
  const authentication = useSelector((state) => state.authentication);
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const [domainMatchId, setDomainMatchId] = useState(null);
  useDocumentTitle(intl.formatMessage(MESSAGES.BASIC));

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/slideshow");
    }
    if (!isAuthenticated && authentication.accountStatus?.needsPassword) {
      navigate("/verification-email-sent");
    }
  });

  useEffect(() => {
    return () => {
      dispatch(loginReset());
    };
  }, []);

  const toLogin = () => {
    navigate("/login");
  };

  const toPricingPage = () => {
    hardNavigateToUnsafe(getTierPricingUrl());
  };

  function getAuthParams() {
    const computedEntry = ACCOUNT_TYPES.REALTOR;
    const computedAccountType = ACCOUNT_TYPES.REALTOR;
    const redirectUrl = TRANSACTION_PATH;

    return { dispatch, computedEntry, computedAccountType, redirectUrl };
  }

  const handleCommit = (formData: FormValues) => {
    return handleEmailSubmit(
      formData,
      apolloClient,
      TIER_PATH.BASIC,
      refetchViewer,
      domainMatchId,
      setDomainMatchId,
      getAuthParams(),
    );
  };

  const handleGoogle = (authCode: string) => {
    handleGoogleSubmit(authCode, refetchViewer, getAuthParams());
  };

  const buttons = (
    <>
      <Button
        buttonColor="light"
        variant="secondary"
        onClick={toPricingPage}
        automationId="pricing-page-button"
      >
        <FormattedMessage id="cc0adea2-0992-4e7a-8226-18bcbb9d64b7" defaultMessage="Pricing Page" />
      </Button>
      <Button buttonColor="light" variant="secondary" onClick={toLogin}>
        <FormattedMessage id="85da2b38-ffa6-4988-89a0-189088b0530c" defaultMessage="Sign in" />
      </Button>
    </>
  );

  return (
    <GradientContainer
      header={
        <FormattedMessage
          id="f193ff50-66e4-47b4-a64e-577156cc03b0"
          defaultMessage="Create a realtor account"
        />
      }
      headerButtons={buttons}
      disclaimer={null}
      showFooter
    >
      <WrappedSignupForm
        onCommit={handleCommit}
        onGoogle={handleGoogle}
        canLoginWithGoogle={canGoogleLogin}
        initialValues={{ email: searchParams.get("email") }}
        Fields={fields}
        domainMatchId={domainMatchId}
      />
    </GradientContainer>
  );
}
export default memo(RealtorSignup);
