import { defineMessages, type IntlShape } from "react-intl";

import { OrganizationTransactionVariant } from "graphql_globals";

const MESSAGES = defineMessages({
  [OrganizationTransactionVariant.ESIGN]: {
    id: "6c96418a-d730-4626-9d77-d15d043ed4fd",
    defaultMessage: "eSign",
  },
  [OrganizationTransactionVariant.NOTARIZATION]: {
    id: "253b9fc7-9d4d-4504-9c06-073dbf6c6b2f",
    defaultMessage: "Notarization",
  },
  [OrganizationTransactionVariant.PROOF]: {
    id: "05ffdfaa-4684-4000-89f6-43d1eb904704",
    defaultMessage: "Proof",
  },
  [OrganizationTransactionVariant.VERIFICATION_OF_FACT]: {
    id: "53f65daa-f6d0-44f1-a3b7-1db9a57ac38e",
    defaultMessage: "Verification of fact",
  },
  attestation: {
    id: "0116874d-ac34-4e42-ba65-7c4945336c9d",
    defaultMessage: "Notarization without SSN",
  },
  [OrganizationTransactionVariant.IDENTIFY]: {
    id: "3226c44d-1765-4d8a-88db-17c74f657c5a",
    defaultMessage: "Identify",
  },
  [OrganizationTransactionVariant.VERIFY]: {
    id: "eff8f934-0914-49e4-a675-95becb4c8cc8",
    defaultMessage: "Verify",
  },
  [OrganizationTransactionVariant.CERTIFY]: {
    id: "e0f6b8f6-0f0d-4c2b-8c4b-0b6b2f1c9a3b",
    defaultMessage: "Certify",
  },
});

export function formattedTransactionVariant(
  transactionVariant: OrganizationTransactionVariant,
  intl: IntlShape,
  ps1583AttestationEnabled?: boolean,
) {
  if (
    transactionVariant === OrganizationTransactionVariant.VERIFICATION_OF_FACT &&
    ps1583AttestationEnabled
  ) {
    return intl.formatMessage(MESSAGES.attestation);
  }
  return intl.formatMessage(MESSAGES[transactionVariant]);
}
