import { memo, useMemo, type ReactNode, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import { map, filter, type Subject } from "rxjs";

import { LeaveWarning } from "common/meeting/notification";
import { MeetingEndedState } from "graphql_globals";
import RegularErrorModal from "common/error_modal/regular_error_modal";
import BeholderContainer from "common/meeting/beholder/container";
import { useSubject } from "util/rxjs/hooks";
import { useQueryPoller } from "util/graphql/query/poll";
import MeetingOverModal from "common/meeting/meeting_over_modal";
import MeetingSidebar from "common/meeting/sidebar";
import Document from "common/meeting/document";
import { Hidden, Visible } from "common/core/responsive";
import { MobileMeetingContainer } from "common/meeting/mobile";
import SelectedDevicesController from "common/selected_devices_controller";
import { ChatProvider } from "common/chat";
import type Channel from "socket/channel";
import { isMobileDevice } from "util/support";
import { QueryWithLoading, type QueryResult } from "util/graphql/query";
import type { ApolloError } from "util/graphql";

import Socket from "./socket";
import MeetingQuery, {
  type BusinessMeetingRoot_meeting_Meeting as Meeting,
  type BusinessMeetingRoot_viewer_user as User,
  type BusinessMeetingRoot_meeting_Meeting_meetingParticipants_$$other as MeetingParticipantsType,
  type BusinessMeetingRoot,
  type BusinessMeetingRootVariables,
} from "./meeting_query.graphql";
import MeetingPollerQuery from "./meeting_poller_query.graphql";

type CacheState = {
  hasOptimisticUpdates: boolean;
};
type CustomInteractionError = {
  interactionErrorMessage: ReactNode;
};
type Interaction =
  | {
      locked: true;
    }
  | {
      locked: false;
      error?: Error | CustomInteractionError;
    };
type DocumentProps = ComponentProps<typeof Document>;
type Props = {
  meeting: Meeting;
  channel: Channel;
  participant: MeetingParticipantsType;
  user: User | null;
  notaryPointer: DocumentProps["notaryPointer"];
  onShowNotaryPointer: () => void;
  indicatedDesignation: DocumentProps["indicatedDesignation"];
};
type MeetingProps = {
  meetingId: string;
  data: BusinessMeetingRoot | undefined;
  error: ApolloError | undefined;
  refetch: QueryResult<BusinessMeetingRoot, BusinessMeetingRootVariables>["refetch"];
};

function useMeetingQueryPoller(
  meeting: Meeting,
  interaction$: Subject<Interaction>,
  cache$: Subject<CacheState>,
) {
  const { id: meetingId, currentDocumentId: documentId } = meeting;
  const semaphore$ = useMemo(() => {
    return interaction$.pipe(map((e) => (e.locked ? "close" : "open")));
  }, [interaction$]);
  const void$ = useMemo(() => {
    return cache$.pipe(filter((e) => e.hasOptimisticUpdates));
  }, [cache$]);
  useQueryPoller({
    query: MeetingPollerQuery,
    interval: 15_000,
    variables: { meetingId, documentId },
    skip: meeting.endedState !== MeetingEndedState.NOT_COMPLETED,
    semaphore$,
    void$,
  });
}

function MeetingInner({
  user,
  meeting,
  notaryPointer,
  channel,
  onShowNotaryPointer,
  indicatedDesignation,
  participant,
}: Props) {
  const navigate = useNavigate();
  const interaction$ = useSubject<Interaction>();
  const cache$ = useSubject<CacheState>();
  useMeetingQueryPoller(meeting, interaction$, cache$);

  const redirectToMyClosings = () => {
    const { id } = meeting.documentBundle!.organizationTransaction;
    navigate(`/realtor_closings?highlightTransactionId=${id}`);
  };

  return (
    <ChatProvider conversationSid={meeting.conversationSid} currentParticipant={participant}>
      <SelectedDevicesController channel={channel} participantId={participant.id}>
        {({ onChangeDevices, selectedDevices, showAVSettings, toggleAVSettings }) => (
          <>
            <LeaveWarning meeting={meeting} />
            <BeholderContainer>
              <Hidden xs sm>
                <MeetingSidebar
                  user={user!}
                  meeting={meeting}
                  onChangeDevices={onChangeDevices}
                  selectedDevices={selectedDevices}
                  showAVSettings={showAVSettings}
                  toggleAVSettings={toggleAVSettings}
                />
                <Document
                  meeting={meeting}
                  notaryPointer={notaryPointer}
                  onShowNotaryPointer={onShowNotaryPointer}
                  indicatedDesignation={indicatedDesignation}
                />
              </Hidden>
              {/* Mobile View */}
              <Visible xs sm>
                <MobileMeetingContainer
                  meeting={meeting}
                  meetingQuery={MeetingQuery}
                  channel={channel}
                  user={user}
                  onChangeDevices={onChangeDevices}
                  selectedDevices={selectedDevices}
                  showAVSettings={showAVSettings}
                  toggleAVSettings={toggleAVSettings}
                >
                  <Document
                    meeting={meeting}
                    notaryPointer={notaryPointer}
                    onShowNotaryPointer={onShowNotaryPointer}
                    indicatedDesignation={indicatedDesignation}
                  />
                </MobileMeetingContainer>
              </Visible>
            </BeholderContainer>
            {meeting.endedState !== MeetingEndedState.NOT_COMPLETED && (
              <MeetingOverModal
                onSubmit={() => {
                  return isMobileDevice() ? navigate("/meeting/completed") : redirectToMyClosings();
                }}
              />
            )}
          </>
        )}
      </SelectedDevicesController>
    </ChatProvider>
  );
}

function MeetingSocket({ data, error, refetch, meetingId }: MeetingProps) {
  const navigate = useNavigate();

  if (error || !data?.meeting) {
    return (
      <RegularErrorModal
        clearErrors={error ? refetch : () => navigate("/")}
        errorString={
          <FormattedMessage
            id="248e1bec-ddec-49d6-8848-a6c4ca5f77fa"
            description="queryFailure"
            defaultMessage="Unable to load meeting."
          />
        }
      />
    );
  }
  const { viewer } = data;
  const viewerUserId = viewer.user!.id;
  const meeting = data.meeting as Meeting;
  const participant = meeting.meetingParticipants.find((p) => p.userId === viewerUserId) as
    | MeetingParticipantsType
    | undefined;
  if (!participant) {
    throw new Error("Missing logged in participant!");
  }
  return (
    <Socket
      meetingId={meetingId}
      refetch={refetch}
      requiredFeatures={meeting.documentBundle?.requiredFeatures}
    >
      {(channel, { pointer, indicatedDesignation, onShowPointer }) => (
        <MeetingInner
          indicatedDesignation={indicatedDesignation}
          meeting={meeting}
          user={viewer.user}
          notaryPointer={pointer}
          channel={channel}
          onShowNotaryPointer={onShowPointer}
          participant={participant}
        />
      )}
    </Socket>
  );
}

function WrappedMeeting() {
  const meetingId = useParams().meetingId!;

  return (
    <QueryWithLoading query={MeetingQuery} variables={{ meetingId }} partialRefetch>
      {({ data, error, refetch }) => <MeetingSocket {...{ data, error, refetch, meetingId }} />}
    </QueryWithLoading>
  );
}
export default memo(WrappedMeeting);
