import { useState, useEffect } from "react";
import { from, interval, exhaustMap, takeUntil, map, filter, first } from "rxjs";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { AsyncJobStatus } from "graphql_globals";
import { useSubject } from "util/rxjs/hooks";
import spinner from "assets/images/processing.svg";
import { captureException } from "util/exception";
import Button from "common/core/button";

import Styles from "./index.module.scss";
import ImportLoop from "./import_loop";
import ConfirmDetails from "./confirm_details";
import AddDocuments from "./add_documents";
import AddSigners from "./add_signers";
import { postImportLoop, getAsyncJob } from "./requests";

type Props = {
  onCancel: () => void;
};

export type Loop = {
  id: number;
  status: string;
  closing_date: string;
  transaction_type: string;
  name: string;
};

export type Signer = {
  dotloop_id: number;
  first_name: string;
  last_name: string;
  email: string;
  dotloop_role: string;
  role: string;
};

export type Document = {
  id: number;
  name: string;
};

function useWaitForPendingDocuments(): (importingLoopJobId: string) => Promise<void> {
  const unmounted$ = useSubject<null>();
  useEffect(
    () => () => {
      unmounted$.next(null);
      unmounted$.complete();
    },
    [],
  );
  return (importingLoopJobId) => {
    return new Promise((resolve, reject) => {
      interval(1500)
        .pipe(
          exhaustMap(() => from(getAsyncJob(importingLoopJobId))),
          map((json) => json.status),
          // filter out any pending responses
          filter((status: string) => status.toLowerCase() !== AsyncJobStatus.PENDING.toLowerCase()),
          // stop once we get to the first emission that is not pending
          first(),
          takeUntil(unmounted$),
        )
        .subscribe({
          next: (status) => {
            if (status.toLowerCase() === AsyncJobStatus.COMPLETED.toLowerCase()) {
              return resolve();
            }
            reject(new Error(`Loop failed to import with unexpected status ${status}`));
          },
          error: reject,
        });
    });
  };
}

export default function DotloopWizard(props: Props) {
  type WizardStep =
    | "ImportLoop"
    | "ImportingLoop"
    | "ConfirmDetails"
    | "AddSigners"
    | "AddDocuments";

  const navigate = useNavigate();
  const [selectedProfileId, setSelectedProfileId] = useState<number | null>(null);
  const [selectedLoop, setSelectedLoop] = useState<Loop | null>(null);
  const [selectedSigners, setSelectedSigners] = useState<Set<Signer>>(new Set());
  const [selectedDocuments, setSelectedDocuments] = useState<Set<Document>>(new Set());
  const [wizardStep, setWizardStep] = useState<WizardStep>("ImportLoop");
  const [uploadError, setUploadError] = useState(false);

  function onCancel() {
    const { onCancel } = props;
    setUploadError(false);
    onCancel();
  }

  const waitForPendingDocuments = useWaitForPendingDocuments();

  const importSelectedLoop = () => {
    if (!selectedProfileId || !selectedLoop) {
      throw new Error("Need to select profile and loop to continue");
    }
    postImportLoop(
      selectedProfileId,
      selectedLoop.id,
      Array.from(selectedDocuments).map((document: Document) => document.id),
      Array.from(selectedSigners).map((signer: Signer) => signer.dotloop_id),
    )
      .then((data) => {
        if (data.gid) {
          return waitForPendingDocuments(data.gid).then(() => data.subject_gid);
        }
        throw new Error("Missing async job ID from response");
      })
      .then((subjectGid) => {
        navigate(`/transaction/update/${subjectGid}`);
      })
      .catch((err) => {
        captureException(err);
        setUploadError(true);
      });
  };

  const handleSelectProfile = (profileId: number) => {
    setSelectedLoop(null);
    setSelectedProfileId(profileId);
    setSelectedDocuments(new Set());
  };

  const goToNextStep = () => {
    switch (wizardStep) {
      case "ImportLoop":
        return setWizardStep("AddDocuments");
      case "AddDocuments":
        return setWizardStep("AddSigners");
      case "AddSigners":
        return setWizardStep("ConfirmDetails");
      case "ConfirmDetails":
        setWizardStep("ImportingLoop");
        importSelectedLoop();
    }
  };

  const goToPreviousStep = () => {
    switch (wizardStep) {
      case "AddDocuments":
        setSelectedDocuments(new Set());
        return setWizardStep("ImportLoop");
      case "AddSigners":
        setSelectedSigners(new Set());
        return setWizardStep("AddDocuments");
      case "ConfirmDetails":
        return setWizardStep("AddSigners");
      case "ImportLoop":
      case "ImportingLoop":
        throw new Error(`Unexpected back attempt on ${wizardStep}`);
    }
  };

  switch (wizardStep) {
    case "ImportLoop":
      return (
        <ImportLoop
          onCancel={onCancel}
          goToNextStep={goToNextStep}
          selectedProfileId={selectedProfileId}
          selectedLoop={selectedLoop}
          onSelectProfile={handleSelectProfile}
          onSelectLoop={setSelectedLoop}
        />
      );
    case "AddDocuments":
      return (
        <AddDocuments
          onCancel={onCancel}
          onGoBack={goToPreviousStep}
          goToNextStep={goToNextStep}
          profileId={selectedProfileId || -1}
          loopId={selectedLoop?.id || -1}
          initialSelectedDocuments={selectedDocuments}
          onSelectDocuments={setSelectedDocuments}
        />
      );
    case "AddSigners":
      return (
        <AddSigners
          onCancel={onCancel}
          onGoBack={goToPreviousStep}
          goToNextStep={goToNextStep}
          profileId={selectedProfileId || -1}
          loopId={selectedLoop?.id || -1}
          initialSelectedSigners={selectedSigners}
          onSelectSigners={setSelectedSigners}
        />
      );
    case "ConfirmDetails":
      return (
        <ConfirmDetails
          onCancel={onCancel}
          onGoBack={goToPreviousStep}
          goToNextStep={goToNextStep}
          selectedLoop={selectedLoop!}
          selectedDocuments={selectedDocuments}
          selectedSigners={selectedSigners}
        />
      );
    case "ImportingLoop":
      return (
        <div className={Styles.importing}>
          {uploadError ? (
            <>
              <div className={Styles.importError}>
                <FormattedMessage
                  id="ee3c9f3a-57a0-41e7-8409-a29f721f4081"
                  defaultMessage="Error Importing Loop. Please try again."
                />
              </div>

              <div className={Styles.buttons}>
                <Button onClick={onCancel} variant="secondary" buttonColor="action">
                  <FormattedMessage
                    id="f260b24b-5b8a-4674-899f-0ccb2500aba3"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </>
          ) : (
            <>
              <img alt="Uploading" src={spinner} className={Styles.spinner} />
              <FormattedMessage
                tagName="div"
                id="ee3c9f3a-57a0-41e7-8409-a29f721f4080"
                defaultMessage="Importing Loop. Please don't leave this page, it may take a few minutes."
              />
            </>
          )}
        </div>
      );
  }
}
