import "./index.scss";

import type { ComponentProps } from "react";
import { defineMessages, useIntl } from "react-intl";

import { SearchField } from "common/core/search_field";

import StateField from "./state";

type StateFieldProps = ComponentProps<typeof StateField>;
type Props = {
  searchQuery: string;
  onSearch: (newSearch: string) => void;
  onChangeSearch: (evt: { value: string }) => void;
  transactionStatusFilter: StateFieldProps["value"];
  onChangeState: StateFieldProps["onChange"];
  organization: StateFieldProps["organization"];
};

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "79c07fbc-d8ec-4b9b-8087-24ff4f94d681",
    defaultMessage: "Search by signer or loan/file #",
  },
  searchLabel: {
    id: "c3df5aa9-bbd1-45b8-8bc8-5a4b83cc0b5e",
    defaultMessage: "Search closing transactions",
  },
});

function ClosingsFilteringMenu({
  organization,
  searchQuery,
  transactionStatusFilter,
  onChangeState,
  onChangeSearch,
  onSearch,
}: Props) {
  const intl = useIntl();
  return (
    <div className="ClosingsFilteringMenu">
      <StateField
        organization={organization}
        value={transactionStatusFilter}
        onChange={onChangeState}
      />
      <SearchField
        value={searchQuery}
        placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
        searchOnBlur
        searchOnClear
        onChange={onChangeSearch}
        onSearch={onSearch}
      />
    </div>
  );
}

export default ClosingsFilteringMenu;
