import { defineMessages } from "react-intl";

export const NOTARY_LABELS = defineMessages({
  videoPermission: {
    id: "43f0e194-8363-4624-9bee-e03d2ed14bb0",
    description: "notary",
    defaultMessage:
      "A built-in camera or connected webcam is required for streaming video " +
      "in the notary call. Please allow access when prompted.",
  },
});

export const SIGNER_LABELS = defineMessages({
  videoPermission: {
    id: "6384c316-5df5-44ce-a7c3-4973b606dd38",
    description: "signer",
    defaultMessage:
      "A built-in camera or connected webcam is required in order to verify your " +
      "photo ID, and for streaming video in the notary call. Please allow access when prompted.",
  },
});
