export default function builder({
  hostName,
  transactionName,
  document,
  apiKey,
  email,
  externalId,
  allowSignerAnnotations,
}) {
  const baseURL = `app.${hostName || "notarize.com"}/easy-link`;
  if (!transactionName) {
    throw new Error("Missing transaction name");
  }
  if (!document) {
    throw new Error("Missing file");
  }
  if (!apiKey) {
    throw new Error("Missing API key");
  }

  const options = {
    ApiKey: encodeURIComponent(apiKey),
    document: encodeURIComponent(document),
    transaction_name: encodeURIComponent(transactionName),
    allow_signer_annotations: allowSignerAnnotations ? "true" : "false",
  };

  if (email) {
    options["signer[email]"] = encodeURIComponent(email);
  }

  if (externalId) {
    options.external_id = encodeURIComponent(externalId);
  }

  // sort keys so we generate consistent urls
  const keys = Object.keys(options).sort();
  const pairs = keys.map((key) => `${key}=${options[key]}`);

  const search = pairs.join("&");
  return `https://${baseURL}?${search}`;
}
