import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useEffect } from "react";

import { SigningRequirementEnum } from "graphql_globals";
import type { FormValues, UseForm } from "common/transaction_creation/v3/form";
import {
  CardDivider,
  ConfiguredCheckboxGroup,
  showField,
} from "common/transaction_creation/v3/common";
import type { Config, ConfiguredField } from "common/transaction_creation/v3/config";
import { useWatch, type FieldPath } from "common/core/form";
import { useNestedError } from "common/core/form/error";
import {
  Ial2CheckboxLabel,
  SmsAuthCheckboxLabel,
  KbaCheckboxLabel,
  ProofRequirementFormValues,
  type ProofRequirementField,
} from "common/proof_requirements/common";
import Link from "common/core/link";
import { SUPPORT_HOST } from "constants/support";
import { Paragraph } from "common/core/typography";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { useId } from "util/html";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  proof: {
    id: "c9946353-1f8a-4756-b797-d53a5f1ba15a",
    defaultMessage: "Add identity verification",
  },
  proofToggle: {
    id: "4751243b-5822-44dd-a5f3-3af268479a89",
    defaultMessage: "Add or remove identity verification",
  },
  proofRequirementsError: {
    id: "e2e9f9c0-8b9a-4b9e-9b9a-9b9a9b9a9b9a",
    defaultMessage: "Please select at least one identity verification option",
  },
});

export function ProofRequirements({
  config,
  configField,
  name,
  signingRequirementName,
  showProofRequirementsName,
  defaultSmsRequirement,
  defaultKbaRequired,
  form,
  requiredOverride,
  makeAutomationId,
}: {
  config: Config;
  configField: ConfiguredField;
  form: UseForm;
  name: FieldPath<FormValues>;
  signingRequirementName: FieldPath<FormValues>;
  showProofRequirementsName: FieldPath<FormValues>;
  defaultSmsRequirement: boolean;
  defaultKbaRequired: boolean;
  requiredOverride?: true;
  makeAutomationId: (field: string) => string;
}) {
  const intl = useIntl();
  const proofRequirement = useWatch({
    control: form.control,
    name,
  }) as ProofRequirementField;

  const signingRequirement = useWatch({
    control: form.control,
    name: signingRequirementName,
  });

  const ial2Selected = proofRequirement.includes(ProofRequirementFormValues.IAL2);
  const smsSelected = proofRequirement.includes(ProofRequirementFormValues.SMS);

  // There are two cases
  // 1. Notarization - we only show proof requirements if it had been previously set (defaults to hidden)
  // 2. Proof - we always default proof requirements to on
  useEffect(() => {
    form.setValue(
      showProofRequirementsName,
      proofRequirement.length > 0 || config.requireProofSigner,
    );
  }, []);

  const showProofRequirements = useWatch({
    control: form.control,
    name: showProofRequirementsName,
  });

  const setShowProofRequirements = () => {
    form.setValue(showProofRequirementsName, !showProofRequirements);

    if (!showProofRequirements && (defaultSmsRequirement || defaultKbaRequired)) {
      const defaultRequirements = [...proofRequirement];

      if (defaultSmsRequirement) {
        defaultRequirements.push(ProofRequirementFormValues.SMS);
      }
      if (defaultKbaRequired) {
        defaultRequirements.push(ProofRequirementFormValues.KBA);
      }

      form.setValue(name, defaultRequirements);
    }
  };

  useEffect(() => {
    // SMS must be checked when IAL2 is checked
    if (ial2Selected && !smsSelected) {
      form.setValue(name, [...proofRequirement, ProofRequirementFormValues.SMS]);
      // setValue is not triggering a re-render to get rid of the error, so re-validate
      form.trigger(name);
    }
  }, [ial2Selected]);

  const error = useNestedError({ control: form.control, name });
  const proofCheckboxesId = useId();

  if (
    !(
      showField(config, configField) &&
      (config.requireProofSigner || signingRequirement === SigningRequirementEnum.ESIGN)
    )
  ) {
    return null;
  }

  return (
    <>
      <CardDivider />
      <div className={Styles.headingWrapper}>
        {intl.formatMessage(MESSAGES.proof)}

        <BinaryToggle
          className={Styles.toggle}
          aria-label={intl.formatMessage(MESSAGES.proofToggle)}
          onChange={setShowProofRequirements}
          value={!!showProofRequirements}
          size="small"
          automationId={makeAutomationId("identity-verification-toggle")}
        />
      </div>

      <div className={Styles.subtitleWrapper}>
        <Paragraph id={proofCheckboxesId} textColor="subtle">
          {
            <FormattedMessage
              id="5637f1cd-d0fb-4550-9f5c-5b2b2f507e76"
              defaultMessage="Proof uses enhanced checks to validate a signer's identity. <link>Learn more</link>"
              values={{
                link: (text) => (
                  <Link href={`${SUPPORT_HOST}/hc/en-us/articles/8706936036887`} underlined>
                    {text}
                  </Link>
                ),
              }}
            />
          }
        </Paragraph>
      </div>

      {showProofRequirements ? (
        <Paragraph className={Styles.proofSection}>
          <ConfiguredCheckboxGroup
            aria-labelledby={proofCheckboxesId}
            groupError={error}
            config={config}
            configField={configField}
            form={form}
            name={name}
            requiredOverride={requiredOverride}
            customErrorMsg={intl.formatMessage(MESSAGES.proofRequirementsError)}
            checkboxes={[
              {
                label: <Ial2CheckboxLabel />,
                value: ProofRequirementFormValues.IAL2,
                "data-automation-id": makeAutomationId("ial2"),
              },
              {
                label: <SmsAuthCheckboxLabel />,
                value: ProofRequirementFormValues.SMS,
                disabledOverride: ial2Selected || undefined,
                "data-automation-id": makeAutomationId("sms"),
              },
              {
                label: <KbaCheckboxLabel />,
                value: ProofRequirementFormValues.KBA,
                "data-automation-id": makeAutomationId("kba"),
              },
            ]}
          />
        </Paragraph>
      ) : null}
    </>
  );
}
