import { type ReactNode, type ReactElement, cloneElement } from "react";
import classnames from "classnames";

import Icon from "common/core/icon";
import Link from "common/core/link";

import { MenuItemWrapper } from "./common";
import Styles from "./item.module.scss";

type LinkProps = {
  url: string;
  externalUrl?: boolean;
  openInCurrentTab?: boolean;
};
type Props = {
  children?: ReactNode;
  "data-automation-id"?: string;
  "aria-label"?: string;
  iconName?: string;
  iconClass?: string;
  danger?: boolean;
  onClick?: () => void;
  linkUrl?: LinkProps;
  customMenuItem?: ReactElement;
  customMenuItemClass?: string;
  overrideRole?: boolean;
  loading?: boolean;
  /** @deprecated please use iconName instead */
  backgroundImage?: string;
};

function PopoutMenuItem({
  onClick,
  children,
  iconName,
  iconClass,
  "data-automation-id": dataAutomationId,
  "aria-label": ariaLabel,
  danger,
  linkUrl,
  customMenuItem,
  customMenuItemClass,
  overrideRole,
  loading,
  backgroundImage,
}: Props) {
  // render a custom element that needs it's own visual styles
  if (customMenuItem) {
    return (
      <MenuItemWrapper role="presentation" className={customMenuItemClass}>
        {cloneElement(customMenuItem, {
          role: "menuitem",
        })}
      </MenuItemWrapper>
    );
  }

  // render an anchor tag that is either internal or external
  if (linkUrl) {
    const linkUrlProps = linkUrl.externalUrl
      ? { href: linkUrl.url, openInCurrentTab: linkUrl.openInCurrentTab }
      : { to: linkUrl.url };

    return (
      <MenuItemWrapper role="presentation">
        <Link
          {...linkUrlProps}
          openInCurrentTab={linkUrl.openInCurrentTab}
          className={classnames(Styles.menuItem, {
            [Styles.danger]: danger,
            [Styles.loading]: loading,
          })}
          data-automation-id={dataAutomationId}
          aria-label={ariaLabel}
          onClick={onClick}
          black
          underlined={false}
        >
          {iconName && <Icon name={iconName} className={classnames(Styles.icon, iconClass)} />}
          {backgroundImage && <img src={backgroundImage} alt="" className={Styles.img} />}
          {children}
        </Link>
      </MenuItemWrapper>
    );
  }

  // render a `<button>` by default if not using a url or custom element
  return (
    <MenuItemWrapper role="presentation">
      <button
        type="button"
        role={overrideRole ? undefined : "menuitem"}
        className={classnames(Styles.menuItem, {
          [Styles.danger]: danger,
          [Styles.loading]: loading,
        })}
        onClick={onClick}
        aria-label={ariaLabel}
        data-automation-id={dataAutomationId}
      >
        {iconName && <Icon name={iconName} className={classnames(Styles.icon, iconClass)} />}
        {backgroundImage && <img src={backgroundImage} alt="" className={Styles.img} />}
        {children}
      </button>
    </MenuItemWrapper>
  );
}

export default PopoutMenuItem;
