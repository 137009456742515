import PropTypes from "prop-types";

import AudioConfiguration from "./config";
import AudioPermissionGetter from "./permission";
import { GRANTED_PERM } from "../permission";

export default function AudioSetup(props) {
  const {
    permissionStatus,
    missingEquipment,
    onAccessClick,
    onAccessGranted,
    onAccessBlocked,
    onConfirmation,
    onSpeakerSelection,
    onMicrophoneSelection,
    selectedSpeakerId,
    selectedMicrophoneId,
    onMissingEquipment,
    onConfigurationMount,
    onPermissionMount,
    isContinueDisabled,
    onSpeakerSelectionDisabled,
  } = props;

  return permissionStatus === GRANTED_PERM ? (
    <AudioConfiguration
      missingEquipment={missingEquipment}
      onConfirmation={onConfirmation}
      selectedSpeakerId={selectedSpeakerId}
      onSpeakerSelection={onSpeakerSelection}
      onSpeakerMissing={onMissingEquipment}
      selectedMicrophoneId={selectedMicrophoneId}
      onMicrophoneSelection={onMicrophoneSelection}
      onMicrophoneMissing={onMissingEquipment}
      onMount={onConfigurationMount}
      isContinueDisabled={isContinueDisabled}
      onSpeakerSelectionDisabled={onSpeakerSelectionDisabled}
      notaryProfile={props.notaryProfile}
      autoFocus
    />
  ) : (
    <AudioPermissionGetter
      permissionStatus={permissionStatus}
      missingEquipment={missingEquipment}
      onAccessClick={onAccessClick}
      onAccessGranted={onAccessGranted}
      onAccessBlocked={onAccessBlocked}
      onSpeakerMissing={onMissingEquipment}
      onMount={onPermissionMount}
    />
  );
}

AudioSetup.propTypes = {
  permissionStatus: PropTypes.string.isRequired,
  missingEquipment: PropTypes.bool,
  onAccessClick: PropTypes.func.isRequired,
  onAccessGranted: PropTypes.func.isRequired,
  onAccessBlocked: PropTypes.func.isRequired,
  onSpeakerSelection: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func,
  onMicrophoneSelection: PropTypes.func.isRequired,
  selectedSpeakerId: PropTypes.string,
  selectedMicrophoneId: PropTypes.string,
  onMissingEquipment: PropTypes.func.isRequired,
  onConfigurationMount: PropTypes.func.isRequired,
  onPermissionMount: PropTypes.func.isRequired,
  onSpeakerSelectionDisabled: PropTypes.func.isRequired,
  isContinueDisabled: PropTypes.bool,
};

AudioSetup.defaultProps = {
  onConfirmation: null,
  selectedSpeakerId: null,
  selectedMicrophoneId: null,
  missingEquipment: null,
};
