import { memo, type ComponentProps, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { NotaryLevels } from "graphql_globals";
import Icon from "common/core/icon";
import { NOTARY_PAYOUT_URL } from "constants/support";

import Styles from "./index.module.scss";

type ModalProps = {
  onClose: () => void;
};

const priceTag = (text: ReactNode) => <div className={Styles.price}>{text}</div>;
const ACCESS_TO = (
  <FormattedMessage id="e2fb4036-5ca7-4e3e-9ae4-7b89c2548c51" defaultMessage="Access to:" />
);
const ACCURACY_STATEMENT = (
  <FormattedMessage
    id="14fa8c92-43f4-4cbb-a332-e654e8a0a37a"
    defaultMessage="80% notarial statement accuracy"
    tagName="li"
  />
);
const BASE_PRICE = (
  <FormattedMessage
    id="b08a4c05-3d4c-4c6a-ad16-f4d1910fce50"
    defaultMessage="<price>$5</price> per completed meeting"
    tagName="td"
    values={{ price: priceTag }}
  />
);
const NOTARY_LEVELS_MESSAGES = Object.freeze({
  [NotaryLevels.PARTNER]: (
    <FormattedMessage id="bf477f7f-dac5-4e73-b010-c548fd85adbc" defaultMessage="Partner" />
  ),
  [NotaryLevels.SILVER]: (
    <FormattedMessage id="e1d46415-8270-4b80-859a-b8f0ed27837c" defaultMessage="Silver" />
  ),
  [NotaryLevels.GOLD]: (
    <FormattedMessage id="bc6eeeb6-adde-4338-8119-add051bc1ca4" defaultMessage="Gold" />
  ),
  [NotaryLevels.PLATINUM]: (
    <FormattedMessage id="962790f3-3ee9-4558-a79e-d50c95d6f128" defaultMessage="Platinum" />
  ),
});
const NOTARY_LEVELS_CLASSES = Object.freeze({
  [NotaryLevels.PARTNER]: Styles.partnerLevel,
  [NotaryLevels.SILVER]: Styles.silverLevel,
  [NotaryLevels.GOLD]: Styles.goldLevel,
  [NotaryLevels.PLATINUM]: Styles.platinumLevel,
});

function retailCallsItem() {
  return (
    <FormattedMessage
      id="16a3e1d6-34c6-43af-bafb-aa5857a0b031"
      defaultMessage="Retail calls"
      tagName="li"
    />
  );
}

function businessCallsItem() {
  return (
    <FormattedMessage
      id="bedf9a41-40d7-4379-86e8-e7d99999012b"
      defaultMessage="Business calls"
      tagName="li"
    />
  );
}

function NotaryLevelBadge({
  level,
  size,
}: {
  level: NotaryLevels | null;
  size?: ComponentProps<typeof Icon>["size"];
}) {
  const notaryLevel = level || NotaryLevels.PARTNER;
  return (
    <div className={Styles.notaryLevel}>
      <Icon className={NOTARY_LEVELS_CLASSES[notaryLevel]} name="seal" size={size} />
      {NOTARY_LEVELS_MESSAGES[notaryLevel]}
    </div>
  );
}
const MemoizedNotaryLevelBadge = memo(NotaryLevelBadge);

function LevelInformationModal({ onClose }: ModalProps) {
  const retailItem = retailCallsItem();
  const businessItem = businessCallsItem();
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="a9ff94c1-d46d-4316-8617-ec7e04421ed0"
          defaultMessage="On-Demand Tiers"
        />
      }
      closeBehavior={{ tag: "with-button", onClose }}
      large
    >
      <FormattedMessage
        id="ff95223b-4ac2-4003-8b8c-f039e2e474a9"
        tagName="p"
        defaultMessage="Proof provides access to meeting leads based on tiers. Tiers take into account customer requirements and document complexity. For more pricing information, read <link>this article</link>."
        values={{
          link: (text) => (
            <a href={NOTARY_PAYOUT_URL} target="_blank" rel="noreferrer">
              {text}
            </a>
          ),
        }}
      />
      <table className={Styles.levelInfoTable}>
        <thead>
          <tr>
            <FormattedMessage
              id="67918c81-c59f-4447-8a45-a048e7115fb9"
              tagName="th"
              defaultMessage="Tier"
            />
            <FormattedMessage
              id="002edf52-2ab9-4ad1-9bd6-09e562cf6f9e"
              tagName="th"
              defaultMessage="Tier Benefits"
            />
            <th />
            <FormattedMessage
              id="002edf52-2ab9-4ad1-9bd6-09e562cf6f9e"
              tagName="th"
              defaultMessage="Tier Requirements"
            />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <MemoizedNotaryLevelBadge level={NotaryLevels.PARTNER} />
            </td>
            <td>
              {ACCESS_TO}
              <ul className={Styles.accessList}>{retailItem}</ul>
            </td>
            {BASE_PRICE}
            <td>
              <ul>
                <FormattedMessage
                  id="637e5104-1ed3-404c-bdaf-bf381f481dc2"
                  defaultMessage="Product Training Completion"
                  tagName="li"
                />
                <FormattedMessage
                  id="0653fbfa-8d9a-4ff8-8688-e3371c7034f3"
                  defaultMessage="Zendesk Account Set Up"
                  tagName="li"
                />
                <FormattedMessage
                  id="d1db6095-7963-4afe-bc04-1adda8fad077"
                  defaultMessage="Compliance Check Completion"
                  tagName="li"
                />
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <MemoizedNotaryLevelBadge level={NotaryLevels.SILVER} />
            </td>
            <td>
              {ACCESS_TO}
              <ul className={Styles.accessList}>
                {retailItem}
                {businessItem}
              </ul>
            </td>
            {BASE_PRICE}
            <td>
              <ul>
                <FormattedMessage
                  id="b3ea17f6-e8a4-463d-b494-6b8a3a3a08ec"
                  defaultMessage="25 completed meetings"
                  tagName="li"
                />
                <FormattedMessage
                  id="c2bbd4b4-82e2-41c6-9cc8-c9c4c4a8f006"
                  defaultMessage="Business Partner Product Training"
                  tagName="li"
                />
                <FormattedMessage
                  id="c9e3e796-d9c3-4d74-930f-c9d608cc576a"
                  defaultMessage="CSAT Min. 4.8"
                  tagName="li"
                />
                {ACCURACY_STATEMENT}
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <MemoizedNotaryLevelBadge level={NotaryLevels.GOLD} />
            </td>
            <td>
              {ACCESS_TO}
              <ul className={Styles.accessList}>
                {retailItem}
                {businessItem}
                <FormattedMessage
                  id="fe48223c-5f0b-48bd-a180-fdb616520634"
                  defaultMessage="Real estate calls"
                  tagName="li"
                />
              </ul>
            </td>
            <FormattedMessage
              id="f3cedcf2-776e-4a9d-b8a7-468a8e712d7c"
              defaultMessage="<price>$25</price> per completed real estate meeting"
              tagName="td"
              values={{ price: priceTag }}
            />
            <td>
              <ul>
                <FormattedMessage
                  id="fd41ca27-d5ec-4dab-898b-ef25c22e9928"
                  defaultMessage="100 completed meetings"
                  tagName="li"
                />
                <FormattedMessage
                  id="d753beaa-3310-4ba1-949c-30c5f06faf27"
                  defaultMessage="Real Estate Product Training"
                  tagName="li"
                />
                <FormattedMessage
                  id="58a85c6b-8ff5-49ce-98d5-d9a194840836"
                  defaultMessage="CSAT Min. 4.9"
                  tagName="li"
                />
                <FormattedMessage
                  id="0efa9aa2-a4e4-4c5c-9e63-2e4302551b40"
                  defaultMessage="$100k E&O"
                  tagName="li"
                />
                {ACCURACY_STATEMENT}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </WorkflowModal>
  );
}
const MemoizedLevelInformationModal = memo(LevelInformationModal);

export {
  MemoizedNotaryLevelBadge as NotaryLevelBadge,
  MemoizedLevelInformationModal as LevelInformationModal,
};
