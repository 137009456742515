import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";
import { SummaryDetailWrapper } from "common/details/summary";

import MarkdDocumentBundleFreeMutation from "./mark_document_bundle_free_mutation.graphql";
import Styles from "./index.module.scss";

const BUNDLE_TAG_FREE = "free_transaction_201810";
const MESSAGES = defineMessages({
  error: {
    id: "205a2e8d-890c-4ce3-90de-e67295a19ee3",
    defaultMessage: "An error occurred",
  },
  ok: {
    id: "a63255dd-e2d3-440a-9089-c06ca5bb514b",
    defaultMessage: "Ok",
  },
  markDiscount: {
    id: "053be0da-328b-4cf1-8e8d-99abbe15f692",
    defaultMessage: "Apply free discount",
  },
  revokeDiscount: {
    id: "ceb3a436-ed93-45dc-822b-d62c5afd6d9e",
    defaultMessage: "Revoke free discount",
  },
  discountApplied: {
    id: "3e78278f-579d-4027-acca-b2530682d48f",
    defaultMessage: "Free discount applied",
  },
  title: {
    id: "ed19a42f-498a-4dd2-aabc-d1413f6853f8",
    defaultMessage: "Mark free",
  },
});

type Props = {
  bundle: {
    id: string;
    tags: { tag: string }[];
    charges: ({ id: string } | null)[] | null;
  };
  onUpdate: () => void;
};

function TransactionMarkFree({ bundle, onUpdate }: Props) {
  const { charges, tags, id } = bundle;

  const intl = useIntl();
  const markDocumentBundleFreeMutateFn = useMutation(MarkdDocumentBundleFreeMutation);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  async function updateDiscount(revoke: boolean) {
    if (!isLoading) {
      setIsLoading(true);

      try {
        await markDocumentBundleFreeMutateFn({
          variables: {
            mutationInput: {
              bundleId: id,
              revoke,
            },
          },
        });
        onUpdate();
      } catch (e) {
        captureException(e);
        setError((e as Error).message);
      }

      setIsLoading(false);
    }
  }

  const charged = Boolean(charges?.length);
  const markedFree = tags.some(({ tag }) => tag === BUNDLE_TAG_FREE);

  const unmarked = (
    <Button
      automationId="mark-discount"
      onClick={() => updateDiscount(false)}
      disabled={isLoading}
      variant="secondary"
      buttonColor="action"
      buttonSize="condensed"
    >
      {intl.formatMessage(MESSAGES.markDiscount)}
    </Button>
  );

  const marked = (
    <>
      <span data-automation-id="discount-text">{intl.formatMessage(MESSAGES.discountApplied)}</span>
      {!charged && (
        <Button
          className={Styles.revokeButton}
          automationId="revoke-discount"
          onClick={() => updateDiscount(true)}
          disabled={isLoading}
          variant="secondary"
          buttonColor="action"
          buttonSize="condensed"
        >
          {intl.formatMessage(MESSAGES.revokeDiscount)}
        </Button>
      )}
    </>
  );

  return (
    <>
      <SummaryDetailWrapper
        term={intl.formatMessage(MESSAGES.title)}
        definition={!markedFree ? unmarked : marked}
      />
      {error && (
        <IllustrationModal
          automationPrefix="payer-type-error-modal"
          title={intl.formatMessage(MESSAGES.error)}
          buttons={[
            <Button key="ok" onClick={() => setError(null)} buttonColor="action" variant="primary">
              {intl.formatMessage(MESSAGES.ok)}
            </Button>,
          ]}
        >
          {error}
        </IllustrationModal>
      )}
    </>
  );
}

export default TransactionMarkFree;
