import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { FormattedDate } from "common/core/format/date";

import ItemizedList from "./itemized";
import type { RealtorTransactionInfoModal_node as OrganizationTransaction } from "./index_fragment.graphql";

type Props = {
  transaction: OrganizationTransaction;
};
type Item = {
  label: ReactNode;
  valueFn: (transaction: OrganizationTransaction) => ReactNode;
};

const ITEMS: readonly Item[] = Object.freeze([
  {
    label: (
      <FormattedMessage
        id="0e5792b2-8a98-4976-96f4-e37142a18903"
        defaultMessage="Transaction Name"
      />
    ),
    valueFn: (transaction) => transaction.name,
  },
  {
    label: (
      <FormattedMessage
        id="3ba8d7c0-6504-44bf-8482-38db74d315b7"
        defaultMessage="Transaction Type"
      />
    ),
    valueFn: (transaction) => transaction.transactionType,
  },
  {
    label: <FormattedMessage id="6ce78179-3dd5-4f57-9fba-486da4f980fa" defaultMessage="Lender" />,
    valueFn: (transaction) => transaction.lenderName,
  },
  {
    label: (
      <FormattedMessage id="eb097b20-ead2-4520-b72f-d55d1e9c7bf4" defaultMessage="Title Agency" />
    ),
    valueFn: (transaction) => transaction.titleAgency?.name,
  },
  {
    label: (
      <FormattedMessage id="4d577ff6-3f6f-4e11-97c9-00026ad0c34f" defaultMessage="File Number" />
    ),
    valueFn: (transaction) => transaction.fileNumber,
  },
  {
    label: (
      <FormattedMessage id="cc063441-b2bf-4454-8223-99abe3ee4778" defaultMessage="Loan Number" />
    ),
    valueFn: (transaction) => transaction.loanNumber,
  },
  {
    label: (
      <FormattedMessage
        id="804feaa5-1e76-404e-ae3e-78cec40a051c"
        defaultMessage="Activation Time"
      />
    ),
    valueFn: (transaction) =>
      transaction.activationTime ? <FormattedDate value={transaction.activationTime} /> : "",
  },
]);

function Summary({ transaction }: Props) {
  return (
    <ItemizedList>
      {ITEMS.map(({ label, valueFn }, index) => (
        <li key={index}>
          <span>{label}</span>
          <span>{valueFn(transaction)}</span>
        </li>
      ))}
    </ItemizedList>
  );
}

export default memo(Summary);
