import "./index.scss";

import { memo, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Portal, useId } from "util/html";
import Overlay from "common/modals/overlay";
import Icon from "common/core/icon";
import History from "common/details/history";

import Summary from "./summary";
import Contacts from "./contacts";
import Signers from "./signers";
import type { RealtorTransactionInfoModal_node as OrganizationTransaction } from "./index_fragment.graphql";

type Props = {
  transaction: OrganizationTransaction;
  onClose: () => void;
};
type TabId = "summary" | "contacts" | "signer" | "history";
type Tab = {
  id: TabId;
  content: ReactNode;
};
type TabProps = {
  tab: Tab;
  isSelected: boolean;
  onClick: (newTab: TabId) => void;
};

const TABS = [
  {
    id: "summary",
    content: (
      <FormattedMessage id="1b687aed-cd6a-4576-ad0f-b073b40f8c0a" defaultMessage="Summary" />
    ),
  },
  {
    id: "contacts",
    content: (
      <FormattedMessage id="3e7b13c4-6ae3-4ebe-9c8f-588ea981372f" defaultMessage="Contacts" />
    ),
  },
  {
    id: "signer",
    content: (
      <FormattedMessage id="d84b3bd5-b1b2-45c1-854b-1289acb01914" defaultMessage="Signers" />
    ),
  },
  {
    id: "history",
    content: (
      <FormattedMessage id="a7afe26f-f565-46d8-a037-bad7842812f5" defaultMessage="History" />
    ),
  },
] as const;

function TabElementInner(props: TabProps) {
  return (
    <div
      className={classnames("TransactionInfoModal--Tab", props.isSelected && "selected")}
      onClick={() => props.onClick(props.tab.id)}
    >
      {props.tab.content}
    </div>
  );
}
const TabElement = memo(TabElementInner);

function renderContent(currentTab: TabId, transaction: OrganizationTransaction) {
  switch (currentTab) {
    case "history":
      return <History bundle={transaction.documentBundle!} />;
    case "signer":
      return <Signers transaction={transaction} />;
    case "contacts":
      return <Contacts transaction={transaction} />;
    case "summary":
    default:
      return <Summary transaction={transaction} />;
  }
}

function InfoModal({ transaction, onClose }: Props) {
  const [currentTab, setCurrentTab] = useState<TabId>(TABS[0].id);
  const modalTitleId = useId();
  return (
    <Portal>
      <Overlay>
        <div role="dialog" aria-labelledby={modalTitleId} className="TransactionInfoModal">
          <header>
            <Icon name="x" deprecatedOnClick={onClose} />
            <span id={modalTitleId}>
              <FormattedMessage
                id="874e699e-54f7-44e0-b61b-6f9696b7829e"
                defaultMessage="Transaction Details"
                description="Transaction details modal header for realtors my closings"
                tagName="h3"
              />
            </span>
          </header>
          <nav>
            {TABS.map((tab) => (
              <TabElement
                key={tab.id}
                tab={tab}
                isSelected={currentTab === tab.id}
                onClick={setCurrentTab}
              />
            ))}
          </nav>
          <div className="TransactionInfoModal--body">{renderContent(currentTab, transaction)}</div>
        </div>
      </Overlay>
    </Portal>
  );
}

export default memo(InfoModal);
