import {
  deserializeDetailedStatus,
  deserializeDateConstraint,
  sortColumnDeserializer,
  sortDirectionDeserializer,
  deserializeSection,
  subSectionTypeGuard,
} from "common/dashboard/filter/deserializers";
import type {
  OrganizationTransactionDetailedStatus,
  OrganizationTransactionColumn,
  SortDirection,
} from "graphql_globals";
import {
  serializeSet,
  getDateConstraintUrlString,
  serializerFactory,
} from "common/dashboard/filter/serializers";
import {
  TransactionSubsectionNamespace,
  type DateConstraint,
  type TransactionSectionNamespace,
} from "common/dashboard/filter_dropdown/common";

function deserializeBusinessSubSection(serializedSubsection: string | null) {
  if (subSectionTypeGuard(serializedSubsection)) {
    return serializedSubsection;
  }

  if (serializedSubsection === null) {
    return TransactionSubsectionNamespace.CREATED_BY_ME;
  }

  return null;
}

export function businessDashboardDeserializer(queryArgs: URLSearchParams) {
  const page = Number(queryArgs.get("page"));
  const query = queryArgs.get("query");
  const detailedStatuses = deserializeDetailedStatus(queryArgs.get("detailedStatuses"));
  const createdDateConstraintArg = queryArgs.get("createdDateConstraint");
  const createdDateConstraint = deserializeDateConstraint(createdDateConstraintArg);
  const completedDateConstraintArg = queryArgs.get("completedDateConstraint");
  const completedDateConstraint = deserializeDateConstraint(completedDateConstraintArg);
  const sortColumn = sortColumnDeserializer(queryArgs.get("sortColumn"));
  const sortDirection = sortDirectionDeserializer(queryArgs.get("sortDirection"));
  const section = deserializeSection(queryArgs.get("section"));
  const subSection = deserializeBusinessSubSection(queryArgs.get("subSection"));

  return {
    page,
    query,
    detailedStatuses,
    createdDateConstraint,
    completedDateConstraint,
    section,
    subSection,
    sortColumn,
    sortDirection,
  };
}

const businessDashboardSerializeMap = {
  page: (arg: number | null) => (arg ? String(arg) : null),
  query: (arg: string | null) => arg || null,
  detailedStatuses: (arg: Set<OrganizationTransactionDetailedStatus> | null) => serializeSet(arg),
  sortColumn: (arg: OrganizationTransactionColumn | null) => arg || null,
  sortDirection: (arg: SortDirection | null) => arg || null,
  createdDateConstraint: (arg: DateConstraint | null) => getDateConstraintUrlString(arg) || null,
  completedDateConstraint: (arg: DateConstraint | null) => getDateConstraintUrlString(arg) || null,
  section: (arg: TransactionSectionNamespace | null) => (arg ? String(arg) : null),
  subSection: (arg: TransactionSubsectionNamespace | null) => (arg ? String(arg) : null),
};
export const businessDashboardSerializer = serializerFactory(businessDashboardSerializeMap);
