import "./index.scss";

import { FormattedMessage } from "react-intl";

function AdobeEnabledEmptyState() {
  return (
    <div className="AdobeEnabledEmptyState">
      <div className="AdobeEnabledEmptyState--header">
        <FormattedMessage
          id="77351c6e-a5a9-4b08-bbb7-9b2da0b05d9d"
          defaultMessage="No transactions"
        />
      </div>
      <div className="AdobeEnabledEmptyState--line-1">
        <FormattedMessage
          id="34acd2d7-ad88-449c-a188-4bbf2cf609d5"
          defaultMessage="Once a transaction with a notarization is created in Adobe Sign, it will be listed here."
        />
      </div>
    </div>
  );
}

export default AdobeEnabledEmptyState;
