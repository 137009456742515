import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";
import Button from "common/core/button";
import EmailVerification from "assets/images/email-verification.svg";
import { useMutation } from "util/graphql";
import { ClientPlatform } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { Heading, Paragraph } from "common/core/typography";

import SendUserVerificationEmailMutation from "./send_user_verification_email_mutation.graphql";
import Styles from "./index.module.scss";

type Props = {
  email: string;
};

function NotaryOnboardingVerify({ email }: Props) {
  const sendUserVerificationEmailMutateFn = useMutation(SendUserVerificationEmailMutation);

  const sendUserVerificationEmail = () => {
    return sendUserVerificationEmailMutateFn({
      variables: {
        input: {
          platform: ClientPlatform.WEB,
        },
      },
    }).then(() => {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: (
          <FormattedMessage id="90ec794a-c66f-4eb2-874f-3c74971ffc42" defaultMessage="Email Sent" />
        ),
      });
    });
  };

  return (
    <div className={Styles.container}>
      <img src={EmailVerification} alt="email verification" />
      <Heading level="h1" textStyle="headingFour" textAlign="center">
        <FormattedMessage
          id="53f4d28a-802f-4f78-9f7c-5205c36e9757"
          defaultMessage="Please verify your email address"
        />
      </Heading>
      <Paragraph size="large" className={Styles.paragraph}>
        <FormattedMessage
          id="3f9510fe-31d2-4475-bc78-4226d773af1f"
          defaultMessage="To get started, verify your email address. An email containing verification instructions was sent to <b>{email}</b>."
          values={{ b, email }}
        />
      </Paragraph>
      <FormattedMessage
        id="7262916a-c06c-4293-aca3-93529e2e250b"
        defaultMessage="<strong>Didn't get the email?</strong> <link>Resend email</link>"
        values={{
          strong: (msg: ReactNode[]) => <strong>{msg}</strong>,
          link: (msg: ReactNode[]) => (
            <Button
              className={Styles.button}
              automationId="resend"
              buttonColor="action"
              variant="tertiary"
              onClick={sendUserVerificationEmail}
            >
              {msg}
            </Button>
          ),
        }}
      />
    </div>
  );
}

export default memo(NotaryOnboardingVerify);
