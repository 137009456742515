import "./explainer.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import webcamImage from "assets/images/tech/webcam.svg";
import microphoneImage from "assets/images/tech/mic.svg";
import speakerImage from "assets/images/tech/speaker.svg";
import internetImage from "assets/images/tech/internet.svg";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import Experiment from "common/experiments";
import { Paragraph } from "common/core/typography";

const EXPERIMENT_NAME = "tech_check_experiment";

class Explainer extends PureComponent {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const { completionRequirements } = this.props;

    return (
      <Experiment name={EXPERIMENT_NAME}>
        {({ assignment }) => (
          <div className="TechCheckExplainer" data-automation-id="tech-check-explainer">
            <div className="TechCheckExplainer--step-icons" aria-hidden>
              <img className="TechCheck--step-icon" src={webcamImage} alt="" />
              <img className="TechCheck--step-icon" src={microphoneImage} alt="" />
              <img className="TechCheck--step-icon" src={speakerImage} alt="" />
              <img className="TechCheck--step-icon" src={internetImage} alt="" />
            </div>

            <Paragraph textAlign="center" className="TechCheck--info">
              {assignment === "control" ? (
                <CompletionRequirementsText
                  stringId="techCheckExplainer"
                  completionRequirements={completionRequirements}
                />
              ) : (
                <CompletionRequirementsText
                  stringId="techCheckExplainerV2"
                  completionRequirements={completionRequirements}
                />
              )}
            </Paragraph>

            <Paragraph textAlign="center" className="TechCheck--info">
              <FormattedMessage
                id="0f146707-2c6c-4a3a-ae66-5d88c3c4fdcf"
                description="allowAccess"
                defaultMessage="Please allow access when prompted for permission."
              />
            </Paragraph>
          </div>
        )}
      </Experiment>
    );
  }
}

Explainer.propTypes = {
  onMount: PropTypes.func.isRequired,
};

export default Explainer;
