import { memo, useState, useCallback, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import type { OrganizationTransactionDetailedStatus } from "graphql_globals";
import { encodeSearchParams } from "util/location";
import BasicAppFrameContainer, {
  BasicAppFrameHeader,
  BasicAppFrameTitle,
} from "common/app_frame/basic_container";
import { NetworkStatus, useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";

import ClosingsQuery, {
  type RealtorClosings_viewer_user_organization as Organization,
  type RealtorClosings_viewer_user_organization_observedTransactions_edges_node as OrganizationTransaction,
} from "./dashboard_query.graphql";
import InfoModal from "./info_modal";
import ClosingListing from "./listing";
import ClosingsFilteringMenu from "./filter";
import Pagination from "./pagination";

type Props = {
  organization: Organization;
  initSearch: string | null;
  onSearch: ComponentProps<typeof ClosingsFilteringMenu>["onSearch"];
  transactionStatusFilter: OrganizationTransactionDetailedStatus | null;
  onChangeStatusFilter: ComponentProps<typeof ClosingsFilteringMenu>["onChangeState"];
};

const ITEMS_PER_PAGE = 10;
const BASE_PAGE_VARS = Object.freeze({
  first: ITEMS_PER_PAGE.toString(),
  last: null,
  before: null,
  after: null,
  page: null,
});

function RealtorClosingsDashboardContent({
  organization,
  initSearch,
  onSearch,
  transactionStatusFilter,
  onChangeStatusFilter,
}: Props) {
  const transactionConnection = organization.observedTransactions;
  const { edges } = transactionConnection;
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(initSearch || "");
  const [infoModalOpenWith, setInfoModalOpenWith] = useState<OrganizationTransaction | null>(null);
  const handleChangeSearch = useCallback(({ value }: { value: string }) => {
    setSearchQuery(value);
  }, []);
  const handleListingClick = useCallback(
    (id: string, meetingId: string | null) => {
      if (meetingId) {
        navigate(`/meeting/${meetingId}`);
        return;
      }
      const edge = edges.find((e) => e.node.id === id);
      if (edge) {
        setInfoModalOpenWith(edge.node);
      }
    },
    [edges, navigate],
  );
  const handleCloseModal = useCallback(() => setInfoModalOpenWith(null), []);
  return (
    <BasicAppFrameContainer>
      <BasicAppFrameTitle>
        <FormattedMessage
          id="60e157e9-eb3f-4c49-962f-faa7536a1762"
          description="Realtor my closings header"
          defaultMessage="My closings"
        />
      </BasicAppFrameTitle>
      <BasicAppFrameHeader>
        <ClosingsFilteringMenu
          organization={organization}
          onChangeState={onChangeStatusFilter}
          onChangeSearch={handleChangeSearch}
          onSearch={onSearch}
          searchQuery={searchQuery}
          transactionStatusFilter={transactionStatusFilter}
        />
      </BasicAppFrameHeader>
      <ClosingListing transactions={edges} onClick={handleListingClick} />
      <Pagination itemsPerPage={ITEMS_PER_PAGE} connection={transactionConnection} />
      {infoModalOpenWith && (
        <InfoModal transaction={infoModalOpenWith} onClose={handleCloseModal} />
      )}
    </BasicAppFrameContainer>
  );
}

function RealtorClosingsDashboard() {
  const navigate = useNavigate();
  const [queryArgs] = useSearchParams();
  const transactionStatusFilter = queryArgs.get("status") as Props["transactionStatusFilter"];
  const searchTerm = queryArgs.get("search");
  const variables = {
    first: queryArgs.get("first")
      ? Number(queryArgs.get("first"))
      : queryArgs.get("last")
        ? undefined
        : ITEMS_PER_PAGE,
    statuses: transactionStatusFilter ? [transactionStatusFilter] : undefined,
    searchTerm: searchTerm || undefined,
    before: queryArgs.get("before") || undefined,
    after: queryArgs.get("after") || undefined,
    last: queryArgs.get("last") ? Number(queryArgs.get("last")) : undefined,
  };
  const { data, networkStatus } = useQuery(ClosingsQuery, { variables, fetchPolicy: "no-cache" });
  const handleChangeStatusFilter = useCallback(
    (status: Props["transactionStatusFilter"]) => {
      navigate(`/realtor_closings?${encodeSearchParams(queryArgs, { ...BASE_PAGE_VARS, status })}`);
    },
    [navigate, queryArgs],
  );
  const handleSearch = useCallback(
    (search: string) => {
      navigate(
        `/realtor_closings?${encodeSearchParams(queryArgs, {
          ...BASE_PAGE_VARS,
          search: search || null,
        })}`,
      );
    },
    [navigate, queryArgs],
  );

  const organization = data?.viewer.user!.organization;
  if (networkStatus === NetworkStatus.loading || !organization) {
    return <LoadingIndicator />;
  }

  return (
    <RealtorClosingsDashboardContent
      initSearch={searchTerm}
      onChangeStatusFilter={handleChangeStatusFilter}
      onSearch={handleSearch}
      transactionStatusFilter={transactionStatusFilter}
      organization={organization}
    />
  );
}

export default memo(RealtorClosingsDashboard);
