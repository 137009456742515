import type { ComponentProps } from "react";

import SignupV2UserNameField from "common/account/signup_v2/form/fields/user_name";
import SignupV2EmailField from "common/account/signup_v2/form/fields/email";
import SignupV2PasswordField from "common/account/signup_v2/form/fields/password";
import SignupV2BusinessNameField from "common/account/signup_v2/form/fields/business_name";

import StateField from "./state";

type Props = {
  usStates: ComponentProps<typeof StateField>["states"];
};

function NotarySignupFields({ usStates }: Props) {
  return (
    <>
      <SignupV2UserNameField />
      <SignupV2EmailField />
      <SignupV2PasswordField />
      <SignupV2BusinessNameField labelText="notaryBusinessName" />
      <StateField states={usStates} />
    </>
  );
}

export default NotarySignupFields;
