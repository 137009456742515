import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { PartnerPayerSourcesEnum } from "graphql_globals";
import Button from "common/core/button";
import { Card, CardSection } from "common/core/card";
import { useForm } from "common/core/form";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";

import UpdateLenderProfileMutation from "./update_lender_profile_mutation.graphql";
import CollabFeeSettingQuery, {
  type CollabFeeSetting_organization_Organization as Organization,
} from "./collab_fee_setting.query.graphql";

type FormValues = {
  lenderPartnerPayer: PartnerPayerSourcesEnum;
  firstName: string;
};

type Props = {
  organization: Organization;
};

const messages = defineMessages({
  success: {
    id: "9f244b84-e3b2-4725-9d05-3fa467877e6a",
    defaultMessage: "Partner Payer successfully updated",
  },
  failure: {
    id: "e733b930-c41f-40be-8036-ed1dfec8b937",
    defaultMessage: "Partner Payer failed to update",
  },
});

function CollabFeeSettingInner({ organization }: Props) {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      lenderPartnerPayer: organization.lenderPartnerPayer!,
    },
  });
  const intl = useIntl();

  const updateCollabPayer = useMutation(UpdateLenderProfileMutation);
  return (
    <Card
      footer={
        <Button
          buttonColor="action"
          variant="primary"
          type="submit"
          onClick={handleSubmit(({ lenderPartnerPayer }) => {
            updateCollabPayer({
              variables: {
                input: {
                  organizationId: organization.id,
                  defaultPartnerPayer: lenderPartnerPayer,
                },
              },
            })
              .catch(() => {
                pushNotification({
                  subtype: NOTIFICATION_SUBTYPES.ERROR,
                  message: intl.formatMessage(messages.failure),
                });
              })
              .then(() => {
                pushNotification({
                  type: NOTIFICATION_TYPES.DEFAULT,
                  message: intl.formatMessage(messages.success),
                });
              });
          })}
        >
          <FormattedMessage
            id="69028df4-29a3-4368-9812-29d4dfac15ac"
            defaultMessage="Save Changes"
          />
        </Button>
      }
    >
      <CardSection>
        <RadioGroup
          label={
            <FormattedMessage
              id="d6bf0bdd-527a-43af-9428-566d52ffdbf4"
              defaultMessage="For transactions with Title Agents"
            />
          }
        >
          <RadioLabel
            label={
              <FormattedMessage
                id="d86da152-7e58-4544-8e39-16e561225026"
                defaultMessage="This account will be charged for the transaction fees"
              />
            }
            radio={
              <RadioInput<FormValues["lenderPartnerPayer"]>
                value={PartnerPayerSourcesEnum.LENDER}
                {...register("lenderPartnerPayer")}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="3a58c060-14c8-4d73-a989-1098cb2ab260"
                defaultMessage="Title agent will be prompted for payment"
              />
            }
            radio={
              <RadioInput<FormValues["lenderPartnerPayer"]>
                value={PartnerPayerSourcesEnum.ORGANIZATION}
                {...register("lenderPartnerPayer")}
              />
            }
          />
        </RadioGroup>
      </CardSection>
    </Card>
  );
}

function CollabFeeSetting() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(CollabFeeSettingQuery, {
    variables: {
      organizationId: activeOrganizationId!,
    },
  });
  const organization = data?.organization;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return <CollabFeeSettingInner organization={organization} />;
}

export default CollabFeeSetting;
