import "./status.scss";

import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import StatusCircle from "common/core/status_circle";
import Icon from "common/core/icon";
import { getLastStatus } from "util/history";
import { getBundleChargeState } from "util/document_bundle";
import { isUnsigned } from "util/transaction";

import type {
  RealtorTransactionListingStatus as TransactionNode,
  RealtorTransactionListingStatus_documentBundle_meetings_edges_node as MeetingNode,
} from "./status_fragment.graphql";

type Props = {
  node: TransactionNode;
  onClick: (meeting: MeetingNode | null) => void;
};
type TStatus = {
  expired: boolean;
  isMeetingInProgress: boolean;
  lastStatus: string;
};

function getStatus({
  expired,
  isMeetingInProgress,
  lastStatus,
}: TStatus): ComponentProps<typeof StatusCircle>["type"] {
  if (expired || isMeetingInProgress) {
    return "warn";
  }
  switch (lastStatus) {
    case "DRAFT":
      return "draft";
    case "RECEIVED":
      return "received";
    case "COMPLETED":
      return "success";
    default:
      return "default";
  }
}

function getLabel({ expired, isMeetingInProgress, lastStatus }: TStatus) {
  if (expired) {
    return <FormattedMessage id="d9c05880-a361-4574-b4bd-a6d95e1265a2" defaultMessage="Expired" />;
  } else if (isMeetingInProgress) {
    return (
      <FormattedMessage
        id="7f48d535-2ecf-4cab-af18-9a16ed2351ad"
        defaultMessage="Join Live Meeting"
      />
    );
  }
  switch (lastStatus) {
    case "DRAFT":
      return <FormattedMessage id="388c40d9-c3fd-4be5-9599-b17b7eb99593" defaultMessage="Draft" />;
    case "RECEIVED":
      return (
        <FormattedMessage id="c2b9f843-c0d0-4f4b-9970-b2049bcbe78d" defaultMessage="Received" />
      );
    case "COMPLETED":
      return (
        <FormattedMessage id="1a2d8361-cf92-4578-8da7-6989331fc61f" defaultMessage="Completed" />
      );
    default:
      return <FormattedMessage id="4a113d3d-e316-4d28-b7e6-c818bdf624c3" defaultMessage="View" />;
  }
}

function Status({ node, onClick }: Props) {
  const { documentBundle, isExpired, state } = node;
  const { meetings, charges, processingState, auditTrails } = documentBundle!;
  const isMeetingInProgress = Boolean(meetings.edges.length);
  const inProgressMeeting = isMeetingInProgress ? meetings.edges[0] : null;
  const lastStatus = getLastStatus(auditTrails, state);
  const bundleChargeState = getBundleChargeState(processingState, charges);
  const expired = isExpired && isUnsigned(bundleChargeState);
  return (
    <div className="StatusCell" onClick={() => onClick(inProgressMeeting?.node ?? null)}>
      <StatusCircle
        type={getStatus({ expired, isMeetingInProgress, lastStatus })}
        pulsate={isMeetingInProgress && !expired}
      />
      {getLabel({ expired, isMeetingInProgress, lastStatus })}
      <Icon className="icon" name="caret-right" />
    </div>
  );
}

export default memo(Status);
