import { FormattedMessage } from "react-intl";

export const BLOCKED_STEP = "blocked";
export const WARNING_STEP = "warning";

export const EXPLAINER = "explainer";
export const VIDEO = "video";
export const AUDIO = "audio";
export const CONNECTION = "connection";

const VIDEO_STEP = Object.freeze({
  name: VIDEO,
  label: (
    <FormattedMessage
      id="f3fe4392-0533-42bd-884c-1ff631d73ce6"
      description="label"
      defaultMessage="Camera"
    />
  ),
});

const AUDIO_STEP = Object.freeze({
  name: AUDIO,
  label: (
    <FormattedMessage
      id="65cf027a-3dcb-45d8-bd05-9164b89d8ee9"
      description="label"
      defaultMessage="Audio"
    />
  ),
});

export const NOTARY_STEPS = Object.freeze([VIDEO_STEP, AUDIO_STEP]);
