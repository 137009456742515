import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import Link from "common/core/link";
import { useQuery } from "util/graphql";
import { Feature } from "graphql_globals";

import ParentOrganization, {
  type ParentOrganization_organization_Organization as ParentOrg,
} from "./parent_org_query.graphql";
import { type DocumentBundleForTransactionDetailsSummary_transaction_publicOrganization as PublicOrg } from "./index_fragment.graphql";
import { SummaryDetailWrapper } from ".";

type Props = { transactionOrg: PublicOrg };

const LABELMESSAGES = defineMessages({
  parentOrg: {
    id: "dd42d038-f21b-44c3-87ac-595c6f1a594b",
    defaultMessage: "Parent organization",
  },
  rootOrg: {
    id: "dd42d038-f21b-44c3-87ac-595c6f1a594b",
    defaultMessage: "Root organization",
  },
  transactionOrg: {
    id: "24dd02f8-206e-4716-9651-0e1bc844cc4d",
    defaultMessage: "Transaction organization",
  },
  transactionOrgHasNotaries: {
    id: "24dd02f8-206e-4716-9651-0e1bc844cc4d",
    defaultMessage: "Organization uses IHN",
  },
});

function OrganizationDisplay({
  organization,
  label,
}: {
  organization: PublicOrg | ParentOrg;
  label: string;
}) {
  const { id, name, organizationType, parentOrganizationId } = organization;
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return (
    <>
      {parentOrganizationId && <OrganizationParent organizationId={parentOrganizationId} />}
      <SummaryDetailWrapper
        term={label}
        definition={
          <div
            data-automation-id="notarization-details-summary-transaction-org"
            className={
              isTxnDetailsRedesign
                ? undefined
                : "NotarizationDetails-summary-transactionOrganization"
            }
          >
            <FormattedMessage
              id="512840a9-589d-4e9b-ab69-517a88b85b88"
              defaultMessage="{name} ({organizationType}) <link>{id}</link>"
              values={{
                name,
                organizationType,
                id,
                link: (text) => <Link to={`/companies/${id}/details`}>{text}</Link>,
              }}
            />
          </div>
        }
      />
    </>
  );
}

function OrganizationParent({ organizationId }: { organizationId: string }) {
  const intl = useIntl();

  const { data, loading } = useQuery(ParentOrganization, {
    variables: { organizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (data?.organization?.__typename !== "Organization") {
    return null;
  }

  const { organization } = data;
  const label = intl.formatMessage(
    organization.parentOrganizationId ? LABELMESSAGES.parentOrg : LABELMESSAGES.rootOrg,
  );

  return <OrganizationDisplay organization={organization} label={label} />;
}

export default function TransactionOrganizationDisplay({ transactionOrg }: Props) {
  const intl = useIntl();
  const orgNotaries = transactionOrg.featureList.includes(Feature.ORGANIZATION_NOTARIES);
  const label = intl.formatMessage(LABELMESSAGES.transactionOrg);

  return (
    <>
      <OrganizationDisplay organization={transactionOrg} label={label} />
      <SummaryDetailWrapper
        term={intl.formatMessage(LABELMESSAGES.transactionOrgHasNotaries)}
        definition={
          orgNotaries ? (
            <FormattedMessage id="533743e7-4405-41e9-bfcd-02e01cbbd332" defaultMessage="Yes" />
          ) : (
            <FormattedMessage id="e3bf1887-b5f8-4ee3-a70f-7ed38993b68e" defaultMessage="No" />
          )
        }
      />
    </>
  );
}
