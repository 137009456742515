import { getPlaidInstance } from "util/plaid";
import Env from "config/environment";

type PlaidInfo = {
  accountId: string;
  accountName: string;
  token: string;
};

const { plaidApiKey, plaidEnvironment } = Env;

/** Open Plaid modal and handle response */
export async function verifyAch(onChange: (info: PlaidInfo) => void, onExit?: () => void) {
  const instance = await getPlaidInstance();
  const linkHandler = instance.create({
    apiVersion: "v2",
    product: ["auth"],
    env: plaidEnvironment,
    selectAccount: true,
    clientName: "Notarize ACH Payment",
    key: plaidApiKey,
    onSuccess: (token, metadata) => {
      onChange({
        accountId: metadata.account_id,
        accountName: metadata.institution.name,
        token,
      });
    },
    onExit,
  });
  return linkHandler.open();
}
