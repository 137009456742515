import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { browserTimeZone } from "util/date";
import AlertMessage from "common/core/alert_message";
import { useQuery } from "util/graphql";
import { format } from "common/core/format/date";
import { OrganizationTransactionDetailedStatus } from "graphql_globals";
import { userFullName } from "util/user";

import SignerFailedKBAQuery, {
  type SignerFailedKBA_documentBundle_DocumentBundle as DocumentBundle,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

type KBAMetadata = {
  id: string;
  fullName: string;
  retryDatetime: Date;
};
type HookInputTypes = {
  documentBundleId: string;
  transactionStatus: OrganizationTransactionDetailedStatus | null;
  kbaRequired: boolean;
};
type Props = {
  kbaFailureList: KBAMetadata[];
  timezone: string | null;
};

// this hook won't work well with dynamically changing bundle id
// the purpose of this hook is to gate the firing of the kba data query
// unless the transaction is in appropriate status and transaction type
export const useSignerFailedKba = ({
  documentBundleId,
  transactionStatus,
  kbaRequired,
}: HookInputTypes): [KBAMetadata[], boolean] => {
  const isRelevantTransactionStatus =
    transactionStatus &&
    ![
      OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.COMPLETE,
      OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
    ].includes(transactionStatus);
  const isDisabled = !kbaRequired || !isRelevantTransactionStatus;
  const { data, loading } = useQuery(SignerFailedKBAQuery, {
    skip: isDisabled,
    variables: { documentBundleId },
  });

  const documentBundle =
    !isDisabled && data ? (data.documentBundle as null | DocumentBundle) : null;
  const signers = documentBundle?.signers || [];

  const signersWhoFailedKba = useMemo(() => {
    return signers.flatMap((signer) => {
      if (signer?.kbaStatus?.kbaBlocked) {
        return [
          {
            id: signer.id,
            fullName: userFullName(signer),
            retryDatetime: new Date(signer.kbaStatus.kbaBlockEnds!),
          },
        ];
      }
      return [];
    });
  }, [signers]);

  return [signersWhoFailedKba, loading];
};

function SignerFailedKbaAlert({ kbaFailureList, timezone }: Props) {
  return (
    <ul data-automation-id="signer-failed-kba-banner">
      {kbaFailureList.map((signer) => (
        <li key={signer.id}>
          <AlertMessage className={Styles.kbaAlertBanner} kind="warning">
            <FormattedMessage
              id="ed242d5f-40cb-4292-b9d7-611790003f50"
              defaultMessage="{signerName} has failed their allotted KBA for one 24-hour period. The signer can try again at {retryDatetime}"
              values={{
                signerName: signer.fullName,
                retryDatetime: format({
                  value: signer.retryDatetime,
                  asTimeZone: timezone || browserTimeZone(),
                  formatStyle: "MM/dd/yyyy h:mm a zzz",
                }),
              }}
            />
          </AlertMessage>
        </li>
      ))}
    </ul>
  );
}

export default SignerFailedKbaAlert;
