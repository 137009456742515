import type { ReactElement, ReactNode } from "react";

import { Heading } from "common/core/typography";

import Styles from "./user_control.module.scss";

type UserTechCheckControlProps = {
  label: string;
  icon?: string;
  children?: ReactElement;
  techCheckDropdown?: ReactNode;
};

export default function UserTechCheckControl({
  icon,
  children,
  label,
  techCheckDropdown,
}: UserTechCheckControlProps) {
  return (
    <div className={Styles.techCheckContainer}>
      <Heading level="h2" textStyle="allCapsLabel" textColor="subtle">
        {label}
      </Heading>
      <div className={Styles.techCheckContent}>
        {techCheckDropdown && (
          <div className={Styles.techCheckControl}>
            {icon && <img alt="" src={icon} />}
            {techCheckDropdown}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
