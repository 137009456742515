import "./index.scss";

import { PureComponent } from "react";
import classnames from "classnames";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import { ChargeStatuses } from "graphql_globals";
import { format } from "common/core/format/date";
import Button from "common/core/button";
import FormattedPrice from "common/core/format/formatted_price";
import RefundForm from "common/transactions/refund_form";
import { LINE_ITEM } from "constants/payment";

import PriceBreakdownItem from "./item";

const messages = defineMessages({
  refund: {
    id: "071550dd-d783-424e-b305-55d313fd9c53",
    defaultMessage: "Refund",
  },
  notpaid: {
    id: "42a73183-609e-463c-8d64-8506294a22ef",
    defaultMessage: "Can't refund when charge not paid",
  },
  cancel: {
    id: "9833a2a5-5269-48f0-8c71-7be3f5739b6a",
    defaultMessage: "Cancel",
  },
});

class PriceBreakdown extends PureComponent {
  state = {
    refundFormOpen: false,
  };

  toggleRefundForm = () => {
    this.setState((prevState) => ({ refundFormOpen: !prevState.refundFormOpen }));
  };

  handleRefund = (params) => {
    return this.props.onRefund(params).then(() => {
      this.setState(() => ({ refundFormOpen: false }));
    });
  };

  renderRefundButtonOrMessage() {
    const { charge, canRefund, intl } = this.props;
    const { cost } = charge;
    if (!cost || !canRefund) {
      return null;
    }
    return charge.state === ChargeStatuses.PAID ? (
      <Button
        onClick={this.toggleRefundForm}
        className="PriceBreakdown--refund-link"
        variant="tertiary"
        buttonColor="danger"
      >
        {intl.formatMessage(this.state.refundFormOpen ? messages.cancel : messages.refund)}
      </Button>
    ) : (
      <Button className="PriceBreakdown--refund-link" variant="tertiary" buttonColor="danger">
        {intl.formatMessage(messages.notpaid)}
      </Button>
    );
  }

  render() {
    const { charge, className, intl } = this.props;
    const { chargeItems, chargeRefunds, chargeDiscounts } = charge;

    // We want to display the first seal or identity confirmation before any other charge items.
    // `partition` will return an array with the first items at index 0 and the additional items at index 1.
    // There should never be both a FIRST_SEAL && FIRST_IDENTITY_CONFIRMATION, but we'll be safe and handle
    // that case anyway. (ESIGNED_BUNDLE should always be the only item in a break down)
    const [firstChargeItems, otherChargeItems] = (chargeItems || []).reduce(
      (accum, charge) => {
        const { item } = charge;
        const index =
          item === LINE_ITEM.FIRST_SEAL ||
          item === LINE_ITEM.FIRST_IDENTITY_CONFIRMATION ||
          item === LINE_ITEM.ESIGNED_BUNDLE
            ? 0
            : 1;
        accum[index].push(charge);
        return accum;
      },
      [[], []],
    );

    const refundIntl = intl.formatMessage(messages.refund);
    return (
      <div className={classnames("PriceBreakdown", className)}>
        <div className="PriceBreakdown--items">
          {firstChargeItems.map(({ item, unitPrice, quantity }) => (
            <PriceBreakdownItem key={item} item={item} amount={unitPrice} quantity={quantity} />
          ))}
          {otherChargeItems.map(({ item, unitPrice, quantity }) => (
            <PriceBreakdownItem key={item} item={item} amount={unitPrice} quantity={quantity} />
          ))}
          {chargeDiscounts.map((chargeDiscount) => (
            <PriceBreakdownItem
              key={chargeDiscount.discount.name}
              item={chargeDiscount.discount.displayName || chargeDiscount.discount.name}
              amount={chargeDiscount.amount}
              quantity={chargeDiscount.quantity}
              onlyShowTotal
              isDiscount
            />
          ))}
          {chargeRefunds.map(({ createdAt, amount }) => (
            <PriceBreakdownItem
              key={createdAt}
              item="refund"
              label={`${refundIntl} ${format({ value: createdAt, formatStyle: "P" })}`}
              amount={amount}
              quantity={1}
              onlyShowTotal
              isRefund
            />
          ))}
        </div>
        <p className="PriceBreakdown--total">
          {this.renderRefundButtonOrMessage()}
          <FormattedMessage
            id="ac58ba0b-7fd3-4f56-88fe-630e4d9d1da4"
            defaultMessage="Total {totalPrice}"
            values={{ totalPrice: <FormattedPrice cents={charge.cost} /> }}
          />
        </p>
        {this.state.refundFormOpen && <RefundForm charge={charge} onRefund={this.handleRefund} />}
      </div>
    );
  }
}

/** @type {(props: { charge: { id: string }; canRefund?: boolean; onRefund?: (params: { reclaim_payout?: boolean; amount?: number; reason: string; charge: { id: string } }) => Promise<unknown> }) => ReactElement} */
export default injectIntl(PriceBreakdown);
