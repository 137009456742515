import "./index.scss";

import { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { FormattedDate } from "common/core/format/date";
import Icon from "common/core/icon";
import { userFullName } from "util/user";

import type { RealtorTransactionListing_node as OrganizationTransaction } from "./index_fragment.graphql";
import Status from "./status";

type Props = {
  transactions: { node: OrganizationTransaction }[];
  onClick: (tId: string, mId: string | null) => void;
};
type RowProps = {
  node: Props["transactions"][number]["node"];
  onClick: Props["onClick"];
  highlighted?: boolean;
};

const NOT_SET = (
  <FormattedMessage
    id="03fd4866-cb2f-474d-a6a7-949ec1221c7e"
    description="Expiry date not set text for realtor my closings"
    defaultMessage="Not Set"
  />
);

function Row({ node, onClick, highlighted }: RowProps) {
  const { id, expiry, titleAgency, lenderName, customerSigners } = node;
  const handleStatusClick = useCallback(
    (meeting?: null | { id: string }) => onClick(id, meeting?.id ?? null),
    [id, onClick],
  );
  return (
    <tr>
      <td>
        {highlighted && (
          <FormattedMessage
            id="755d3542-8d29-43e6-8fbc-bcac4d79f74e"
            defaultMessage="Just completed"
          >
            {(txt) => <Icon name="tick" title={txt[0] as string} />}
          </FormattedMessage>
        )}
        {userFullName(customerSigners[0])}
      </td>
      <td>{titleAgency?.name}</td>
      <td>{lenderName}</td>
      <td>{expiry ? <FormattedDate value={expiry} /> : NOT_SET}</td>
      <td>
        <Status node={node} onClick={handleStatusClick} />
      </td>
    </tr>
  );
}

const MemoizedRow = memo(Row);

function ClosingListing({ transactions, onClick }: Props) {
  const [searchParams] = useSearchParams();
  const highlightTransactionId = searchParams.get("highlightTransactionId");
  if (!transactions.length) {
    return (
      <div className="ClosingListing ClosingListing__empty">
        <FormattedMessage
          id="de18b89d-a8fc-44a4-8734-7ecdd823149c"
          defaultMessage="No closings to show"
        />
      </div>
    );
  }
  return (
    <div className="ClosingListing">
      <table>
        <thead>
          <tr>
            <FormattedMessage
              id="11296c29-2ec2-418d-8621-dd6272817a0f"
              defaultMessage="Primary Signer"
              tagName="th"
            />
            <FormattedMessage
              id="951c1f43-4ce5-4777-bce3-b6e639751bcd"
              defaultMessage="Title Agency"
              tagName="th"
            />
            <FormattedMessage
              id="7e10408d-3100-42bc-a771-f0105a83684e"
              defaultMessage="Lender"
              tagName="th"
            />
            <FormattedMessage
              id="c38ecb50-7413-4dd9-8145-71dd08c59262"
              defaultMessage="Closing Date"
              tagName="th"
            />
            <FormattedMessage
              id="99970352-8576-404e-938e-f6331a87fb5d"
              defaultMessage="Status"
              tagName="th"
            />
          </tr>
        </thead>
        <tbody>
          {transactions.map(({ node }) => (
            <MemoizedRow
              key={node.id}
              node={node}
              onClick={onClick}
              highlighted={node.id === highlightTransactionId}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default memo(ClosingListing);
