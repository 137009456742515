import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";

// This is a special file that has cache control turned off.
const DOWNLOAD_FILE_URL = "https://static.notarize.com/bandwidth-test.blob.zip";

// One thousand bytes in a kilo, one thousand kilo in a mega.
const MEGABYTE_DIVISOR = 1000 * 1000;

/**
 * @name testDownloadSpeed
 * @description Use this function to test the download speed of the brower.
 * @param {number} time Length of the test in seconds. This is a *maximum* time.
 * @returns {Promise<number>} This promise resolves with a speed in megabytes per second
 *   or rejects with a failure.
 */
export function testDownloadSpeed(time) {
  return new Promise((resolve, reject) => makeMeteredRequest(time, resolve, reject));
}

function currentTimeSeconds() {
  return new window.Date().getTime() / 1000;
}

function elapsedTimeInSeconds(startTime) {
  const currentTime = currentTimeSeconds();
  return currentTime - startTime;
}

function calculateSpeed(startTime, downloadedBytes) {
  const speedInBytesPerSecond = downloadedBytes / elapsedTimeInSeconds(startTime);
  return speedInBytesPerSecond / MEGABYTE_DIVISOR;
}

function makeMeteredRequest(totalTimeSeconds, resolve, reject) {
  // Make sure that even if we never call onprogress, we still *always* kill the connection test.
  const maxTimeoutID = setTimeout(timeoutTest, totalTimeSeconds * 1000);
  const startTime = currentTimeSeconds();
  const xhr = new XMLHttpRequest();
  let speedSoFar = 0;

  function cleanup() {
    xhr.onreadystatechange = null; // So abort doesn't reject our promise...
    xhr.abort();
    clearTimeout(maxTimeoutID);
  }
  function timeoutTest() {
    cleanup();
    if (speedSoFar === 0) {
      segmentTrack("A Network Test Reported No Bytes Downloaded");
    }
    resolve(speedSoFar);
  }

  xhr.responseType = "blob";
  xhr.onreadystatechange = function () {
    const { readyState, status, response } = xhr;
    const isDone = readyState === 4;
    if (isDone && status >= 200 && status < 300) {
      resolve(calculateSpeed(startTime, response.size));
      cleanup();
    } else if (isDone) {
      reject("The request failed.");
      cleanup();
    }
  };
  xhr.onprogress = function ({ loaded }) {
    if (loaded) {
      speedSoFar = calculateSpeed(startTime, loaded);
    }
  };
  // Every time we do the test, we don't want the browser to cache things so we bust the cache
  // with a GET var of the start time of the test.
  xhr.open("GET", `${DOWNLOAD_FILE_URL}?cacheBust=${startTime}`, true);
  xhr.send();
}

export async function canMakeTestCall({ token, room }) {
  const TwilioVideo = await import("twilio-video");
  let connection;
  try {
    connection = await TwilioVideo.connect(token, {
      name: room,
      audio: false,
      video: false,
      insights: false,
    });
  } catch (error) {
    segmentTrack(EVENT.TEST_VIDEO_CALL_FAILED, { error });
    return false;
  }
  connection.disconnect();
  segmentTrack(EVENT.TEST_VIDEO_CALL_SUCCEEDED);
  return true;
}
