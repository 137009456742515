import { useState } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";

import { TextInput } from "common/core/form/text";
import { SECTIONS } from "constants/details/summary";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useForm } from "common/core/form";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import { Label } from "common/core/form/layout";
import { useId } from "util/html";

import Styles from "./index.module.scss";
import UpdateOrganizationTransactionMutation, {
  type UpdateOrganizationTransaction,
  type UpdateOrganizationTransactionVariables,
} from "./update_organization_transaction_mutation.graphql";
import { SummaryDetailWrapper } from "..";

type FormValues = {
  number: string | null;
};

const MESSAGES = defineMessages({
  numberInput: {
    id: "92a03072-8e2e-4b38-8e75-9dfd3abc013b",
    defaultMessage: "Number input",
  },
  error: {
    id: "2ff69fc9-3614-4d64-a933-4461aeedd80a",
    defaultMessage: "Number could not be updated, please contact support.",
  },
});

type FileOrLoanNumber =
  | { fileNumber: string | null; loanNumber?: never }
  | { loanNumber: string | null; fileNumber?: never };

export function NumberDetails({
  id,
  fileNumber,
  loanNumber,
  type,
  canEdit,
}: {
  id: string;
  type: "fileNumber" | "loanNumber";
  canEdit: boolean;
} & FileOrLoanNumber) {
  const intl = useIntl();
  const number = fileNumber || loanNumber;
  const numberId = useId();
  const [formState, setFormState] = useState<{ editing?: boolean; loading?: boolean } | null>(null);
  const updateOrganizationTransaction = useMutation<
    UpdateOrganizationTransaction,
    UpdateOrganizationTransactionVariables
  >(UpdateOrganizationTransactionMutation);

  const form = useForm<FormValues>({
    defaultValues: {
      number,
    },
  });

  const shortenedType = type === "fileNumber" ? "file" : "loan";

  const updateNumber = async (values: FormValues) => {
    try {
      setFormState({ loading: true });
      await updateOrganizationTransaction({
        variables: {
          input: {
            id,
            transaction: { [type]: values.number },
          },
        },
      });
      setFormState(null);
    } catch (e) {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(MESSAGES.error),
      });
      captureException(e);
    }
  };

  return (
    <SummaryDetailWrapper
      term={intl.formatMessage(SECTIONS[type])}
      definition={
        formState?.editing ? (
          <form className={Styles.editContent} onSubmit={form.handleSubmit(updateNumber)}>
            <Label className={Styles.label} htmlFor={numberId}>
              <FormattedMessage
                id="96d59d09-e6df-49e4-b8e4-874f86283c2d"
                defaultMessage="{type} number"
                values={{ type: shortenedType }}
              />
            </Label>
            <TextInput
              id={numberId}
              aria-invalid={false}
              aria-label={intl.formatMessage(MESSAGES.numberInput)}
              data-automation-id={`${shortenedType}-number-input`}
              {...form.register("number")}
            />
            <div className={Styles.editButtons}>
              <Button
                buttonColor="action"
                variant="secondary"
                automationId={`cancel-${shortenedType}-number`}
                onClick={() => {
                  setFormState(null);
                }}
              >
                <FormattedMessage
                  id="afe52181-3a35-407c-b833-96863f7be174"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                type="submit"
                buttonColor="action"
                variant="primary"
                automationId={`save-${shortenedType}-number`}
                isLoading={formState.loading}
              >
                <FormattedMessage
                  id="928957c0-060f-4a7c-8b9d-ac4ab8c4e65f"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </form>
        ) : (
          <div className={Styles.content}>
            {number && <span data-automation-id={`${shortenedType}-number`}>{number}</span>}
            {canEdit && (
              <LinkStyledButton
                underlined={false}
                onClick={() => {
                  setFormState({ editing: true });
                }}
                data-automation-id={`edit-${shortenedType}-number`}
              >
                <FormattedMessage
                  id="7eb27ad3-fd25-4dcf-bbd7-96c62e3eca29"
                  defaultMessage="Edit {type} number"
                  values={{ type: shortenedType }}
                />
              </LinkStyledButton>
            )}
          </div>
        )
      }
    />
  );
}
