import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import Webcam from "util/react_user_media";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import { isSafari } from "util/support";
import microphone from "assets/images/tech/mic.svg";
import error from "assets/images/tech/mic-error.svg";
import mediaBlocked from "assets/images/media-blocked.png";
import { Heading, Paragraph } from "common/core/typography";

import { BLOCKED_PERM, REQUESTED_PERM } from "../permission";
import Styles from "../permission.module.scss";

function refreshPage() {
  window.location.reload();
}

class AudioPermissionGetter extends PureComponent {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const {
      permissionStatus,
      missingEquipment,
      onAccessClick,
      onAccessGranted,
      onAccessBlocked,
      onSpeakerMissing,
    } = this.props;

    const permRequested = permissionStatus === REQUESTED_PERM;
    let buttonContent = (
      <FormattedMessage
        id="f28b5614-6084-4f3a-8035-5362862b6e5b"
        defaultMessage="Allow Microphone Access"
      />
    );
    if (permRequested) {
      buttonContent = <SmallLoadingIndicator colour="white" />;
    } else if (permissionStatus === BLOCKED_PERM) {
      buttonContent = (
        <FormattedMessage id="efe5a90e-fea2-41c2-804d-a26a03ca126c" defaultMessage="Retry" />
      );
    }
    const AllowAccessButton = (
      <Button
        className="TechCheck--button"
        buttonColor="action"
        variant="primary"
        onClick={onAccessClick}
        disabled={permRequested}
        automationId="allow-micro-access-button"
      >
        {buttonContent}
      </Button>
    );
    return (
      <div className={Styles.permissionContainer}>
        <img
          className="TechCheck--step-icon"
          src={permissionStatus === BLOCKED_PERM ? error : microphone}
          alt=""
        />
        <Heading level="h2" textStyle="headingFour" textAlign="center">
          Audio
        </Heading>
        <Paragraph textColor="subtle" textAlign="center">
          <FormattedMessage
            id="152a61d0-9fff-4c8c-8ee6-bb79d54cbe31"
            defaultMessage={
              "A built-in or connected microphone, and connected speakers " +
              "or headphones are required for audio in the notary call. When " +
              "prompted please allow access."
            }
          />
        </Paragraph>
        {permissionStatus === BLOCKED_PERM && !isSafari() && !missingEquipment && (
          <>
            <Paragraph textColor="subtle" textAlign="center">
              <FormattedMessage
                id="59af6b2c-e176-4c27-94c6-946e457793ab"
                defaultMessage="To enable microphone access, select the ( {img} ) icon in the right of the address bar. Click this icon and choose to allow this page access to your computer's microphone. After allowing access, you can retry below."
                values={{ img: <img src={mediaBlocked} alt="media blocked" /> }}
              />
            </Paragraph>
            {AllowAccessButton}
          </>
        )}
        {permissionStatus === BLOCKED_PERM && isSafari() && !missingEquipment && (
          <div>
            <p className="TechCheck--info">
              <FormattedMessage
                id="fe4668fb-3880-47ad-8291-312a399d8775"
                defaultMessage="We have not been granted access to your Microphone. Please try again or change your Safari Preferences for this site."
              />
            </p>
            <Button
              className="TechCheck--button"
              buttonColor="action"
              variant="primary"
              onClick={refreshPage}
              disabled={permissionStatus === REQUESTED_PERM}
              automationId="allow-micro-access-button-try-again"
            >
              <FormattedMessage id="c550888c-2b8e-45a1-8dd6-8087f2d52156" defaultMessage="Retry" />
            </Button>
          </div>
        )}
        {missingEquipment && (
          <div>
            <p className="TechCheck--info">
              <FormattedMessage
                id="8b567b62-4327-46a8-9ccc-b659cee254f9"
                defaultMessage="We do not detect a microphone available. Please check your microphone and try again. If the problem persists, try switching browsers or using a mobile device."
              />
            </p>
            {AllowAccessButton}
          </div>
        )}
        {permissionStatus !== BLOCKED_PERM && AllowAccessButton}
        {permissionStatus === REQUESTED_PERM && (
          <Webcam
            audio
            video={false}
            width={0}
            height={0}
            onSuccess={onAccessGranted}
            onFailure={(error) =>
              error.name === "NotFoundError" ? onSpeakerMissing(error) : onAccessBlocked(error)
            }
          />
        )}
      </div>
    );
  }
}

AudioPermissionGetter.propTypes = {
  permissionStatus: PropTypes.string.isRequired,
  missingEquipment: PropTypes.bool,
  onAccessClick: PropTypes.func.isRequired,
  onAccessGranted: PropTypes.func.isRequired,
  onAccessBlocked: PropTypes.func.isRequired,
  onSpeakerMissing: PropTypes.func.isRequired,
};

AudioPermissionGetter.defaultProps = {
  missingEquipment: null,
};

export default AudioPermissionGetter;
