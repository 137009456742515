import { useEffect, useState, type ChangeEvent } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import MfaIcon from "assets/images/mfa_icon.svg";
import MfaSuccessIcon from "assets/images/mfa_success.svg";
import { Heading, Paragraph } from "common/core/typography";
import LoadingIndicator from "common/core/loading_indicator";
import { useMutation } from "util/graphql";
import Button from "common/core/button";
import Icon from "common/core/icon";
import MaskedInput from "common/form/inputs/masked_input";
import AlertMessage from "common/core/alert_message";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { hardNavigateTo } from "util/navigation";

import Styles from "./index.module.scss";
import GenerateTOTPSecretMutation from "./generate_totp_secret_mutation.graphql";
import SubmitTOTPSetupMutation from "./submit_totp_setup_mutation.graphql";

type TotpSetupState =
  | {
      kind: "generate";
      status: null | string;
    }
  | {
      kind: "confirm";
      code: string;
      status: null | "loading" | "success" | "error";
      errorMsg: null | string;
    };

const MESSAGES = defineMessages({
  success: {
    id: "8696e4fa-9e4a-494e-98f5-ab8081cc60b3",
    defaultMessage: "You successfully set up multi-factor authentication.",
  },
  invalidCodeError: {
    id: "0392f385-19ee-4e17-a3c3-5871d46c24f7",
    defaultMessage: "The code you entered was invalid. Please try again.",
  },
  mutationError: {
    id: "d6bd8a3a-72f5-4c7f-a877-96a8654a53fb",
    defaultMessage:
      "We are unable to set up your authenticator app at this time. Please try again or reach out to customer support.",
  },
  label: {
    id: "f7171c20-934e-4773-9a65-5693cf1bb393",
    defaultMessage: "QR Code",
  },
  downloadAppStep: {
    id: "148479a9-1859-41d3-b570-36b0d5b43877",
    defaultMessage: "Download an authenticator app",
  },
  scanQrCodeStep: {
    id: "42b78993-dc0b-4829-8cd0-35047fa0c741",
    defaultMessage: "Scan the QR code below in the authenticator app",
  },
  enterCodeStep: {
    id: "f7cc9156-92b9-473a-a6ba-7198ccdcd36e",
    defaultMessage: "Enter the 6-digit verification code",
  },
});

function TotpMfaSetup({ refetch }: { refetch?: () => void }) {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const redirectUponCompletion = searchParams.get("redirectUponCompletion");
  const [state, setState] = useState<TotpSetupState>({
    kind: "generate",
    status: null,
  });
  const [qrSvg, setQrSvg] = useState<string>("");
  const generateTotpSecretMutateFn = useMutation(GenerateTOTPSecretMutation);
  const submitTotpSetupMutateFn = useMutation(SubmitTOTPSetupMutation);

  const fetchQrCode = async () => {
    setState({ kind: "generate", status: "loading" });
    setQrSvg("");
    const { data } = await generateTotpSecretMutateFn();
    setState({
      kind: "generate",
      status: "success",
    });
    setQrSvg(btoa(data!.generateTotpSecret!.qrStream));
  };

  useEffect(() => {
    fetchQrCode();
  }, []);

  const submitTotp = async () => {
    if (state.kind === "confirm") {
      setState((state) => ({ ...state, status: "loading" }));
      try {
        await submitTotpSetupMutateFn({ variables: { input: { secret: state.code } } });
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
        setState((state) => ({ ...state, status: "success" }));
      } catch (e) {
        if ((e as Error).message.includes("invalid_code")) {
          setState((state) => ({
            ...state,
            status: "error",
            errorMsg: intl.formatMessage(MESSAGES.invalidCodeError),
          }));
        } else {
          setState((state) => ({
            ...state,
            status: "error",
            errorMsg: intl.formatMessage(MESSAGES.mutationError),
          }));
        }
      }
    }
  };

  if (state.kind === "confirm" && state.status === "success") {
    return (
      <div className={Styles.container}>
        <img className={Styles.image} src={MfaSuccessIcon} aria-hidden="true" alt="" />
        <Heading level="h1" textStyle="headingFour" textAlign="center" className={Styles.heading}>
          <FormattedMessage
            id="4b9d0ce8-a1ec-40ff-9537-298ab05ac97e"
            defaultMessage="Your account is protected!"
          />
        </Heading>
        <Paragraph size="large" className={Styles.info}>
          <FormattedMessage
            id="69cb07f9-5d9c-4378-84aa-d858a7cc0da8"
            defaultMessage="You successfully secured your account with multi-factor authentication. You'll use this to login to Proof."
          />
        </Paragraph>
        <div className={Styles.confirmContainer}>
          <Icon className={Styles.checkmark} name="tick" />
          <div className={Styles.confirmLabel}>
            <FormattedMessage
              id="491f27bd-650c-49e2-bfbd-4070dee5770c"
              defaultMessage="Authenticator App"
            />
          </div>
        </div>
        <div className={Styles.divider}></div>
        <div className={Styles.buttonContainer}>
          <Button
            className={Styles.continueButton}
            buttonColor="action"
            buttonSize="large"
            variant="primary"
            onClick={refetch ? refetch : () => hardNavigateTo(redirectUponCompletion as string)}
          >
            <FormattedMessage id="9a780239-c234-4a23-a641-0082c0718b7e" defaultMessage="Continue" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <img className={Styles.image} src={MfaIcon} aria-hidden="true" alt="" />
      <Heading level="h1" textStyle="headingFour" textAlign="center" className={Styles.heading}>
        <FormattedMessage
          id="b6bd61af-8e70-4878-8c2a-472c796c96cd"
          defaultMessage="Secure your account"
        />
      </Heading>
      {state.kind === "generate" && (
        <>
          <SetupStep number={1} label={intl.formatMessage(MESSAGES.downloadAppStep)} />
          <Paragraph size="large" className={Styles.info}>
            <FormattedMessage
              id="148479a9-1859-41d3-b570-36b0d5b43877"
              defaultMessage="Enable multi-factor authentication (MFA) by downloading an authenticator app (like Google Authenticator) on your mobile device."
            />
          </Paragraph>
          <SetupStep number={2} label={intl.formatMessage(MESSAGES.scanQrCodeStep)} />
          <Paragraph size="large" className={Styles.info}>
            <FormattedMessage
              id="acaba2d1-220f-40f1-9f6c-dc25501802a4"
              defaultMessage="Then, scan the QR code using the authenticator app on your mobile device and enter the 6-digit verification code."
            />
          </Paragraph>
          <div className={Styles.qrCodeContainer}>
            {state.status === "loading" ? (
              <LoadingIndicator positionRelative />
            ) : (
              <img
                className={Styles.qrCode}
                src={`data:image/svg+xml;base64,${qrSvg}`}
                alt="qr code"
              />
            )}
          </div>
          <div className={Styles.divider}></div>
          <div className={Styles.buttonContainer}>
            <Button
              buttonColor="action"
              variant="primary"
              buttonSize="large"
              className={Styles.continueButton}
              onClick={() => {
                setState({
                  kind: "confirm",
                  code: "",
                  status: null,
                  errorMsg: null,
                });
              }}
            >
              <FormattedMessage
                id="57def776-be60-4bdb-888e-01263afdf9ab"
                defaultMessage="Continue"
              ></FormattedMessage>
            </Button>
          </div>
        </>
      )}

      {state.kind === "confirm" && (
        <>
          <SetupStep number={3} label={intl.formatMessage(MESSAGES.enterCodeStep)} />
          <Paragraph size="large" className={Styles.info}>
            <FormattedMessage
              id="7a989b4d-f5d9-446f-bd57-82ab1c33df21"
              defaultMessage="Enter the 6-digit verification code from the authenticator app for Proof to complete the process."
            />
          </Paragraph>
          {state.status === "error" && (
            <AlertMessage>
              <FormattedMessage
                id="45667a38-f220-432f-b91c-cc258829a0c6"
                defaultMessage="{errorMsg}"
                values={{ errorMsg: state.errorMsg }}
              />
            </AlertMessage>
          )}
          <div className={Styles.enterCode}>
            <div className={Styles.message}>
              <FormattedMessage
                id="379a24db-a671-47ad-b81e-2af92fe0e079"
                defaultMessage="Enter the 6-digit verification code"
              />
            </div>
            <MaskedInput
              aria-describedby="totp-info"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const code = e.target.value;
                setState((state) => ({ ...state, code }));
              }}
              value={state.code}
              maskType="number"
              maxlength={6}
              aria-label={intl.formatMessage(MESSAGES.label)}
              placeholder="******"
              autoFocus
              useStyledInput
            />
          </div>
          <div className={Styles.divider}></div>
          <div className={Styles.footer}>
            <Button
              className={Styles.goBackButton}
              buttonColor="action"
              buttonSize="large"
              variant="secondary"
              onClick={() => {
                setState((state) => ({ ...state, kind: "generate" }));
              }}
            >
              <FormattedMessage
                id="6f967ddc-2795-4ae3-9f95-a6891767de77"
                defaultMessage="Go Back"
              />
            </Button>
            <Button
              className={Styles.continueButton}
              buttonColor="action"
              buttonSize="large"
              variant="primary"
              type="submit"
              onClick={submitTotp}
              disabled={state.code.length !== 6}
            >
              <FormattedMessage
                id="f69dbc55-e2af-4047-adb6-c07bab89e07b"
                defaultMessage="Continue"
              />
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function SetupStep({ number, label }: { number: number; label: string }) {
  return (
    <div className={Styles.step}>
      <div aria-hidden="true" className={Styles.stepNumber}>
        <span>{number}</span>
      </div>
      <span className={Styles.stepLabel}>{label}</span>
    </div>
  );
}

export default TotpMfaSetup;
