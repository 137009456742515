import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, type ReactElement } from "react";
import { defineMessages, useIntl } from "react-intl";
import { differenceInWeeks } from "date-fns";

import { Feature } from "graphql_globals";
import { useQuery, useMutation } from "util/graphql";
import { useOnboardingSteps, OnboardingWrapper } from "common/onboarding";
import Svg from "common/core/svg";
import ProofBusinessPortalLight from "assets/images/logos/portal-logos/proof-business-light.svg";
import ProofBusinessPortalLightMobile from "assets/images/logos/portal-logos/business-light.svg";
import { useActiveOrganization } from "common/account/active_organization";
import { TIER_NAME } from "constants/tier";
import LoadingIndicator from "common/core/loading_indicator";
import { BUSINESS_ODN_STEPS, STEPS } from "common/onboarding/steps";
import { InviteCoworkerStep } from "common/onboarding/steps/invite_coworker";
import { PaymentStep } from "common/onboarding/steps/payment";
import TagUserMutation from "common/onboarding_slides/tag_user_mutation.graphql";
import { useViewer } from "util/viewer_wrapper";
import { type BusinessRouterViewer_viewer as Viewer } from "business_portal/router/viewer_query.graphql";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { useMobileScreenClass } from "common/core/responsive";
import { usePermissions } from "common/core/current_user_role";

import { SLIDESHOW_TAG_V2 } from "./slideshow";
import BusinessAccountOnboardingWrapperQuery, {
  type BusinessAccountOnboardingWrapper_viewer_user as WrapperUser,
} from "./wrapper.graphql";
import BusinessAccountOnboardingQuery from "./index.graphql";
import { Industry } from "./steps/industry";

const ONBOARDABLE_TIERS = Object.freeze(
  new Set([
    TIER_NAME.PRO_TRIAL,
    TIER_NAME.PRO,
    TIER_NAME.ELITE,
    TIER_NAME.BUSINESS_PRO_IHN,
    TIER_NAME.BUSINESS_PRO_ODN,
  ]),
);

const MESSAGES = defineMessages({
  portalLogoAlt: {
    id: "990e68bc-3aac-4a93-a464-cc6addce6a5b",
    defaultMessage: "Proof for Business",
  },
});

export function useBusinessOnboardingPermissions() {
  const { hasPermissionFor } = usePermissions();
  return hasPermissionFor("addTeamMember") && hasPermissionFor("editOrganizationDetails");
}

/**
 * @description
 * User can skip onboarding if they had a custom tier set by Proof (as they would have been onboarded already)
 * or over a week after their tier start date.
 * If neither are true, we check if they have the tag that gets added after completing onboarding.
 */
export function userCanSkipOnboarding({
  user,
  canEditOrg,
}: {
  user: WrapperUser;
  canEditOrg: boolean;
}) {
  const shouldCompleteOnboarding =
    !user.tags?.some((tag) => tag?.tag === SLIDESHOW_TAG_V2) &&
    !user.organization?.paymentSpecified &&
    canEditOrg;

  if (
    !ONBOARDABLE_TIERS.has(user.activeTier.name) ||
    differenceInWeeks(new Date(), new Date(user.activeTier.startAt)) > 1 ||
    !shouldCompleteOnboarding
  ) {
    return true;
  }

  return false;
}

export function BusinessAccountOnboardingWrapper({ children }: { children: ReactElement }) {
  const navigate = useNavigate();
  const { data } = useQuery(BusinessAccountOnboardingWrapperQuery, {
    variables: {
      userTagList: [SLIDESHOW_TAG_V2],
    },
  });
  const canEditOrg = useBusinessOnboardingPermissions();
  const user = data?.viewer.user;
  const isOnboarded =
    user &&
    userCanSkipOnboarding({
      user,
      canEditOrg,
    });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!isOnboarded) {
      return navigate("/onboarding");
    }
  }, [data]);

  if (isOnboarded) {
    return children;
  }

  return null;
}

export function BusinessAccountOnboarding() {
  const isMobileScreenSize = useMobileScreenClass();
  const intl = useIntl();
  const [activeOrganizationId] = useActiveOrganization();
  const { viewer: portalViewer } = useViewer<Viewer>();

  const { data, loading } = useQuery(BusinessAccountOnboardingQuery, {
    variables: {
      organizationId: activeOrganizationId!,
    },
  });
  const tagUser = useMutation(TagUserMutation);

  const completeBusinessOnboarding = useCallback(async () => {
    return await tagUser({
      variables: {
        input: {
          userId: portalViewer.user!.id,
          tag: SLIDESHOW_TAG_V2,
        },
        tags: [SLIDESHOW_TAG_V2],
      },
    });
  }, [portalViewer.user]);

  // Steps are the same for both ODN and IHN. Using this naming to keep consistent with Title onboarding steps
  const steps = BUSINESS_ODN_STEPS;
  const { currentStep, stepSvg, onNext } = useOnboardingSteps(steps, completeBusinessOnboarding);

  function renderCurrentStep() {
    if (!data || loading) {
      return <LoadingIndicator />;
    }

    if (data.organization?.__typename !== "Organization") {
      throw new Error(`Expected organization, got ${data.organization?.__typename}.`);
    }

    const { organization, viewer } = data;
    const {
      id,
      name,
      defaultPayer,
      paymentSpecified,
      defaultPaymentSource,
      assignableRoles,
      featureList,
    } = organization;
    const { industryOptions, notaryUsStates, user } = viewer;

    const isIhnEnabled = featureList.includes(Feature.ORGANIZATION_NOTARIES);

    switch (currentStep) {
      case STEPS.INDUSTRY:
        return (
          <Industry
            organizationId={id}
            organizationName={name}
            industryOptions={industryOptions}
            onNext={onNext}
          />
        );
      case STEPS.INVITE:
        return (
          <InviteCoworkerStep
            portal="business"
            organizationId={id}
            roles={assignableRoles}
            notaryUsStates={notaryUsStates}
            isIhnEnabled={isIhnEnabled}
            onNext={onNext}
            onSkip={() => segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_INVITE_SKIPPED)}
          />
        );
      case STEPS.PAYMENT:
        return (
          <PaymentStep
            portal="business"
            organizationId={id}
            onNext={onNext}
            defaultPayerFormData={paymentSpecified ? defaultPayer : null}
            defaultPaymentSourceFormData={defaultPaymentSource}
            userId={user?.id}
          />
        );
      default:
        return null;
    }
  }

  return (
    <OnboardingWrapper
      logo={
        <Svg
          src={isMobileScreenSize ? ProofBusinessPortalLightMobile : ProofBusinessPortalLight}
          alt={intl.formatMessage(MESSAGES.portalLogoAlt)}
        />
      }
      image={stepSvg}
      content={renderCurrentStep()}
      currentStep={currentStep}
      steps={steps}
    />
  );
}
