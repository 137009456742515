import { FormattedMessage } from "react-intl";

import type { NotaryActivationStatus } from "graphql_globals";
import { FormattedActivationStatus } from "common/notary/panel/membership_details/activation_status";
import Icon from "common/core/icon";
import Link from "common/core/link";
import { Heading } from "common/core/typography";
import { Badge } from "common/core/badge";

import Styles from "./index.module.scss";

type PanelMembership = {
  activationStatus: NotaryActivationStatus;
  brandColor: string | null;
  id: string;
  name: string;
};
type Props = { membership: PanelMembership };

export function PanelMembershipItem({ membership }: Props) {
  return (
    <div className={Styles.membershipItem}>
      <div className={Styles.info}>
        <Heading level="h3" textStyle="headingFive">
          <FormattedMessage
            id="d32d32c5-7d71-407e-9051-0d0c63cbc637"
            defaultMessage="{icon} {name}"
            values={{
              icon: (
                <Icon
                  name="panel-icon-no-background"
                  className={Styles.icon}
                  style={{ backgroundColor: membership.brandColor || undefined }}
                />
              ),
              name: membership.name,
            }}
          />
        </Heading>
        <Badge kind="infoBlue">
          <FormattedActivationStatus status={membership.activationStatus} />
        </Badge>
      </div>
      <div className={Styles.links}>
        <Link to={`/notary-panel-memberships/${membership.id}`}>
          <FormattedMessage
            id="b6fe0fb8-42ec-415a-890d-b5565d0b082c"
            defaultMessage="View details"
          />
        </Link>
      </div>
    </div>
  );
}
