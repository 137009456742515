import { FormattedMessage } from "react-intl";

import {
  OrganizationTransactionStatus,
  OrganizationTransactionDetailedStatus,
} from "graphql_globals";

enum ColorClasses {
  GREY = "grey",
  BLUE = "blue",
  RED = "red",
  GREEN = "green",
  ORANGE = "orange",
  PURPLE = "purple",
}

export const OrganizationTransactionStatusColors = {
  [OrganizationTransactionStatus.INACTIVE]: ColorClasses.GREY,
  [OrganizationTransactionStatus.ACTIVE]: ColorClasses.BLUE,
  [OrganizationTransactionStatus.ACTION_NEEDED]: ColorClasses.ORANGE,
  [OrganizationTransactionStatus.LIVE]: ColorClasses.PURPLE,
  [OrganizationTransactionStatus.SUCCESS]: ColorClasses.GREEN,
  [OrganizationTransactionStatus.FAILURE]: ColorClasses.RED,
};

export function OrganizationTransactionDetailedStatusMessage({
  detailedStatus,
}: {
  detailedStatus: OrganizationTransactionDetailedStatus;
}) {
  switch (detailedStatus) {
    case OrganizationTransactionDetailedStatus.DRAFT:
      return <FormattedMessage id="a50d893d-4aab-47cd-8002-519cc04157e7" defaultMessage="Draft" />;
    case OrganizationTransactionDetailedStatus.RECALLED:
      return (
        <FormattedMessage id="05cae3cb-79d1-48f6-9f88-3c8b891b3304" defaultMessage="Recalled" />
      );
    case OrganizationTransactionDetailedStatus.ORDER_PLACED:
      return (
        <FormattedMessage id="886af28f-3c1f-4736-9b85-9546a5c2ecd6" defaultMessage="Order placed" />
      );
    case OrganizationTransactionDetailedStatus.SENT_TO_TITLE_AGENT:
      return (
        <FormattedMessage
          id="c7a9ed9b-9458-45e6-81a9-316ccce81921"
          defaultMessage="Sent to title agent"
        />
      );
    case OrganizationTransactionDetailedStatus.SENT_TO_SIGNER:
      return <FormattedMessage id="c5521359-2ea7-4b89-91a9-ffa0ce105fee" defaultMessage="Sent" />;
    case OrganizationTransactionDetailedStatus.ACTIVE:
      return <FormattedMessage id="c129fd23-2d58-477d-aac5-80c5a2273599" defaultMessage="Active" />;
    case OrganizationTransactionDetailedStatus.VIEWED:
      return <FormattedMessage id="2e6eeb1d-243a-41bb-bbbe-12b9d76547a7" defaultMessage="Viewed" />;
    case OrganizationTransactionDetailedStatus.STARTED:
      return (
        <FormattedMessage id="2d710f72-d26d-4080-811a-0934bce68dcf" defaultMessage="Started" />
      );
    case OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE:
      return (
        <FormattedMessage
          id="6d23dff3-cbed-4523-8b5c-026c4ca5abef"
          defaultMessage="Partially complete"
        />
      );
    case OrganizationTransactionDetailedStatus.ATTEMPTED:
      return (
        <FormattedMessage id="0b750102-9c35-4d1d-ae13-d03bcbba126f" defaultMessage="Attempted" />
      );
    case OrganizationTransactionDetailedStatus.AWAITING_PAYMENT:
      return (
        <FormattedMessage
          id="fc9c1f42-9a88-4a01-a39b-9e6263a22c05"
          defaultMessage="Awaiting payment"
        />
      );
    case OrganizationTransactionDetailedStatus.OPEN_ORDER:
      return (
        <FormattedMessage id="ec313af7-11d7-40a0-b45d-36e7f75a45b2" defaultMessage="Open order" />
      );
    case OrganizationTransactionDetailedStatus.REVIEW_NOW:
    case OrganizationTransactionDetailedStatus.ADD_DOCUMENT:
      return (
        <FormattedMessage
          id="f8681ff1-49d5-4ebb-9d11-542401882604"
          defaultMessage="Add documents"
        />
      );
    case OrganizationTransactionDetailedStatus.SIGN_NOW:
      return (
        <FormattedMessage id="062472d9-634b-4f68-8dcf-557b93be1e8d" defaultMessage="Sign now" />
      );
    case OrganizationTransactionDetailedStatus.PAYMENT_REQUIRED:
      return (
        <FormattedMessage
          id="87088339-f9fc-461d-a936-5d12c8bc77a2"
          defaultMessage="Payment required"
        />
      );
    case OrganizationTransactionDetailedStatus.MEETING_IN_PROGRESS:
      return (
        <FormattedMessage
          id="6a1e24ea-bf3f-42d7-8692-32a8cad02858"
          defaultMessage="Meeting in progress"
        />
      );
    case OrganizationTransactionDetailedStatus.COMPLETE:
      return (
        <FormattedMessage id="4b9a5eaa-19cc-41df-aaac-52c920388f1d" defaultMessage="Complete" />
      );
    case OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS:
      return (
        <FormattedMessage
          id="9ada7678-5348-417a-b8c3-9a1337cd09f0"
          defaultMessage="Complete with rejections"
        />
      );
    case OrganizationTransactionDetailedStatus.EXPIRED:
      return (
        <FormattedMessage id="f773f211-d57f-4199-be8e-7e845a13aa65" defaultMessage="Expired" />
      );
    case OrganizationTransactionDetailedStatus.ESIGN_COMPLETE:
      return (
        <FormattedMessage
          id="79f622b5-9436-4311-a8c8-9345aa2576f8"
          defaultMessage="eSign complete"
        />
      );
    case OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE:
      return (
        <FormattedMessage
          id="bcf1f98b-13cd-45e9-9eca-22524c428589"
          defaultMessage="Wet sign complete"
        />
      );
    case OrganizationTransactionDetailedStatus.ON_HOLD:
      return (
        <FormattedMessage id="223a04d3-6336-4389-b264-2b7357d5a8b1" defaultMessage="On hold" />
      );
    case OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN:
      return (
        <FormattedMessage
          id="79c7b0f0-cdf1-4e8c-96f6-55dc4e388e2b"
          defaultMessage="Converted to wet sign"
        />
      );
    case OrganizationTransactionDetailedStatus.READY_FOR_REVIEW:
      return (
        <FormattedMessage
          id="eb95789b-f75f-4406-9c95-d9bfde69308b"
          defaultMessage="Ready for review"
        />
      );
    default:
      return null;
  }
}
