import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import { useFeatureFlag } from "common/feature_gating";
import AlertMessage from "common/core/alert_message";
import Link from "common/core/link";
import { useViewer } from "util/viewer_wrapper";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";

import { isNotaryIHN } from "./capacity";
import type { NotaryMfaDisabled as Viewer } from "./notary_mfa_disabled.graphql";
import Styles from "./notary_mfa_disabled.module.scss";

const MFA_SETTINGS_PATH = "/settings/profile/overview?scrollToSecurityForm=true";

// signerApp prop can be removed with display-mfa-banner. Adding/removing the prop is
// simpler than adding the flag to the signer app when the flag is already ON in all envs
function NotaryMfaDisabled({ children, signerApp }: { children: ReactNode; signerApp?: boolean }) {
  const navigate = useNavigate();
  const { viewer } = useViewer<Viewer>();
  const { notaryProfile, authenticationRequirements } = viewer.user!;
  const mfaEnabled = Boolean(authenticationRequirements.length);
  const displayMfaBanner = Boolean(
    (useFeatureFlag("display-mfa-banner") || signerApp) &&
      notaryProfile &&
      !isNotaryIHN(notaryProfile),
  );

  function handleLinkClick() {
    if (signerApp) {
      navigate(MFA_SETTINGS_PATH);
    } else {
      redirectToSubdomain(AppSubdomains.customer, MFA_SETTINGS_PATH);
    }
  }

  return (
    <div
      className={classnames(Styles.wrapper, displayMfaBanner && !mfaEnabled && Styles.multipleRows)}
    >
      {displayMfaBanner && !mfaEnabled && (
        <AlertMessage kind="info" centerText>
          <FormattedMessage
            id="08499e3b-12f5-489b-8a66-e0aa501d2ac6"
            tagName="div"
            defaultMessage="Multi-factor authentication is required for your account. Update your <settingsLink>settings</settingsLink>."
            values={{
              settingsLink: (text) => (
                <Link automationId="mfa-required-banner-link" onClick={handleLinkClick}>
                  {text}
                </Link>
              ),
            }}
          />
        </AlertMessage>
      )}
      {children}
    </div>
  );
}

export default NotaryMfaDisabled;
