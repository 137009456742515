import "./deprecated_index.scss";

import { useState, type ReactNode, type ReactElement } from "react";
import { useIntl, FormattedMessage, type IntlShape } from "react-intl";

import {
  Feature,
  OrganizationTransactionCreationSource,
  OrganizationTransactionDetailedStatus,
  UserAction,
  OrganizationTypeEnum,
  MortgageTransactionType,
  CompletionRequirement,
  OrganizationTransactionStatus,
  OrganizationTransactionVariant,
  RequiredFeature,
} from "graphql_globals";
import Link from "common/core/link";
import { usePermissions } from "common/core/current_user_role";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { TransactionStatus } from "common/dashboard/columns";
import {
  formattedTransactionType,
  isHybridTransactionType,
} from "common/mortgage/transactions/utils";
import TransactionMessageSubject from "common/signer/items/transaction_message_subject";
import TransactionMessage from "common/signer/items/transaction_message";
import TransactionSignature from "common/signer/items/transaction_signature";
import AdminTransactionDetailsDateTimes from "common/details/summary/datetimes";
import PriceBreakdownWithRefund from "common/transactions/price_breakdown/with_refund";
import PaymentStatus from "common/transactions/payment_status";
import PayerType from "common/transactions/payer_type";
import TransactionMarkFree from "common/transactions/mark_free";
import TransactionHacks from "common/hacks/transaction";
import { ActivationLink } from "common/transactions/activation_link";
import modalScrollContent from "common/core/modal_scroll_content";
import SignerFailedKbaAlert, {
  useSignerFailedKba,
} from "common/transactions/signer_failed_kba_alert";
import { formattedTransactionVariant } from "common/core/format/formatted_transaction_variant";
import { SECTIONS } from "constants/details/summary";
import APP from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { SEGMENT_EVENTS } from "constants/analytics";
import Env from "config/environment";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { isNotaryDocumentDownloadProhibited } from "common/notary/capacity";
import { useMutation } from "util/graphql";
import { userFullName } from "util/user";
import { segmentTrack } from "util/segment";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME, PS1583_ATTESTATION } from "constants/feature_gates";
import { ESIGN_ONLY_TRANSACTIONS } from "constants/transaction";
import { TransactionDetailsAlertMessages } from "common/transactions/details/header/alerts";

import SigningTimeSchedule from "../signing_time_schedule";
import AddWetSignDocumentToOrganizationTransactionMutation from "../add_wet_sign_document_to_organization_transaction_mutation.graphql";
import TitleAgencyName from "../title_agency_name";
import AdminTools from "../admin_tools";
import type { DeprecatedDocumentBundleForTransactionDetailsSummary as DocumentBundle } from "./deprecated_index_fragment.graphql";
import AdminUpdateOrganizationTransactionMutation from "../admin_update_organization_transaction_mutation.graphql";
import TransactionOrganizationDisplay from "../transaction_organization_display";
import { NumberDetails } from "../number_details";

type Props = {
  bundle: DocumentBundle;
  entry?: string;
  viewer: {
    user: null | {
      id: string;
      notaryProfile?: Parameters<typeof isNotaryDocumentDownloadProhibited>[0];
      organization?: null | {
        organizationType: OrganizationTypeEnum;
        canShowPaymentInTransactionForInvoicing: boolean;
      };
    };
  };
  refetch: () => Promise<unknown>;
};

const { hasHacksEnabled } = Env;
const CREATION_SOURCE_LABELS: Readonly<
  Partial<Record<OrganizationTransactionCreationSource, ReactElement>>
> = Object.freeze({
  [OrganizationTransactionCreationSource.NOTARIZE]: (
    <FormattedMessage
      id="5e432784-ce07-42ee-a96e-9a820165e0ec"
      defaultMessage="Created from Notarize"
    />
  ),
  [OrganizationTransactionCreationSource.NOTARIZE_API]: (
    <FormattedMessage
      id="3d3276f6-faf1-45f3-9aa5-5e9d714a167d"
      defaultMessage="Created from Notarize (API)"
    />
  ),
  [OrganizationTransactionCreationSource.EASYLINK]: (
    <FormattedMessage
      id="937d81ca-3ecd-44da-b425-0445d3fd5d90"
      defaultMessage="Created from Notarize (EasyLink)"
    />
  ),
  [OrganizationTransactionCreationSource.ENCOMPASS]: (
    <FormattedMessage
      id="5eea2c27-9e13-4e46-bba2-4c08d9a93af7"
      defaultMessage="Created from Encompass"
    />
  ),
  [OrganizationTransactionCreationSource.DROPBOX]: (
    <FormattedMessage
      id="5d5a0a62-b28e-40b5-84ba-353e774982fe"
      defaultMessage="Created from Dropbox"
    />
  ),
  [OrganizationTransactionCreationSource.RESWARE]: (
    <FormattedMessage
      id="55ca1c7f-0ff2-4bcd-901b-6a9d2fcddd4b"
      defaultMessage="Created from Resware"
    />
  ),
});

/** Return the creation source in a human readable format */
function getCreationSource(creationSource: OrganizationTransactionCreationSource) {
  switch (creationSource) {
    case OrganizationTransactionCreationSource.DOTLOOP:
      return "Dotloop";
    case OrganizationTransactionCreationSource.DROPBOX:
      return "Dropbox";
    case OrganizationTransactionCreationSource.ENCOMPASS:
      return "Encompass";
    case OrganizationTransactionCreationSource.RESWARE:
      return "ResWare";
    case OrganizationTransactionCreationSource.SOFTPRO:
      return "SoftPro";
    default:
      return "Notarize's API";
  }
}
function renderWetSignAlertBanner(
  transaction: NonNullable<DocumentBundle["transaction"]>,
  viewerOrganization: { organizationType: OrganizationTypeEnum } | undefined | null,
) {
  switch (viewerOrganization?.organizationType) {
    case OrganizationTypeEnum.LENDER:
      return (
        <AlertMessage kind="warning">
          <FormattedMessage
            id="140f551e-2b33-4f71-a2ac-006006fbdc06"
            defaultMessage="Return to {creationSource} if you'd like to make updates to this transaction."
            values={{ creationSource: getCreationSource(transaction.creationSource) }}
          />
        </AlertMessage>
      );
    case OrganizationTypeEnum.TITLE_AGENCY:
      return (
        <AlertMessage kind="warning">
          <FormattedMessage
            id="140f551e-2b33-4f71-a2ac-006006fbdc06"
            defaultMessage="{lender} at {organization} is making changes to this closing package. We'll send you an updated invitation as soon as the documents are ready again."
            values={{
              lender: userFullName(transaction.employee, transaction.employee.email || undefined),
              organization: transaction.publicOrganization.name,
            }}
          />
        </AlertMessage>
      );
  }
  return null;
}

function renderTransactionTypeWithVariant(
  transactionType: string | null,
  transactionVariant: OrganizationTransactionVariant,
  isMortgage: boolean,
  requiresNsaMeeting: boolean,
  intl: IntlShape,
  ps1583AttestationEnabled: boolean,
) {
  return isMortgage || ESIGN_ONLY_TRANSACTIONS.includes(transactionType ?? "") ? (
    formattedTransactionType({ requiresNsaMeeting, type: transactionType, intl })
  ) : transactionType ? (
    <>
      {formattedTransactionVariant(transactionVariant, intl)} ({transactionType})
    </>
  ) : (
    formattedTransactionVariant(transactionVariant, intl, ps1583AttestationEnabled)
  );
}
/** @deprecated */
/** should only be used for Admin & Biz portals */
function Summary(props: Props) {
  const { bundle, entry: portal, viewer, refetch } = props;
  const viewerOrganization = viewer.user?.organization;
  const notaryProfile = viewer.user?.notaryProfile;
  const { retrievalId, retrievalPin, transaction, completionRequirements, referralInfo, meetings } =
    bundle;

  const execRefetch = () => refetch();
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const [kbaData] = useSignerFailedKba({
    documentBundleId: bundle.id,
    transactionStatus: transaction.detailedStatus,
    kbaRequired: Boolean(
      completionRequirements?.includes(CompletionRequirement.NOTARIZATION) ||
        bundle.participants?.some((participant) => participant?.proofRequirement?.kba),
    ),
  });
  const addWetSignDocumentToOrganizationTransactionMutateFn = useMutation(
    AddWetSignDocumentToOrganizationTransactionMutation,
  );
  const [loading, setLoading] = useState(false);
  const handleConvertToWetSign = () => {
    setLoading(true);
    segmentTrack(SEGMENT_EVENTS.WET_SIGN_COMPLETE_BUTTON_CLICKED);
    addWetSignDocumentToOrganizationTransactionMutateFn({
      variables: { input: { organizationTransactionId: transaction.id } },
    })
      .then(execRefetch)
      .finally(() => {
        setLoading(false);
      });
  };

  const adminUpdateOrganizationTransactionMutateFn = useMutation(
    AdminUpdateOrganizationTransactionMutation,
  );

  const updateOrganizationTransaction = async (params: {
    activationTime: string | undefined | null;
    activationTimezone: string | undefined | null;
    expiry: string | undefined | null;
    expiryTimezone: string | undefined | null;
  }) => {
    await adminUpdateOrganizationTransactionMutateFn({
      variables: {
        input: {
          id: transaction.id,
          activationTime: params.activationTime,
          activationTimezone: params.activationTimezone,
          expiry: params.expiry,
          expiryTimezone: params.expiryTimezone,
        },
      },
    });
    return execRefetch();
  };

  function detailSection(key: keyof typeof SECTIONS, content: ReactNode) {
    return (
      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow title={intl.formatMessage(SECTIONS[key])}>
          <div className={`NotarizationDetails-summary-${key}`}>{content}</div>
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>
    );
  }

  function ihnNotaryAssignmentSection(transaction: NonNullable<DocumentBundle["transaction"]>) {
    const isNotarization =
      transaction.transactionVariant === OrganizationTransactionVariant.NOTARIZATION;

    if (!isNotarization || (!transaction.closer && !transaction.notarizeCloserOverride)) {
      return null;
    }
    return detailSection(
      "notaryAssignment",
      // Hard-assigned to a specific notary
      transaction.closer ? (
        <FormattedMessage
          id="809864e1-b300-4ad1-9166-04b45aaf3535"
          defaultMessage="IHN - {notaryName}"
          values={{ notaryName: userFullName(transaction.closer) }}
        />
      ) : (
        // Explicitly assigned to the Notarize network
        <FormattedMessage
          id="5e5a09ab-f8a1-4e7e-87ce-46a97c06c0f9"
          defaultMessage="Notarize network"
        />
      ),
    );
  }

  const canConvertHybridTransaction =
    transaction.transactionType &&
    isHybridTransactionType(transaction.transactionType) &&
    (transaction.detailedStatus === OrganizationTransactionDetailedStatus.ESIGN_COMPLETE ||
      transaction.detailedStatus === OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN);
  const canConvertWetSignTransaction =
    transaction.transactionType === MortgageTransactionType.wet_sign &&
    transaction.detailedStatus === OrganizationTransactionDetailedStatus.SENT_TO_TITLE_AGENT;

  const canConvertStatusToWetSignComplete =
    viewerOrganization?.organizationType === OrganizationTypeEnum.LENDER &&
    (canConvertWetSignTransaction || canConvertHybridTransaction);

  const statusSection = (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title={intl.formatMessage(SECTIONS.status)}>
        <div className="NotarizationDetails-summary-status">
          <TransactionStatus
            status={transaction.status}
            detailedStatus={transaction.detailedStatus}
          />
        </div>
      </DeprecatedDetailGridRow>
      {/* TODO: BIZ-6014 - move Mark Wet Sign complete to actions, move banner to header */}
      {canConvertStatusToWetSignComplete && (
        <AlertMessage kind="info">
          <FormattedMessage
            id="e6467d88-5e01-442b-bb17-a975ffb33c2b"
            defaultMessage="Was the closing completed offline? <action>Mark as Wet Sign Complete.</action>"
            values={{
              action: (text) => (
                <Button
                  variant="tertiary"
                  buttonColor="action"
                  onClick={handleConvertToWetSign}
                  isLoading={loading}
                  data-automation-id="mark-wet-sign-complete"
                >
                  &nbsp; {text}
                </Button>
              ),
            }}
          />
        </AlertMessage>
      )}
    </DeprecatedDetailGridSection>
  );

  const isWetSignTransaction = transaction.transactionType === MortgageTransactionType.wet_sign;

  const showAdvancedDetails = Boolean(
    hasPermissionFor("advancedTransactionDetails") ||
      transaction.publicOrganization.featureList.includes(Feature.ADVANCED_TRANSACTION_CREATION),
  );
  const showCustomizedEmail =
    Boolean(transaction.publicOrganization.featureList.includes(Feature.CUSTOM_EMAILS)) &&
    isWetSignTransaction;

  let lenderOrTitleRow = null;
  if (portal === APP.TITLE_AGENCY) {
    lenderOrTitleRow = (
      <DeprecatedDetailGridRow title="Lender">{transaction.lenderName}</DeprecatedDetailGridRow>
    );
  } else if (portal === APP.LENDER) {
    lenderOrTitleRow = (
      <DeprecatedDetailGridRow title="Title Agency">
        {transaction.titleAgency?.name}
      </DeprecatedDetailGridRow>
    );
  }

  const isMortgageTransaction = transaction.isMortgage;

  const showReadOnlyBlurb =
    !hasPermissionFor("seeNotarizationSummaryReadOnly") &&
    isMortgageTransaction &&
    transaction.userAction !== UserAction.EDIT &&
    transaction.detailedStatus === OrganizationTransactionDetailedStatus.DRAFT;

  const showSignerFailedKbaAlert = kbaData.length > 0;

  let recordingLocationText = null;
  if (transaction.recordingLocation) {
    recordingLocationText = transaction.recordingLocation.name;
  } else if (transaction.jurisdictionEligibilityConsent) {
    recordingLocationText = (
      <FormattedMessage id="26963ea4-05ad-4309-a5ac-39975d6f8d08" defaultMessage="Bypassed" />
    );
  }
  const isReschedulerEnabled =
    (!transaction.lenderInitiated || transaction.isCollaborative) &&
    !showReadOnlyBlurb &&
    // at the moment, lenders do NOT want this functionality. they would rather recall and/or recreate the transaction
    // because the docs have to change anyway
    viewerOrganization?.organizationType === OrganizationTypeEnum.TITLE_AGENCY &&
    transaction.status !== OrganizationTransactionStatus.SUCCESS;

  const meetingIdDisplay = () => {
    return (
      <div>
        {hasPermissionFor("meetingDetails") && (
          <span>
            <FormattedMessage
              id="1e902959-3e9c-4ad5-ab60-a1624e63acf2"
              defaultMessage="{meetingCount, plural, one{# meeting has} other{# meetings have}} occurred"
              values={{ meetingCount: meetings.edges.length }}
            />{" "}
          </span>
        )}
        {meetings.edges.map((edge, index) => {
          const meetingId = edge.node.id;
          return (
            <span key={meetingId}>
              {index ? <span>, </span> : ""}
              {hasPermissionFor("meetingDetails") ? (
                <Link underlined={false} to={`/analytics/page/1/${meetingId}/notary`}>
                  {meetingId}
                </Link>
              ) : (
                <span>{meetingId}</span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const requiredFeature = (feature: RequiredFeature) =>
    bundle.requiredFeatures?.includes(feature) ? (
      <FormattedMessage id="011ccd7a-92e6-4c78-aed8-6650bfece12c" defaultMessage="Yes" />
    ) : (
      <FormattedMessage id="ba2f68a5-2c69-435c-9d8d-78a9a7fe697f" defaultMessage="No" />
    );

  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const ps1583AttestationEnabled = useFeatureFlag(PS1583_ATTESTATION);
  const organizationName = transaction.publicOrganization.name;
  const brandName = brandNameEnabled
    ? transaction.publicOrganization.organizationBrand.name
    : organizationName;

  return (
    <>
      <TransactionDetailsAlertMessages transaction={transaction} />
      <div className="NotarizationDetails-summary">
        <DeprecatedDetailGrid>
          {showSignerFailedKbaAlert && (
            <SignerFailedKbaAlert
              kbaFailureList={kbaData}
              timezone={transaction.expiryTimezone || null}
            />
          )}
          {showReadOnlyBlurb && (
            <div className="NotarizationDetails-summary--blurb">
              <div className="NotarizationDetails-summary--blurb--icon">
                <Icon name="question" />
              </div>
              <div className="NotarizationDetails-summary--blurb--inner">
                <FormattedMessage
                  id="70c7e7a3-87f7-4c93-a512-82d494691595"
                  defaultMessage="This draft is read-only because {orgName} is still working on finalizing this closing package. You will receive a notification email when they are ready for the title documents."
                  values={{ orgName: brandName }}
                />
              </div>
            </div>
          )}
          {!hasPermissionFor("summaryTransactionScheduleInfo") && (
            <SigningTimeSchedule
              transactionId={transaction.id}
              isReschedulerEnabled={isReschedulerEnabled}
            />
          )}
          {/* TODO: BIZ-6016, move to header area */}
          {isWetSignTransaction &&
            transaction.detailedStatus === OrganizationTransactionDetailedStatus.RECALLED &&
            renderWetSignAlertBanner(transaction, viewerOrganization)}
          {detailSection("transactionName", transaction.name)}
          {transaction.address &&
            detailSection("propertyAddress", formattedPropertyAddress(transaction.address))}
          {showAdvancedDetails &&
            detailSection(
              "transactionType",
              renderTransactionTypeWithVariant(
                transaction.transactionType,
                transaction.transactionVariant,
                transaction.isMortgage,
                transaction.requiresNsaMeeting,
                intl,
                ps1583AttestationEnabled,
              ),
            )}
          {lenderOrTitleRow}
          {detailSection("transactionId", transaction.id)}
          {detailSection("meetings", meetingIdDisplay())}
          {CURRENT_PORTAL === APP.ADMIN && (
            <>
              {detailSection("credibleWitness", requiredFeature(RequiredFeature.CREDIBLE_WITNESS))}
              {detailSection("transactionWitness", requiredFeature(RequiredFeature.WITNESS))}
              <TransactionOrganizationDisplay transactionOrg={transaction.publicOrganization} />
            </>
          )}
          {transaction.verifyLink ? (
            detailSection(
              "accessPortal",
              <Link href={transaction.verifyLink}>
                <FormattedMessage
                  id="d6369516-b9dc-408c-bea1-5023119686a3"
                  defaultMessage="View documents"
                />
              </Link>,
            )
          ) : (
            <>
              {retrievalId && detailSection("accessId", retrievalId)}
              {retrievalPin &&
                hasPermissionFor("downloadTransactionDocuments") &&
                !isNotaryDocumentDownloadProhibited(notaryProfile, transaction) &&
                detailSection("accessPin", retrievalPin)}
            </>
          )}
          {referralInfo?.campaignName && detailSection("campaignName", referralInfo.campaignName)}
          {referralInfo?.campaignId && detailSection("campaignId", referralInfo.campaignId)}
          {referralInfo?.code && detailSection("code", referralInfo.code)}
          {statusSection}
          {hasPermissionFor("summaryCreationSource") &&
            detailSection(
              "creationSource",
              CREATION_SOURCE_LABELS[transaction.creationSource] || transaction.creationSource,
            )}
          {ihnNotaryAssignmentSection(transaction)}
          {(isMortgageTransaction || transaction.fileNumber) && (
            <NumberDetails
              type="fileNumber"
              id={transaction.id}
              fileNumber={transaction.fileNumber}
              canEdit={hasPermissionFor("editOrganizationTransactions")}
            />
          )}
          {(isMortgageTransaction || transaction.loanNumber) && (
            <NumberDetails
              type="loanNumber"
              id={transaction.id}
              loanNumber={transaction.loanNumber}
              canEdit={hasPermissionFor("editOrganizationTransactions")}
            />
          )}
          {isMortgageTransaction &&
            hasPermissionFor("summaryLenderSection") &&
            detailSection("lenderName", transaction.lenderName)}
          {isMortgageTransaction && hasPermissionFor("summaryTitleAgencySection") && (
            <TitleAgencyName
              onUpdate={execRefetch}
              transactionId={transaction.id}
              name={transaction.titleAgency?.name}
              titleAgentId={transaction.titleAgency?.id}
            />
          )}
          {isMortgageTransaction &&
            hasPermissionFor("summaryUnderWriter") &&
            detailSection("titleUnderwriterName", transaction.titleUnderwriter?.name)}
          {isMortgageTransaction && detailSection("recordingLocation", recordingLocationText)}
          {showAdvancedDetails && detailSection("externalId", transaction.externalId)}
          {showCustomizedEmail && <TransactionMessageSubject transaction={transaction} />}
          {showCustomizedEmail && <TransactionMessage transaction={transaction} />}
          {showCustomizedEmail && <TransactionSignature transaction={transaction} />}
          {isMortgageTransaction && hasPermissionFor("transactionDetailsDateTimes") && (
            <AdminTransactionDetailsDateTimes
              transaction={transaction}
              onSubmitActivationForm={(values) => {
                return updateOrganizationTransaction({
                  activationTime: values?.activation,
                  activationTimezone: values?.activationTimezone,
                  expiry: transaction.expiry,
                  expiryTimezone: transaction.expiryTimezone,
                });
              }}
              onSubmitExpiryForm={(values) => {
                return updateOrganizationTransaction({
                  activationTime: transaction.activationTime,
                  activationTimezone: transaction.activationTimezone,
                  expiry: values?.expiry,
                  expiryTimezone: values?.expiryTimezone,
                });
              }}
            />
          )}
          <PaymentStatus
            bundle={bundle}
            canShowPaymentInTransactionForInvoicing={Boolean(
              viewerOrganization?.canShowPaymentInTransactionForInvoicing,
            )}
            renderPriceBreakdown={(charge) => (
              <PriceBreakdownWithRefund refetch={execRefetch} charge={charge} />
            )}
            adminCapabilities={hasPermissionFor("paymentStatus")}
            onUpdate={execRefetch}
          />
          {hasPermissionFor("summaryPayerInfo") && (
            <>
              {!bundle.charges?.length && (
                <DeprecatedDetailGridSection>
                  <DeprecatedDetailGridRow
                    title={
                      <FormattedMessage
                        id="adedff8d-3a16-437e-8e37-e3dbdbaa4a81"
                        defaultMessage="Payer"
                      />
                    }
                  >
                    <PayerType
                      // PayerType only handles updating transaction.payer
                      // if there are charges then the PayerType field will be rendered in the charge details
                      canUpdate={Boolean(transaction)}
                      bundle={bundle}
                      payerType={bundle.payer}
                      onUpdate={execRefetch}
                    />
                  </DeprecatedDetailGridRow>
                </DeprecatedDetailGridSection>
              )}
              {hasPermissionFor("markBundleFree") && (
                <TransactionMarkFree onUpdate={execRefetch} bundle={bundle} />
              )}
              <ActivationLink activationLink={transaction.activationLink} />
              <AdminTools organizationTransactionId={transaction.id} documentBundleId={bundle.id} />
            </>
          )}
          {hasHacksEnabled && <TransactionHacks documentBundleId={bundle.id} />}
        </DeprecatedDetailGrid>
      </div>
    </>
  );
}

export const DeprecatedSummary = modalScrollContent(Summary);
