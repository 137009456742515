import { type UpdateNotaryProfileSetting_updateNotaryProfileSetting_notaryProfile as NotaryProfile } from "common/tech_check/video/update_notary_profile_setting_mutation.graphql";

import VideoConfiguration from "./config";
import VideoPermissionGetter from "./permission";
import { GRANTED_PERM } from "../permission";

type VideoSetupProps = {
  notaryProfile: NotaryProfile | null;
  role: string;
  permissionStatus: string;
  onAccessClick: () => void;
  onAccessGranted: () => void;
  onAccessBlocked: () => void;
  onWebcamSelection: () => void;
  selectedWebcamId?: string;
  onMissingEquipment: () => void;
  missingEquipment?: boolean;
  onPermissionMount: () => void;
};

export default function VideoSetup({
  role,
  permissionStatus,
  onAccessClick,
  onAccessGranted,
  onAccessBlocked,
  onWebcamSelection,
  selectedWebcamId,
  onMissingEquipment,
  missingEquipment,
  onPermissionMount,
  notaryProfile,
}: VideoSetupProps) {
  return permissionStatus === GRANTED_PERM ? (
    <VideoConfiguration
      onWebcamSelection={onWebcamSelection}
      onMissingWebcam={onMissingEquipment}
      missingEquipment={missingEquipment}
      selectedWebcamId={selectedWebcamId}
      notaryProfile={notaryProfile}
    />
  ) : (
    <VideoPermissionGetter
      role={role}
      permissionStatus={permissionStatus}
      onAccessClick={onAccessClick}
      onAccessGranted={onAccessGranted}
      onAccessBlocked={onAccessBlocked}
      onMissingWebcam={onMissingEquipment}
      missingEquipment={missingEquipment}
      onMount={onPermissionMount}
    />
  );
}
