// eslint-disable-next-line no-restricted-imports
import type { PossibleTypesMap } from "@apollo/client";

import type Applications from "constants/applications";
import { setCookie, deleteCookie, getCookie } from "util/cookie";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    __PROOF_RUNTIME_CONFIGURATION__: unknown;
    __PROOF_BUILDTIME_CONFIGURATION__: unknown;
  }
}

type Configuration = Readonly<{
  // `environmentName` is specically not named to discourage its use. Do **not** write conditionals with it.
  sentryDSN?: string;
  sentryReplaySampleRate?: number;
  monitoringEndpoint?: string;
  easyLinkApiKey?: string;
  segmentId?: string;
  marketingSegmentId?: string;
  pendoApiKey?: string | null;
  pendoIdPrefix?: string | null;
  personaEnvironment?: "production";
  portalName: (typeof Applications)[keyof typeof Applications];
  apiHost: string;
  socketHost: string;
  testTransactionApiHost: string;
  releaseName: string;
  mobileAppDownloadUrl: string;
  googleSignInAppHost: string;
  hasHacksEnabled: boolean;
  isCustomerFacingTestEnvironment: boolean;
  hasTestingEnvironmentWarning: boolean;
  connectToStripeClientId: string;
  paymentStripeApiKey: string;
  plaidEnvironment: "production" | "sandbox";
  plaidApiKey: string;
  zendeskChatApiKey: string;
  hostName: string;
  isTestMarketingEnvironment: boolean;
  googleMapsApiKey: string;
  launchDarklyApiKey: string;
  launchDarklySignerApiKey: string;
  launchDarklyAdminApiKey: string;
  googlePayMerchantId: string;
  googlePayEnvironment: string;
  graphQLOperationClientName?: string | null;
  graphQLSchema: Readonly<{
    introspectionDataEndpoint: string;
    possibleTypes: PossibleTypesMap;
    denormalizedTypeNames: readonly string[];
    /** Enum name mapped to object of all variants */
    runtimeGraphQLEnums: Readonly<Record<string, Readonly<Record<string, string>>>>;
  }>;
  socureDeviceRiskSdkKey?: string;
}>;

let OVERRIDES_KEY: string;

function clearRuntimeOverrides() {
  deleteCookie(OVERRIDES_KEY);
  window.location.reload();
}

function setRuntimeOverrides(overrides: Partial<Configuration>) {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  setCookie(OVERRIDES_KEY, JSON.stringify(overrides), { expires });
  window.location.reload();
}

function getRuntimeOverrides(): Partial<Configuration> | undefined | null {
  try {
    const stored = getCookie(OVERRIDES_KEY) || "null";
    return JSON.parse(stored) as Partial<Configuration> | null;
  } catch {}
}

function constructRuntimeConfiguration(): Configuration {
  const buildConfiguration = window.__PROOF_BUILDTIME_CONFIGURATION__ as Configuration | undefined;
  if (!buildConfiguration) {
    throw new Error("App not configured at buildtime, cannot run");
  }

  const envName =
    (buildConfiguration as unknown as { environmentName: string | undefined }).environmentName ||
    "unknown";
  OVERRIDES_KEY = `proof-conf-overrides-${envName}`;

  // We allow a Proof engineer to override configuration by using the dev console.
  const runtimeConfiguration = {
    ...buildConfiguration,
    ...getRuntimeOverrides(),
    clearOverrides: clearRuntimeOverrides,
    override: setRuntimeOverrides,
  };
  window.__PROOF_RUNTIME_CONFIGURATION__ = runtimeConfiguration;
  return runtimeConfiguration;
}

export default constructRuntimeConfiguration();
