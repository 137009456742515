import "./index.scss";

import { useCallback, memo, type Dispatch, type SetStateAction } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { encodeSearchParams } from "util/location";
import Icon from "common/core/icon";

import type { RealtorTransactionPagination as TransactionConnection } from "./index_fragment.graphql";

type PageStateSetter = SetStateAction<number>;
type Props = {
  connection: TransactionConnection;
  itemsPerPage: number;
};
type CaretProps = {
  direction: "next" | "prev";
  onClick: Dispatch<PageStateSetter>;
};

const RANGE = 5;
const RANGE_ITER = Object.freeze(Array.from({ length: RANGE }));
const BASE_OFFSET = Math.floor(RANGE / 2);

function usePagination({ pageInfo }: TransactionConnection, itemsPerPage: number) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get("page"));
  const handleSetPageNumber = useCallback(
    (setter: PageStateSetter) => {
      const newPage = typeof setter === "function" ? setter(currentPage) : setter;
      const overrides =
        newPage > currentPage
          ? {
              after: pageInfo.endCursor,
              before: null,
              first: itemsPerPage.toString(),
              last: null,
              page: newPage.toString(),
            }
          : {
              after: null,
              before: pageInfo.startCursor,
              first: null,
              last: itemsPerPage.toString(),
              page: newPage.toString(),
            };
      navigate(`/realtor_closings?${encodeSearchParams(searchParams, overrides)}`);
    },
    [navigate, searchParams, pageInfo, currentPage],
  );
  return { currentPage, handleSetPageNumber };
}

function Caret({ direction, onClick }: CaretProps) {
  const isNext = direction === "next";
  const callback: PageStateSetter = isNext ? (p) => p + 1 : (p) => p - 1;
  const icon = isNext ? "right" : "left";
  return (
    <li className="PaginationRoot--Caret" onClick={() => onClick(callback)}>
      <Icon name={`caret-${icon}`} />
    </li>
  );
}

function Pagination({ connection, itemsPerPage }: Props) {
  const { currentPage, handleSetPageNumber } = usePagination(connection, itemsPerPage);
  const maxPage = Math.ceil(connection.totalCount / itemsPerPage) - 1;
  const offset = Math.max(0, Math.min(maxPage - RANGE + 1, currentPage - BASE_OFFSET));
  return (
    <ul className="PaginationRoot">
      {currentPage !== 0 && <Caret direction="prev" onClick={handleSetPageNumber} />}
      {RANGE_ITER.map((_, index) => {
        const number = index + offset;
        return number > maxPage ? null : (
          <li
            key={number}
            className={`PaginationRoot--Page${number === currentPage ? " current" : ""}`}
          >
            {number + 1}
          </li>
        );
      })}
      {currentPage < maxPage && <Caret direction="next" onClick={handleSetPageNumber} />}
    </ul>
  );
}

export default memo(Pagination);
