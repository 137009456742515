import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState } from "react";

import { useForm, useWatch } from "common/core/form";
import { useMutation } from "util/graphql";
import {
  Footer,
  FormHeading,
  HelperText,
  Error,
  AccountSetupHeading,
} from "common/onboarding/common";
import { AutomaticFormRow } from "common/core/form/layout";
import { Select } from "common/core/form/select";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { TextInput } from "common/core/form/text";
import { STEPS } from "common/onboarding/steps";
import { OptionBar, RadioGroup, RadioInput } from "common/core/form/option";
import { useMobileScreenClass } from "common/core/responsive";

import Styles from "./index.module.scss";
import type {
  BusinessAccountOnboarding_viewer as Viewer,
  BusinessAccountOnboarding_organization_Organization as Organization,
} from "../../index.graphql";
import UpdateOrganizationMutation from "../update_organization_mutation.graphql";

type FormValues = {
  industry: string | null;
  industryOther: string;
};

const OTHER_INDUSTRY = "Other";

const MESSAGES = defineMessages({
  industry: {
    id: "958a411b-7b18-4cc0-b6ca-3ef1fd0e8cdc",
    defaultMessage: "Select an industry",
  },
  industryPlaceholder: {
    id: "0ae1de86-6d48-43dd-ae29-13ea46077ca7",
    defaultMessage: "Select one",
  },
  industryError: {
    id: "b5bb4ab3-d5f2-4c49-892f-bffbbe54f1df",
    defaultMessage: "Please select an industry.",
  },
  industryOtherError: {
    id: "b588185b-9e01-4525-b6f8-e7fff0ecabda",
    defaultMessage: "Please enter an option.",
  },
});

export function Industry({
  organizationId,
  organizationName,
  industryOptions,
  onNext,
}: {
  organizationId: Organization["id"];
  organizationName: Organization["name"];
  industryOptions: Viewer["industryOptions"];
  onNext: () => void;
}) {
  const intl = useIntl();
  const isMobileScreenSize = useMobileScreenClass();
  const [status, setStatus] = useState<"error" | "loading" | null>(null);
  const form = useForm<FormValues>({
    defaultValues: {
      industry: null,
    },
  });

  const industryValue = useWatch({ name: "industry", control: form.control });

  const updateOrganization = useMutation(UpdateOrganizationMutation);

  const save = (formValues: FormValues) => {
    const industry =
      formValues.industry === OTHER_INDUSTRY ? formValues.industryOther : formValues.industry;

    try {
      setStatus("loading");
      updateOrganization({
        variables: {
          input: {
            id: organizationId,
            industry,
          },
        },
      });
      segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_INDUSTRY, {
        industry,
        other_industry: formValues.industry === OTHER_INDUSTRY,
        skipped: false,
      });
      onNext();
    } catch {
      setStatus("error");
    } finally {
      setStatus(null);
    }
  };

  const formHeading = (
    <FormattedMessage
      id="ef7a5046-eb97-4812-af20-793d020fb7db"
      defaultMessage="Which industry best describes {organizationName}?"
      values={{ organizationName }}
    />
  );

  const coreFooterProps = {
    portal: "business" as const,
    step: STEPS.INDUSTRY,
    loading: status === "loading",
    onSkip: () => {
      segmentTrack(SEGMENT_EVENTS.BUSINESS_ONBOARDING_INDUSTRY, {
        skipped: true,
      });
      onNext();
    },
    saveButtonAutomationId: "industry-modal-primary-btn",
    skipButtonAutomationId: "industry-modal-skip-btn",
  };

  const desktopContent = (
    <>
      <FormHeading>{formHeading}</FormHeading>

      <HelperText>
        <FormattedMessage
          id="11be59a6-ed6d-42ff-91e7-0797db98432e"
          defaultMessage="Proof's software is built to serve a variety of industry use cases."
        />
      </HelperText>

      <AutomaticFormRow
        fullWidth
        form={form}
        name="industry"
        registerOptions={{ required: intl.formatMessage(MESSAGES.industryError) }}
        label={intl.formatMessage(MESSAGES.industry)}
        placeholder={intl.formatMessage(MESSAGES.industryPlaceholder)}
        highlightLabelOnError
      >
        <Select
          items={industryOptions.map((i) => {
            return { value: i, label: i };
          })}
          aria-invalid={isAriaInvalid(form.formState.errors.industry)}
        />
      </AutomaticFormRow>

      {industryValue === OTHER_INDUSTRY && (
        <AutomaticFormRow<FormValues>
          label={
            <FormattedMessage
              id="bedb0fad-d299-4a00-9003-a11189a2c8eb"
              defaultMessage="Enter industry"
            />
          }
          form={form}
          name="industryOther"
          registerOptions={{ required: intl.formatMessage(MESSAGES.industryOtherError) }}
          fullWidth
          as={TextInput}
        />
      )}

      {status === "error" && <Error />}

      <Footer
        {...coreFooterProps}
        tooltipText={
          <FormattedMessage
            id="7b436c50-c668-4dae-8903-aa45ec72e8e1"
            defaultMessage="This information helps us better support and onboard your business."
          />
        }
      />
    </>
  );

  const mobileDeviceContent = (
    <>
      <AccountSetupHeading />
      <RadioGroup labelSize="large" label={formHeading}>
        {industryOptions.map((i) => {
          return (
            <OptionBar
              key={i}
              label={i}
              input={
                <RadioInput
                  value={i}
                  {...form.register("industry", {
                    required: intl.formatMessage(MESSAGES.industryError),
                  })}
                />
              }
              hasError={Boolean(form.formState.errors.industry)}
            />
          );
        })}
      </RadioGroup>

      {industryValue === OTHER_INDUSTRY && (
        <AutomaticFormRow<FormValues>
          className={Styles.mobileIndustry}
          label={
            <FormattedMessage
              id="bedb0fad-d299-4a00-9003-a11189a2c8eb"
              defaultMessage="Enter industry"
            />
          }
          form={form}
          name="industryOther"
          registerOptions={{ required: intl.formatMessage(MESSAGES.industryOtherError) }}
          fullWidth
          as={TextInput}
        />
      )}

      <FormattedFieldError
        className={Styles.error}
        inputName="industry"
        error={form.formState.errors.industry}
      />

      {status === "error" && <Error />}

      <Footer
        {...coreFooterProps}
        mobileDeviceStyling
        continueButtonText={
          <FormattedMessage id="119a47a9-f8bf-40a2-94c8-e76f4d3375dd" defaultMessage="Continue" />
        }
      />
    </>
  );

  return (
    <>
      <form onSubmit={form.handleSubmit(save)}>
        {isMobileScreenSize ? mobileDeviceContent : desktopContent}
      </form>
    </>
  );
}
