import { Field } from "redux-form";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import FormRowHelpTooltip from "common/form/form_row_help_tooltip";

import { verifyAch } from "./ach_util";
import Styles from "./ach.module.scss";

// Hash out the account name
function AccountName({ accountName, name, last4 }) {
  // If field has been given a value from Plaid modal display the given bank name
  if (accountName) {
    return <span>{accountName}</span>;

    // When no value is present fallback to the existing bank details
  } else if (name) {
    return (
      <span>
        {name} •••• {last4}
      </span>
    );
  }

  return null;
}

function AccountLabel({ fieldValue, existingValue, className }) {
  const { name, last4 } = existingValue || {};
  const accountName = fieldValue?.accountName || null;

  if (name || accountName) {
    return (
      <label htmlFor="file" className={classnames("ellipsis", className)}>
        <AccountName name={name} last4={last4} accountName={accountName} />
      </label>
    );
  }

  return null;
}

function ACHButton({ children, onChange }) {
  return (
    <Button buttonColor="action" variant="secondary" onClick={() => verifyAch(onChange)}>
      {children}
    </Button>
  );
}

// TODO: Clean up this field to be a proper, reusable field (thanks, James Phillips)
// see proper examples in /common/forms/fields/
function ACHField({ input: { value, onChange }, existingBankDetails }) {
  if (existingBankDetails?.name || value) {
    return (
      <div className={Styles.accountLabelGroup}>
        <AccountLabel
          fieldValue={value}
          existingValue={existingBankDetails}
          className={Styles.label}
        />
        <ACHButton onChange={onChange}>Change</ACHButton>
      </div>
    );
  }

  return <ACHButton onChange={onChange}>Connect Account</ACHButton>;
}

export default function AchForm({ paymentSource }) {
  const existingAccount = paymentSource?.type === "AchAccount" ? paymentSource : null;
  return (
    <div>
      <FormGroup fields={["account"]} className={Styles.accountLabelGroup}>
        <label htmlFor="ach-account">Account</label>
        <Field name="achAccount" component={ACHField} existingBankDetails={existingAccount} />

        <FormRowHelpTooltip tooltipPlacement="top" iconOutside>
          <FormattedMessage
            id="c70722a0-4175-42b2-95cc-7d0bd85fdb0f"
            defaultMessage="Allow your company to pay directly with a bank account. These charges will be batched and processed daily."
          />
        </FormRowHelpTooltip>

        <FormGroupErrors fields={["achAccount"]} />
      </FormGroup>
    </div>
  );
}
