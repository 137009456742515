import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useFeatureFlag } from "common/feature_gating";
import { ESIGN_TRANSACTION_TYPE, PROOF_TRANSACTION_TYPE } from "constants/transaction";
import {
  PROOF_TRANSACTIONS,
  HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN,
  LENDER_ESIGN,
  HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN,
} from "constants/feature_gates";
import { Heading, Paragraph } from "common/core/typography";
import { EmptyStateTile, EmptyStateTileGrid } from "common/dashboard/empty_state";

import Styles from "./index.module.scss";

type BusinesssGettingStartedProps = {
  trackTransactionStarted?: (input: { isBatch?: boolean }) => void;
  dotloopEligible: boolean;
  handleImportLoop: () => void;
  isNstNotary: boolean;
};

function GettingStartedHeader() {
  return (
    <div className={Styles.header}>
      <Heading level="h2" textStyle="headingFour">
        <FormattedMessage
          id="ebd23ccb-4296-4718-9bf7-5459a988dd3c"
          defaultMessage="Send your first transaction"
        />
      </Heading>
      <Paragraph size="large">
        <FormattedMessage
          id="b4d62691-a25e-476c-90a0-adb83643deb0"
          defaultMessage="You'll be able to track your signer's progress here."
        />
      </Paragraph>
    </div>
  );
}

function StandardNotarizationTile(props: { onClick(): void }) {
  return (
    <EmptyStateTile
      {...props}
      data-automation-id="start-a-standard-transaction"
      text={
        <FormattedMessage
          id="73c641e6-1af7-49d0-85bf-2f9a65e26355"
          defaultMessage="Standard notarization"
        />
      }
      subText={
        <FormattedMessage
          id="c7268604-cb8e-4b0f-a974-1f1602946a53"
          defaultMessage="Remote notarizations for non-closings"
        />
      }
      iconName="notarize-filled"
    />
  );
}

function ProofTile(props: { onClick(): void }) {
  return (
    <EmptyStateTile
      {...props}
      data-automation-id="start-a-proof-transaction"
      text={<FormattedMessage id="9639c69a-e61a-46c8-89b5-eb46a89cf252" defaultMessage="Proof" />}
      subText={
        <FormattedMessage
          id="3d862131-6d38-4e57-9ff6-b3fb70a417a3"
          defaultMessage="Secure, identity-verified signatures without notarization"
        />
      }
      iconName="secure"
    />
  );
}

function EsignatureTile(props: { onClick(): void }) {
  return (
    <EmptyStateTile
      {...props}
      data-automation-id="start-an-esign-transaction"
      text={
        <FormattedMessage id="5e901c7f-5e7f-4371-a098-7960d1962b15" defaultMessage="eSignature" />
      }
      subText={
        <FormattedMessage
          id="1d812d72-cad3-4402-b404-0862a10a94c2"
          defaultMessage="Digital signatures without identity verification or notarization"
        />
      }
      iconName="sign"
    />
  );
}

export function BusinessGettingStarted(props: BusinesssGettingStartedProps) {
  const { dotloopEligible, handleImportLoop, isNstNotary } = props;
  const navigate = useNavigate();
  const proofTransactionsEnabled = useFeatureFlag(PROOF_TRANSACTIONS) && !isNstNotary;
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);

  return (
    <>
      <GettingStartedHeader />

      <EmptyStateTileGrid>
        {showNotarizationOption && (
          <StandardNotarizationTile
            onClick={() => {
              props.trackTransactionStarted?.({});
              navigate("/transaction/new");
            }}
          />
        )}
        {proofTransactionsEnabled && (
          <ProofTile onClick={() => navigate(`/transaction/new?type=${PROOF_TRANSACTION_TYPE}`)} />
        )}

        {showEsignOption && (
          <EsignatureTile
            onClick={() => navigate(`/transaction/new?type=${ESIGN_TRANSACTION_TYPE}`)}
          />
        )}

        {dotloopEligible && (
          <EmptyStateTile
            data-automation-id="import-a-loop-button"
            onClick={handleImportLoop}
            text={
              <FormattedMessage
                id="73c641e6-1af7-49d0-85bf-2f9a65e26356"
                defaultMessage="Import a loop"
              />
            }
            iconName="download"
          />
        )}
      </EmptyStateTileGrid>
    </>
  );
}

export function RealGettingStarted({
  handleRealEstateOption,
  isTitlePortal,
}: {
  handleRealEstateOption(): void;
  isTitlePortal?: boolean;
}) {
  const navigate = useNavigate();
  const proofTransactionsEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  const lenderEsignEnabled = useFeatureFlag(LENDER_ESIGN);
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);

  return (
    <>
      <GettingStartedHeader />

      <EmptyStateTileGrid>
        <EmptyStateTile
          data-automation-id="start-a-real-estate-closing"
          onClick={handleRealEstateOption}
          text={
            <FormattedMessage
              id="bed7c446-7a08-4455-977b-1d520fa12bd9"
              defaultMessage="Real estate closing"
            />
          }
          subText={
            <FormattedMessage
              id="2acc328b-2682-44af-a667-cf755d0d3d26"
              defaultMessage="HELOCs, home purchases, and refinances"
            />
          }
          iconName="address"
        />

        {showNotarizationOption && (
          <StandardNotarizationTile
            onClick={() => {
              navigate("/transaction/create");
            }}
          />
        )}
        {proofTransactionsEnabled && (
          <ProofTile
            onClick={() => navigate(`/transaction/create?type=${PROOF_TRANSACTION_TYPE}`)}
          />
        )}

        {showEsignOption && (lenderEsignEnabled || isTitlePortal) && (
          <EsignatureTile
            onClick={() => navigate(`/transaction/create?type=${ESIGN_TRANSACTION_TYPE}`)}
          />
        )}
      </EmptyStateTileGrid>
    </>
  );
}
