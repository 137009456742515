type PlaidSucessMD = {
  account_id: string;
  institution: { name: string };
};
type PlaidOptions = {
  apiVersion: "v2";
  product: string[];
  env: "production" | "sandbox";
  selectAccount: boolean;
  clientName: string;
  key: string;
  onSuccess: (token: string, metadata: PlaidSucessMD) => void;
  onExit?: () => void;
};
type PlaidHandler = {
  open: () => void;
};
type PlaidAPI = {
  create: (args: PlaidOptions) => PlaidHandler;
};

declare const window: Window & { Plaid?: PlaidAPI };

function injectPlaid() {
  return new Promise<PlaidAPI>((resolve, reject) => {
    const scriptElem = document.createElement("script");
    scriptElem.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    scriptElem.type = "text/javascript";
    scriptElem.async = scriptElem.defer = true;
    scriptElem.onload = () => {
      resolve(window.Plaid!);
    };
    scriptElem.onerror = reject;
    document.body.appendChild(scriptElem);
  });
}

export async function getPlaidInstance(): Promise<PlaidAPI> {
  return window.Plaid || injectPlaid();
}
