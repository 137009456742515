// NewBadge and FeatureAnnouncement may not be used if there are no current new features
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { ComponentProps, ReactElement } from "react";
import { FocusScope } from "@react-aria/focus";
import classnames from "classnames";

import { Heading, Paragraph, Substyle } from "common/core/typography";
import Link from "common/core/link";
import Tooltip from "common/core/tooltip";
import Button from "common/core/button";
import type TooltipOverlay from "common/core/tooltip/overlay";
import { useId } from "util/html";
import { Badge } from "common/core/badge";
import { IconButton } from "common/core/button/icon_button";

import Styles from "./index.module.scss";

type ToolTipProps = ComponentProps<typeof TooltipOverlay>;

type FeatureAnnouncementProps = {
  heading: string;
  description: string;
  onDismiss?: () => void;
  customButton?: ReactElement;
  learnMoreLink?: string;
  arrowPlacement?: ToolTipProps["placement"];
  autoFocus?: boolean;
};

const MESSAGES = defineMessages({
  closeDialog: {
    id: "4fecbe1c-1a6d-4018-93cf-acd77bfd5add",
    defaultMessage: "Close dialog",
  },
});

export function NewBadge({
  toolTipContent,
  toolTipClassName,
  toolTipPlacement = "top",
}: {
  toolTipContent?: string;
  toolTipClassName?: string;
  toolTipPlacement?: ToolTipProps["placement"];
}) {
  const newBadge = (
    <Badge kind="new" className={Styles.badge}>
      <FormattedMessage id="85cee0cf-1aff-4059-88b7-f41b2a0ffd51" defaultMessage="New!" />
    </Badge>
  );
  return toolTipContent ? (
    <Tooltip target={newBadge} placement={toolTipPlacement} className={toolTipClassName}>
      {toolTipContent}
    </Tooltip>
  ) : (
    newBadge
  );
}

export function Announcement({
  heading,
  description,
  onDismiss,
  arrowPlacement,
  autoFocus,
  subHeading,
}: Omit<FeatureAnnouncementProps, "onDismiss" | "customButton" | "learnMoreLink"> & {
  subHeading?: string;
  onDismiss: () => void;
}) {
  const intl = useIntl();
  const announcementHeadingId = useId();
  const announcementDescriptionId = useId();
  const announcementClasses = classnames(
    Styles.lightAnnouncementContainer,
    arrowPlacement && `${Styles.withArrow} ${Styles[arrowPlacement]}`,
  );

  return (
    <div>
      <FocusScope contain autoFocus={autoFocus}>
        <div
          role="dialog"
          aria-labelledby={announcementHeadingId}
          aria-describedby={announcementDescriptionId}
          className={announcementClasses}
        >
          <IconButton
            name="x-small"
            className={Styles.closeButton}
            onClick={onDismiss}
            automationId="workflow-modal-close-button"
            label={intl.formatMessage(MESSAGES.closeDialog)}
            variant="tertiary"
            buttonColor="dark"
          />

          <div className={Styles.announcementContent}>
            {subHeading && (
              <Heading level="h2" textStyle="allCapsLabelSmall" textColor="subtle">
                {subHeading}
              </Heading>
            )}
            <Heading
              id={announcementHeadingId}
              level="h1"
              textStyle="subtitleSmall"
              className={Styles.announcementHeading}
            >
              {heading}
            </Heading>
            <Paragraph id={announcementDescriptionId}>{description}</Paragraph>
          </div>
        </div>
      </FocusScope>
    </div>
  );
}

export function FeatureAnnouncement({
  heading,
  description,
  onDismiss,
  learnMoreLink,
  customButton,
  arrowPlacement,
  autoFocus,
}: FeatureAnnouncementProps) {
  const announcementHeadingId = useId();
  const announcementDescriptionId = useId();
  const announcementClasses = classnames(
    Styles.announcementContainer,
    arrowPlacement && `${Styles.withArrow} ${Styles[arrowPlacement]}`,
  );

  return (
    <div>
      <FocusScope contain autoFocus={autoFocus}>
        <div
          role="dialog"
          aria-labelledby={announcementHeadingId}
          aria-describedby={announcementDescriptionId}
          className={announcementClasses}
        >
          <div className={Styles.announcementContent}>
            <Heading
              id={announcementHeadingId}
              level="h1"
              textStyle="headingSix"
              className={Styles.announcementHeading}
            >
              <Substyle size="small">{heading}</Substyle>
            </Heading>
            <Paragraph id={announcementDescriptionId} size="small">
              {description}
            </Paragraph>
            {customButton && customButton}
            {onDismiss && (
              <Button
                buttonColor="action"
                variant="primary"
                fullwidth
                className={Styles.dismissButton}
                onClick={onDismiss}
              >
                <FormattedMessage
                  id="4f73ab58-e20d-4a36-b07e-32bbe8ab69be"
                  defaultMessage="Dismiss"
                />
              </Button>
            )}
            {learnMoreLink && (
              <Link underlined={false} href={learnMoreLink} className={Styles.learnMoreButton}>
                <FormattedMessage
                  id="c4148088-098b-4348-8018-77467fb5648c"
                  defaultMessage="Learn more"
                />
              </Link>
            )}
          </div>
        </div>
      </FocusScope>
    </div>
  );
}
