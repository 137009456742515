import { defineMessages, useIntl } from "react-intl";

import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { hardNavigateToUnsafe } from "util/navigation";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Icon from "common/core/icon";

import DocumentDetailsPreviewHeader from "./document_details_preview_header";
import DocumentBundleActions from "./actions";

type Props = {
  document: {
    name: string | null;
    url: string;
  };
  backPath?: () => void;
  downloadable?: boolean;
  showResponsiveView?: boolean;
};

const MESSAGES = defineMessages({
  title: {
    id: "116d27ee-d1ce-4d8b-b341-33900ce56822",
    defaultMessage: "Supplementary documents",
  },
  back: {
    id: "4f4fc8f9-6b04-4ce2-a780-5afe385035a3",
    defaultMessage: "Back",
  },
});

function SupplementaryDocument({
  document,
  backPath,
  downloadable = true,
  showResponsiveView,
}: Props) {
  const { url } = document;
  const intl = useIntl();
  const onDownload = () => {
    // TODO: PLAT-2362 use safe version if possible
    hardNavigateToUnsafe(url);
    // The DocumentBundleActions component expects a promise to be returned
    // in order to control the loading state
    return Promise.resolve();
  };

  return (
    <div
      className={
        showResponsiveView
          ? "NotarizationDetails-responsive NotarizationDetails-responsive-supplementary-document-wrapper"
          : ""
      }
    >
      {!showResponsiveView && backPath && (
        <DocumentDetailsPreviewHeader documentName={document.name} backPath={backPath} />
      )}
      {showResponsiveView && backPath && (
        <DeprecatedDetailGridSection>
          <DeprecatedDetailGridRow
            className="NotarizationDetails-responsive-title"
            title={intl.formatMessage(MESSAGES.title)}
          />
          <div className="NotarizationDetails-responsive-document-header">
            <Icon
              className="icon"
              name="arrow-left"
              size="large"
              aria-label={intl.formatMessage(MESSAGES.back)}
              deprecatedOnClick={backPath}
            />
            {document.name}
          </div>
        </DeprecatedDetailGridSection>
      )}
      <div className="NotarizationDetails-supplementaryDocument">
        <PDFViewer url={url} />
      </div>
      <DocumentBundleActions downloadAction={downloadable ? onDownload : undefined} />
    </div>
  );
}

export default SupplementaryDocument;
