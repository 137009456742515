import { type UsState, makeFontDesc } from ".";
import { wrapText, FULL_SEAL_WIDTH } from "./text";
import type { NotaryProfileWizardSealCreatorModal_notaryProfile_address as Address } from "../creator_modal_fragment.graphql";

type AddressParams = {
  context: CanvasRenderingContext2D;
  address: Address;
  usState: UsState;
};

type DrawAddressParams = {
  context: CanvasRenderingContext2D;
  address: Address;
  textAlign?: CanvasTextAlign;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
  maxWidth?: number;
  lineHeight?: number;
  usState: UsState;
};

const CENTER_TEXT = 495;

function buildAddress(address: Address, usState: UsState): string {
  return `${address.line1}${address.line2}, ${address.city}, ${usState} ${address.postal}`;
}

function drawAddress({
  context,
  address,
  textAlign = "center",
  fontSize = 18,
  bold = false,
  x = CENTER_TEXT,
  y = 190,
  maxWidth = FULL_SEAL_WIDTH,
  lineHeight = 30,
  usState,
}: DrawAddressParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  wrapText({ context, text: buildAddress(address, usState), x, y, maxWidth, lineHeight });
}

export function placeAddress({ context, address, usState }: AddressParams) {
  switch (usState) {
    case "WV":
      return drawAddress({ context, address, usState, x: 460, y: 180 });
    case "AZ":
    case "MI":
    case "MT":
    case "TX":
    case "VA":
    case "FL":
    case "NV":
    case "IN":
    case "IA":
    case "KY":
    case "MD":
    case "ND":
    case "NE":
    case "OK":
    case "WA":
    case "TN":
    case "AR":
    case "KS":
    case "NH":
    case "WI":
    case "NJ":
    case "NY":
    case "AK":
    case "CO":
    case "PA":
    case "OH":
    case "MO":
    case "MN":
    case "ID":
      return;
    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
