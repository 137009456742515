import { AnnotationSubtype, NotarialActs } from "graphql_globals";
import { userFullName } from "util/user";
import {
  getSplitTimeZoneDateParts,
  getTimeZoneDate,
} from "common/meeting/pdf/annotation/date_interaction";
import { format } from "common/core/format/date";
import { DATE_ANNOTATION_FORMAT } from "constants/annotations";

import { getStateSpecificMiddlePreprintedAnnotationSubtypes } from "./pre_printed";

type AnnotationDesc = Readonly<
  | {
      subtype: AnnotationSubtype.FREE_TEXT;
      text: string;
      distanceFactor?: number;
    }
  | {
      subtype: AnnotationSubtype.DISCLOSURE;
      text?: string;
      distanceFactor?: number;
    }
  | {
      subtype: Exclude<
        AnnotationSubtype,
        AnnotationSubtype.FREE_TEXT | AnnotationSubtype.SEAL | AnnotationSubtype.DISCLOSURE
      >;
      distanceFactor?: number;
    }
>;
type UsState = string;
type Namable = Parameters<typeof userFullName>[0];
type Options = Readonly<{
  notaryProfile: {
    notaryId: null | string;
    locationCounty: null | string;
    locationAddress: null | { city: string | null };
    county: null | string;
    licenseExpiry: null | string;
    notaryDisclaimerText: null | string;
  };
  notaryUser: Namable;
  notaryUsStateName: UsState;
  isAttorney: boolean | null;
  principals: Namable[];
  notarialAct: NotarialActs;
  acknowledgementType?: "individual" | "representative";
}>;
type DescriptionGenerator = (options: Options) => Generator<AnnotationDesc>;

function formatPrincipalNames(principals: Options["principals"]) {
  switch (principals.length) {
    case 0:
      throw new Error("At least one principal required");
    case 1:
      return userFullName(principals[0]);
    case 2:
      return `${userFullName(principals[0])} and ${userFullName(principals[1])}`;
    default: {
      // 3+ principals
      const lastIndex = principals.length - 1;
      const leadingPrincipals = principals
        .slice(0, lastIndex)
        .map((principal) => userFullName(principal))
        .join(", ");
      return `${leadingPrincipals}, and ${userFullName(principals[lastIndex])}`;
    }
  }
}

function formatDate(value: string) {
  return format({ value, formatStyle: DATE_ANNOTATION_FORMAT });
}

function freeTextDescription(text: string, distanceFactor?: number): AnnotationDesc {
  return { subtype: AnnotationSubtype.FREE_TEXT, text, distanceFactor };
}

function disclosureTextDescription(text: string, distanceFactor?: number): AnnotationDesc {
  return { subtype: AnnotationSubtype.DISCLOSURE, text, distanceFactor };
}

function* basicStateAndCountyPreamble(options: Options): Generator<AnnotationDesc> {
  yield freeTextDescription(`State of ${options.notaryUsStateName}`);
  yield freeTextDescription(`County of ${options.notaryProfile.locationCounty}`, 2);
}

const PLACEHOLDER = "_______";
const STANDARD_REPRESENATIVE_PLACEHOLDERS = `as ${PLACEHOLDER} of ${PLACEHOLDER}`;
const BEHALF_REPRESENATIVE_PLACEHOLDERS = `as ${PLACEHOLDER} on behalf of ${PLACEHOLDER}`;

const BASIC_PREAMBLE_STATES = Object.freeze(
  new Set([
    "Alaska",
    "Arizona",
    "Arkansas",
    "Colorado",
    "Florida",
    "Idaho",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Maryland",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New York",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Pennsylvania",
    "Texas",
    "Tennessee",
    "Washington",
    "West Virginia",
    "Wisconsin",
  ]),
);

const STATE_PREAMBLE_LOOKUP: Readonly<Record<UsState, DescriptionGenerator | undefined>> =
  Object.freeze({
    *Indiana(options) {
      yield freeTextDescription("State of Indiana");
      yield freeTextDescription(`County of ${options.notaryProfile.locationCounty}`);
      yield freeTextDescription(
        `City of ${options.notaryProfile.locationAddress?.city || "Unknown"}`,
        2,
      );
    },
    *Virginia(options) {
      yield freeTextDescription("Commonwealth of Virginia");
      yield freeTextDescription(`County of ${options.notaryProfile.locationCounty}`, 2);
    },
  });

const STATEMENT_ACT_LOOKUP: Readonly<
  Record<UsState, Record<string, DescriptionGenerator | undefined> | undefined>
> = Object.freeze({
  Alaska: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The foregoing instrument was acknowledged before me this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Subscribed and sworn to or affirmed before me by ${formatPrincipalNames(
          options.principals,
        )}`,
      );
      yield freeTextDescription(`on ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Arizona: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        "The foregoing instrument was acknowledged before me by means of communication technology",
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        "Signed and sworn to (or affirmed) before me by means of communication technology",
      );
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
  },
  Arkansas: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      const [day, month, year] = getSplitTimeZoneDateParts(options.notaryUsStateName, true);

      yield freeTextDescription(`On this ${day} day of ${month}, ${year}, before me,`);
      yield freeTextDescription(
        `${userFullName(options.notaryUser)}, the undersigned notary, personally appeared`,
      );

      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `${formatPrincipalNames(options.principals)} known to me (or satisfactorily proven)`,
          );
          break;
        case "representative":
          yield freeTextDescription(
            `${formatPrincipalNames(options.principals)} ${STANDARD_REPRESENATIVE_PLACEHOLDERS},`,
          );
          yield freeTextDescription(`known to me (or satisfactorily proven)`);
      }
      yield freeTextDescription("to be the person whose name(s) is/are");
      yield freeTextDescription(
        "subscribed to the within instrument and acknowledged that he/she/they",
      );
      yield freeTextDescription("executed the same for the purposes therein contained.", 2);
      yield freeTextDescription("In witness whereof I hereunto set my hand and official seal.");
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Subscribed and sworn to before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      const [day, month, year] = getSplitTimeZoneDateParts(options.notaryUsStateName, true);
      yield freeTextDescription(
        `I, ${userFullName(
          options.notaryUser,
        )}, certify that this is a true and perfect copy of the original document`,
      );
      yield freeTextDescription(
        `${PLACEHOLDER} presented to me by ${formatPrincipalNames(options.principals)}`,
      );
      yield freeTextDescription(`on this ${day} day of ${month}, ${year}.`);
    },
  },
  Colorado: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("This record was acknowledged before me");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to (or affirmed) before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a document in the",
      );
      yield freeTextDescription(`possession of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated ${getTimeZoneDate(options.notaryUsStateName)}`, 2);
    },
  },
  Florida: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield disclosureTextDescription(
        "This foregoing instrument was acknowledged before me by means of online notarization,",
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield disclosureTextDescription(
        "Sworn to (or affirmed) and subscribed before me by means of online notarization,",
      );
      yield freeTextDescription(
        `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
  },
  Idaho: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("This record was acknowledged before me");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${BEHALF_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to before me");
      yield freeTextDescription(
        `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify that this is a true and correct copy of a record");
      yield freeTextDescription(
        `in the possession of ${formatPrincipalNames(options.principals)}.`,
      );
      yield freeTextDescription(`Dated ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Indiana: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        "I certify that the attached or associated electronic record entitled",
      );
      yield freeTextDescription(
        `${PLACEHOLDER} and dated ${PLACEHOLDER} was acknowledged and signed`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by the principal ${formatPrincipalNames(options.principals)}`);
          break;
        case "representative":
          yield freeTextDescription(
            `by the principal ${formatPrincipalNames(
              options.principals,
            )}, ${STANDARD_REPRESENATIVE_PLACEHOLDERS}`,
          );
          break;
      }
      yield freeTextDescription(
        `who was located in this city ${PLACEHOLDER}, county ${PLACEHOLDER},`,
      );
      yield freeTextDescription(`state or province ${PLACEHOLDER}, and country ${PLACEHOLDER}`);
      yield freeTextDescription("and who appeared by audio visual communication on this date,");
      yield freeTextDescription("was notarized by me, the remote notary public,");
      yield freeTextDescription(
        `on this date ${getTimeZoneDate(options.notaryUsStateName)} in this city and county ${
          options.notaryProfile.locationAddress?.city || ""
        }, ${options.notaryProfile.locationCounty || ""}, Indiana.`,
        2,
      );
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}`);
      yield freeTextDescription(
        `who was located in this city ${PLACEHOLDER}, county ${PLACEHOLDER},`,
      );
      yield freeTextDescription(`state or province ${PLACEHOLDER}, and country ${PLACEHOLDER}`);
      yield freeTextDescription("and who appeared by audio visual communication on this date,");
      yield freeTextDescription("was notarized by me, the remote notary public,");
      yield freeTextDescription(
        `on this date ${getTimeZoneDate(options.notaryUsStateName)} in this city and county ${
          options.notaryProfile.locationAddress?.city || ""
        }, ${options.notaryProfile.locationCounty || ""}, Indiana.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify that this is a true and correct copy of a record in");
      yield freeTextDescription(`the possession of ${formatPrincipalNames(options.principals)}`);
      yield freeTextDescription(
        `who was located in this city ${PLACEHOLDER}, county ${PLACEHOLDER},`,
      );
      yield freeTextDescription(`state or province ${PLACEHOLDER}, and country ${PLACEHOLDER}`);
      yield freeTextDescription("and who appeared by audio visual communication on this date,");
      yield freeTextDescription("was notarized by me, the remote notary public,");
      yield freeTextDescription(
        `on this date ${getTimeZoneDate(options.notaryUsStateName)} in this city and county ${
          options.notaryProfile.locationAddress?.city || ""
        }, ${options.notaryProfile.locationCounty || ""}, Indiana.`,
        2,
      );
    },
  },
  Iowa: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("This instrument was acknowledged before me");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${BEHALF_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to before me");
      yield freeTextDescription(
        `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify that this is a true and correct copy of a document");
      yield freeTextDescription(
        `in the possession of ${formatPrincipalNames(options.principals)}.`,
      );
      yield freeTextDescription(`Dated ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Kansas: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The record was acknowledged before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a record in the possession",
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Kentucky: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("The foregoing instrument was acknowledged before me on");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to (or affirmed) before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        `On this date ${getTimeZoneDate(
          options.notaryUsStateName,
        )}, I certify that this is a true and correct copy of a document`,
      );
      yield freeTextDescription(
        `in the possession of ${formatPrincipalNames(options.principals)} dated ${PLACEHOLDER}.`,
        2,
      );
    },
  },
  Maryland: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The record was acknowledged before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to (or affirmed) before me");
      yield freeTextDescription(
        `this ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify that this is a true and correct copy of a record");
      yield freeTextDescription(
        `in the possession of ${formatPrincipalNames(options.principals)}.`,
      );
      yield freeTextDescription(
        `Dated ${getTimeZoneDate(options.notaryUsStateName)} by ${userFullName(
          options.notaryUser,
        )}.`,
        2,
      );
    },
  },
  Michigan: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `Acknowledged by ${formatPrincipalNames(
              options.principals,
            )} before me on ${getTimeZoneDate(options.notaryUsStateName)}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `Acknowledged before me on ${getTimeZoneDate(
              options.notaryUsStateName,
            )} by ${formatPrincipalNames(options.principals)}`,
          );
          yield freeTextDescription(`${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`, 2);
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Subscribed and sworn to by ${formatPrincipalNames(options.principals)}`,
      );
      yield freeTextDescription(`before me on ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Minnesota: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield disclosureTextDescription(
        `This record was acknowledged before me by use of communication technology`,
      );

      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )},`,
          );
          yield freeTextDescription(
            `who declared that he/she is located in ${PLACEHOLDER} and that this record`,
          );
          break;
        case "representative":
          yield freeTextDescription(
            ` on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}`,
          );
          yield freeTextDescription(`as ${PLACEHOLDER} of or for ${PLACEHOLDER},`);
          yield freeTextDescription(
            `who declared that he/she is located in ${PLACEHOLDER} and that this record`,
          );
          break;
      }
      yield freeTextDescription("is to be filed with or relates to a matter before a court,");
      yield freeTextDescription("government entity, public official or other entity");
      yield freeTextDescription("located in the territorial jurisdiction of the United States, or");
      yield freeTextDescription("involves property located in the territorial jurisdiction of,");
      yield freeTextDescription(
        "or a transaction substantially connectd with, the United States.",
        2,
      );
    },
    *[NotarialActs.JURAT](options) {
      yield disclosureTextDescription("Signed and sworn to (or affirmed) before me by use of");
      yield freeTextDescription(
        `communication technology on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      yield freeTextDescription(
        `by ${formatPrincipalNames(options.principals)}, who declared that he/she is located`,
      );
      yield freeTextDescription(`in ${PLACEHOLDER} and that this record`);
      yield freeTextDescription("is to be filed with or relates to a matter before a court,");
      yield freeTextDescription("government entity, public official or other entity");
      yield freeTextDescription("located in the territorial jurisdiction of the United States,");
      yield freeTextDescription("or involves property located in the territorial jurisdiction of,");
      yield freeTextDescription(
        "or a transaction substantially connectd with, the United States.",
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify that this is a true and correct copy of a document in");
      yield freeTextDescription(`the possession of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}`, 2);
    },
  },
  Missouri: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `On this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}, before me, the undersigned notary, personally appeared by remote online means`,
      );
      yield freeTextDescription(
        `${formatPrincipalNames(
          options.principals,
        )}, (personally known to me) (proved to me through`,
      );
      yield freeTextDescription(
        `identification documents, which were ${PLACEHOLDER}) (proved to me on the oath or affirmation`,
      );
      yield freeTextDescription(
        `of ${PLACEHOLDER}, who is personally known to me and stated to me that he/she`,
      );
      yield freeTextDescription(
        "personally knows the document signer and is unaffected by the document,) to be the",
      );
      yield freeTextDescription(
        "person whose name is signed on the preceding or attached document, and",
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            "acknowledged to me that he/she signed it voluntarily for its state purpose.",
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `acknowledged to me that he/she signed it voluntarily for its state purpose ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `On this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}, before me, the undersigned notary, personally appeared by remote online means`,
      );
      yield freeTextDescription(
        `${formatPrincipalNames(
          options.principals,
        )}, (personally known to me) (proved to me through identification documents,`,
      );
      yield freeTextDescription(
        `which were ${PLACEHOLDER}) (proved to me on the oath or affirmation`,
      );
      yield freeTextDescription(
        `of ${PLACEHOLDER}, who is personally known to me and stated to me that he/she`,
      );
      yield freeTextDescription(
        "personally knows the document signer and is unaffected by the document,) to be the",
      );
      yield freeTextDescription(
        "person who signed the preceding or attached document in my presence",
      );
      yield freeTextDescription(
        "and who swore or affirmed to me that the contents of the document are truthful and accurate",
      );
      yield freeTextDescription("to the best of his/her knowledge and belief.", 2);
    },
  },
  Montana: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield disclosureTextDescription(
        `This record was acknowledged before me by use of communication technology on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )}, who declared that he/she is located in ${PLACEHOLDER}`,
          );
          break;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} as ${PLACEHOLDER} of or for ${PLACEHOLDER},`,
          );
          yield freeTextDescription(`who declared that he/she is located in ${PLACEHOLDER}`);
          break;
      }
      yield freeTextDescription(
        "and this record is part of, or pertains to, a matter that is to be filed",
      );
      yield freeTextDescription(
        "with or is before a court, government entity, or other entity located in the United States",
      );
      yield freeTextDescription(
        "or involves property located in or a transaction substantially connected with, the United States.",
        2,
      );
    },
    *[NotarialActs.JURAT](options) {
      yield disclosureTextDescription(
        `Signed and sworn to (or affirmed) before me by use of communication technology on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(
        `by ${formatPrincipalNames(
          options.principals,
        )}, who declared that he/she is located in ${PLACEHOLDER}`,
      );
      yield freeTextDescription(
        "and this record is part of, or pertains to, a matter that is to be filed",
      );
      yield freeTextDescription(
        "with or is before a court, government entity, or other entity located in the United States",
      );
      yield freeTextDescription(
        "or involves property located in or a transaction substantially connected with, the United States.",
        2,
      );
    },
  },
  Nebraska: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The foregoing instrument was acknowledged before me this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(options.principals)} ${BEHALF_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `The foregoing instrument was subscribed and sworn before me this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
  },
  Nevada: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("This instrument was acknowledged before me");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to (or affirmed) before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a document in the",
      );
      yield freeTextDescription(`possession of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated ${getTimeZoneDate(options.notaryUsStateName)}`, 2);
    },
  },
  "North Dakota": {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `This record was acknowledged before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn (or affirmed) before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify that this is a true and correct copy of a record");
      yield freeTextDescription(
        `in the possession of ${formatPrincipalNames(options.principals)}.`,
      );
      yield freeTextDescription(`Dated ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  "New Hampshire": {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `This instrument was acknowledged before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a document in the possession",
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  "New Jersey": {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The record was acknowledged before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a record in the possession",
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  "New York": {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      const [day, month, year] = getSplitTimeZoneDateParts(options.notaryUsStateName, true);

      yield freeTextDescription(
        `On the ${day} day of ${month} in the year ${year} before me, the `,
      );
      yield freeTextDescription(
        `undersigned, personally appeared ${formatPrincipalNames(
          options.principals,
        )}, personally known`,
      );
      yield freeTextDescription("to me or proved to me on the basis of satisfactory evidence");
      yield freeTextDescription("to be the individual(s) whose name(s) is (are) subscribed to the");
      yield freeTextDescription(
        "within instrument and acknowledged to me that he/she/they executed the",
      );
      yield freeTextDescription(
        "same in his/her/their capacity(ies), and that by his/her/their signature(s)",
      );
      yield freeTextDescription(
        "on the instrument, the individual(s), or the person upon behalf of",
      );
      yield freeTextDescription("which the individual(s) acted, executed the instrument.");
    },
    *[NotarialActs.JURAT](options) {
      const [day, month, year] = getSplitTimeZoneDateParts(options.notaryUsStateName, true);
      yield freeTextDescription(`Subscribed and sworn to before me this ${day} day of ${month},`);
      yield freeTextDescription(`${year} by ${formatPrincipalNames(options.principals)}.`);
    },
  },
  Ohio: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The foregoing instrument was acknowledged before me this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(options.principals)} ${BEHALF_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Sworn to or affirmed and subscribed before me by ${formatPrincipalNames(
          options.principals,
        )}`,
      );
      yield freeTextDescription(`this date of ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Oklahoma: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `This instrument was acknowledged before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a record in the possession",
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Pennsylvania: {
    *[NotarialActs.ATTESTATION](options) {
      yield freeTextDescription(
        `Signed (or attested) before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `This record was acknowledged before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} as ${PLACEHOLDER} who represents that (he, she, or they) are authorized`,
          );
          yield freeTextDescription(`to act on behalf of ${PLACEHOLDER}.`, 2);
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        `I certify that this is a true and correct copy of a ${PLACEHOLDER} in the possession`,
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Tennessee: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield disclosureTextDescription(
        `Personally appeared by audio-video communication before me, ${userFullName(
          options.notaryUser,
        )}, ${options.notaryProfile.notaryDisclaimerText},`,
      );
      yield freeTextDescription(
        `${formatPrincipalNames(
          options.principals,
        )}, who acknowledged that such person executed the`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription("within instrument for the purposes therein contained.", 2);
          yield freeTextDescription(
            `Witness my hand, at office, this ${getTimeZoneDate(options.notaryUsStateName)}`,
          );
          yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
          yield freeTextDescription(
            `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            "within instrument for the purposes therein contained, and who",
          );
          yield freeTextDescription(
            `further acknowledged that such person is the ${PLACEHOLDER} of ${PLACEHOLDER}`,
          );
          yield freeTextDescription(
            `and is authorized to execute this instrument on behalf of ${PLACEHOLDER}.`,
            2,
          );
          yield freeTextDescription(
            `Witness my hand, at office, this ${getTimeZoneDate(options.notaryUsStateName)}`,
          );
          yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
          yield freeTextDescription(
            `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
            2,
          );
      }
    },
    *[NotarialActs.AFFIDAVIT](options) {
      yield freeTextDescription(
        `${formatPrincipalNames(options.principals)}, being duly sworn, makes an oath as follows:`,
        2,
      );
      yield freeTextDescription(PLACEHOLDER, 3);
      yield freeTextDescription("___Place signer's signature here___", 3);
      yield disclosureTextDescription(
        `Sworn and subscribed before me by audio-video communication this ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
        2,
      );
      yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
      yield { subtype: AnnotationSubtype.DISCLAIMER };
      yield freeTextDescription(
        `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
        2,
      );
    },
  },
  Texas: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield disclosureTextDescription(
        "This instrument was acknowledged before me by means of an interactive two-way",
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `audio and video communication on ${getTimeZoneDate(
              options.notaryUsStateName,
            )} by ${formatPrincipalNames(options.principals)}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `audio and video communication on ${getTimeZoneDate(
              options.notaryUsStateName,
            )} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Sworn to and subscribed before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        `On this date ${getTimeZoneDate(
          options.notaryUsStateName,
        )}, I certify that this is a true and correct copy of a document`,
      );
      yield freeTextDescription(
        `in the possession of ${formatPrincipalNames(options.principals)} dated ${PLACEHOLDER}`,
        2,
      );
    },
  },
  Virginia: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("The foregoing instrument was acknowledged before me");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("The foregoing instrument was subscribed and sworn");
      yield freeTextDescription(
        `before me on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify this to be a complete, full, true and exact reproduction of the original document.",
      );
      yield freeTextDescription(`Certified this ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Washington: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription("This record was acknowledged before me");
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn (or affirmed) before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a record in the possession",
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  "West Virginia": {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      yield freeTextDescription(
        `The record was acknowledged before me on ${getTimeZoneDate(options.notaryUsStateName)}`,
      );
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
          return;
        case "representative":
          yield freeTextDescription(
            `by ${formatPrincipalNames(
              options.principals,
            )} ${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`,
            2,
          );
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription(
        `Signed and sworn to (or affirmed) before me on ${getTimeZoneDate(
          options.notaryUsStateName,
        )}`,
      );
      yield freeTextDescription(`by ${formatPrincipalNames(options.principals)}.`, 2);
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription(
        "I certify that this is a true and correct copy of a record in the possession",
      );
      yield freeTextDescription(`of ${formatPrincipalNames(options.principals)}.`);
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
  Wisconsin: {
    *[NotarialActs.ACKNOWLEDGEMENT](options) {
      switch (options.acknowledgementType!) {
        case "individual":
          yield freeTextDescription(
            `This record was acknowledged before me on ${getTimeZoneDate(
              options.notaryUsStateName,
            )} by ${formatPrincipalNames(options.principals)}.`,
            2,
          );
          return;
        case "representative":
          yield freeTextDescription(
            `This record was acknowledged before me on ${getTimeZoneDate(
              options.notaryUsStateName,
            )} by ${formatPrincipalNames(options.principals)}`,
          );
          yield freeTextDescription(`${STANDARD_REPRESENATIVE_PLACEHOLDERS}.`, 2);
      }
    },
    *[NotarialActs.JURAT](options) {
      yield freeTextDescription("Signed and sworn to (or affirmed) before me");
      yield freeTextDescription(
        `on ${getTimeZoneDate(options.notaryUsStateName)} by ${formatPrincipalNames(
          options.principals,
        )}.`,
        2,
      );
    },
    *[NotarialActs.COPY_CERTIFICATION](options) {
      yield freeTextDescription("I certify or attest that this is a true and correct copy of a");
      yield freeTextDescription(
        `record in the possession of ${formatPrincipalNames(options.principals)}.`,
      );
      yield freeTextDescription(`Dated: ${getTimeZoneDate(options.notaryUsStateName)}.`, 2);
    },
  },
});

const STATE_POSTAMBLE_LOOKUP: typeof STATE_PREAMBLE_LOOKUP = Object.freeze({
  *Alaska(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Arizona(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER, distanceFactor: 2 };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Arkansas(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield { subtype: AnnotationSubtype.NOTARY_ID };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Colorado(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Florida() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.NOTARY_NAME, distanceFactor: 2 };
    yield freeTextDescription("___ Personally Known OR ___ Produced Identification");
    yield freeTextDescription(`Type of Identification Produced ${PLACEHOLDER}`, 2);
  },
  *Idaho(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Indiana(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `Commissioned in ${options.notaryProfile.county || "Unknown"} County`,
    );
    yield { subtype: AnnotationSubtype.NOTARY_NAME };
    yield freeTextDescription(
      `Date notary public commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Iowa() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER, distanceFactor: 2 };
  },
  *Kansas() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
  },
  *Kentucky() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
  },
  *Maryland(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Michigan() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
  },
  *Minnesota(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
    );
    yield freeTextDescription(
      `My notary commission number is: ${options.notaryProfile.notaryId!}`,
      2,
    );
  },
  *Missouri() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
  },
  *Montana() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
  },
  *Nebraska(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Nevada() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
  },
  *"New Hampshire"(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *"New Jersey"(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *"New York"() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
  },
  *"North Dakota"(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Ohio(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Oklahoma(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Pennsylvania(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Texas() {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
  },
  *Virginia(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.NOTARY_ID };
    yield {
      subtype: AnnotationSubtype.FREE_TEXT,
      text: `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      distanceFactor: 2,
    };
  },
  *Washington(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *"West Virginia"(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE, distanceFactor: 2 };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    yield freeTextDescription(
      `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
      2,
    );
  },
  *Wisconsin(options) {
    yield { subtype: AnnotationSubtype.NOTARY_SIGNATURE };
    yield { subtype: AnnotationSubtype.DISCLAIMER };
    if (!options.isAttorney) {
      yield freeTextDescription(
        `My commission expires: ${formatDate(options.notaryProfile.licenseExpiry!)}`,
        2,
      );
    }
  },
});

function* fallbackToSubtypes({
  notaryUsStateName,
  isAttorney,
}: Options): Generator<AnnotationDesc> {
  for (const subtype of getStateSpecificMiddlePreprintedAnnotationSubtypes(
    notaryUsStateName,
    Boolean(isAttorney),
  )) {
    if (subtype !== AnnotationSubtype.SEAL) {
      yield { subtype };
    }
  }
}

function* defaultPreambleGenerator(): Generator<AnnotationDesc> {
  yield { subtype: AnnotationSubtype.STATE };
  yield { subtype: AnnotationSubtype.COUNTY };
}

export function* getStateSpecificMiddleNonPreprintedAnnotationDescriptions(
  options: Options,
): Generator<AnnotationDesc> {
  const { notaryUsStateName, notarialAct } = options;
  if (!options.acknowledgementType && notarialAct === NotarialActs.ACKNOWLEDGEMENT) {
    throw new Error("Missing acknowledgementType with acknowledgement act type");
  }

  const preambleGenerator = BASIC_PREAMBLE_STATES.has(notaryUsStateName)
    ? basicStateAndCountyPreamble
    : STATE_PREAMBLE_LOOKUP[notaryUsStateName] || defaultPreambleGenerator;
  yield* preambleGenerator(options);

  const statementSpecificGenerator = STATEMENT_ACT_LOOKUP[notaryUsStateName]?.[notarialAct];
  const generator = statementSpecificGenerator || fallbackToSubtypes;
  yield* generator(options);

  // We only do this if we used a statementSpecificGenerator since fallbackToSubtypes
  // will generate postambles by default.
  const postambleGenerator = STATE_POSTAMBLE_LOOKUP[notaryUsStateName];
  if (statementSpecificGenerator && postambleGenerator) {
    yield* postambleGenerator(options);
  }
}
