// keep this updated with ./easy_link.scss

const style = `
  a.notarize-easy-link {
    display: inline-block;
    border-radius: 3px;
    color: white;
    background: #0075FF url("http://static.notarize.com/notarize-button-icon.png") 16px center no-repeat;
    text-indent: 32px;
    background-size: 24px 24px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    padding: 0px 16px;
    font-size: 14px;
    letter-spacing: 0.01em;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-decoration: none;
  }

  a.notarize-easy-link:hover {
    color: white;
  }
`
  .replace(/\n/g, "")
  .replace(/\s{2,}/g, " ")
  .trim();

export default style;
