import { Observable } from "rxjs";

import FLSeal from "assets/images/seals/FL.png";
import COSeal from "assets/images/seals/CO.png";
import NVSeal from "assets/images/seals/NV.png";
import TXSeal from "assets/images/seals/TX.png";
import VASeal from "assets/images/seals/VA.png";
import INSeal from "assets/images/seals/IN.png";
import AZSeal from "assets/images/seals/AZ.png";
import IASeal from "assets/images/seals/IA.png";
import MISeal from "assets/images/seals/MI.png";
import MOSeal from "assets/images/seals/MO.png";
import MTSeal from "assets/images/seals/MT.png";
import IDSeal from "assets/images/seals/ID.png";
import KYSeal from "assets/images/seals/KY.png";
import MNSeal from "assets/images/seals/MN.png";
import NDSeal from "assets/images/seals/ND.png";
import NESeal from "assets/images/seals/NE.png";
import OHSeal from "assets/images/seals/OH.png";
import OKSeal from "assets/images/seals/OK.png";
import PASeal from "assets/images/seals/PA.png";
import TNSeal from "assets/images/seals/TN.png";
import MDSeal from "assets/images/seals/MD.png";
import WASeal from "assets/images/seals/WA.png";
import NYSeal from "assets/images/seals/NY.png";
import WVSeal from "assets/images/seals/WV.png";

import { placeCity } from "./city";
import { placeExpirationDate } from "./expiration_date";
import { placeRegistrationNumber } from "./registration_number";
import { placeText } from "./text";
import { placeCounty } from "./county";
import { placeName } from "./name";
import { placeAddress } from "./address";
import type { NotaryProfileWizardSealCreatorModal_notaryProfile_address as Address } from "../creator_modal_fragment.graphql";

export type UsState =
  | "FL"
  | "CO"
  | "NV"
  | "TX"
  | "VA"
  | "IN"
  | "AZ"
  | "IA"
  | "MI"
  | "MO"
  | "MT"
  | "ID"
  | "KY"
  | "MN"
  | "ND"
  | "NE"
  | "OH"
  | "OK"
  | "PA"
  | "MD"
  | "TN"
  | "WA"
  | "AK"
  | "NY"
  | "NJ"
  | "WI"
  | "NH"
  | "AR"
  | "KS"
  | "WV";

type SealBuilder = (context: HTMLCanvasElement) => void;
type CreatorParams = {
  name: string | null;
  expirationDate: Date | null;
  registrationNumber: string | null;
  usState: UsState;
  county?: string | null;
  city?: string | null;
  isAttorney: boolean | null;
  address: Address | null;
};
type BuilderParams = {
  loadedImage: HTMLImageElement;
  params: Omit<CreatorParams, "usState"> & { usState: UsState };
};

type DrawArcTextParams = {
  context: CanvasRenderingContext2D;
  str: string;
  centerX: number;
  centerY: number;
  radius: number;
  angle: number;
};

export function makeFontDesc(size: number, bold?: boolean) {
  return `${bold ? "bold " : ""}${size}px Gotham SSm A, Gotham SSm B, Helvetica, Arial, sans-serif`;
}

export function drawTextAlongArc(params: DrawArcTextParams) {
  const { context, str, centerX, centerY, radius, angle } = params;
  const len = str.length;
  context.save();
  context.translate(centerX, centerY);
  context.rotate((-1 * angle) / 2);
  context.rotate((-1 * (angle / len)) / 2);
  for (const char of str) {
    context.rotate(angle / len);
    context.save();
    context.translate(0, -1 * radius);
    context.fillText(char, 0, 0);
    context.restore();
  }
  context.restore();
}

const CANVAS_WIDTH = 720;
const CANVAS_HEIGHT = 288;
const SEAL_IMAGE_LOOKUP: Readonly<Record<UsState, string>> = Object.freeze({
  FL: FLSeal,
  CO: COSeal,
  NV: NVSeal,
  TX: TXSeal,
  VA: VASeal,
  IN: INSeal,
  AZ: AZSeal,
  IA: IASeal,
  MI: MISeal,
  MO: MOSeal,
  MT: MTSeal,
  ID: IDSeal,
  KY: KYSeal,
  MN: MNSeal,
  ND: NDSeal,
  NE: NESeal,
  OH: OHSeal,
  OK: OKSeal,
  PA: PASeal,
  TN: TNSeal,
  MD: MDSeal,
  WA: WASeal,
  AK: PASeal,
  NY: NYSeal,
  NJ: NYSeal,
  WI: NYSeal,
  NH: NYSeal,
  AR: PASeal,
  KS: PASeal,
  WV: WVSeal,
});

function makeSealBuilder({ loadedImage, params }: BuilderParams): SealBuilder {
  return (canvas) => {
    const { name, usState, registrationNumber, expirationDate, county, city, isAttorney, address } =
      params;
    const canvasHeight = usState === "TN" ? 600 : CANVAS_HEIGHT;
    const canvasWidth = usState === "TN" ? 600 : CANVAS_WIDTH;
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;
    const context = canvas.getContext("2d")!;
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    context.drawImage(loadedImage, 0, 0);

    if (name) {
      placeName({ context, name, usState, isAttorney });
    }
    if (registrationNumber) {
      placeRegistrationNumber({ context, registrationNumber, usState });
    }
    if (expirationDate) {
      placeExpirationDate({ context, expirationDate, usState, isAttorney });
    }
    if (county) {
      placeCounty({ context, county, usState });
    }
    if (city) {
      placeCity({ context, city, usState });
    }
    if (address) {
      placeAddress({ context, address, usState });
    }
    placeText({ context, usState, isAttorney });
  };
}

export function getSealBuilderObservable(params: CreatorParams): Observable<SealBuilder> {
  const { usState } = params;
  return new Observable((observer) => {
    const image = new Image();
    image.onload = () => {
      observer.next(
        makeSealBuilder({
          loadedImage: image,
          params: params as BuilderParams["params"],
        }),
      );
      observer.complete();
    };
    image.src = SEAL_IMAGE_LOOKUP[usState];
  });
}
