import { FormattedMessage } from "react-intl";

import { FormRow } from "common/core/form/layout";
import { RadioGroup, RadioInput, RadioLabel } from "common/core/form/option";
import {
  OrganizationApiKeyCompletionRequirement,
  type OrganizationApiKeyPayer,
} from "graphql_globals";
import type { UseFormReturn } from "common/core/form";
import { useId } from "util/html";
import { FormCard } from "common/dashboard/common";
import { type ProofRequirementField } from "common/proof_requirements/common";
import { useFeatureFlag } from "common/feature_gating";
import { PS1583_ATTESTATION } from "constants/feature_gates";

export const SIGNER_UPLOAD_PROOF_MESSAGE_PROPS = {
  disabledMessage: (
    <FormattedMessage
      id="ce258c7c-d856-420a-b622-bd92a8c961d9"
      defaultMessage="Proof is currently only available for eSign signatures."
    />
  ),
  proofSectionSubcopy: (
    <FormattedMessage
      id="94ac5162-207d-4e99-920d-05287fc8be69"
      defaultMessage="Select at least one identity verification option."
    />
  ),
};

export type SignerUploadFormValues = {
  id: string;
  active: boolean;
  name: string;
  completionRequirement: OrganizationApiKeyCompletionRequirement;
  minSigners: string;
  maxSigners: string;
  payer: OrganizationApiKeyPayer;
  proofRequirement?: ProofRequirementField;
};

export function SignerUploadTxnType({
  form,
  canRequireVerificationOfFact,
}: {
  form: UseFormReturn<SignerUploadFormValues>;
  canRequireVerificationOfFact: boolean;
}) {
  const { register } = form;
  const radioGroupId = useId();
  const ps1583AttestationEnabled = useFeatureFlag(PS1583_ATTESTATION);
  return (
    <FormCard
      cardTitle={
        <span id={radioGroupId}>
          <FormattedMessage
            id="7a2ccfbd-5665-4575-8161-7aa2ac4e9b1b"
            defaultMessage="What kind of signatures are required?"
          />
        </span>
      }
      extraSpacing
    >
      <FormRow>
        <RadioGroup aria-labelledby={radioGroupId}>
          <RadioLabel
            label={
              <FormattedMessage
                id="641606ae-5b4e-4682-9c5d-995c40faa49f"
                defaultMessage="Notarization"
              />
            }
            radio={
              <RadioInput
                value={OrganizationApiKeyCompletionRequirement.NOTARIZATION}
                {...register("completionRequirement")}
              />
            }
          />
          {canRequireVerificationOfFact && (
            <RadioLabel
              label={
                ps1583AttestationEnabled ? (
                  <FormattedMessage
                    id="b7026ad2-fa8a-489c-96a3-7f33228cc093"
                    defaultMessage="Notarization without SSN"
                  />
                ) : (
                  <FormattedMessage
                    id="d65e6096-2e8e-42be-92db-c8ce17292dd7"
                    defaultMessage="Verification of fact"
                  />
                )
              }
              radio={
                <RadioInput
                  value={OrganizationApiKeyCompletionRequirement.PROOFING}
                  {...register("completionRequirement")}
                />
              }
            />
          )}

          <RadioLabel
            label={
              <FormattedMessage id="054019a1-9add-4a9a-9b98-0bdaf65dbc84" defaultMessage="eSign" />
            }
            radio={
              <RadioInput
                value={OrganizationApiKeyCompletionRequirement.ESIGN}
                {...register("completionRequirement")}
              />
            }
          />
        </RadioGroup>
      </FormRow>
    </FormCard>
  );
}

export function SignerUploadSigners({ form }: { form: UseFormReturn<SignerUploadFormValues> }) {
  const { watch, register } = form;
  const completionRequirement = watch("completionRequirement");
  const radioGroupId = useId();

  return (
    <FormCard
      cardTitle={
        <span id={radioGroupId}>
          <FormattedMessage
            id="efce5ae1-d8d3-44ed-ab17-5994672b0b1e"
            defaultMessage="How many signers?"
          />
        </span>
      }
      extraSpacing
    >
      <FormRow>
        <RadioGroup aria-labelledby={radioGroupId}>
          <RadioLabel
            label={
              <FormattedMessage id="ff46c8cb-f65b-46c0-9f5e-df74a2992839" defaultMessage="One" />
            }
            radio={<RadioInput value="1" {...register("maxSigners")} />}
          />
          {completionRequirement !== OrganizationApiKeyCompletionRequirement.ESIGN && (
            <RadioLabel
              label={
                <FormattedMessage id="71c80e87-a8c1-4719-9049-a1014d8ce2e1" defaultMessage="Two" />
              }
              radio={<RadioInput value="2" {...register("maxSigners")} />}
            />
          )}
        </RadioGroup>
      </FormRow>
    </FormCard>
  );
}
