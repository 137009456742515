import { useState, type ComponentProps } from "react";
import { useParams, useNavigate, useLocation, Navigate } from "react-router-dom";
import { getMinutes, getHours } from "date-fns";
import { defineMessages, useIntl } from "react-intl";

import { browserTimeZone } from "util/date";
import store from "redux/store";
import { addError } from "redux/actions/errors";
import { captureException } from "util/exception";
import { EVENT } from "constants/analytics";
import { ERROR_TYPES } from "constants/errors";
import { segmentTrack } from "util/segment";
import { useMutation } from "util/graphql";
import { QueryWithLoading, isGraphQLError } from "util/graphql/query";
import { useRawMutation } from "util/graphql/mutation";
import { usePermissions } from "common/core/current_user_role";
import TransactionEditQuery, {
  type TransactionEdit as TransactionEditType,
  type TransactionEditVariables,
  type TransactionEdit_transaction_OrganizationTransaction as Transaction,
  type TransactionEdit_organization_Organization as Organization,
  type TransactionEdit_organization_Organization_activePricingPlan_prices as OrganizationPrices,
  type TransactionEdit_viewer_user as User,
  type TransactionEdit_transaction_OrganizationTransaction_organizationTransactionWitnesses as Witness,
  type TransactionEdit_transaction_OrganizationTransaction_document_bundle_instructions as Instruction,
  type TransactionEdit_transaction_OrganizationTransaction_contacts as Contact,
} from "common/transactions/graphql/queries/edit_query.graphql";
import UpdateOrgTransactionMutation from "common/transactions/graphql/mutations/update_org_transaction_mutation.graphql";
import UpsertDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/upsert_document_bundle_instruction_mutation.graphql";
import DeleteDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/delete_document_bundle_instruction_mutation.graphql";
import SendOrganizationTransactionMutation from "common/transactions/graphql/mutations/send_organization_transaction_mutation.graphql";
import { DEFAULT_TRANSACTION_NAME } from "constants/transaction";
import { denormalizeDatetime, normalizeDatetime } from "util/transaction";
import { TRANSACTION_PATH, transactionDetailsRoute, transactionEditRouteV3 } from "util/routes";
import { encodeCustomerSigners } from "util/customer_signers";
import { useActiveOrganization } from "common/account/active_organization";
import { newPathWithPreservedSearchParams } from "util/location";
import {
  AuthTypes,
  SigningScheduleTypes,
  ProofRequirementMfa,
  TransactionMembershipRoles,
  Payer,
  OrganizationTypeEnum,
  type AddressType,
  type SignerCapacityInputType,
  OrganizationTransactionVariant,
} from "graphql_globals";
import { useFeatureFlag } from "common/feature_gating";
import {
  TRANSACTION_LEVEL_PAYER_CONFIGURATION,
  PROOF_TRANSACTIONS,
  REAL_ESTATE_ESIGN,
  REAL_ESTATE_PROOF,
} from "constants/feature_gates";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import {
  useTransactionCreationV3,
  TRANSACTION_CREATION_V3_OPT_OUT_TAG,
} from "common/transaction_creation/v3/detection";
import { useGetConfigId } from "common/transaction_creation/v3/config";
import { getEventFromFormV2, pendoTrack } from "util/pendo";
import { TransactionErrorRedirect } from "common/transaction_creation/v3/form";

import EsignTransactionEditForm from "./esign";
import TransactionEditForm from "./edit";
import RealEstateEsignEditForm from "./real_estate_esign";

const messages = defineMessages({
  genericError: {
    id: "7d8cad82-b058-4f52-ae49-432e68508d57",
    defaultMessage: "Something went wrong",
  },
});

const TRANSACTION_EDIT_QUERY_USER_TAG_LIST = [TRANSACTION_CREATION_V3_OPT_OUT_TAG];

export type CustomerSignerFromForm = {
  id: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string | null;
  address: AddressType | null;
  phoneCountryCode: string | undefined;
  phoneNumber: string | undefined;
  signatoryTitle: string | null;
  capacities: SignerCapacityInputType[];
  ial2Proof: boolean;
  smsAuthProof: boolean;
  kbaProof: boolean;
  recipientGroup: {
    sharedInboxEmail: string | null;
  } | null;
};

export type WriteUpdateMutationArgs = {
  signingScheduleType: SigningScheduleTypes | null;
  additionalSealFee: OrganizationPrices["additionalSeal"];
  notarizationFee: OrganizationPrices["notaverseFirstSeal"];
  transactionFees: "custom" | "default";
  transactionName: Transaction["name"];
  transactionType: Transaction["transaction_type"];
  externalId: Transaction["externalId"];
  signerDetails: CustomerSignerFromForm[];
  organizationTransactionWitnesses: Transaction["organizationTransactionWitnesses"];
  secondaryId: Transaction["secondary_id_required"];
  subjectLine: Transaction["message_subject"];
  customerNote: Transaction["message"];
  emailSignature: Transaction["message_signature"];
  payer: Transaction["payer"];
  activationDate: Date | null;
  activationTimezone: string | null;
  expirationDate: Date | null;
  expirationTimezone: Transaction["expirationTimezone"];
  notaryMeetingDate: Date | null;
  notaryMeetingTime: Date | null;
  notaryMeetingTimezone: Transaction["notaryMeetingTimezone"];
  notarizeCloserOverride: Transaction["notarizeCloserOverride"];
  closerAssigneeId: string | null;
  personallyKnownToNotary: boolean;
  smsAuthenticationRequired: boolean;
  entityName: Transaction["entityName"];
  loanNumber: string | null;
  fileNumber: string | null;
  recallReason: string | null;
  pointsOfContact: Contact[];
  isTransactionForEntity: boolean;
};

// these fields aren't used for update mutation, but still used by components in the form
export type FormValues = WriteUpdateMutationArgs & {
  notaryNote: string;
  cosigner: boolean;
  recalled: boolean;
  hasPointsOfContact: boolean;
  isRecalled: boolean;
};

type Props = {
  data: TransactionEditType;
  variant: OrganizationTransactionVariant | null;
};

function TransactionContentContainer({ data, variant }: Props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const proofTransactionsEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  const transactionLevelPayerEnabled = useFeatureFlag(TRANSACTION_LEVEL_PAYER_CONFIGURATION);

  const sendOrganizationTransactionMutateFn = useMutation(SendOrganizationTransactionMutation);

  const [updateTransactionMutateFn, { loading: updateTransactionMutationLoading }] = useRawMutation(
    UpdateOrgTransactionMutation,
  );

  const [upsertBundleInstructionMutateFn, { loading: upsertBundleInsructionLoading }] =
    useRawMutation(UpsertDocumentBundleInstructionMutation);

  const [deleteDocumentBundleInstructionMutateFn, { loading: deleteBundleInstructionLoading }] =
    useRawMutation(DeleteDocumentBundleInstructionMutation);

  const mutationLoading = Boolean(
    updateTransactionMutationLoading ||
      upsertBundleInsructionLoading ||
      deleteBundleInstructionLoading,
  );
  const isEsign = variant === OrganizationTransactionVariant.ESIGN;
  const isProof = variant === OrganizationTransactionVariant.PROOF;
  const realEstateEsignEnabled = useFeatureFlag(REAL_ESTATE_ESIGN) && isEsign;
  const realEstateProofEnabled = useFeatureFlag(REAL_ESTATE_PROOF) && isProof;

  const transaction = data.transaction as Transaction;
  const organization = data.organization as Organization;
  const viewer = data.viewer;
  const user = viewer.user as User;
  const userTimezone = user.timezone;
  const transactionName = transaction.name === DEFAULT_TRANSACTION_NAME ? null : transaction.name;

  const defaultTimezone = (timezone: User["timezone"]) => {
    return timezone || userTimezone || browserTimeZone();
  };

  // Also make sure that existing transactions from v1 can be loaded in v2
  const notaryMeetingTimezone = defaultTimezone(transaction.notaryMeetingTimezone);

  const notaryMeetingTime = transaction.notaryMeetingTime
    ? denormalizeDatetime(transaction.notaryMeetingTime, notaryMeetingTimezone!)
    : null;
  const windowTimezone = defaultTimezone(transaction.expirationTimezone);
  const activationTime = transaction.activationTime
    ? denormalizeDatetime(transaction.activationTime, windowTimezone!)
    : null;
  const expirationTime = transaction.expirationTime
    ? denormalizeDatetime(transaction.expirationTime, windowTimezone!)
    : null;

  const customerSigners = transaction.customerSigners;
  const organizationTransactionWitnesses = transaction.organizationTransactionWitnesses;
  const signerDetails = customerSigners.map((customerSigner) => ({
    id: customerSigner.id,
    firstName: customerSigner.first_name,
    middleName: customerSigner.middle_name,
    lastName: customerSigner.last_name,
    // if is recipient group, override the email value to be the same as the sharedInboxEmail of the recipientGroup
    // so that form validations for signerDetails pass
    email: customerSigner.recipientGroup
      ? customerSigner.recipientGroup.sharedInboxEmail
      : customerSigner.email,
    address: customerSigner.address.country ? customerSigner.address : null,
    phoneCountryCode: customerSigner.phone?.country_code,
    phoneNumber: customerSigner.phone?.number,
    capacities: customerSigner.capacities,
    signatoryTitle: customerSigner.signatoryTitle,
    recipientGroup: customerSigner.recipientGroup,
    ial2Proof: false, // handled in proof child components
    smsAuthProof: false, // handled in proof child components
    kbaProof: false, // handled in proof child components
  }));
  const currentNotaryNote = transaction.document_bundle?.instructions[0];
  const transactionPricing = transaction.businessMembership?.pricingPlan;
  const accountPricing = organization.activeTier.prices;
  // initial data set from accountPricing if transaction prices are null
  const notarizationFee = transactionPricing
    ? transactionPricing.prices.notaverse_first_seal
    : accountPricing.notaverseFirstSeal;
  const additionalSealFee = transactionPricing
    ? transactionPricing.prices.notaverse_additional_seal
    : accountPricing.notaverseAdditionalSeal;

  const transactionRadioValue =
    notarizationFee !== accountPricing.notaverseFirstSeal ||
    additionalSealFee !== accountPricing.notaverseAdditionalSeal
      ? "custom"
      : "default";

  const getSmsAuthenticationRequired = () => {
    const smsRequired = transaction.authenticationRequirement === AuthTypes.SMS;
    // Proof transaction or no default SMS requirement
    if (isProof || !smsRequired) {
      return false;
    }
    // Esign when proof txns feature on
    else if (isEsign && proofTransactionsEnabled) {
      return false;
    }
    // Esign when proof txns feature off
    else if (isEsign && !proofTransactionsEnabled) {
      return true;
    }
    // Notarization
    return true;
  };

  const [initialFormData] = useState<FormValues>({
    transactionName,
    transactionType: transaction.transaction_type,
    entityName: transaction.entityName,
    isTransactionForEntity: Boolean(transaction.entityName),
    notaryNote: currentNotaryNote ? currentNotaryNote.text : "",
    externalId: transaction.externalId,
    signerDetails,
    organizationTransactionWitnesses,
    secondaryId: transaction.secondary_id_required,
    customerNote: transaction.message,
    subjectLine: transaction.message_subject,
    emailSignature: transaction.message_signature,
    cosigner: signerDetails.length > 1,
    payer: transaction.payer,
    signingScheduleType: notaryMeetingTime
      ? SigningScheduleTypes.DATE
      : expirationTime || activationTime
        ? SigningScheduleTypes.WINDOW
        : null,
    activationDate: activationTime,
    activationTimezone: windowTimezone,
    expirationDate: expirationTime,
    expirationTimezone: windowTimezone,
    notaryMeetingTimezone,
    notaryMeetingTime,
    notaryMeetingDate: notaryMeetingTime,
    personallyKnownToNotary: Boolean(
      transaction.customerSigners.some((customer) => customer.personallyKnownToNotary),
    ),
    closerAssigneeId: transaction.closer?.id ?? null,
    notarizeCloserOverride: transaction.notarizeCloserOverride,
    smsAuthenticationRequired: getSmsAuthenticationRequired(),
    notarizationFee: notarizationFee / 100,
    additionalSealFee: additionalSealFee / 100,
    transactionFees: transactionRadioValue,
    loanNumber: transaction.loanNumber,
    fileNumber: transaction.fileNumber,
    recallReason: transaction.recallReason,
    recalled: transaction.recalled,
    pointsOfContact: transaction.contacts,
    hasPointsOfContact: transaction.contacts.length > 0,
    isRecalled: transaction.recalled,
  });

  function getForm() {
    if (
      (realEstateEsignEnabled || realEstateProofEnabled) &&
      organization.organizationType === OrganizationTypeEnum.TITLE_AGENCY
    ) {
      if (isProof) {
        return "real_estate_proof";
      }
      return "real_estate_esign";
    }
    if (isEsign) {
      return "esign";
    }
    if (isProof) {
      return "proof";
    }
    return "notarization";
  }
  const form = getForm();

  function handleSave(data: WriteUpdateMutationArgs) {
    return writeUpdateMutation(data);
  }

  function handleSaveAndClose(data: WriteUpdateMutationArgs) {
    handleSave(data).then(() => {
      navigate(newPathWithPreservedSearchParams(TRANSACTION_PATH));
      segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SAVE_EXIT, {
        organization_transaction_id: transaction.id,
      });
    });
  }

  function handleClose() {
    navigate(newPathWithPreservedSearchParams(TRANSACTION_PATH));
  }

  function handleSend(data: WriteUpdateMutationArgs) {
    // There's a check that payment is specified for standard transactions in the edit component
    // before calling this function. This check is not required for eSign v1.
    writeUpdateMutation(data, true)
      .then(() => {
        return sendOrganizationTransactionMutateFn({
          variables: {
            input: {
              id: transaction.id,
            },
          },
        });
      })
      .then(() => {
        segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_SEND, {
          organization_transaction_id: transaction.id,
          form,
          form_version: 2,
        });
        pendoTrack(getEventFromFormV2(form), {
          form: form.toLowerCase(),
          formVersion: 2,
        });
        const navigateTo = "/transaction/records";
        navigate(newPathWithPreservedSearchParams(navigateTo));
      })
      .catch((error) => {
        const errorString =
          ((isGraphQLError(error) && error.graphQLErrors[0]?.message) || undefined) ??
          intl.formatMessage(messages.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
        if (!isGraphQLError(error)) {
          captureException(error);
        }
      });
  }

  async function handleSignNow(data: WriteUpdateMutationArgs) {
    try {
      await writeUpdateMutation(data, true);
      await sendOrganizationTransactionMutateFn({
        variables: {
          input: {
            id: transaction.id,
          },
        },
      });
      redirectToSubdomain(
        AppSubdomains.customer,
        transaction.document_bundle?.id ? `/bundle/${transaction.document_bundle.id}/prepare` : "/",
      );
    } catch (error) {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        const errorString =
          error.graphQLErrors[0].message || intl.formatMessage(messages.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(error);
      }
    }
  }

  function getDisplayName(transactionName: Transaction["name"]) {
    if (transactionName) {
      return transactionName;
    }
    const { node } = transaction.document_bundle?.documents.edges[0] || {};
    return node?.name;
  }

  function handleUpdateNotaryInstruction(instruction: Instruction) {
    return upsertBundleInstructionMutateFn({
      variables: {
        input: {
          documentBundleId: transaction.document_bundle?.id,
          text: instruction.text,
          organizationId: organization.id,
          documentBundleInstructionId: instruction.id,
        },
      },
      update(cache, { data }) {
        if (instruction.id) {
          return;
        }
        const queryData = cache.readQuery<TransactionEditType, TransactionEditVariables>({
          query: TransactionEditQuery,
          variables: {
            transactionId: transaction.id,
            organizationId: organization.id,
            userTagList: TRANSACTION_EDIT_QUERY_USER_TAG_LIST,
          },
        });
        const cachedTransaction = queryData!.transaction as Transaction;
        const cachedDocumentBundle = cachedTransaction.document_bundle;
        const cachedInstructions = cachedDocumentBundle!.instructions;

        const newTransaction = {
          ...cachedTransaction,
          document_bundle: {
            ...cachedDocumentBundle,
            instructions: [
              ...cachedInstructions,
              data?.upsertDocumentBundleInstruction?.documentBundleInstruction,
            ],
          },
        };
        cache.writeQuery({
          query: TransactionEditQuery,
          variables: {
            transactionId: transaction.id,
            organizationId: organization.id,
            userTagList: TRANSACTION_EDIT_QUERY_USER_TAG_LIST,
          },
          data: { ...queryData, transaction: newTransaction },
        });
      },
    }).catch((error) => {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        const errorString =
          error.graphQLErrors[0].message || intl.formatMessage(messages.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(error);
      }
    });
  }

  function handleDeleteNotaryInstruction(id: Instruction["id"]) {
    return deleteDocumentBundleInstructionMutateFn({
      variables: { input: { id } },
    }).catch((error) => {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        const errorString =
          error.graphQLErrors[0].message || intl.formatMessage(messages.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(error);
      }
    });
  }

  function writeUpdateMutation(
    {
      signingScheduleType,
      additionalSealFee,
      notarizationFee,
      transactionFees,
      transactionName,
      transactionType,
      externalId,
      signerDetails,
      organizationTransactionWitnesses,
      secondaryId,
      subjectLine,
      customerNote,
      emailSignature,
      payer,
      activationDate,
      expirationDate,
      expirationTimezone,
      notaryMeetingDate,
      notaryMeetingTime,
      notaryMeetingTimezone,
      notarizeCloserOverride,
      closerAssigneeId,
      personallyKnownToNotary,
      smsAuthenticationRequired,
      entityName,
      loanNumber,
      fileNumber,
      recallReason,
      pointsOfContact,
    }: WriteUpdateMutationArgs,
    isSending = false,
  ) {
    // if closer override is on, personally known must be off
    const personallyKnownInput = !notarizeCloserOverride && personallyKnownToNotary;
    // We take the id's from the transaction customerSigners and map them onto the form customerSigners
    const formattedCustomerSigners = signerDetails.map(
      (signer: CustomerSignerFromForm, index: number) => {
        return {
          ...signer,
          // Id will be undefined initially for additional signers until they are saved
          id: transaction.customerSigners[index]?.id,
          personallyKnownToNotary: index === 0 ? Boolean(personallyKnownInput) : false,
          proofRequirement: isProof
            ? {
                ca: signer.ial2Proof ? { selfie: true } : null,
                mfa: signer.smsAuthProof ? { type: ProofRequirementMfa.SMS } : null,
                kba: Boolean(signer.kbaProof),
              }
            : null,
          // if this is a signing group clear the email value so it is not updated on the server
          ...(signer.recipientGroup ? { email: null } : {}),
        };
      },
    );

    const accountPricing = organization.activeTier.prices;
    const isDefault = transactionFees === "default";
    const notaverseFirstSeal = isDefault
      ? accountPricing.notaverseFirstSeal / 100
      : notarizationFee;
    const notaverseAdditionalSeal = isDefault
      ? accountPricing.notaverseAdditionalSeal / 100
      : additionalSealFee;
    const userTimezone = user.timezone;
    const defaultTimezone = (timezone: User["timezone"]) => {
      return timezone || userTimezone || browserTimeZone();
    };

    const formattedOrganizationTransactionWitnesses = organizationTransactionWitnesses.map(
      (witness: Witness, index: number) => {
        return {
          ...witness,
          id: witness.id || transaction.organizationTransactionWitnesses[index]?.id,
        };
      },
    );

    const transactionTimezone = defaultTimezone(notaryMeetingTimezone);

    const notaryMeetingDatetime =
      notaryMeetingTime && notaryMeetingDate
        ? normalizeDatetime({
            hour: getHours(notaryMeetingTime),
            minutes: getMinutes(notaryMeetingTime),
            date: notaryMeetingDate,
            timezone: transactionTimezone!,
          })
        : null;

    const windowTimezone = defaultTimezone(expirationTimezone);
    const activationDatetime = activationDate
      ? normalizeDatetime({ hour: 0, minutes: 0, date: activationDate, timezone: windowTimezone! })
      : null;
    const expirationDatetime = expirationDate
      ? normalizeDatetime({ hour: 0, minutes: 0, date: expirationDate, timezone: windowTimezone! })
      : null;

    const authRequirement =
      organization.defaultAuthenticationRequirement === AuthTypes.SMS
        ? smsAuthenticationRequired
          ? AuthTypes.SMS
          : AuthTypes.NONE
        : transaction.authenticationRequirement;

    const signingSchedule =
      signingScheduleType === SigningScheduleTypes.DATE
        ? {
            activationTime: null,
            activationTimezone: null,
            expiry: null,
            expiryTimezone: null,
            notaryMeetingTime: notaryMeetingDatetime,
            notaryMeetingTimezone: notaryMeetingDatetime ? notaryMeetingTimezone : null,
          }
        : signingScheduleType === SigningScheduleTypes.WINDOW
          ? {
              activationTime: activationDatetime,
              activationTimezone: windowTimezone,
              expiry: expirationDatetime,
              expiryTimezone: windowTimezone,
              notaryMeetingTime: null,
              notaryMeetingTimezone: null,
            }
          : {
              activationTime: null,
              activationTimezone: null,
              expiry: null,
              expiryTimezone: null,
              notaryMeetingTime: null,
              notaryMeetingTimezone: null,
            };

    const transactionPayer = transactionLevelPayerEnabled ? payer : organization.defaultPayer;
    const transactionPayerIsOrg = transactionPayer === Payer.ORGANIZATION;
    const inputData = {
      input: {
        id: transaction.id,
        organizationId: organization.id,
        memberships: [
          {
            organizationId: organization.id,
            role: TransactionMembershipRoles.BUSINESS,
            prices: {
              notaverseFirstSeal: transactionPayerIsOrg ? 0 : notaverseFirstSeal * 100,
              notaverseAdditionalSeal: transactionPayerIsOrg ? 0 : notaverseAdditionalSeal * 100,
            },
          },
        ],
        transaction: {
          name: isSending ? getDisplayName(transactionName) : transactionName,
          transactionType,
          secondaryIdRequired: secondaryId,
          entityName,
          message: customerNote,
          messageSubject: subjectLine,
          messageSignature: emailSignature,
          payer: transactionPayer,
          requiredAuth: authRequirement,
          externalId,
          loanNumber,
          fileNumber,
          recallReason,
        },
        customers: encodeCustomerSigners(formattedCustomerSigners),
        signingSchedule,
        notarizeCloserOverride: Boolean(notarizeCloserOverride),
        closerAssigneeId:
          notarizeCloserOverride || !closerAssigneeId || closerAssigneeId === "unassigned"
            ? null
            : closerAssigneeId,
        witnesses: formattedOrganizationTransactionWitnesses.map((witness: Witness) => {
          return {
            id: witness.id,
            email: witness.email,
            phoneNumber: witness.phoneNumber,
            firstName: witness.firstName,
            middleName: witness.middleName,
            lastName: witness.lastName,
          };
        }),
        contacts: pointsOfContact.map((contact) => ({
          id: contact.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          phoneNumber: contact.phoneNumber,
          email: contact.email,
          role: contact.role,
          title: contact.title,
          shownToSigner: contact.shownToSigner,
          // default to true because we want all contacts in esign/proof to be able to view (through verify portal)
          accessToTransaction: true,
        })),
      },
    };

    const updateMutationPromise = updateTransactionMutateFn({
      variables: inputData,
      refetchQueries: [
        {
          query: TransactionEditQuery,
          variables: {
            transactionId: transaction.id,
            organizationId: organization.id,
            userTagList: TRANSACTION_EDIT_QUERY_USER_TAG_LIST,
          },
        },
      ],
    });

    return updateMutationPromise.catch((error) => {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        const errorString =
          error.graphQLErrors[0].message || intl.formatMessage(messages.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(error);
      }
    });
  }

  const { employee } = transaction;

  switch (form) {
    case "real_estate_esign":
    case "real_estate_proof":
      return (
        <RealEstateEsignEditForm
          isProof={isProof}
          initialData={initialFormData}
          organization={organization}
          viewer={viewer}
          transaction={transaction}
          onSaveAndClose={handleSaveAndClose}
          onSave={handleSave}
          onSend={handleSend}
          disabledSubmit={mutationLoading}
        />
      );
    case "esign":
    case "proof":
      return (
        <EsignTransactionEditForm
          isProof={isProof}
          initialData={initialFormData}
          organization={organization}
          viewer={viewer}
          employee={employee}
          transaction={transaction}
          onSaveAndClose={handleSaveAndClose}
          onSave={handleSave}
          onSend={handleSend}
          disabledSubmit={mutationLoading}
          openAnnotateModalAfterDocumentsUploaded
        />
      );
    default:
      return (
        <TransactionEditForm
          initialData={initialFormData}
          hasPermissionFor={hasPermissionFor}
          organization={organization}
          viewer={viewer}
          employee={employee}
          transaction={transaction}
          onSaveAndClose={handleSaveAndClose}
          onSave={handleSave}
          onSend={handleSend}
          onClose={handleClose}
          onSignNow={handleSignNow}
          disabledSubmit={mutationLoading}
          onDeleteNotaryInstruction={handleDeleteNotaryInstruction}
          onUpdateNotaryInstruction={handleUpdateNotaryInstruction}
          openAnnotateModalAfterDocumentsUploaded
        />
      );
  }
}

function WithV3Check(props: ComponentProps<typeof TransactionContentContainer>) {
  const { data } = props;
  const configId = useGetConfigId(props.variant, data.organization as Organization);

  return useTransactionCreationV3(data.viewer.user!) ? (
    <Navigate replace to={transactionEditRouteV3((data.transaction as Transaction).id, configId)} />
  ) : (
    <TransactionContentContainer {...props} />
  );
}

function TransactionContainer() {
  const [activeOrganizationId] = useActiveOrganization();
  const { transactionId } = useParams();
  const { pathname } = useLocation();

  return (
    <QueryWithLoading
      query={TransactionEditQuery}
      variables={{
        transactionId: transactionId!,
        organizationId: activeOrganizationId!,
        userTagList: TRANSACTION_EDIT_QUERY_USER_TAG_LIST,
      }}
    >
      {({ data, error }) => {
        if (!data?.transaction) {
          return <TransactionErrorRedirect error={error} />;
        }

        const transaction = data.transaction as Transaction;

        if (!transaction.editable) {
          return <Navigate replace to={transactionDetailsRoute(transaction.id)} />;
        }

        function getVariant() {
          // For business transactions on creation, the transactionVariant on creation comes back as
          // NOTARIZATION for esign and proof txns until they're saved, so we have to check the path name
          // instead of variant and override it to ensure the right config is shown. This will need to be
          // in place until v2 is cleaned up.
          if (pathname.startsWith("/transaction/esign")) {
            return OrganizationTransactionVariant.ESIGN;
          }

          if (pathname.startsWith("/transaction/proof")) {
            return OrganizationTransactionVariant.PROOF;
          }

          if (transaction.transactionVariant === OrganizationTransactionVariant.CERTIFY) {
            return transaction.transactionVariant;
          }

          // OrganizationTransactionVariant.VERIFICATION_OF_FACT uses notarization form
          return OrganizationTransactionVariant.NOTARIZATION;
        }

        return <WithV3Check data={data} variant={getVariant()} />;
      }}
    </QueryWithLoading>
  );
}

export default TransactionContainer;
