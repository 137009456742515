import type { ComponentProps } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { getDetailsMessage, getAnecdoteMessage } from "util/history";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import { LongFormattedDateTime } from "common/core/format/date";
import { EVENTS } from "constants/history";

type Props = {
  separated: boolean;
  item: { actionMetadata: string | null; createdAt: string | null };
  completionRequirements: ComponentProps<
    typeof CompletionRequirementsText
  >["completionRequirements"];
};

function getEventMessage(
  event: string | undefined,
  completionRequirements: Props["completionRequirements"],
) {
  switch (event) {
    case EVENTS.MEETING_SUCCESS:
      return (
        <CompletionRequirementsText
          stringId="documentCompleteHistoryMessage"
          completionRequirements={completionRequirements}
        />
      );
    case EVENTS.KBA_IN_PROGRESS:
      return (
        <FormattedMessage
          id="d0f66364-7f67-4b9c-abb0-956ce7932dc5"
          defaultMessage="Identity Verification In Progress"
        />
      );
    case EVENTS.KBA_FAILURE:
      return (
        <FormattedMessage
          id="1ed1e346-2f60-4bef-8653-86c0ca05e95b"
          defaultMessage="Couldn't Confirm Signer's Identity"
        />
      );
    case EVENTS.TECHNICAL_DIFFICULTY:
      return (
        <FormattedMessage
          id="ac40aa3a-e976-42a3-8681-248a0a3945be"
          defaultMessage="Signer Failed Connection Test"
        />
      );
    case EVENTS.MEETING_REQUESTED:
      return (
        <FormattedMessage
          id="e0b92efb-6116-4e38-a4d4-38060c490470"
          defaultMessage="Meeting Requested"
        />
      );
    case EVENTS.SIGNER_ENDED_MEETING:
      return (
        <FormattedMessage
          id="cad13f55-f4a5-423a-83f3-2f4efcacbe83"
          defaultMessage="Signer Exited Meeting Queue"
        />
      );
    case EVENTS.MEETING_STARTED:
      return (
        <FormattedMessage
          id="5a5d9bed-d563-47dc-b843-10e0f2ffaa88"
          defaultMessage="Meeting Started"
        />
      );
    case EVENTS.NOTARY_TERMINATED_MEETING:
    case "notary_ended_meeting":
      return (
        <FormattedMessage
          id="41b5d697-acfa-4201-bdcc-0b8f14e58379"
          defaultMessage="Notary Meeting Cancelled"
        />
      );
    case EVENTS.NOTARY_SUBMITTED_REPORT:
      return (
        <FormattedMessage
          id="446bf08f-6639-41f1-bc52-7bc25c792db0"
          defaultMessage="Notary Submitted Termination Report"
        />
      );
    case EVENTS.CONNECTION_LOST:
      return (
        <FormattedMessage
          id="4930c638-fae7-416b-8456-d689b7759fb0"
          defaultMessage="Signer closed or lost connection with the application."
        />
      );
    case EVENTS.PAYMENT_FAILED:
      return (
        <FormattedMessage
          id="17d03d15-56c1-454b-a7d1-a6c5890c29cf"
          defaultMessage="Payment Failed"
        />
      );
    default:
      return null;
  }
}

function HistoryItem({ separated, item, completionRequirements }: Props) {
  const intl = useIntl();
  const {
    event,
    details,
    anecdote,
    termination_reasons: terminationReasons,
    termination_anecdotes: terminationAnecdotes,
  } = JSON.parse(item.actionMetadata!) as {
    event: string | undefined;
    details: unknown;
    anecdote: unknown;
    termination_reasons: unknown;
    termination_anecdotes: unknown;
  };

  const detailsMessage = getDetailsMessage(intl, details, terminationReasons);
  const anecdoteMessage = getAnecdoteMessage(intl, anecdote, terminationAnecdotes);
  const eventMessage = getEventMessage(event, completionRequirements);
  return (
    <div
      className={classnames(
        "TransactionHistory-item",
        separated && "TransactionHistory-item-separated",
      )}
    >
      {Boolean(eventMessage) && (
        <div>
          <div className="TransactionHistory-row-header">
            <FormattedMessage id="07d19bec-db9f-4c36-a4e5-2d5dc46f336b" defaultMessage="Event" />
          </div>
          <div className="TransactionHistory-row-content">{eventMessage}</div>
          <div className="TransactionHistory-row-timestamp">
            <LongFormattedDateTime value={item.createdAt} />
          </div>
        </div>
      )}
      {Boolean(detailsMessage) && (
        <div>
          <div className="TransactionHistory-row-header">
            <FormattedMessage id="551bb3e9-6303-4d6c-a747-0646eff86694" defaultMessage="Details" />
          </div>
          <div className="TransactionHistory-row-content">{detailsMessage}</div>
        </div>
      )}
      {Boolean(anecdoteMessage) && (
        <div>
          <div className="TransactionHistory-row-header">
            <FormattedMessage id="a61497af-30f0-4a44-a9b8-818826d8dd7f" defaultMessage="Anecdote" />
          </div>
          <div className="TransactionHistory-row-content">{anecdoteMessage}</div>
        </div>
      )}
    </div>
  );
}

export default HistoryItem;
