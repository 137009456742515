import "./index.scss";

import { useState, type ReactNode } from "react";

type Props = {
  children: ReactNode;
  expansion: ReactNode;
};

function ExpandableHelp({ children, expansion }: Props) {
  const [show, setShow] = useState(false);
  return (
    <div className="NotaryProfileWizardExandableHelp">
      <div onClick={() => setShow(!show)}>{children}</div>
      {show && <div>{expansion}</div>}
    </div>
  );
}

export default ExpandableHelp;
