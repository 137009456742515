import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";

import { AnchorText, DeleteButton, Footer } from "../additional_tools";
import Styles from "./index.module.scss";

function getHeaderIcon(annotationType: string) {
  switch (annotationType) {
    case "CheckmarkAnnotation":
      return {
        icon: "checkmark",
        name: (
          <FormattedMessage id="41889a1a-a3e9-4c8a-99e4-f4a6d88ea58f" defaultMessage="Checkmark" />
        ),
      };
    case "TextAnnotation":
      return {
        icon: "text",
        name: (
          <FormattedMessage id="f0d928b1-af79-47b7-9696-6095a6c1be67" defaultMessage="Text box" />
        ),
      };
    case "WhiteboxAnnotation":
      return {
        icon: "white-out",
        name: (
          <FormattedMessage id="bd9bad5b-d6ee-4c13-b5ff-6a2fbc8b740d" defaultMessage="Whiteout" />
        ),
      };
    default:
      return null;
  }
}

export function AnnotationMenu({
  annotation,
  onDelete,
  showAnchorTextTags,
}: {
  annotation: { id: string; __typename: string; documentAnchor?: { anchorText: string } };
  onDelete: (annotation: { id: string }) => void;
  showAnchorTextTags?: boolean;
}) {
  const headerIcon = getHeaderIcon(annotation.__typename);
  return (
    <>
      <div className={Styles.container} data-automation-id="annotation-menu">
        <div className={Styles.header}>
          {headerIcon && (
            <>
              <Icon className={Styles.headerIcon} name={headerIcon.icon} />
              {headerIcon.name}
            </>
          )}
        </div>
        {showAnchorTextTags && <AnchorText selectedAnnotation={annotation} />}
      </div>
      <Footer>
        <DeleteButton onClick={() => onDelete(annotation)} />
      </Footer>
    </>
  );
}
