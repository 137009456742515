import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  NotaryCapacityType,
  NotaryComplianceStatuses,
  NotaryOnboardingStatuses,
  NotaryProfileInvalidFields,
} from "graphql_globals";
import AppSubdomains from "constants/app_subdomains";
import { redirectToSubdomain } from "util/application_redirect";
import {
  isNotaryNSTCallReady,
  isNotaryODNCallReady,
  isNotaryProfileNotTerminatedFor,
} from "common/notary/capacity";
import { LevelInformationModal } from "common/notary/level";
import { NSTRoot } from "common/get_started/common/root";
import { NSTSection, TaskSection } from "common/get_started/common/section";
import {
  SetupNotaryProfileTaskItem,
  AddLogoTaskItem,
  SetupBillingTaskItem,
  AddCompanyNameTaskItem,
} from "common/get_started/common/task_item";
import {
  ProofAcademyItem,
  EasylinkLinkItem,
  SendHowToItem,
  SendClientMeetingsPanel,
} from "common/get_started/business/common";
import {
  NotaryToolsHowToItem,
  NotaryGrowRonHowToItem,
} from "common/get_started/common/how_to_item";
import { HowItWorksLinkItem, FaqLinkItem } from "common/get_started/common/link_item";
import JoinNodPanel from "common/notary/on_demand_queue/join_nod_panel";
import StartNODShiftPanel from "common/notary/workforce/shiftboard/start_shift_panel";
import { SETTINGS_PATH } from "util/routes";
import { usePermissions } from "common/core/current_user_role";

import Styles from "./notary_individual_contractor.module.scss";
import type {
  BusinessGetStarted_viewer as Viewer,
  BusinessGetStarted_organization_Organization as Organization,
} from "./business_get_started_query.graphql";
import { PanelMembershipItem } from "../common/panel_membership_item";
import NotaryStats from "./notary_stats";
import ProofCertificatePromo from "./proof_certificate_promo";

type Props = {
  viewer: Viewer;
  organization: Organization;
};

/** Get started page for any non-byon notary. */
function NotaryIndividualContractorGetStarted({ organization, viewer }: Props) {
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
  const settingsRedirect = () => redirectToSubdomain(AppSubdomains.business, SETTINGS_PATH);
  const user = viewer.user!;
  const notaryProfile = user.notaryProfile!;
  const isOnboardingComplete =
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED;
  const isCompliant = notaryProfile.complianceStatus === NotaryComplianceStatuses.COMPLIANT;
  const needsReview = notaryProfile.complianceStatus === NotaryComplianceStatuses.NEEDS_REVIEW;
  const { hasPermissionFor } = usePermissions();
  const canCreateEasyLinks = hasPermissionFor("createEasyLinks");

  return (
    <NSTRoot>
      <header>
        <h2 className={Styles.welcome}>
          <FormattedMessage
            id="8256e20b-59b2-480c-88b8-d9181972ccef"
            defaultMessage="Welcome back, {firstName}"
            values={{ firstName: user.firstName }}
          />
        </h2>
      </header>
      {!needsReview && (
        <NotaryStats viewer={viewer} handleLearnMoreAboutTiers={() => setIsLevelModalOpen(true)} />
      )}

      {isOnboardingComplete && (
        <div className={Styles.startWorkPanel}>
          <SendClientMeetingsPanel notaryProfile={notaryProfile} />

          {isNotaryODNCallReady(notaryProfile) ? (
            <StartNODShiftPanel
              onStartShift={() => redirectToSubdomain(AppSubdomains.business, "/on-demand-queue")}
              notaryProfile={notaryProfile}
            />
          ) : isNotaryProfileNotTerminatedFor(notaryProfile, NotaryCapacityType.ON_DEMAND) ? (
            <JoinNodPanel notaryProfile={notaryProfile} />
          ) : null}
        </div>
      )}

      {notaryProfile.panelMemberships.length > 0 && (
        <NSTSection
          title={
            <FormattedMessage
              id="a9787f81-8ce0-4391-914e-b152389998ac"
              defaultMessage="Your panels ({count})"
              values={{ count: notaryProfile.panelMemberships.length }}
            />
          }
        >
          {notaryProfile.panelMemberships.map((m) => (
            <PanelMembershipItem key={m.name} membership={m} />
          ))}
        </NSTSection>
      )}

      <ProofCertificatePromo notaryProfile={notaryProfile} showCTA />

      <TaskSection
        title={
          <FormattedMessage
            id="c80174d5-a22e-420c-a138-b55ea72d25d9"
            defaultMessage="Finish setting up your account"
          />
        }
      >
        {!isCompliant && (
          <SetupNotaryProfileTaskItem complete={false} notaryProfile={notaryProfile} />
        )}
        <AddLogoTaskItem onClick={settingsRedirect} complete={Boolean(organization.logoUrl)} />
        <SetupBillingTaskItem
          complete={
            Boolean(organization.paymentSpecified) ||
            !notaryProfile.capacities.some((capacity) =>
              capacity.validation.invalidFields.includes(
                NotaryProfileInvalidFields.INVALID_PAYMENT,
              ),
            )
          }
          onClick={() =>
            redirectToSubdomain(AppSubdomains.business, "/settings/billing/payment-settings")
          }
        />
        <AddCompanyNameTaskItem
          complete={Boolean(organization.name && organization.address.line1)}
          onClick={settingsRedirect}
        />
      </TaskSection>

      <NSTSection
        title={
          <FormattedMessage
            id="a9787f81-8ce0-4391-914e-b152389998ac"
            defaultMessage="Learn how to use the Proof platform"
          />
        }
      >
        <ProofAcademyItem notaryProfile={notaryProfile} />
        {isNotaryNSTCallReady(notaryProfile) ? <SendHowToItem /> : <NotaryGrowRonHowToItem />}
        <NotaryToolsHowToItem notaryProfile={notaryProfile} />
      </NSTSection>

      <NSTSection
        title={
          <FormattedMessage
            id="2a01f2f5-d0b7-4afb-b416-e8196f1e6161"
            defaultMessage="Other helpful links"
          />
        }
      >
        <HowItWorksLinkItem />
        {canCreateEasyLinks && <EasylinkLinkItem />}
        <FaqLinkItem />
      </NSTSection>

      {isLevelModalOpen && <LevelInformationModal onClose={() => setIsLevelModalOpen(false)} />}
    </NSTRoot>
  );
}

export default memo(NotaryIndividualContractorGetStarted);
