import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import { useForm } from "common/core/form";
import { OrganizationApiKeyCompletionRequirement, OrganizationApiKeyPayer } from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { QueryWithLoading } from "util/graphql/query";
import { useProofTransactionsEnabled } from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";
import { MULTISIGNER_EASYLINK } from "constants/feature_gates";
import { transformProofRequirementForSubmit } from "common/proof_requirements/common";

import { MultiSignerSelection, NameInput, PayerRadioGroup, ProofRequirement } from "../common";
import {
  type SignerUploadFormValues,
  SignerUploadSigners,
  SignerUploadTxnType,
  SIGNER_UPLOAD_PROOF_MESSAGE_PROPS,
} from "./common";
import { CreateEasylinkLayout } from "../create";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";

export function CreateSignerUploadEasylink(props: {
  notaryProfile: Parameters<typeof useProofTransactionsEnabled>[0];
}) {
  const proofTransactionsEnabled = useProofTransactionsEnabled(props.notaryProfile);
  const multiSignerEasylinkEnabled = useFeatureFlag(MULTISIGNER_EASYLINK);
  const [activeOrganizationId] = useActiveOrganization();
  const form = useForm<SignerUploadFormValues>({
    defaultValues: {
      completionRequirement: OrganizationApiKeyCompletionRequirement.NOTARIZATION,
      maxSigners: "1",
      minSigners: "1",
      payer: OrganizationApiKeyPayer.CUSTOMER,
    },
  });
  const { watch, setValue } = form;

  const completionRequirement = watch("completionRequirement");
  useEffect(() => {
    if (
      completionRequirement === OrganizationApiKeyCompletionRequirement.ESIGN &&
      !multiSignerEasylinkEnabled
    ) {
      setValue("maxSigners", "1");
    }
  }, [completionRequirement]);

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;
        const { canRequireVerificationOfFact, defaultAuthenticationRequirement } = organization;
        return (
          <CreateEasylinkLayout
            form={form}
            title={
              <FormattedMessage
                id="7ed349d6-f3a0-4a8b-a673-63ee6a339eb5"
                defaultMessage="Signer upload"
              />
            }
            getSubmitVariables={(values) => {
              return {
                input: {
                  name: values.name,
                  payer: values.payer,
                  completionRequirement: values.completionRequirement,
                  minSigners: parseInt(values.minSigners, 10) || 1,
                  maxSigners: parseInt(values.maxSigners, 10),
                  organizationId: activeOrganizationId!,
                  proofRequirement: transformProofRequirementForSubmit(values.proofRequirement),
                },
              };
            }}
          >
            <NameInput form={form} />
            <SignerUploadTxnType
              form={form}
              canRequireVerificationOfFact={canRequireVerificationOfFact}
            />
            {proofTransactionsEnabled && (
              <ProofRequirement
                disabled={
                  form.getValues().completionRequirement !==
                  OrganizationApiKeyCompletionRequirement.ESIGN
                }
                form={form}
                defaultAuthenticationRequirement={defaultAuthenticationRequirement}
                {...SIGNER_UPLOAD_PROOF_MESSAGE_PROPS}
              />
            )}
            {multiSignerEasylinkEnabled ? (
              <MultiSignerSelection form={form} />
            ) : (
              <SignerUploadSigners form={form} />
            )}
            <PayerRadioGroup form={form} organization={organization} user={data!.viewer.user!} />
          </CreateEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
