import { FormattedMessage } from "react-intl";

import { SummaryDetailWrapper } from "common/details/summary";

import Styles from "./transaction_signature.module.scss";

type Props = {
  transaction: { messageSignature: null | string };
};

export default function TransactionSignature({ transaction }: Props) {
  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage id="c9e3b3e5-ae90-46a2-b3b7-f855f6ef142f" defaultMessage="Signature" />
      }
      definition={
        <span className={Styles.content}>
          {transaction.messageSignature || (
            <FormattedMessage
              id="169c1547-7d73-4d98-a1f9-20a1430fd8bf"
              defaultMessage="No signature provided"
            />
          )}
        </span>
      }
    />
  );
}
