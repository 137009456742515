import { FormattedMessage } from "react-intl";
import { formatPhoneNumber } from "react-phone-number-input";

import { ProofRequirementMfa } from "graphql_globals";
import { Container, Row } from "common/core/responsive";
import { Substyle } from "common/core/typography";

import type { IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners as TransactionCustomer } from "../index.query.graphql";
import { Col, SensitiveValue } from "..";

export default function SignerProfile(props: { customer: TransactionCustomer }) {
  const {
    customer: { email, phone, proofRequirement, userId },
  } = props;

  const ial2Proof = proofRequirement?.ca?.selfie;
  const smsProof = proofRequirement?.mfa?.type === ProofRequirementMfa.SMS;
  const kbaProof = proofRequirement?.kba;
  const formattedPhoneNumber = phone && formatPhoneNumber(`+${phone.countryCode}${phone.number}`);

  return (
    <dl>
      <Container fluid noSpacing>
        <Row>
          <Col>
            <dt>
              <Substyle textColor="subtle" size="small">
                <FormattedMessage
                  id="ab937f64-9205-4b1d-81a0-5ec08192159a"
                  defaultMessage="User ID"
                />
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">{userId}</Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle textColor="subtle" size="small">
                <FormattedMessage
                  id="54827a8f-e25b-4d5c-9543-893dd48f45bc"
                  defaultMessage="Email address"
                />
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                <SensitiveValue
                  value={email}
                  obfuscatedValue={`${email?.substring(0, 4)}****${email?.substring(email.indexOf("@"), email.length)}`}
                />
              </Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle textColor="subtle" size="small">
                <FormattedMessage
                  id="3b4b8a3b-943e-45d3-b1e3-7b2c94f20f6e"
                  defaultMessage="Phone number"
                />
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                {formattedPhoneNumber && (
                  <SensitiveValue
                    obfuscatedValue={formattedPhoneNumber.replace(/\d(?=(?:\D*\d){4})/g, "*")}
                    value={formattedPhoneNumber}
                  />
                )}
              </Substyle>
            </dd>
          </Col>
          {!proofRequirement && (
            <Col>
              <dt>
                <Substyle textColor="subtle" size="small">
                  <FormattedMessage
                    id="3e85920e-738d-4310-bce7-d2038f7fdd7b"
                    defaultMessage="KBA Required"
                  />
                </Substyle>
              </dt>
              <dd>
                <Substyle size="defaultSize">
                  {props.customer.requiresKba ? (
                    <FormattedMessage
                      id="a2f9b405-ec0d-40c3-bd2b-b42027c517f4"
                      defaultMessage="Yes"
                    />
                  ) : (
                    <FormattedMessage
                      id="609dae82-6f95-4e21-b098-76d351024528"
                      defaultMessage="No"
                    />
                  )}
                </Substyle>
              </dd>
            </Col>
          )}
          {proofRequirement && (
            <Col>
              <dt>
                <Substyle textColor="subtle" size="small">
                  <FormattedMessage
                    id="4e8515d6-b4b8-450b-8844-5396a69bff22"
                    defaultMessage="Required Verification Method(s)"
                  />
                </Substyle>
              </dt>
              <dd>
                {ial2Proof && (
                  <Substyle display="block" size="defaultSize">
                    <FormattedMessage
                      id="24df835f-5fca-463c-8c2e-40dc2d28c60a"
                      defaultMessage="IAL-2 compliant identity verification"
                    />
                  </Substyle>
                )}
                {smsProof && (
                  <Substyle display="block" size="defaultSize">
                    <FormattedMessage
                      id="3ec75d63-fd4a-41f2-8a48-8dc53bb04039"
                      defaultMessage="SMS authentication"
                    />
                  </Substyle>
                )}
                {kbaProof && (
                  <Substyle display="block" size="defaultSize">
                    <FormattedMessage
                      id="2b0fddd3-6ea5-44d2-be82-38548c7a07ea"
                      defaultMessage="Knowledge-based authentication (KBA)"
                    />
                  </Substyle>
                )}
              </dd>
            </Col>
          )}
        </Row>
      </Container>
    </dl>
  );
}
