import { OrganizationTypeEnum, Feature } from "graphql_globals";

function trialAvailable(organization: { scheduledTiers: { isTrial: boolean }[] }): boolean {
  return !organization.scheduledTiers.some((tier) => tier.isTrial === true);
}

function transactionFilteringEnabled(organization: { featureList: Feature[] }): boolean {
  return organization.featureList.includes(Feature.TRANSACTION_FILTERING);
}

export function activeTier(organization: { activeTier: { name: string } }): string {
  return organization.activeTier.name;
}

export function showUpgrade(organization: {
  organizationType: OrganizationTypeEnum;
  scheduledTiers: { isTrial: boolean }[];
  featureList: Feature[];
}): boolean {
  return (
    isBusiness(organization) &&
    !(trialAvailable(organization) || transactionFilteringEnabled(organization))
  );
}

function isBusiness(organization: { organizationType: OrganizationTypeEnum }): boolean {
  return organization.organizationType === OrganizationTypeEnum.BUSINESS;
}
