import { memo, useEffect, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import { SubmissionError } from "redux-form";

import { useDispatch } from "redux/util";
import { useQuery } from "util/graphql";
import { computeDefaultType } from "common/account/util";
import { signup, loginReset } from "redux/actions/authentication";
import APP from "constants/applications";
import AppSubdomains from "constants/app_subdomains";
import { ACCOUNT_TYPES } from "common/account/constants";
import GraphicContainer, {
  GraphicContainerContentFooter,
  GraphicContainerContentHeader,
} from "common/account/signup_v2/graphic_container";
import ProofCard from "common/account/login/proof/card";
import { redirectUrl } from "util/application_redirect";

import NotarySignupForm from "./form";
import BusinessNotarySignupQuery from "./index_query.graphql";

type SubmitCb = Exclude<ComponentProps<typeof NotarySignupForm>, undefined>["onCommit"];

function NotarySignup() {
  const { data } = useQuery(BusinessNotarySignupQuery);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(loginReset());
    },
    [],
  );

  const handleEmailSubmit: SubmitCb = ({
    firstName,
    lastName,
    email,
    password,
    businessName,
    state,
  }) => {
    const signupAction = signup({
      firstName,
      lastName,
      email,
      accountType: ACCOUNT_TYPES.NOTARY_MARKETPLACE,
      entry: computeDefaultType(APP.BUSINESS),
      password,
      businessName,
      state,
      tos: true,
      redirectUrl: redirectUrl(AppSubdomains.customer, "/settings/notary/onboarding"),
    });
    return dispatch(signupAction).catch((err: string) => {
      throw new SubmissionError({ email: err });
    });
  };

  const signupUsStates = data?.viewer.icNotarySignupStates;

  return (
    <GraphicContainer>
      <ProofCard
        header={
          <GraphicContainerContentHeader
            title={
              <FormattedMessage
                id="fd59c9d4-f092-451a-9a5e-4c1fd0453b3e"
                defaultMessage="Create your Proof account and join the Notarize Network"
              />
            }
          />
        }
        body={<NotarySignupForm onCommit={handleEmailSubmit} signupUsStates={signupUsStates} />}
        footer={<GraphicContainerContentFooter isNotary />}
      />
    </GraphicContainer>
  );
}

export default memo(NotarySignup);
