import { useRef, useEffect, useState, memo, type ComponentProps, type ReactNode } from "react";
import { v4 } from "uuid";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import classnames from "classnames";
// eslint-disable-next-line no-restricted-imports -- type only export
import type PSPDFKit from "pspdfkit";

import { constrainSize } from "util/number";
import type { PageTypes } from "graphql_globals";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import Svg from "common/core/svg";
import docIconImage from "assets/images/doc.svg";
import TooltipOverlay from "common/core/tooltip/overlay";
import { useId } from "util/html";
import { Substyle } from "common/core/typography";
import LoadingIndicator from "common/core/loading_indicator";

import { usePDFContext, type LoadOptions } from ".";
import Styles from "./util.module.scss";
import { constrainDesignationSize } from "./designation";

export type KitModule = typeof PSPDFKit;
export type KitInstance = InstanceType<KitModule["Instance"]>;
export type KitAnnotation = InstanceType<KitModule["Annotations"]["Annotation"]>;
export type KitShapeAnnotation = InstanceType<KitModule["Annotations"]["ShapeAnnotation"]>;
export type KitAnnotationMixin = Pick<KitAnnotation, "id" | "pageIndex" | "boundingBox">;
export type KitAnnotationWillChangeEvent = {
  annotation: KitAnnotation | undefined;
  reason: string;
};
export type PageInformation = {
  getBasisPageIndex: (pageType: PageTypes) => number;
  getNormalizedPageInfo: (denormalizedIndex: number) => {
    pageType: PageTypes;
    normalizedIndex: number;
  };
};
export type UserUpdateDecorationEvent =
  | { type: "resize"; newHeight: number; newWidth: number }
  | { type: "edittext"; newText: string; newWidth: number }
  | { type: "move"; newX: number; newY: number };
type HTMLButtonProps = ComponentProps<"button">;
type BaseProps = {
  stableMainPdfUrl: LoadOptions["mainPdfUrl"];
  className?: string;
  stableAppendedPdfs?: LoadOptions["appendedPdfs"];
  onPagePress?: LoadOptions["onPagePress"];
  onPageChange?: LoadOptions["onPageChange"];
  onSelectedAnnotationOrDesignationChange?: LoadOptions["onSelectedAnnotationOrDesignationChange"];
  stableRenderPageSplits?: LoadOptions["renderPageSplits"];
  onPermissionError?: LoadOptions["onPermissionError"];
  readOnly?: LoadOptions["readOnly"];
  initialLoadPage?: number;
  onAnnotationWillChange?: (event: KitAnnotationWillChangeEvent) => void;
  replacingDocument?: boolean;
};

const MESSAGES = defineMessages({
  togglePageNav: {
    id: "ddb885b6-8469-4e66-bbc4-280a72541c25",
    defaultMessage: "Page navigation",
  },
});

export function getPspPageInfo(instance: KitInstance, pspPageIndex: number) {
  const pageInfo = instance.pageInfoForIndex(pspPageIndex);
  if (!pageInfo) {
    throw new Error(`Attempted to draw non-existent page index ${pspPageIndex}`);
  }
  return pageInfo;
}

function constrainKitAnnotationSize(instance: KitInstance, kitAnnotation: KitAnnotation) {
  const { pageIndex, boundingBox } = kitAnnotation;
  const pageInfo = getPspPageInfo(instance, pageIndex);
  return (
    constrainDesignationSize(kitAnnotation) ||
    constrainSize({
      width: boundingBox.width,
      height: boundingBox.height,
      maxWidth: pageInfo.width - 10,
      maxHeight: pageInfo.height - 10,
    })
  );
}

export function makeResizeEvent(instance: KitInstance, kitAnnotation: KitAnnotation) {
  const constrained = constrainKitAnnotationSize(instance, kitAnnotation);
  return { type: "resize" as const, newWidth: constrained.width, newHeight: constrained.height };
}

export function makeEditTextEvent({ boundingBox, text }: KitAnnotation) {
  const newText = text.value.replace(/(\n|\r)/g, "").trim();
  return newText
    ? { type: "edittext" as const, newText, newWidth: boundingBox.width }
    : { type: "delete" as const };
}

export function makeMoveEvent(instance: KitInstance, { pageIndex, boundingBox }: KitAnnotation) {
  const pageInfo = getPspPageInfo(instance, pageIndex);
  return {
    type: "move" as const,
    newX: boundingBox.left,
    newY: pageInfo.height - boundingBox.top,
  };
}

export function makePspId(baseNotarizeId: string): string {
  // We add a v4 just to be safe in case theres some reason that an annotation gets added twice.
  // Real Notarize ID always goes first so `getNotarizeIdFromPspId` works.
  return `${baseNotarizeId}|${v4()}|`;
}

export function getNotarizeIdFromPspId(pspId: string): string {
  return pspId.substring(0, pspId.indexOf("|"));
}

export function encodeMoreAttributesInPspId(
  pspId: string,
  ...attributes: (false | string)[]
): string {
  // Just like makePspId, we make sure to bookmark the data so that you can select like [data-automation-id*="|data|"].
  return attributes.reduce<string>((id, attribute) => {
    if (attribute) {
      id = `${id}${attribute}|`;
    }
    return id;
  }, pspId);
}

/**
 * When we update an immutable js object (what PSPDFKit uses internally), we only want to call .set()
 * if and only if the actual value of the property has changed. This allows us to skip creating new objects
 * if there are no changes, and this allows us to use memoization techniques like `distinctUntilChanged`
 * to avoid unnesscary interactions with PSPDFKit update API.
 */
export function updateImmutableAnnotationEfficently<
  Obj extends
    | KitAnnotation
    | KitAnnotation["boundingBox"]
    | NonNullable<KitShapeAnnotation["fillColor"]>,
>(immutableRecord: Obj, keyValuePairs: Partial<Obj>): Obj {
  const diffs = (Object.entries(keyValuePairs) as [keyof Obj, unknown][]).filter(
    ([key, newValue]) => immutableRecord[key] !== newValue,
  );
  return diffs.length
    ? (immutableRecord.withMutations((mutableRecord: unknown) => {
        for (const [key, newValue] of diffs) {
          // @ts-expect-error -- typescript cant match the keys here with the Obj con
          mutableRecord.set(key, newValue);
        }
      }) as Obj)
    : immutableRecord;
}

function useStableHandler<Handler>(handler: Handler) {
  // so don't need to reload if handler changes
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  });
  return handlerRef;
}

/** Internal use for pspdf module to draw a pdf container */
export function PDFBase({
  stableMainPdfUrl,
  className,
  stableAppendedPdfs,
  onPagePress,
  onPageChange,
  onSelectedAnnotationOrDesignationChange,
  stableRenderPageSplits,
  initialLoadPage,
  readOnly,
  onPermissionError,
  onAnnotationWillChange,
  replacingDocument,
}: BaseProps) {
  const pagePressRef = useStableHandler(onPagePress);
  const pageChangeRef = useStableHandler(onPageChange);
  const selectedAnnotationOrDesignationChangeRef = useStableHandler(
    onSelectedAnnotationOrDesignationChange,
  );
  const elemRef = useRef<null | HTMLDivElement>(null);
  const { loadInto } = usePDFContext();
  const [hasPermissionError, setHasPermissionError] = useState(false);

  useEffect(() => {
    setHasPermissionError(false);
    return loadInto({
      mainPdfUrl: stableMainPdfUrl,
      container: elemRef.current!,
      initialLoadPage,
      readOnly,
      appendedPdfs: stableAppendedPdfs,
      onPermissionError: onPermissionError || (() => setHasPermissionError(true)),
      onPagePress: (clickInformation) => pagePressRef.current?.(clickInformation),
      onPageChange: (pageInfo) => pageChangeRef.current?.(pageInfo),
      onSelectedAnnotationOrDesignationChange: (annotationOrDesignationId) => {
        return selectedAnnotationOrDesignationChangeRef.current?.(annotationOrDesignationId);
      },
      renderPageSplits: stableRenderPageSplits,
      onAnnotationWillChange,
    });
  }, [loadInto, stableMainPdfUrl, stableAppendedPdfs, stableRenderPageSplits, onPermissionError]);

  return (
    <>
      {hasPermissionError && (
        <div className={Styles.defaultPermissionError}>
          <FormattedMessage
            id="f5f73e01-3191-48ad-8a45-40cf3f863581"
            defaultMessage="Error loading document; your temporary access to the document may have expired. Try reloading the page."
          />
        </div>
      )}
      <div
        className={classnames(className || Styles.pdfContainer, SENSITIVE_CLASS)}
        ref={elemRef}
      />
      {replacingDocument && (
        <div className={Styles.replaceDocLoading}>
          <LoadingIndicator positionRelative />
          <Substyle textStyle="subtitleSmall" className={Styles.replaceDocLoadingText}>
            <FormattedMessage
              id="b448de6f-f5c3-4191-a575-6cfc86c96813"
              defaultMessage="Replacing document..."
            />
          </Substyle>
        </div>
      )}
    </>
  );
}

export function PDFControlButton({
  active,
  overrideTooltip,
  ...restProps
}: Omit<HTMLButtonProps, "aria-label" | "aria-pressed" | "onClick" | "className" | "type"> & {
  onClick: NonNullable<HTMLButtonProps["onClick"]>;
  "aria-label": string;
  "aria-pressed"?: boolean;
  overrideTooltip?: string;
  active?: boolean;
}) {
  const tooltipId = useId();
  return (
    <div className={Styles.tooltipContainer}>
      <button
        {...restProps}
        type="button"
        className={classnames(Styles.pdfControl, active && Styles.active)}
        aria-describedby={overrideTooltip ? tooltipId : undefined}
      />
      {restProps["aria-label"] && (
        <TooltipOverlay
          id={overrideTooltip ? tooltipId : undefined}
          size="mini"
          trigger="hover"
          placement="left"
          aria-hidden={overrideTooltip ? "false" : "true"}
        >
          {overrideTooltip ? overrideTooltip : restProps["aria-label"]}
        </TooltipOverlay>
      )}
    </div>
  );
}

function PageNavigationToggleButton() {
  const { useTogglePageNavigation } = usePDFContext();
  const { toggleState, toggle } = useTogglePageNavigation();
  const intl = useIntl();
  const isButtonActive = toggleState === "open";
  return toggleState === "unknown" ? null : (
    <PDFControlButton
      onClick={toggle}
      active={isButtonActive}
      aria-label={intl.formatMessage(MESSAGES.togglePageNav)}
      aria-pressed={isButtonActive}
    >
      <Svg className={Styles.svg} src={docIconImage} aria-hidden="true" alt="" />
    </PDFControlButton>
  );
}

function MobilePageNavigationOption({ children }: { children: ReactNode }) {
  const { useTogglePageNavigation } = usePDFContext();
  const { toggleState, toggle } = useTogglePageNavigation();
  const intl = useIntl();
  const isButtonActive = toggleState === "open";
  return toggleState === "unknown" ? null : (
    <div
      onClick={toggle}
      aria-label={intl.formatMessage(MESSAGES.togglePageNav)}
      aria-pressed={isButtonActive}
    >
      {children}
    </div>
  );
}

const MemoizedPageNavigationToggleButton = memo(PageNavigationToggleButton);
export { MemoizedPageNavigationToggleButton as PageNavigationToggleButton };
const MemoizedMobilePageNavigationOption = memo(MobilePageNavigationOption);
export { MemoizedMobilePageNavigationOption as MobilePageNavigationOption };

/** Returns true if element is active, focused PSPDFKit text annotation */
export function userIsInteractingWithTextAnnotation(element: Element | null | undefined): boolean {
  // PSPDFKit uses contenteditable elements for text annotations.
  return element?.getAttribute("contenteditable") === "true";
}
