import { type UsState, makeFontDesc } from ".";

const CENTER_TEXT = 495;
export const FULL_SEAL_WIDTH = 700;

type WrapTextParams = {
  context: CanvasRenderingContext2D;
  text: string;
  x: number;
  y: number;
  maxWidth: number;
  lineHeight: number;
};

type TextParams = {
  context: CanvasRenderingContext2D;
  usState: UsState;
  isAttorney: boolean | null;
};

type DrawTextParams = {
  context: CanvasRenderingContext2D;
  text: string;
  textAlign?: CanvasTextAlign;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
  maxWidth?: number;
  lineHeight?: number;
};

export function wrapText({ context, text, x, y, maxWidth, lineHeight }: WrapTextParams) {
  let line = "";
  for (const word of text.split(" ")) {
    const testLine = `${line}${word} `;
    const { width } = context.measureText(testLine);
    if (width > maxWidth && line) {
      context.fillText(line.trim(), x, y - 10);
      line = `${word} `;
      y += lineHeight - 10;
    } else {
      line = testLine;
    }
  }
  context.fillText(line.trim(), x, y);
}

function drawText({
  context,
  text,
  textAlign = "center",
  fontSize = 20,
  bold = false,
  x = CENTER_TEXT,
  y = 187,
  maxWidth = FULL_SEAL_WIDTH,
  lineHeight = 30,
}: DrawTextParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  wrapText({ context, text, x, y, maxWidth, lineHeight });
}

function drawIdahoText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: `NOTARY PUBLIC`,
    x: 360,
    y: 150,
    fontSize: 28,
  });
  return drawText({
    context,
    text: `STATE OF IDAHO`,
    x: 360,
    y: 190,
    fontSize: 28,
  });
}

function drawOhioText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: "Notary Public,",
    x: 475,
    y: 120,
    fontSize: 24,
  });
  return drawText({
    context,
    text: `State of Ohio`,
    x: 475,
    y: 150,
    fontSize: 24,
  });
}

function drawMinnesotaText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: `Notary Public`,
    x: 415,
    y: 120,
    fontSize: 21,
  });
  return drawText({
    context,
    text: `State of Minnesota`,
    x: 415,
    y: 150,
    fontSize: 21,
  });
}

function drawColoradoText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: "NOTARY PUBLIC",
    x: 360,
    y: 100,
    fontSize: 28,
  });
  return drawText({
    context,
    text: "STATE OF COLORADO",
    x: 360,
    y: 150,
    fontSize: 28,
  });
}

function drawAlaskaText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: "Notary Public",
    x: 360,
    y: 145,
    fontSize: 28,
  });
  drawText({
    context,
    text: "State of Alaska",
    x: 360,
    y: 175,
    fontSize: 28,
  });
}

function drawKansasText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: "NOTARY PUBLIC",
    x: 360,
    y: 145,
    fontSize: 34,
  });
  return drawText({
    context,
    text: "STATE OF KANSAS",
    x: 360,
    y: 190,
    fontSize: 34,
  });
}

function drawWestVirginiaText(context: CanvasRenderingContext2D) {
  drawText({
    context,
    text: "OFFICIAL SEAL",
    x: 460,
    y: 65,
    fontSize: 18,
  });
  return drawText({
    context,
    text: "ELECTRONIC NOTARY PUBLIC",
    x: 460,
    y: 115,
    fontSize: 18,
  });
}

function drawWisconsinText(context: CanvasRenderingContext2D, isAttorney: boolean | null) {
  const moveDownForAttorney = isAttorney ? 30 : 0;
  drawText({
    context,
    text: `Notary Public`,
    x: 360,
    y: 120 + moveDownForAttorney,
    fontSize: 34,
  });
  return drawText({
    context,
    text: `State of Wisconsin`,
    x: 360,
    y: 170 + moveDownForAttorney,
    fontSize: 34,
  });
}

export function placeText({ context, usState, isAttorney }: TextParams) {
  switch (usState) {
    case "ID":
      return drawIdahoText(context);
    case "MN":
      return drawMinnesotaText(context);
    case "MO":
      return drawText({
        context,
        text: "Electronic Notary Public - Notary Seal",
        x: 360,
        y: 100,
        fontSize: 28,
      });
    case "OH":
      return drawOhioText(context);
    case "PA":
      return drawText({
        context,
        text: "Commonwealth of Pennsylvania - Notary Seal",
        x: 360,
        y: 60,
        fontSize: 26,
      });
    case "CO":
      return drawColoradoText(context);
    case "AK":
      return drawAlaskaText(context);
    case "NY":
      return drawText({
        context,
        text: "NOTARY PUBLIC, STATE OF NEW YORK",
        x: 360,
        y: 105,
        fontSize: 32,
      });
    case "NJ":
      return drawText({
        context,
        text: "NOTARY PUBLIC",
        x: 360,
        y: 120,
        fontSize: 34,
      });
    case "WI":
      return drawWisconsinText(context, isAttorney);
    case "NH":
      return drawText({
        context,
        text: "NOTARY PUBLIC, STATE OF NEW HAMPSHIRE",
        x: 360,
        y: 140,
        fontSize: 30,
      });
    case "AR":
      return drawText({
        context,
        text: "ELECTRONIC NOTARY PUBLIC",
        x: 50,
        y: 135,
        fontSize: 34,
        textAlign: "left",
      });
    case "WV":
      return drawWestVirginiaText(context);
    case "KS":
      return drawKansasText(context);
    case "AZ":
    case "MI":
    case "MT":
    case "TX":
    case "VA":
    case "FL":
    case "NV":
    case "IN":
    case "IA":
    case "KY":
    case "MD":
    case "ND":
    case "NE":
    case "OK":
    case "WA":
    case "TN":
      return;
    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
