import { useEffect, useState } from "react";

import Button from "common/core/button";
import Icon from "common/core/icon";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";

import { getProfiles } from "./requests";

export type Profile = {
  id: number;
  name: string;
  default: boolean;
};

type Props = {
  onSelectProfile: (profileId: number) => void;
  initialSelectedProfile: number | null;
};

export default function ProfileSelector({ onSelectProfile, initialSelectedProfile }: Props) {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfileName, setSelectedProfileName] = useState<string>("Select Profile");

  const handleSelectProfile = (profile: Profile) => {
    setSelectedProfileName(profile.name);
    onSelectProfile(profile.id);
  };

  useEffect(() => {
    getProfiles().then((data: Profile[]) => {
      setProfiles(data);
      const profile = initialSelectedProfile
        ? data.find((p: Profile) => p.id === initialSelectedProfile)
        : data.find((p: Profile) => p.default);

      if (profile) {
        handleSelectProfile(profile);
      }
    });
  }, []);

  return (
    <PopoutMenu
      className="DotloopWizard--header--button"
      target={
        <Button
          automationId="select-profile-button"
          buttonColor="action"
          buttonSize="large"
          variant="primary"
        >
          {selectedProfileName}
          <Icon className="DotloopWizard--header--button--icon" name="caret-down" />
        </Button>
      }
      placement="bottomRight"
    >
      {({ close }) =>
        profiles.map((profile: Profile) => (
          <PopoutMenuItem
            key={profile.id}
            data-automation-id={`profile-${profile.id}`}
            onClick={() => {
              close();
              handleSelectProfile(profile);
            }}
          >
            {profile.name}
          </PopoutMenuItem>
        ))
      }
    </PopoutMenu>
  );
}
